import { Fragment, useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { NewPrompt } from "../types/NewPrompt";
import { Section } from "../types/Section";
import { Tool } from "../types/Tool";
import { PromptForm, PromptFormData } from '../forms/PromptForm';

export const AddPromptModal = ({ 
    isOpen, 
    onClose, 
    onSubmit,
    initialSection,
    tools,
    isLoadingTools
}: { 
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (prompt: NewPrompt) => void;
    initialSection: Section;
    tools: Tool[];
    isLoadingTools: boolean;
}) => {
    // Track dark mode state
    const [isDarkMode, setIsDarkMode] = useState(false);
    
    // Check if dark mode is enabled
    useEffect(() => {
        const checkDarkMode = () => {
            const isDark = document.getElementById('root')?.classList.contains('dark') || false;
            setIsDarkMode(isDark);
        };
        
        checkDarkMode();
        
        // Set up an observer to detect changes to the dark mode
        const observer = new MutationObserver(checkDarkMode);
        const rootElement = document.getElementById('root');
        
        if (rootElement) {
            observer.observe(rootElement, { attributes: true, attributeFilter: ['class'] });
        }
        
        return () => observer.disconnect();
    }, [isOpen]);
    const [formData, setFormData] = useState<PromptFormData>({
        title: '',
        prompt: '',
        section: initialSection,
        labels: [],
        toolId: '',
        type: 'component'
    });

    useEffect(() => {
        if (isOpen) {
            setFormData(prev => ({
                ...prev,
                section: initialSection
            }));
        }
    }, [isOpen, initialSection]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
        setFormData({ title: '', prompt: '', section: initialSection, labels: [], toolId: '', type: 'component' });
        onClose();
    };



    if (!isOpen) return null;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel className={`w-full max-w-2xl transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all backdrop-blur-sm ring-1 ${isDarkMode ? 'bg-gray-800/95 ring-white/5 text-white' : 'bg-white/95 ring-black/5 text-gray-900'}`}>
                                <DialogTitle
                                    as="h3"
                                    className={`text-lg font-medium leading-6 mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                                >
                                    Create New Prompt
                                </DialogTitle>

                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <PromptForm
                                        formData={formData}
                                        onFormDataChange={setFormData}
                                        tools={tools}
                                        isLoadingTools={isLoadingTools}
                                    />

                                    <div className="mt-6 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className={`px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isDarkMode ? 'text-gray-300 hover:bg-gray-700 focus:ring-offset-gray-800' : 'text-gray-700 hover:bg-gray-50 focus:ring-offset-white'}`}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className={`px-4 py-2 text-sm font-medium text-white rounded-md transition-all duration-200 shadow-sm hover:shadow transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isDarkMode ? 'bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 focus:ring-offset-gray-800' : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 focus:ring-offset-white'}`}
                                        >
                                            Create Prompt
                                        </button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};