import ContentAccordion from "../../components/ContentAccordion";
import React, {useEffect, useState} from "react";
import DocumentationNav from "./DocumentationNav";
import PromptEngineeringCatLabel from "../../components/labels/PromptEngineeringCatLabel";
import BaseButton from "../../components/base/Button";
import '../../scss/Alert.scss';
import AlertBoxTimed, {AlertType} from "../../components/alerts/AlertBoxTimed";
import { formattingChatGPT } from "./strategyTemplates/formattingChatGPT";
import PromptExampleChat from "../../components/documentation/PromptExampleChat";

function FrameworkDocumentation() {
    const [targetTipId, setTargetTipId] = useState<string | null>(null);

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Framework";

        // Check if there's a hash in the URL that matches a tip
        const hash = window.location.hash;
        if (hash && !hash.includes("#handout-framework")) {
            // Remove the # from the hash
            const targetId = hash.substring(1);
            setTargetTipId(targetId);
            
            // Find the element and scroll to it
            const element = document.getElementById(targetId);
            if (element) {
                // Use a small delay to ensure the DOM is ready
                setTimeout(() => {
                    // Scroll to element with offset
                    const yOffset = -250; // Adjust this value to control how much space appears above the element
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }, 100);
            }
        }
    }, []);

    /*
    * Only show the handout version of the page.
    * */
    if (window.location.hash.includes("#handout-framework")) {
        sessionStorage.setItem('handout-framework', "true");
    }
    const [theLLM, setTheLLM] = useState("the LLM");
    const [successAlertLLMText, setSuccessAlertLLMText] = useState("");

    

    //const theLLM = "the LLM";
    const docLink = "/documentation/framework";

    interface ButtonChangeTextProps {
        toText: string,
        title: string
    }

    const ButtonChangeText: React.FC<ButtonChangeTextProps> = ({toText, title}) => {
        return (
            <button onClick={() => { setTheLLM(toText); setSuccessAlertLLMText(`The framework has been updated. It is now tailored specifically for ${toText}!`); }}
                    className={`text-sm text-center rounded-full hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 max-w-full px-6 py-2 bg-gradient-to-r  border border-[#0c66ee] dark:text-white mr-5 ${theLLM === toText ? 'bg-sky-700 text-white' : ''}`}>
                {title}
            </button>);
    }

    const contextTip = [
        {
            title: "Strategy: Place the context at the end of your prompt",
            hashID: "context-at-the-end-of-your-prompt",
            docLink: docLink,
            description:
                <>
                    <p>A straightforward method for crafting your prompt is to begin with the task at hand and then include the context at the end. Additionally, studies suggest that enclosing the context within triple quotes """ or hash marks ### leads to more effective results.</p>
                    <PromptExampleChat
                        title="Prompt with context on the end"
                        userPrompt='Summarize the text below &#123;acting role, format, register&#125; <br /> Text: """&#123;Your context&#125;"""'
                    />
                    
                    <p>An alternative method involves the utilization of XML. When referencing articles that are sourced from external platforms, a practical approach is to embed these articles within &lt;article&gt;&lt;/article&gt; tags. This technique not only structures the content effectively but also ensures a clear distinction between original and referenced material.</p>
                    <PromptExampleChat
                        title="Prompt with components"
                        userPrompt={`
                            &lt;article&gt;&#123;The article&#125;&lt;/article&gt; <br />
                            &lt;article&gt;&#123;The article&#125;&lt;/article&gt;
                        `}
                    />
                </>,
            fireIcon:true,
        },
        {
            title: "Strategy: Using images to enhance context",
            hashID: "context-prompting-picture",
            docLink: docLink,
            description: `
                <p>Multimodal AI models, which can process both text and images, offer a powerful way to enhance context through visual information. Instead of relying solely on textual descriptions, you can upload images to provide additional context for your prompts. This approach is particularly effective when:</p>
                <ul class="list-disc pl-6 mb-4">
                    <li>Describing complex visual elements or designs</li>
                    <li>Analyzing specific details in documents or diagrams</li>
                    <li>Providing real-world examples or references</li>
                    <li>Explaining technical concepts with visual aids</li>
                </ul>
                <p>For example, when planning dinner, you could take a picture of your refrigerator's contents and ask ${theLLM} to suggest recipes based on the available ingredients. This practical approach helps generate personalized meal ideas while avoiding food waste. Similarly, you could photograph your pantry or specific ingredients to get tailored cooking instructions or alternative recipe suggestions.</p>
                <p>To make the most of image-based context:</p>
                <ol class="list-decimal pl-6 mb-4">
                    <li>Ensure your images are clear and focused on the relevant details</li>
                    <li>Specify which aspects of the image ${theLLM} should pay attention to</li>
                    <li>Combine image context with clear textual instructions</li>
                </ol>
                <p>This multimodal approach often leads to more precise and contextually aware responses, as ${theLLM} can reference both visual and textual information in its analysis.</p>`,
            fireIcon: true,
        },
        {
            title: "Technique: Enhancing results with prompting techniques and examples",
            hashID: "context-prompting-techniques",
            docLink: docLink,
            description:
                "<p>Incorporating examples into your context can significantly influence the outcome of your efforts. It's important to give thoughtful consideration to this approach. Techniques like <a href=\"/documentation/prompt-techniques#zero-single-few-shot\" class=\"text-blue-500 text-bold\" title='Shot prompting techniques'>zero-shot, single-shot, or few-shot prompting</a> are essential for tailoring outputs to fit specific contexts. For instance, if your goal is to generate creative global ideas, zero-shot prompting can be particularly effective. Conversely, for obtaining detailed answers that build upon previously created content, few-shot prompting is the way to go. Learn more about these strategies in our blog post titled \"Predictions and Prompting Techniques.\"</p>",
            techniqueIcon:true,
        },
        {
            title: "Warning: Know the context window",
            hashID: "context-window",
            docLink: docLink,
            description:
                "<p class='mb-5'>The term \"context window\" refers to the \"current memory\" of a language model like GPT, indicating how much of our ongoing conversation it can remember. It represents the volume of text that the model can use when formulating responses. Essentially, it's the working memory of the model, crucial for keeping the dialogue or text generation coherent and relevant to the context. In other words, the context window measures the amount of information a language model can access and consider for its response. A token, which is the smallest unit of text the model processes, could be a word or punctuation mark. Therefore, it's important to always check the context window of the model you're using to ensure you don't exceed it in any steps of your framework. This context window encompasses both your input and the model's generated output.</p>" +
                "<p class='mb-10'><strong>Context windows (token limit):</strong></p>" +
                "<p class='mb-10 grid grid-cols-2'><span class=\"p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950\">GPT 4o: 128.000 tokens</span>" +
                "<span class=\"p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950\">GPT 4o mini: 128.000 tokens</span>" +
                "<span class=\"p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950\">GPT 4: 8.192 tokens</span>" +
                "<span class=\"p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950\">GPT 3.5: 4.096 tokens</span></p>" +
                "<p class='mb-10'><strong>Resources:</strong></p>" +
                '<p class="mb-10"><a href="https://platform.openai.com/docs/models/overview" target="_blank" class="max-w-full px-8 py-4 ml-4 rounded-md border border-[#0c66ee] dark:text-white hover:bg-[#0c66ee] hover:text-white">OpenAI Models Overview</a></p>',
            warningIcon:true,
        },
    ];

    const registerTip = [
        {
            title: "Strategy: Learn your own register",
            hashID: "register-learn-own-register",
            docLink: docLink,
            description:
                "<p>Beyond sharing your original content and instructing "+ theLLM +" to replicate your writing style, a beneficial tactic is to submit your work to "+ theLLM +" and seek its evaluation of your unique style. Following this, you have the option to either share this analysis as your standard writing register or, if it becomes your default style, incorporate it into your Custom Instructions.</p>",
            fireIcon:true,
        },
        {
            title: "Strategy: Find the right register",
            hashID: "find-register",
            docLink: docLink,
            description:
                <>
                    <p>If you're unsure about which register best suits your task, consider seeking suggestions from the model. You can request general advice, as illustrated in the next example, or specify particular requirements if you have something specific in mind.</p>
                    <PromptExampleChat 
                        title="Example Prompt"
                        userPrompt={`Give me 20 registers I could use to write a LinkedIn post`}
                        showActions={true}
                    />
                </>,
            fireIcon:true,
        },
        {
            title: "Strategy: Create register (writing) style guides",
            hashID: "writing-style-guides",
            docLink: docLink,
            description:
                "<p>Creating comprehensive writing style guides allows you to maintain consistent communication across all outputs. These guides can define your or your organization's voice, tone, terminology, and formatting preferences. By incorporating these style guides into your prompts, you ensure that " + theLLM + " generates content that aligns perfectly with your brand's identity.</p>" +
                "<p>A style guide can include:</p>" +
                "<ul class='list-disc pl-8 mb-4'>" +
                "<li>Voice and tone characteristics</li>" +
                "<li>Preferred terminology and phrases</li>" +
                "<li>Grammar and punctuation preferences</li>" +
                "<li>Formatting conventions</li>" +
                "<li>Brand-specific guidelines</li>" +
                "<li>and much more...</li>" +
                "</ul>" +
                "<p>By storing your style guide as a \"Register Component\" (see Architecture section), you can easily share it across your organization, ensuring everyone maintains a consistent writing style in their prompts. This centralized approach makes it simple to update and distribute style guidelines while maintaining uniformity in all AI-generated content.</p>",
            fireIcon: true,
        },
    ];

    const actingRoleTip = [
        {
            title: "Warning: Formulate the acting role carefully",
            hashID: "a-formulate-acting-role-carefully",
            docLink: docLink,
            description:
                "<p>When you ask "+ theLLM +" to \"Write me a LinkedIn post about this new article to promote it, acting as a LinkedIn expert with 10 years of experience,\" it becomes unclear if the task is solely to write the post or to also post it on LinkedIn, adopting the persona of a LinkedIn expert. The previous instruction caused "+ theLLM +" to adopt the identity of a LinkedIn expert who actively posts the content, as opposed to merely drafting it for your use. This might lead to responses like: \"As a LinkedIn expert, I am thrilled to share my latest article...\". To avoid such confusion, it's important to articulate your prompts with clarity and define your objectives explicitly (G - Goal-driven).</p>" +
                "<p>Hence, a more effective approach would be to direct "+ theLLM +" to assume the role of a LinkedIn expert for the purpose of creating a LinkedIn post for you, which you can then publish yourself.</p>",
            warningIcon: true,
        },
        {
            title: "Tip: Make your prompts better by using personas",
            hashID: "a-using-a-persona",
            docLink: docLink,
            description: <>
                <p>Using a persona (an imaginary character) in your prompts makes conversations with AI more engaging and creative. A persona is like a character with specific traits, experiences, and viewpoints. This helps you explore topics in new and interesting ways.</p>
                <p>When you use personas, your conversations become more natural and meaningful. You can change personas based on what you need, which helps the AI give you better and more varied responses.</p>
                <p>Teams can create and share personas to use them together. For example, when hiring new people, you can create a persona of your ideal employee. You can then use this persona to write better job descriptions and think of good interview questions. This makes the whole process easier and more effective.</p>

                <PromptExampleChat 
                    title="Using Persona Prompts"
                    userPrompt={`Act as the &lt;persona&gt; below.<br /><br />
                &lt;persona&gt;<br />
                &nbsp; &nbsp; YOUR PERSONA INPUT<br />
                &lt;/persona&gt;
                        `}
                />
                </>,
            alertIcon: true,
        },
        {
            title: "Tip: How to create and improve your personas",
            hashID: "a-creating-personas",
            docLink: docLink,
            description: `
                <p>Creating a useful persona is simple. Start by asking an AI tool to generate a basic persona based on a few key details you want. For example, you might ask for "a senior software developer with 10 years of experience in healthcare technology."</p>
                <p>The real power comes from improving your personas over time. As you learn new information, add it to your persona. For instance, if you're working with a client, you can add their feedback, preferences, and needs to your persona of them. This helps the AI better understand and match their style in future conversations.</p>
                <p>Think of personas as living documents that grow more helpful as you add more details. This approach is also your first step toward working with AI agents - more advanced AI tools that can handle complex tasks independently.</p>`,
            alertIcon: true,
        },
        {
            title: "Strategy: Custom acting roles and Actions (ChatGPT)",
            hashID: "a-custom-acting-roles",
            docLink: docLink,
            description:
                <>
                    <p>When working with interactive prompts, you have the option to create your own acting roles and refer to them throughout the conversation. For instance, in the Custom Instructions ChatGPT feature, you can define your own Acting Role and instruct ChatGPT accordingly. For example, if you're acting as yourself (let's say, using my name: Jeroen Egelmeers), you can predefine how ChatGPT should respond. This could include having an informal tone or making other adjustments to cater to your specific needs. By doing so, you can prompt more efficiently and effectively with the desired outcome.</p>
                    <p>By leveraging this capability, you can adapt ChatGPT to better suit your specific requirements, thereby optimizing your interactive prompts and making the most of this feature.</p>
                    <p>Additionally, with ChatGPT's "Custom Instructions" feature, you can predefine these roles. This means you set up the instructions just once, and they're always ready for use. No repeated configurations — they remain available indefinitely, streamlining your interactions with the platform.</p>
                    <p>You can further enhance the efficiency of using "custom acting roles" by writing functions/actions for quicker activation. For instance, you could include in your Custom Instructions that beginning your prompt with "!RWL" instructs ChatGPT to create a LinkedIn post using the context you provide following the action marker (e.g., "!RWL &#123;your context&#125;"). It's important to also specify in these instructions to note when this "action" is activated, ensuring clarity regardless of whether the action call precedes the prompt.</p>
                    <PromptExampleChat 
                        title="Custom Instructions"
                        systemPrompt={`If I start my prompt with "!RWL", then create a LinkedIn post for me. Act as a [Acting role], use [register], and output in this [format] ... Mention on the top of the output that the !RWL custom instruction was triggered.`}
                        userPrompt={`!RWL [your context]`}
                    />
                    <p>Please be aware: Act carefully when using Custom Instructions in ChatGPT. They will be placed before your actual instruction and thus can take up much of your <a href="/documentation/framework#context-window" title="Context Window" className="text-blue-500 font-bold\">context window</a> if not used effectively.</p>
                    <p>Note: Be cautious when using Custom Instructions without action markers (such as ! in front of them) to ensure they don't get triggered unintentionally throughout a prompt when using specific words instead of intentionally triggering the action.</p>
              
                  </>,
            fireIcon: true,
        },
    ];


    const formatTip = [
        {
            title: "Strategy: Understanding your audience - tailoring responses",
            hashID: "f-understand-your-audience",
            docLink: docLink,
            description:
                "<p>By explicitly identifying your target audience, you enable "+ theLLM +" to optimize its responses more effectively. This approach ensures that the content, tone, and format of the output are specifically aligned with the audience's preferences and expectations, significantly enhancing the relevance and effectiveness of the communication.</p>",
            fireIcon: true,
        },
        {
            title: "Tip: Understanding parameters",
            hashID: "f-understand-parameters",
            docLink: docLink,
            description:
                "<p>By understanding the parameters, you can tailor your prompt to ensure the model generates responses that align with your specific needs. This can lead to more relevant and accurate results.</p>" +
                `<ul class="list-disc pl-8 mb-4">
                    <li><strong>Temperature</strong>: Controls the randomness of the generated text. Higher values (e.g., 0.8) make the output more creative and diverse, while lower values (e.g., 0.2) make it more focused and structured.</li>
                    <li><strong>Response format</strong>: Determines the format of the generated response. Common formats include text and JSON.</li>
                    <li><strong>Max tokens</strong>: Sets the maximum number of tokens (words) in the generated response. This can help control the length and complexity of the output.</li>
                    <li><strong>Top p</strong>: Controls the diversity of the generated tokens. Higher values (e.g., 0.9) make the output more diverse, while lower values (e.g., 0.2) make it more focused.</li>
                    <li><strong>Frequency penalty</strong>: Penalizes tokens that appear more frequently in the input text. This can help the model generate more creative and unique responses.</li>
                    <li><strong>Presence penalty</strong>: Penalizes tokens that appear in the input text. This can help the model generate more diverse and unique responses.</li>
                </ul>
                <p>In the OpenAI playground you can find all parameters: <a href=\"https://platform.openai.com/playground\" target=\"_blank\" class=\"text-blue-500 font-bold\">https://platform.openai.com/playground</a>.</p>
                `,
            fireIcon: true,
        },
        {
            title: "Strategy: Example-driven prompting",
            hashID: "f-example-driven-prompting",
            docLink: docLink,
            description:
                "<p>Example-driven prompting lets you provide one or more examples (single-shot or few-shot prompting) to guide the model in generating responses. By giving sample responses, you can influence the model to mimic the style, structure, or tone you set. The more examples you provide (few-shot prompting), the more refined and aligned the output will be with your desired format.</p>" +
                "<p>Be aware that when using example-driven prompting, any specific phrases or structures in your example text may be reused in the output. This can be beneficial for maintaining consistency, but it may also introduce unintended focus. For instance, if \"music\" is frequently mentioned in your examples, the generated output may reflect that focus. When you use few-shot prompting, you’ll generally see more variety and creativity in the responses.</p>" +
                "<p>Note that if your examples do not follow the same exact structure, the model will have more freedom with the output layout, leading to creative formatting variations. If you need the model to strictly follow a particular format while also mirroring your style and tone, combining template-driven prompting with example-driven prompting can be an effective alternative.</p>",
            fireIcon: true,
        },
        {
            title: "Strategy: Template-driven prompting",
            hashID: "f-template-driven-prompting",
            docLink: docLink,
            description:
                <>
                <p>If you have a particular format in mind, using template-driven prompting is highly effective. Simply provide the exact format you want to the model. This allows it to utilize the given structure and fill in the necessary details.</p>

                <PromptExampleChat
                    title="Template-driven prompting"
                    userPrompt={` .... <br />
                    Use this format for my LinkedIn post:<br /> 
                    &#123;strong hook&#125;<br /><br /> 
                    &#123;short description&#125;<br /><br /> 
                    &#123;key takeaways with emoticons as bullet points&#125;<br /> <br />
                    &#123;call to action&#125;<br /> <br />
                    &#123;hashtags&#125;<br />... `}
                    showActions={true}
                />
                
                <p>This way, {theLLM} will replace the sections between &#123; &#125; with the generated output.</p>
                </>,
            fireIcon: true,
        },
        {
            title: "Strategy: Formatting in ChatGPT",
            hashID: "f-in-chatgpt",
            docLink: docLink,
            description: formattingChatGPT,
            fireIcon: true,
        }
    ];

    const taskTips = [
        {
            title: "Strategy: Ask the model to ask questions",
            hashID: "t-ask-to-ask-questions",
            docLink: docLink,
            description:
                "<p>Do you find yourself uncertain about the context or lacking sufficient details? Studies indicate that prompting models to inquire for additional information is effective. In such instances, "+ theLLM +" will pose questions to you for clarification. Once you respond, it will then proceed to generate the output based on your input.</p>",
            fireIcon:true,
        },
        {
            title: "Strategy: One word can make the difference",
            hashID: "t-using-the-right-words",
            docLink: docLink,
            description: `
            <p>Many people struggle with choosing the right word for their tasks. Words like "review," "rewrite," and "revise" may seem similar, just as "craft," "make," and "generate" do. However, each word prompts the Large Language Model (LLM) to return a different type of output. To achieve your desired results, it's crucial to select the appropriate word for the task at hand. If you're unsure, ask for the definition of a word or consult the LLM on the best word to use to accomplish your goal. Being goal-driven (G) in your approach will lead to more effective and accurate outcomes.</p>
            `,
            fireIcon: true,
        },
        {
            title: "Technique: Emotional prompting",
            hashID: "t-emotional-prompting",
            docLink: docLink,
            description:
                `<p class="mb-10">Large language models are potentially capable of comprehending and reacting to emotional signals. 
            Studies have shown that when emotional expressions are employed in interactions with AI chatbots, these models tend to 
            generate better results. This approach is known as "Emotional Prompting," a method that enhances the effectiveness of the 
            responses generated by your prompts.</p>` +
                `<p><a href="/documentation/prompt-techniques#emotional-prompting" class="max-w-full px-8 py-4 ml-4 rounded-md border border-[#0c66ee] dark:text-white bg-[#0c66ee] text-white hover:bg-slate-900" title='Emotional Prompting'>Emotional Prompting</a></p>`,
            techniqueIcon: true,
        },
        {
            title: "Strategy: Enhancing output quality through leading words",
            hashID: "t-leading-words",
            docLink: docLink,
            description:
                `<p>Utilizing specific leading words or phrases in prompts can significantly improve the quality and precision of `+ theLLM +`'s responses. For example, instructing the model with phrases like 'Think step by step,' 'Elaborate in detail,' or 'Provide a comprehensive explanation,' guides the AI to adopt a more structured, thorough, and methodical approach in its responses. This technique is particularly useful in scenarios where in-depth analysis, clear breakdown of concepts, or step-wise instructions are required. By effectively directing the model's focus and manner of response, you can tailor the output to meet specific needs, whether it's for educational purposes, problem-solving, or detailed explanations. Additionally, incorporating phrases that encourage creativity or lateral thinking, such as 'Imagine if' or 'Explore alternatives,' can lead to more innovative and diverse responses, showcasing the model's capability to generate unique ideas and perspectives.</p>` +
                `<p>Additionally, if you want to begin your output in a specific format, it's helpful to set the tone using the first few words of that desired syntax. For example, you can start with: "In the world of AI..." This approach ensures that the response aligns with the intended style and structure from the very beginning.</p>`,
            fireIcon: true,
        },
        {
            title: "Strategy: Minimizing bias in model outputs",
            hashID: "t-minimizing-bias",
            docLink: docLink,
            description:
                <>
                    <p>AI models, including {theLLM}, can exhibit biases, stemming from the nature of their training data. These biases may manifest in answers that inadvertently reinforce stereotypes or offer skewed perspectives. However, research indicates that actively instructing the model to generate unbiased responses and to avoid reliance on stereotypes can mitigate this issue. It's crucial to be aware of potential biases and to actively seek balanced, fair, and inclusive outputs from the model.</p>
                    <PromptExampleChat
                        title="Minimizing bias"
                        userPrompt={` .... Ensure that your output is unbiased and does not rely on stereotypes ...`}
                        description="Please be aware that the training data may still contain biases, especially those related to culture, and that this approach may not completely eliminate them. However, by actively instructing the model to generate unbiased responses, you can reduce the impact of well-known biases and stereotypes."
                        showActions={true}
                    />
                </>,
            fireIcon: true,
        },
    ];

    const interactiveTip = [
        {
            title: "Tip: Working with unique ID's in tables",
            hashID: "i-unique-ids",
            docLink: docLink,
            description:
                <><p>Another instance involves organizing the output in a tabular format, with each row assigned a unique<br />
                                    ID. This approach allows you to seek clarification for specific entries by referencing their<br />
                                    corresponding IDs. Consequently, you can generate ideas and evaluate their suitability for your<br />
                                    requirements. If a particular idea aligns with your needs, you can inquire further to obtain<br />
                                    additional information about it. This method enhances the efficiency of communication with {theLLM}<br />
                                    and enables a more structured and targeted exchange of information.</p>
                <PromptExampleChat 
                    title="Working with unique ID's in tables"
                    userPrompt={` ... Format it in a table, starting each row with a unique ID ...`}
                    showActions={true}
                />
                </>,
            fireIcon: true,
        },
        {
            title: "Strategy: First share context, then give it a task (Prompt Priming)",
            hashID: "i-share-context-then-task",
            docLink: docLink,
            description:
               <><p>In the realm of content creation, crafting an effective strategy is paramount. Among the myriad of techniques available, interactive prompting emerges as a particularly useful method. This approach is built on a foundational structure: initially present the context, followed by the task, especially when dealing with a substantial amount of data (context). Should it be possible to encapsulate everything within a single prompt, it's recommended to position the context at the bottom. In summary, the strategy to adopt is: <a href="/documentation/framework/#craft-c" className="text-blue-500 text-bold" title='Craft - C'>Craft - Context &rarr; Strategy: Place the context at the end of your prompt</a>.</p>
                <p>Imagine you're about to embark on a writing journey. The initial step involves setting the stage with necessary background information. This could range from topics you wish to explore, to insights from articles or other sources that you plan to incorporate into your new piece.</p>
                <p>Here's how it unfolds:</p>
                <PromptExampleChat 
                    title="Initial Prompt (priming)"
                    userPrompt={`Over the next few prompts, I'll be providing pieces of information. Please retain this information as we proceed. Once I've shared all relevant details, I'll transition to asking questions based on the provided context. A simple 'OK' from you will signal your understanding and readiness to move forward.`}
                    showActions={true}
                />

                <p><strong>Subsequent Prompts (2-x):</strong></p>
                   <p>These prompts will contain the context—vital pieces of information or perspectives that will serve as the foundation for our discussion.</p>
                   <p><strong>Final Prompt:</strong></p>
                   <p>Now, with the context fully laid out, we turn to the task at hand. This is where your insights, based on the previously shared information, come into play. Also make sure to include all other components of the framework here.</p>
                   <p>This method ensures that the foundation of your writing is solid, informed by a comprehensive understanding of the subject matter. It's a strategic approach designed to enhance the depth and relevance of your content.</p></>,
            fireIcon: true,
        },
    ];


    const nonDisclosureTip = [
        {
            title: "Placeholder-based prompting",
            hashID: "placeholder-based-prompts",
            docLink: docLink,
            description:
                <>
                <p>When generating output using {theLLM} you can use placeholder-based prompting to ensure that sensitive data such as personal information, or other confidential information is not shared with {theLLM}. The idea is to remove the sensitive parts of the prompt and replace them with placeholders, such as &#123;placeholder&#125;. This way, {theLLM} will also take the placeholder along and generate the output with the placeholder included. You can then inject the actual data after the output is generated.</p>
                <PromptExampleChat 
                    title="Placeholder-based prompting"
                    userPrompt={`
                        Send an e-mail about Prompt Engineering Discussion to &#123;personaA&#125; from &#123;companyA&#125;. Use &lt;register&gt;, &lt;format&gt; and &lt;context&gt;<br /><br />
                        &lt;register&gt;Formal&lt;/register&gt; <br />
                        &lt;format&gt; <br />
                        &nbsp;&#123;header to personA&#125;<br /><br />
                        &nbsp;&#123;sub header at companyA&#125;<br /><br />
                        &nbsp;&#123;paragraph&#125;<br /><br />
                        &nbsp;&#123;cta&#125;<br /><br />
                        &nbsp;&#123;myInfo&#125;<br />
                        &lt;/format&gt;<br />
                        &lt;context&gt;I want to organize a teams meeting on 12 february at 13:00h&lt;/context&gt;
                        `}
                    assistantResponse={`
                        Subject: Invitation to Teams Meeting on February 12 at 13:00h<br /><br />

                    Dear &#123;personaA&#125;,<br /><br />

                    Prompt Engineering Discussion at &#123;companyA&#125;<br /><br />

                    I hope this email finds you well. I would like to invite you to a discussion on Prompt Engineering, where we can explore its impact and best practices. The session is scheduled for February 12 at 13:00h via Microsoft Teams.<br /><br />

                    Please let me know if this time works for you or if any adjustments are needed. I look forward to your insights on this important topic.<br /><br />

                    Best regards,<br />
                    [Your Name]<br />
                    [Your Position]<br />
                    [Your Contact Information]
                    `}
                    showActions={true}
                />

                <p>As you can see, the placeholders &#123;personA&#125; and &#123;companyA&#125; are used in the output, and we can inject the actual data when we want to actually use this data. This is a powerful way to ensure that sensitive data is not shared with {theLLM} and that you have full control over the output.</p>
                </>,
            fireIcon: true,
        },        
        {
            title: "Warning: also adhere GDPR guidelines",
            hashID: "n-gdpr-guidelines",
            docLink: docLink,
            description:
                "<p>This also includes adhering to GDPR guidelines when interacting with Generative AI tools such as ChatGPT, Microsoft Copilot or Gemini. Under GDPR (General Data Protection Regulation), it's imperative not to share any personal information about individuals without their explicit consent. This regulation protects the privacy and personal data of individuals, ensuring that their information is handled responsibly and securely.</p>",
            warningIcon: true,
        },
        {
            title: "Warning: adhere to AI Act regulations (Europe)",
            hashID: "n-ai-act-regulations",
            docLink: docLink,
            description:
                "<p>When using Generative AI tools such as ChatGPT, Microsoft Copilot, or Gemini, it's essential to comply with the AI Act. This regulation categorizes AI systems based on risk levels and mandates specific obligations, including transparency, data privacy, and the avoidance of harm.</p>" +
                "<p><strong>Key guidelines to follow under the AI Act:</strong></p>" +
                "<ul class=\"list-disc list-inside ml-4 mb-4\">" +
                "  <li><strong>Transparency:</strong> Clearly disclose when content is AI-generated to maintain honesty and user trust.</li>" +
                "  <li><strong>Data Privacy:</strong> Ensure compliance with data protection laws like GDPR when handling personal data.</li>" +
                "  <li><strong>Avoidance of Harm:</strong> Refrain from generating misleading, deceptive, or harmful content.</li>" +
                "  <li><strong>Non-Discrimination:</strong> Prevent bias or discriminatory outcomes in AI-generated outputs.</li>" +
                "  <li><strong>Human Oversight:</strong> Monitor and review AI outputs, ensuring responsible usage and intervention when necessary.</li>" +
                "</ul>" +
                "<p>These measures are critical for aligning with the AI Act and promoting responsible AI usage in prompt engineering.</p>",
            warningIcon: true,
        },        
    ];

    const goalDrivenTip = [
        {
            title: "Technique: Validating AI responses with Chain of Thought (CoT)",
            hashID: "g-validate-via-cot",
            docLink: docLink,
            description: `<p class="mb-10">To boost the accuracy of AI-generated answers, try the "Chain of Thought (CoT)" technique. Similar to solving a math problem by showing each step, CoT involves breaking down complex queries into logical steps. This method doesn't just seek direct answers from the AI but guides it to explain its reasoning process towards a solution. It not only yields more precise responses but also mirrors a human-like, systematic approach to problem-solving, making it easier to validate the AI's output.</p>` +
                `<p><a href="/documentation/prompt-techniques#chain-of-thought-prompting" title="Chain of Thought Prompting" class="max-w-full px-8 py-4 ml-4 rounded-md border border-[#0c66ee] bg-[#0c66ee] text-white hover:bg-slate-900">Chain Of Thought prompting</a></p>`,
            techniqueIcon: true,
        }
    ]

    /* There are no goalDrivenTips */

    const adaptAndImproveTip = [
        {
            title: "Pro tip: understanding predictions",
            hashID: "ai-understand-predictions",
            docLink: docLink,
            description:
                `<p>Are you feeling stuck with the outcomes you're getting? If you're curious to dive deeper into how AI predictions unfold, exploring the OpenAI Playground can be incredibly insightful. One useful feature to check out is "Show probabilities." When you enable this setting and input a prompt, the playground vividly demonstrates the inner workings of the prediction process. You can hover over each word to see the array of possible words considered by the AI and the specific choice it made. This interactive exploration can significantly enhance your understanding of how AI thinks and decides.</p>` +
                `<p class="mb-10">Please be aware that to enable this feature, you need to navigate to the "complete" (legacy) section within the playground area. This option is located adjacent to the "Playground" title and default set to "Chat" on the link provided below.</p>` +
                `<p class="mb-10"><a href="https://platform.openai.com/playground/" target="_blank" class="max-w-full px-8 py-4 ml-4 rounded-md border border-[#0c66ee] dark:text-white hover:bg-[#0c66ee] hover:text-white">OpenAI Playground</a></p>`,
            fireIcon: true,
        },
    ];


    return(
    <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="documentation">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
            <DocumentationNav page="Framework" />
            <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white" id="framework"
                 data-aos="fade-up"
                 data-aos-delay="100">
                <div className={"block flex col-2 mb-1"} id="interactive-prompt-engineering">
                    <h1 className={"mb-1"}><span className="text-header-gradient">CRAFTING AI</span> prompts framework</h1>
                    <div className={"mt-3 ml-4 pt-1"}>
                        <PromptEngineeringCatLabel category="IPE" />
                    </div>
                </div>
                <p>The <strong className="dark:text-white">CRAFTING AI prompts framework</strong> is designed to empower users in effectively utilizing
                    various large language models (LLMs) like ChatGPT, Microsoft Copilot, and Gemini for diverse writing and engagement tasks while ensuring ethical and responsible use. It consists of three essential parts <strong className="dark:text-white">CRAFT</strong>, <strong className="dark:text-white">ING</strong>,
                    and <strong className="dark:text-white">AI</strong>.
                    <br />Let's explore each component in detail:</p>

                <p><strong><a href={"/documentation/framework#craft"} className="text-bold underline dark:text-white">CRAFT</a></strong>: The crafting phase focuses on providing specific details and context to
                    enhance response quality. By framing prompts using natural language, {theLLM} better understands
                    intentions, context, and nuances, resulting in a more conversational and human-like interaction.
                    Additionally, specify writing style, acting roles, format requirements, and task objectives to guide {theLLM} accurately.</p>
                <p><strong><a href={"/documentation/framework#validate"} className="text-bold underline dark:text-white">ING</a></strong>: The validation phase promotes interactive dialogue and integrates legal,
                    security, and privacy considerations. Through fostering back-and-forth exchanges, refining
                    inquiries, and collaborating with {theLLM}, you ensure that the generated responses align with
                    ethical and responsible practices. By being mindful of non-disclosure agreements, data security, and
                    privacy guidelines, your prompts maintain compliance, protect sensitive information, and establish
                    clear goals. This approach enables you to derive meaningful insights while upholding confidentiality
                    and achieving desired outcomes.</p>
                <p><strong><a href={"/documentation/framework#enhance"} className="text-bold underline dark:text-white">AI</a></strong>: The enhancement phase emphasizes staying proactive and embracing new
                    possibilities. Adapt your approach by validating responses, making necessary adjustments, and
                    refining prompts based on initial results. By continuously improving your prompts, you maximize the
                    potential of AI tools and generate the best possible outcomes.</p>
                <p>By adhering to the CRAFTING AI prompts framework, you can effectively utilize the capabilities of LLMs like ChatGPT, Microsoft Copilot, and Gemini to improve your writing and enhance your overall engagement.</p>

                <p>Let's delve into each aspect of the framework to gain a deeper understanding.</p>

                <p>If you are using any of the tools listed below, click on the corresponding tool to modify the text and read it as if you were already using it.</p>

                <ButtonChangeText toText={"ChatGPT"} title={"ChatGPT"} />
                <ButtonChangeText toText={"Microsoft Copilot"} title={"Microsoft Copilot"} />
                <ButtonChangeText toText={"Gemini"} title={"Gemini"} />
                <ButtonChangeText toText={"Claude"} title={"Claude"} />
                <ButtonChangeText toText={"the LLM"} title={"Keep it for any LLM"} />

                <AlertBoxTimed text={successAlertLLMText} type={AlertType.SUCCESS} />

                <hr/>

                <h2 id="craft"><span className="text-header-gradient">Crafting</span> phase</h2>
                <p>In the Crafting Phase, we will focus on writing the prompt (the message we send to the language model). To create an effective prompt, we need to consider a few key factors. First, define your goal: what do you want to achieve? It’s important to remember not to include any confidential or personal data. Also, it helps to build reference points in your prompt so you can navigate back and forth during your conversation.</p>
                <p>Before you start, take time to plan your prompt carefully and clarify your objective. Ask yourself: What is the real goal here? For example, if a student is writing an essay for a course, is the true goal to write the essay, or simply to get a high grade (or worse, to mislead a teacher, especially if the use of AI is prohibited)? The way you define your goal will have a significant impact on the outcome.</p>
                <p>We will validate this in the Validation Phase, but doing it correctly upfront will save time and effort later on.</p>
                <p>When crafting your prompts, keep in mind that the tool or model you're using might be influenced by the country or region it's from. Some countries have strict guidelines to reduce bias, and may be more positive about certain topics than others. To get an overview of the tools and their data storage, check the <a href="/tools" title="Tools" className="text-bold text-blue-600 hover:text-sky-300">tool page</a>! This might influence how you want to structure your prompt.</p>

                <hr className="w-[60%] h-[0.3px] bg-gray-600 border-0 mb-2 mt-1" />

                <p id="craft-c"><strong className="dark:text-white">C - Context</strong>: For LLMs to fully grasp the context, it is essential to provide specific details using natural language. 
                The more precise and detailed your information, the better the response will be. 
                It is crucial to have a clear understanding of the scope and effectively communicate it to {theLLM}. 
                Framing prompts in natural language is vital for achieving a more conversational and human-like response. 
                By using natural language, {theLLM} can better understand your intentions, context, and nuances. 
                This facilitates a smoother and more intuitive interaction, creating a prompt that feels like a genuine conversation.</p>

                <p>In addition to general context, it is important to provide clear descriptions of examples, keywords, deadlines, citations, quotes, arguments, or counterarguments that should be considered. 
                    If for example, specific laws need to be taken into account in the generated output, be sure to mention them in your prompt. This ensures that {theLLM} incorporates relevant information and contributes to high-quality responses. 
                    Providing such contextual details enhances the overall user experience and ensures that {theLLM} generates outputs aligned with your expectations.</p>

                <p>If you are using a tool or environment where <strong>references</strong> to specific <strong>sources</strong> can be added, think about documents, books, articles, or web pages that should be specified, and clearly state why they are relevant to the context. 
                    For example, you can mention relevant scientific articles, books, or web pages that you believe should be taken into account when generating content. Or, if you are a programmer, you can mention specific classes, functions, or methods that you want the tool to be aware of when generating code. 
                    By providing such details, you ensure that the tool can generate responses informed by relevant sources and consider multiple perspectives.</p>

                <ContentAccordion items={contextTip} grayTitle={true} hashID={targetTipId} />

                <p id="craft-r"><strong className="dark:text-white">R - Register (Writing Style)</strong>: When formulating prompts, it is important to specify
                    the desired writing style or register, taking into account factors such as formality, tone,
                    vocabulary, and grammar. This is especially crucial when there are specific proficiency requirements
                    or when attempting to mimic a particular writing style. For example, you can include phrases like
                    "as a student with an English level [B1]" to inform {theLLM} about your language proficiency. It is
                    also possible to provide writing samples or request considerations for language proficiency. Clearly
                    indicate whether a formal or informal tone is preferred. Additionally, describe whether the use of
                    specific terminologies or analogies is permitted or not, and specify the language in which the
                    response should be provided. By providing such details, {theLLM} will generate responses accordingly.
                </p>
                <p>Regarding readability, you have the option to request outputs in a conversational tone.
                    Simply specify: "Write the output as if you're talking to a friend."
                    This approach helps in achieving a more relatable and engaging delivery of information.</p>
                <p>However, keep in mind that the register can be influenced by cultural differences present in the training data, which can impact the way communication is perceived.
                    For example, the way you talk to a friend might differ significantly from someone in another country, where conversations could be much more animated due to cultural differences.
                    Understanding the origin of the training data can provide insights into how detailed you should be when describing your preferred register.
                    As always, the more details you provide, the better the generated responses will align with your expectations.</p>

                <ContentAccordion items={registerTip} grayTitle={true} hashID={targetTipId} />

                <p id="craft-a"><strong className="dark:text-white">A - Acting Role</strong>: Decide on the persona or role you want {theLLM} to take on, which
                    can enhance engagement. Instruct {theLLM} to assume the role of a LinkedIn expert with a significant
                    following, for instance. You can also request a specific point of view to explore multiple
                    perspectives or opinions. By assigning an acting role, you guide {theLLM}'s responses to align with
                    the desired expertise or perspective, resulting in more targeted and relevant outputs.</p>
                <p>Please note that manipulating factors such as years of expertise and the number of followers can
                    significantly influence {theLLM}'s responses. Therefore, it is essential to provide specific
                    instructions to guide {theLLM} accurately towards your intended direction.</p>
                <p>And again, it is important to consider the cultural influences present in the training data of the model. For example, if the model is predominantly trained on data from a specific country, such as the United States, the output may reflect cultural nuances from that region. ChatGPT, for instance, is primarily trained on American data, which can result in a tone that is highly enthusiastic and may not align with your personal style or objectives. To ensure the content is suitable for your intended audience, be explicit about the desired tone and cultural context. By acknowledging potential biases, you can provide clearer instructions to mitigate them and achieve more precise and effective results.</p>

                <ContentAccordion items={actingRoleTip} grayTitle={true} hashID={targetTipId} />

                <p id="craft-f"><strong className="dark:text-white">F - Format</strong>: Clearly state any specific formatting requirements you have for the
                    output. For instance, you can specify if you'd like the content to be organized in a table format,
                    include hashtags, bullet points, graphs, templates, or emoticons. Providing specific formatting
                    instructions will help ensure that the generated output meets your desired structure and readability
                    preferences.</p>

                <p>When you require specific outputs, such as files or code, providing clear instructions is crucial. For example, 
                    if you desire an interactive map of your country that highlights all your office locations in HTML, 
                    specify that you need it in interactive HTML format. Additionally, request a download link for convenient access.
                    Also, clearly mention any specific colors or formatting requirements you want the model to incorporate.</p>

                <p>Large Language Models (LLMs) are language-based systems designed to excel at understanding and generating human-like text, 
                    but they are not inherently optimized for precise numerical calculations and might fail due to their prediction-based nature.
                    Therefore, it’s better to ask the model to generate (and execute) code in a programming language like Python, where it excels. 
                    By leveraging its strength in text-to-code generation, the LLM can create accurate scripts for calculations and return 
                    correct results. This approach combines the model’s linguistic capabilities with the computational precision of 
                    programming, ensuring both reliability and effectiveness in solving numerical tasks. Make sure to clearly specify the 
                    programming language you want the model to use in such cases.</p>

                <p>It is also important to think about how you will reference parts of the generated output later on. 
                    For example, if you're using a table, start each row with a unique ID so you can easily refer back to those IDs in subsequent prompts. This will help ensure continuity and make the conversation smoother and more efficient.</p>

                <p>Finally, utilizing parameters can be highly valuable as they enable customization of the generated
                output in {theLLM}. These adjustable settings allow you to fine-tune various aspects of the model's
                behavior. For example, you can leverage the "temp=" parameter to control the temperature and adjust
                the level of creativity in the output. Parameters provide the flexibility to tailor the
                characteristics of the generated responses to your specific needs. If you would like to explore the
                complete list of parameters available in {theLLM}, simply ask, "Which parameters does {theLLM} have?"
                It's important to note that while you can adjust these parameters when using OpenAI's API, there may
                be specific constraints or limitations depending on the platform or implementation you are
                using.</p>

                <ContentAccordion items={formatTip} grayTitle={true} />

                <p id="craft-t"><strong className="dark:text-white">T - Task</strong>: Clearly state the task or purpose of the prompt. Use words such as
                    "craft," "make,"
                    "compose," "create," "summarize," "translate," "generate," "revise," "review," or "(re)-write." For
                    example, ask {theLLM} to compose a LinkedIn post on a specific topic or revise this article. Ask {theLLM} to summarize your PDF file and you can also provide a clear call to action.
                    If you're facing a complex task with multiple components, consider dividing it into simpler, individual tasks. The best practice is to limit your prompt to one task at a time.
                    This approach allows you to maintain focus and achieve high-quality results by addressing one aspect at a time. You can use the interactive (I) approach for your follow-up tasks.</p>

                <p>Furthermore, if not already addressed in preceding instructions, take a moment to reflect on or specify your intended audience.
                    Understanding who you are targeting is crucial for tailoring the content to meet their expectations and interests effectively.
                    Additionally, if your prompt includes extensive context or additional information, make sure to clearly delineate the task.
                    Specify it by starting with: "Your task is..." This clarity ensures that the main objective stands out, guiding the focus directly to what needs to be accomplished.
                    Remember to align the task with your goal as specified in the goal-driven section. Clearly articulate how the task supports your intended outcome. This helps in targeting the model towards achieving the desired result.</p>

                <p>If there are any restrictions relevant to the task, specify them clearly. For instance, if there are topics, styles, or perspectives that should be avoided or emphasized, make sure to exclude things that might not be of interest.
                    However, keep these restrictions limited, and primarily focus on guiding {theLLM} on what it should do.</p>

                <p>If you want {theLLM} to strictly adhere to your instructions without deviation, conclude your prompt with
                    "Strictly follow these instructions." Conversely, if you wish to disregard any previous
                    instructions, include the word "stop" followed by the action you want to halt. If you want to start
                    with a clean slate and reset any previous instructions, simply prompt: "Reset previous instructions
                    and start fresh." (or start a new chat). This will enable you to provide a new task or request without any carryover from
                    prior instructions. To minimize the chance of fabricated responses, it's also important to prompt clearly.
                    Include in your task "If you don't know the answer, respond with: 'I don't know the answer.'".
                    And finally, if you want to break it up, use "Take a deep breath and work on it step by step."</p>

                <ContentAccordion items={taskTips} grayTitle={true} />

                <hr/>

                <h2 id="validate"><span className="text-header-gradient">Validation</span> phase</h2>
                <p>During the Crafting Phase, we focused on writing the prompt. Now, in the Validation Phase, 
                    we will first evaluate <strong>the prompt</strong> itself. We will check if the prompt is interactive—does it include reference points? We’ll also ensure that no confidential data is shared and confirm that the prompt is goal-driven, ensuring it aligns with the objective we set at the beginning.</p>
                <p>Once the prompt is validated, we will move on to evaluate the output. After confirming that the prompt is goal-driven, we’ll validate whether the output meets our desired goal and provides the results we expect.</p>

                <hr className="w-[60%] h-[0.3px] bg-gray-600 border-0 mb-2 mt-1" />

                <p id="validate-i"><strong className="dark:text-white">I - Interactive</strong>: Crafting prompts that promote interactive dialogue is essential for
                    fostering dynamic and engaging conversations with {theLLM}. This encourages a back-and-forth exchange
                    and collaborative problem-solving, enabling you to explore ideas, seek clarification, and refine
                    your inquiries. The interactive nature of prompts enhances the overall experience, helping derive
                    meaningful insights from {theLLM}'s responses, which becomes particularly valuable when dealing with
                    large amounts of data. Reflection, a key aspect of this interactive approach, involves designing
                    prompts that explicitly reference and build upon previous input or context. By incorporating
                    reflection, you empower {theLLM} to respond coherently and contextually, demonstrating awareness of
                    the conversation flow, addressing specific points, and generating interactive and dynamic responses.
                    Leveraging reflection in prompts facilitates a more engaging experience, fostering deeper
                    interaction and improving the quality of generated prompts.</p>

                <ContentAccordion items={interactiveTip} grayTitle={true} hashID={targetTipId} />

                <p id="validate-n"><strong className="dark:text-white">N - Non-disclosure</strong>: When crafting prompts, it is crucial to incorporate legal,
                    security, and privacy considerations. Protect sensitive information and ensure compliance with
                    non-disclosure agreements (NDAs). Specify any constraints or guidelines related to data security and
                    privacy to maintain ethical practices. It's important to execute this validation step before
                    submitting your prompt. Once you send it to an AI tool, it may be saved and exposed to the internet,
                    so exercise caution and discretion. Safeguarding confidentiality is paramount in this process.</p>

                <ContentAccordion items={nonDisclosureTip} grayTitle={true} hashID={targetTipId} />

                <p id="validate-g"><strong className="dark:text-white">G - Goal-driven</strong>: Clearly defining your goals and objectives in your prompts is
                    essential for guiding {theLLM} to provide accurate and relevant answers. By specifying your desired
                    outcomes and indicating the specific information or insights you are seeking, {theLLM} can better
                    align its responses to meet your expectations. This goal-driven approach ensures that {theLLM} understands your intent and focuses its responses to address your specific needs effectively.</p>

                    <p>When designing prompts, it’s vital to evaluate not only the structure of your prompts but also the underlying biases or cultural nuances embedded within the AI model or tool you are using. Models may have built-in guardrails, regulatory influences, or cultural framings that shape their responses. For example, specific models might emphasize certain perspectives or provide outputs aligned with the norms or priorities of the regions or organizations that developed them.</p>
                    <p>To ensure goal alignment, consider how these factors might influence the outcome and validate prompts by testing them across various contexts. This could involve analyzing the results for fairness, inclusivity, or alignment with your expectations. Additionally, consult with subject matter experts or explore alternative models if necessary to cross-check and mitigate these influences. A thoughtful approach ensures more accurate, reliable, and representative results.</p>

                <p><strong>Before sending the prompt</strong>, it’s important to first validate whether the prompt itself is structured in a way that will help you reach your goal. This involves checking if the prompt is clear, complete, and aligned with the desired outcome. Only after this validation of the input should you proceed with sending the prompt.</p>

                <p>If you validated the prompt, you can send your prompt.</p>

                <p><strong>Once the prompt is sent</strong>, the next step is to validate whether the outcome meets the expected results (output). Ensure that the response aligns with your goal and provides accurate, factual information—especially when dealing with legal documents or other contexts where precision is crucial. Additionally, evaluate the response for ethical considerations, such as fairness, inclusivity, and the avoidance of harmful stereotypes or biases. If necessary, you can adjust formatting parameters (F) or reframe the prompt to further refine the output and improve its relevance, accuracy, and ethical alignment.</p>
                
                <ContentAccordion items={goalDrivenTip} grayTitle={true} hashID={targetTipId} />

                <hr/>

                <h2 id="enhance"><span className="text-header-gradient">Enhancement</span> phase</h2>
                <p>As AI tools evolve rapidly, it's important to adapt and improve your strategy and prompts. The "AI" aspect of the framework reminds you to refine your output by asking follow-up questions and adapting your approach based on the initial results.</p>

                <hr className="w-[60%] h-[0.3px] bg-gray-600 border-0 mb-2 mt-1" />

                <p id="enhance-ai"><strong className="dark:text-white">AI - Adapt and Improve</strong>:
                    If the output we validated during the Validation Phase <strong>is not correct</strong>, the next step is to iterate back to the Crafting Phase. Use the components from that phase (that are relevant to review) to refine and enhance the prompt, ensuring the output better aligns with your goal. This iterative process is key to improving the results.</p>
                <p><strong>If the output is correct</strong>, it's time to adapt and improve your overall strategy. Start by identifying any reusable components from the prompt that can be applied to future prompts or shared within your organization. Saving and sharing these components not only speeds up productivity but also fosters a more sustainable approach by reducing redundant work and streamlining processes across teams.</p>
                <p>Continuously improving your prompts and embracing new possibilities is essential for generating optimal outcomes. Stay proactive and adaptable to ensure ongoing success when working with these powerful tools.</p>


                    <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                    src={require("../../assets/img/framework/framework-overview.png")} alt="Framework overview"/></p></div>

                <ContentAccordion items={adaptAndImproveTip} grayTitle={true} />

                <p>It is not necessary to format your prompt following each step individually, but it is important to
                    include all the elements of the framework within your prompt. A valid prompt that encompasses all
                    parts of the framework could be:</p>

                <PromptExampleChat 
                    title="Example of a 'basic prompt' " 
                    userPrompt="Acting as a [role], perform [task], in [format requirements], use [register/writing style] and use this context: [context]." 
                    showActions={true}
                />

                <p>If you prefer using a template or employing them as components (refer to the architecture section), this approach is also viable and will yield the same results:</p>

                <PromptExampleChat
                    title="Example of 'template prompt'"
                    userPrompt="
                    # Context #<br />
                    [Context]<br />
                    <br />
                    # Register #<br />
                    [Register]<br />
                    <br />
                    # Acting role #<br />
                    [Acting role]<br />
                    <br />
                    # Format #<br />
                    [Format]<br />
                    <br />
                    # Task #<br />
                    [Task]
                    "
                    showActions={true}
                />
                <p>When working with more than one register, acting role, format, or task component, it is highly beneficial to organize and format them using XML-style tags. This method provides clarity, structure, and scalability, making it especially effective for complex or multi-layered tasks. <strong>This is our preferred approach</strong>.</p>
               
                <PromptExampleChat
                    title="Example of 'prompt with Prompt Components' (preferred)"
                    userPrompt={`
                        <code>
                            &lt;register&gt;[register]&lt;/register&gt;<br />
                            &lt;acting_role&gt;[acting role]&lt;/acting_role&gt;<br />
                            &lt;format&gt;[format]&lt;/format&gt;<br />
                            &lt;task&gt;[task]&lt;/task&gt;<br />
                            &lt;context&gt;[Your context dump here]&lt;/context&gt;
                        </code>
                    `}
                    showActions={true}
                />

                <p><i>Please note: for certain use cases, it may be better to place the 'context' at the end of your prompt. Refer to the strategies section for more information. The order of the components does not matter.</i></p>
                <p>By incorporating these components in your
                    prompt, you provide {theLLM} with the necessary instructions and guidance to generate a response that
                    aligns with your specific requirements.</p>
                <p>By following these expert techniques, you can craft prompts that elicit remarkable responses from {theLLM},
                    taking your writing and engagement to new heights. If you're looking for specific examples
                    for each element in this framework, you can copy and paste the text describing the framework to {theLLM} and ask for examples directly from the AI.
                    This way, you can explore and experience the examples firsthand, enabling you to understand how to apply the framework effectively. So go ahead,
                    interact with {theLLM} and discover the power of this framework in generating remarkable
                    responses!</p>
                <p>Please be aware that this is an iterative process, which means that after the enhancement phase, you
                    will return to the crafting phase. You can adapt and improve the generated output by writing another
                    prompt , utilizing the interactive (i) approach to refine it towards your desired goal (g), while
                    considering the non-disclosure (n) constraints. In your follow-up prompt, you can build upon the
                    previous output without reiterating all of the CRAFT elements. Instead, concentrate on the elements
                    that will improve and contribute to the output's enhancement (AI).</p>
                <p><i>Released: May 22, 2023 - Last updated: Feb 11, 2025</i></p>

                <hr />

                <p>Now that you understand the basics of crafting effective prompts, it's time to take your skills to the next level. Let's dive into advanced prompt engineering techniques and explore how you can harness the full potential of AI.</p>
                <BaseButton url={"/documentation/prompt-techniques#prompt-techniques"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                    Next: Prompt Techniques
                </BaseButton>
            </div>
        </div>
    </section>);
}

export default FrameworkDocumentation;