// Components
import React, {useEffect, useState} from "react";
import DocumentationNav from "../DocumentationNav";
import QuadrantChart from "../../../components/documentation/QuadrantChart";
import axios from "../../../libs/axios";
import RequireAuth from "../../../components/auth/RequireAuth";
import { handleRateLimit, isRateLimited } from '../../../utils/rateLimit';
import RateLimitMessage from '../../../components/RateLimitMessage';
import BaseButton from "../../../components/base/Button";

function CodingFrameworkDocumentation() {
  const [isQuadrantExplanationOpen, setIsQuadrantExplanationOpen] = useState(false);

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Coding with AI"
  }, []);

  enum XLabel {
    TEXT = "Textual output",
    RUN = "Run",
    PUBLISH = "Publish",
    DEPLOY = "Deploy",
    IDEINT = "IDE Integration",
  }

  enum YLabel {
    CODE_COMPLETION = "Code compl.",
    TASK_AUTOMATION = "Task autom.",
    PROJECT_AUTOMATION = "Project autom.",
    AI_ENGINEER = "AI Engineer",
    AIDevTeams = "AI Dev teams",
  }

  const xLabels: XLabel[] = [XLabel.TEXT, XLabel.RUN, XLabel.PUBLISH, XLabel.DEPLOY, XLabel.IDEINT];
  const yLabels: YLabel[] = [
    YLabel.CODE_COMPLETION,
    YLabel.TASK_AUTOMATION,
    YLabel.PROJECT_AUTOMATION,
    YLabel.AI_ENGINEER,
  ];

  const [dataPoints, setDataPoints] = useState<{ xLabel: XLabel; yLabel: YLabel; xRating: number; yRating: number; label: string; url?: string }[]>([]);
  const [quadrantError, setQuadrantError] = useState<string | null>(null);
  const [filterType, setFilterType] = useState<'rated' | 'unrated' | null>(null);
  const [isRateLimitActive, setIsRateLimitActive] = useState(isRateLimited());
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check rate limit status periodically
    const checkRateLimit = () => {
      setIsRateLimitActive(isRateLimited());
    };
    const timer = setInterval(checkRateLimit, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return; // Exit if not authenticated

    const fetchData = async () => {
      if (isRateLimitActive) {
        setQuadrantError(null);
        return;
      }
      try {
          const response = await axios.get(`/tools/code/quadrant`);
          if (response.data.success) {
              // Map the API response to match our XLabel and YLabel enums
              const mappedData = response.data.data.map((point: any) => ({
                  xLabel: point.xLabel === 'IDE Integration' ? XLabel.IDEINT : point.xLabel === 'Run' ? XLabel.RUN : point.xLabel === 'Publish' ? XLabel.PUBLISH : point.xLabel === 'Deploy' ? XLabel.DEPLOY : XLabel.TEXT,
                  yLabel: point.yLabel === 'AI Engineer' ? YLabel.AI_ENGINEER : point.yLabel === 'Project automation' ? YLabel.PROJECT_AUTOMATION : point.yLabel === 'Code completion' ? YLabel.CODE_COMPLETION : YLabel.TASK_AUTOMATION,
                  xRating: point.xRating,
                  yRating: point.yRating,
                  label: point.label,
                  url: point.url
              }));
              setDataPoints(mappedData);
          }
      } catch (error: any) {
          if (error.response?.status === 429) {
            handleRateLimit();
            setIsRateLimitActive(true);
            setQuadrantError(null);
          } else if (error.response?.status === 401) {
            setQuadrantError('You need to be logged in to view this section');
          } else {
            setQuadrantError('An error occurred while fetching data');
          }
      }
  };
  
    fetchData();
  }, [isAuthenticated]);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="coding">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="Applications" />

              <div className={"col-span-12 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section lg:col-span-9"}
                   id="techniques"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <RequireAuth
                    id="coding"
                    sectionDescription="Learn how to use coding assistants with the Crafting AI Prompts Framework."
                    title="Coding Framework Documentation"
                    onAuthenticated={() => setIsAuthenticated(true)}
                  >
                  <h1 className="text-bold"><span className="text-header-gradient">Coding</span> with AI</h1>
                  <p>The IPE framework provides a solid foundation for writing effective prompts, including those for coding. On this page, we take a deep dive into the unique challenges of using Generative AI for software development and explore specialized tools that can enhance the coding workflow. While the core principles of the IPE framework remain relevant, this content is specifically tailored to Software Engineers and anyone looking to craft better prompts for AI-assisted coding tools. Think of these techniques as practical extensions of the framework—designed to help developers maximize AI-powered tools and better understand how the framework can enhance their workflow within those tools.</p>

                  <hr />

                  <h2 id="coding-landscape"><span className="text-header-gradient">Code Assistant</span> Landscape</h2>
                  <p>First, let's dive into what tools are available. In the quadrant below, they are mapped based on their capabilities and user ratings from this website.</p>
                  <p>It's important to note that an AI Engineer is not always the best option for every use case (even though it's at the top of the quadrant). For example, if your organization has Software Engineers who are accustomed to working in IDEs, it might be more relevant to explore assistants that are integrated into those environments. There are different options available, so it's best to assess what fits your organization's needs and choose accordingly.</p>
                  <p>The following chart provides a high-level overview of the coding assistant landscape, mapping different solutions by their capabilities and integration levels. The x-axis represents the integration level, ranging from runnable code snippets (Run) to solutions that can be published (Publish) or deployed (Deploy), and finally to fully integrated solutions that are part of your IDE (IDE Integration). The y-axis represents the capabilities of the solution, ranging from simple code completion to more advanced capabilities such as AI Engineers. Each solution is positioned on the chart based on its average user ratings for IDE integration or based on the average review score (X) and code capabilities (Y), providing a quick visual representation of the landscape.</p>
                  
                  {isRateLimitActive ? <RateLimitMessage /> : 
                    <>
                      <div className="my-8 w-full">
                        <QuadrantChart
                          maxWidth={900}
                          aspectRatio={0.44}
                          xLabels={xLabels}
                          yLabels={yLabels}
                          dataPoints={dataPoints}
                          filterType={filterType}
                        />
                        <div className="flex items-center justify-center gap-6 mt-4 text-sm">
                          <div 
                            className="flex items-center gap-2 cursor-pointer"
                            onMouseEnter={() => setFilterType('rated')}
                            onMouseLeave={() => setFilterType(null)}
                          >
                            <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                            <span>Rated tools</span>
                          </div>
                          <div 
                            className="flex items-center gap-2 cursor-pointer"
                            onMouseEnter={() => setFilterType('unrated')}
                            onMouseLeave={() => setFilterType(null)}
                          >
                            <div className="w-3 h-3 rounded-full bg-red-400"></div>
                            <span>Unrated tools</span>
                          </div>
                        </div>
                      </div>
      
                      <p className="italic">Only verified tools are shown in the list above. If you really have a tool added that we should make available to everyone, create a ticket in your dashboard to request it, and we'll check it out soon!</p>
                    </>
                  }

                  <div className="mt-4">
                    <button
                      onClick={() => setIsQuadrantExplanationOpen(!isQuadrantExplanationOpen)}
                      className="flex w-full cursor-pointer select-none items-center justify-between rounded-lg bg-gray-50 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
                      aria-expanded={isQuadrantExplanationOpen}
                    >
                      <span>Quadrant Explanation</span>
                      <span className={`shrink-0 transition duration-300 transform ${isQuadrantExplanationOpen ? 'rotate-180' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-5 w-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                      </span>
                    </button>
                    <div 
                      className={`transition-all duration-300 ease-in-out ${isQuadrantExplanationOpen ? 'max-h-[2000px] opacity-100 mt-4' : 'max-h-0 opacity-0 overflow-hidden'}`}
                    >
                      <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10">
                        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6">Y-axis</th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100">Description</th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100">Position</th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">AI Engineer</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Advanced AI models that can generate code based on human instructions</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-500/20">Tool's Code Rating</span></td>
                            </tr>
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">Project Automation</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Tools that can automate tasks and workflows on a project level</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-500/20">Tool's Code Rating</span></td>
                            </tr>
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">Task Automation</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Tools that can automate a specific task or set of tasks</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-500/20">Tool's Code Rating</span></td>
                            </tr>
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">Code Completion</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Basic code completion</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 ring-1 ring-inset ring-blue-700/10 dark:ring-blue-500/20">Tool's Code Rating</span></td>
                            </tr>
                          </tbody>
                          <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-100 sm:pl-6">X-axis</th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100">Description</th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100">Position</th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">Textual output</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Tools that provide text-based suggestions or explanations</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200 ring-1 ring-inset ring-green-700/10 dark:ring-green-500/20">Tool's Review Rating</span></td>
                            </tr>
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">Run</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Tools that can execute code or commands</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200 ring-1 ring-inset ring-green-700/10 dark:ring-green-500/20">Tool's Review Rating</span></td>
                            </tr>
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">Publish</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Tools that can share or publish code and content</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200 ring-1 ring-inset ring-green-700/10 dark:ring-green-500/20">Tool's Review Rating</span></td>
                            </tr>
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">Deploy</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Tools that can deploy code to production environments</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200 ring-1 ring-inset ring-green-700/10 dark:ring-green-500/20">Tool's Review Rating</span></td>
                            </tr>
                            <tr className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200">
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100 sm:pl-6">IDE Integration</td>
                              <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400">Tools that integrate directly with development environments</td>
                              <td className="px-3 py-4"><span className="inline-flex items-center px-2.5 py-1 rounded-md text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200 ring-1 ring-inset ring-purple-700/10 dark:ring-purple-500/20">Tool's IDE Integration Rating</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <h2 id="vibe-coding"><span className="text-header-gradient">Vibe</span> Coding</h2>
                  <p>Vibe Coding is the practice of programming alongside AI, where you describe exactly what you want the code to do, and the AI generates it for you. The key is to be clear and specific — the more detailed your instructions, the better the results. Instead of manually writing out every line of code, you focus on defining the logic and structure, and the AI handles the implementation.</p>
                  <p>This approach allows you to offload repetitive or boilerplate code to the AI while staying in control of the overall design. For example, instead of writing a data processing function from scratch, you could specify the input format, the transformation steps, and the desired output — and the AI will generate the code accordingly. Vibe Coding speeds up development, reduces mental load, and helps you explore alternative solutions you might not have considered.</p>
                
                  <hr />

                  <h2 id="flush-coding"><span className="text-header-gradient">Flush</span> Coding</h2>
                  <p>Flush Coding is a modern approach to software development where, instead of carefully maintaining and refactoring existing code, you simply rewrite it using AI. Traditional coding practices focus on preserving and improving legacy code over time - but with AI-powered tools, it’s often faster and more effective to flush out the old code and let AI generate a fresh, optimized version from scratch.</p>
                  <p>By focusing on small, self-contained components, Flush Coding allows developers to bypass the complexity of refactoring and benefit from AI’s ability to generate clean, modern code that follows the latest best practices. It’s faster, reduces technical debt, and improves code consistency - though it can be less sustainable in the long run since it treats code as something disposable rather than permanent.</p>
                  <p>Flush Coding works best when you value speed and flexibility over long-term stability, making it ideal for rapid prototyping, feature development, and modernizing outdated code. Why fix it when you can just flush it?</p>

                  <hr />

                  <h2 id="rules-files"><span className="text-header-gradient">Rules</span> Files</h2>
                  <p>Rules files are a way to define the rules and constraints that the AI should follow when generating code. They are typically written in a simple, human-readable format (like .md files) and can be used to enforce coding standards, security policies, and other best practices.</p>
                  <p>Some tools support rules files, while others may have their own way of defining rules. It's important to understand the specific requirements of the tool you're using and follow them carefully to ensure the best results.</p>
                  
                  <p>Here is a list of rules files available for Cursor, which provides an example of how they work:</p>
                  <BaseButton url={"https://github.com/PatrickJS/awesome-cursorrules/tree/main"} targetBlank={true} styles="max-w-full px-8 py-4 bg-color[#3D5A6C] border border-[#0c66ee] dark:text-white">
                  Awesome Cursorrules
                  </BaseButton>

                  <h2 id="coding-more">More <span className="text-header-gradient">Coming Soon</span></h2>
                  <div className="rounded-lg bg-blue-100 border border-blue-500 text-blue-700 p-4" role="alert">
                    <p className="font-bold">Note</p>
                    <p>We are currently working on adding more information to this page on how to use these tools from beginner friendly to advanced techniques. We will be adding more resources, tutorials, and examples to help you get the most out of your coding experience. Stay tuned and check back soon!</p>
                  </div>

                  <div className={"pt-5"}>
                        <BaseButton url={"/documentation/applications/chatgpt#chatgpt"} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                            Next: ChatGPT
                        </BaseButton>
                    </div>      
                </RequireAuth>

              </div>

        </div>
      </section>
  );
}

export default CodingFrameworkDocumentation;
