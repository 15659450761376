import React, { useEffect, useState, useRef } from 'react';
import axios from "../../../libs/axios";
import { format, parseISO, eachDayOfInterval, subYears, startOfDay, getDay } from 'date-fns';

const ActivityHeatmap = () => {
    const [tooltip, setTooltip] = useState({ show: false, content: '', position: { left: 0, top: 0 } });
    const tooltipRef = useRef(null);
    const [activityData, setActivityData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await axios.get('/user-activity', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'X-User-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }
                });
                setActivityData(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching activity data:', err);
                setError('Failed to load activity data');
                setLoading(false);
            }
        };

        fetchActivity();
    }, []);

    const getColorIntensity = (dateStr, activity, maxCount) => {
        // If it's an empty date (not in the calendar range), return invisible box
        if (!dateStr) {
            return 'opacity-0';
        }

        // Find registration date once
        const registrationDateEntry = Object.entries(activityData?.activity || {}).find(([_, data]) => data?.isRegistrationDate);
        
        // If there's no activity data, check if it's before registration
        if (!activity) {
            if (registrationDateEntry) {
                const registrationDateStr = registrationDateEntry[0];
                if (dateStr < registrationDateStr) {
                    return 'bg-gray-100/50 dark:bg-gray-700/30';
                }
            }
            return 'bg-gray-200 dark:bg-gray-700/70';
        }

        // Calculate total activity (logins + ratings + reviews)
        const totalActivity = (activity.count || 0) + (activity.toolsRated || 0) + (activity.toolsReviewed || 0);
        const intensity = Math.ceil((totalActivity / maxCount) * 4);

        // Registration date gets special amber colors with intensity
        if (activity.isRegistrationDate) {
            switch (intensity) {
                case 1: return 'bg-amber-200 dark:bg-amber-900';
                case 2: return 'bg-amber-300 dark:bg-amber-700';
                case 3: return 'bg-amber-400 dark:bg-amber-600';
                case 4: return 'bg-amber-500 dark:bg-amber-500';
                default: return 'bg-amber-200 dark:bg-amber-900';
            }
        }

        // Test results get special colors with intensity
        if (activity.testsPassed) {
            switch (intensity) {
                case 1: return 'bg-purple-200 dark:bg-purple-900';
                case 2: return 'bg-purple-300 dark:bg-purple-700';
                case 3: return 'bg-purple-400 dark:bg-purple-600';
                case 4: return 'bg-purple-500 dark:bg-purple-500';
                default: return 'bg-purple-200 dark:bg-purple-900';
            }
        }

        if (activity.testsFailed) {
            switch (intensity) {
                case 1: return 'bg-red-200 dark:bg-red-900';
                case 2: return 'bg-red-300 dark:bg-red-700';
                case 3: return 'bg-red-400 dark:bg-red-600';
                case 4: return 'bg-red-500 dark:bg-red-500';
                default: return 'bg-red-200 dark:bg-red-900';
            }
        }

        // Email verification date gets special teal colors
        if (activity.isEmailVerificationDate) {
            switch (intensity) {
                case 1: return 'bg-teal-200 dark:bg-teal-500';
                case 2: return 'bg-teal-300 dark:bg-teal-600';
                case 3: return 'bg-teal-400 dark:bg-teal-700';
                case 4: return 'bg-teal-500 dark:bg-teal-800';
                default: return 'bg-teal-200 dark:bg-teal-900';
            }
        }

        // If there's no activity at all, return empty state color
        if (totalActivity === 0 && !activity.testsPassed && !activity.testsFailed) {
            return 'bg-gray-100 dark:bg-gray-700/50';
        }

        // Default activity colors (green)
        switch (intensity) {
            case 1: return 'bg-green-200 dark:bg-green-900';
            case 2: return 'bg-green-300 dark:bg-green-700';
            case 3: return 'bg-green-400 dark:bg-green-600';
            case 4: return 'bg-green-500 dark:bg-green-500';
            default: return 'bg-gray-200 dark:bg-gray-700/30';
        }
    };

    if (loading) return (
        <div className="flex items-center justify-center p-4">
            <div className="animate-pulse text-gray-600">Loading activity data...</div>
        </div>
    );

    if (error) return (
        <div className="flex items-center justify-center p-4">
            <div className="text-red-500">{error}</div>
        </div>
    );

    if (!activityData?.activity) return null;

    // Generate dates for the last year, ending today
    const endDate = startOfDay(new Date());
    const startDate = subYears(endDate, 1);
    const allDates = eachDayOfInterval({ start: startDate, end: endDate }).reverse();

    // Group dates into weeks and track month positions
    const weeks = [];
    let currentWeek = [];
    // Fill in missing days at the start to align with the week
    const firstDay = getDay(allDates[0]);
    for (let i = 0; i < 4; i++) {
        currentWeek.push(['', 0]);
    }

    // Process all dates
    let currentWeekIndex = 0;
    allDates.forEach((date, index) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        // Only include dates that are in the past
        if (date <= new Date()) {
            const activity = activityData?.activity[dateStr];
            currentWeek.push([dateStr, activity || null]);
        }

        if (currentWeek.length === 7) {
            weeks.push(currentWeek);
            currentWeek = [];
            currentWeekIndex++;
        }
    });

    // Fill in any remaining days in the last week
    if (currentWeek.length > 0) {
        while (currentWeek.length < 7) {
            currentWeek.push(['', null]);
        }
        weeks.push(currentWeek);
    }

    // After constructing weeks, compute monthPositions by iterating columns (weeks) left to right
    const computedMonthPositions = new Map();

    weeks.forEach((week, weekIndex) => {
        for (let dayIndex = 0; dayIndex < week.length; dayIndex++) {
            const [date, count] = week[dayIndex];
            if (date) {
                const month = format(parseISO(date), 'MMM');
                if (!computedMonthPositions.has(month)) {
                    computedMonthPositions.set(month, weekIndex);
                }
                // Once we identify a non-empty cell for this column, break
                break;
            }
        }
    });

    return (
        <div className="relative">
            {tooltip.show && (
                <div 
                    ref={tooltipRef}
                    className="fixed z-50 px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded shadow-lg pointer-events-none transition-all duration-150 space-y-1"
                    style={{
                        left: `${tooltip.position.left}px`,
                        top: `${tooltip.position.top}px`,
                        transform: tooltip.position.align === 'right' ? 'translateX(-100%)' : 'none'
                    }}
                >
                    {tooltip.content}
                </div>
            )}
            
            <div className="space-y-6 flex flex-col items-center">
                <h3 className="text-lg font-semibold text-gray-800 mb-1 dark:text-gray-100">
                    Activity
                    <span className="text-sm font-normal ml-2 text-gray-500 dark:text-gray-400">(Last year)</span>
                </h3>

                <div className="w-full">
                    <div className="relative">
                        <div className="overflow-x-auto px-4 py-1 md:flex md:justify-center scrollbar-thin heatmap-scroll" 
                             style={{ 
                                 WebkitOverflowScrolling: 'touch',
                                 scrollbarWidth: 'thin',
                                 msOverflowStyle: 'none'
                             }}>
                            <style>
                                {`.heatmap-scroll::-webkit-scrollbar {
                                    height: 4px;
                                }
                                .heatmap-scroll::-webkit-scrollbar-track {
                                    background: transparent;
                                }
                                .heatmap-scroll::-webkit-scrollbar-thumb {
                                    background-color: rgba(156, 163, 175, 0.3);
                                    border-radius: 20px;
                                }
                                `}
                            </style>
                            <div style={{ minWidth: 'max-content' }} className="flex flex-col">
                            
                            <div className="inline-flex gap-[2px]">
                                {(() => {
                                    const shownMonths = new Set();
                                    const currentMonth = format(new Date(), 'MMM');
                                    let firstCurrentMonthColumn = -1;
                                    let lastColumnWithCurrentMonth = -1;

                                    // First pass: find first and last columns with current month
                                    weeks.forEach((week, index) => {
                                        for (let i = week.length - 1; i >= 0; i--) {
                                            const [date] = week[i];
                                            if (date && format(parseISO(date), 'MMM') === currentMonth) {
                                                lastColumnWithCurrentMonth = index;
                                                if (firstCurrentMonthColumn === -1) {
                                                    firstCurrentMonthColumn = index;
                                                }
                                                break;
                                            }
                                        }
                                    });

                                    return weeks.map((week, weekIndex) => {
                                        // Find the month for this column by checking dates from top to bottom
                                        let columnMonth = null;
                                        for (let i = week.length - 1; i >= 0; i--) {
                                            const [date] = week[i];
                                            if (date) {
                                                columnMonth = format(parseISO(date), 'MMM');
                                                break;
                                            }
                                        }

                                        // Show month label if:
                                        // 1. It's the first time we see this month (from right to left) OR
                                        // 2. It's the current month AND it's either:
                                        //    a. The rightmost occurrence (firstCurrentMonthColumn)
                                        //    b. The leftmost occurrence (lastColumnWithCurrentMonth)
                                        const showMonth = columnMonth && (
                                            (!shownMonths.has(columnMonth) && columnMonth !== currentMonth) || 
                                            (columnMonth === currentMonth && 
                                                (weekIndex === firstCurrentMonthColumn || weekIndex === lastColumnWithCurrentMonth)
                                            )
                                        );

                                        if (showMonth) {
                                            shownMonths.add(columnMonth);
                                        }

                                        return (
                                        <div key={weekIndex} className="flex flex-col gap-[2px]">
                                            {/* Render activity squares */}
                                            {week.map(([date], dayIndex) => (
                                                <div
                                                    key={date || `empty-${weekIndex}-${dayIndex}`}
                                                    className={`w-[12px] h-[12px] rounded-sm ${getColorIntensity(date, activityData?.activity[date], activityData.maxCount)} 
                                                        ${date && new Date(date) <= new Date() ? 'cursor-pointer transition-colors duration-200 hover:ring-2 hover:ring-gray-400 dark:hover:ring-gray-600 hover:ring-opacity-50' : ''}`}
                                                    onMouseEnter={(e) => {
                                                        if (date) {
                                                            const element = e.currentTarget;
                                                            const rect = element.getBoundingClientRect();
                                                            const activity = activityData?.activity[date];
                                                            const padding = 8;
                                                            
                                                            let content = [];
                                                            
                                                            // Add registration info if it's a registration date
                                                            if (activity?.isRegistrationDate === true) {
                                                                content.push('🎉 Account registered');
                                                            }

                                                            // Add email verification info if it's verification date
                                                            if (activity?.isEmailVerificationDate === true) {
                                                                content.push('✉️ Email verified');
                                                            }
                                                            
                                                            // Add login count if there are logins
                                                            if (activity?.count > 0) {
                                                                content.push(`${activity.count} login${activity.count > 1 ? 's' : ''}`);
                                                            }

                                                            // Add tool rating count if there are ratings
                                                            if (activity?.toolsRated > 0) {
                                                                content.push(`${activity.toolsRated} tool rating${activity.toolsRated > 1 ? 's' : ''}`);
                                                            }

                                                            // Add tool review count if there are reviews
                                                            if (activity?.toolsReviewed > 0) {
                                                                content.push(`${activity.toolsReviewed} tool${activity.toolsReviewed > 1 ? 's' : ''} reviewed`);
                                                            }

                                                            // Add test information if there are tests
                                                            if (activity?.testsCount > 0) {
                                                                if (activity.testsPassed) {
                                                                    content.push(`${activity.testsCount} test${activity.testsCount > 1 ? 's' : ''} passed 🎉`);
                                                                } else if (activity.testsFailed) {
                                                                    content.push(`${activity.testsCount} test${activity.testsCount > 1 ? 's' : ''} attempted`);
                                                                }
                                                            }
                                                            
                                                            // If no activity at all
                                                            if (content.length === 0) {
                                                                content.push('No activity');
                                                            }

                                                            // Start with default position (to the right)
                                                            let left = rect.right + padding;
                                                            let top = rect.top + (rect.height / 2);
                                                            
                                                            // If tooltip might go off right edge, position it to the left
                                                            let align = 'left';
                                                            if (left + 200 > window.innerWidth) { // 200 is an estimated max tooltip width
                                                                left = rect.left - padding;
                                                                align = 'right';
                                                            }
                                                            
                                                            // If tooltip might go off left edge, position it below
                                                            if (left < 0) {
                                                                left = rect.left;
                                                                top = rect.bottom + padding;
                                                            }
                                                            
                                                            // If tooltip might go off bottom edge, position it above
                                                            if (top + 50 > window.innerHeight) { // 50 is an estimated max tooltip height
                                                                top = rect.top - padding - 50;
                                                            }
                                                            
                                                            setTooltip({
                                                                show: true,
                                                                content: `${format(parseISO(date), 'MMM d, yyyy')}: ${content.join(', ')}`,
                                                                position: { left, top, align }
                                                            });
                                                        }
                                                    }}
                                                    onMouseLeave={() => setTooltip({ show: false, content: '', position: { left: 0, top: 0 } })}
                                                />
                                            ))}
                                            {/* Render month label below the column */}
                                            <div className="relative h-8">
                                                <div className="text-xs text-gray-500 dark:text-gray-400 absolute left-1/2 -translate-x-1/2 whitespace-nowrap mt-3">
                                                    {showMonth ? columnMonth : ''}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    });
                                })()}
                            </div>
                            </div>
                        </div>
                        <div className="h-6"></div> {/* Spacer for absolute positioned labels */}
                    </div>

                    <div className="flex justify-center">
                        <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                            <span>Less</span>
                            <div className="flex gap-1">
                                <div className="w-[12px] h-[12px] rounded-sm bg-gray-200 dark:bg-gray-700/50" />
                                <div className="w-[12px] h-[12px] rounded-sm bg-green-200 dark:bg-green-900" />
                                <div className="w-[12px] h-[12px] rounded-sm bg-green-300 dark:bg-green-700" />
                                <div className="w-[12px] h-[12px] rounded-sm bg-green-400 dark:bg-green-600" />
                                <div className="w-[12px] h-[12px] rounded-sm bg-green-500 dark:bg-green-500" />
                            </div>
                            <span>More</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityHeatmap;
