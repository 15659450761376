import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../libs/axios";
import CertificateGenerator from "../dashboard/certificate";
import { AlertCircle, CheckCircle } from "lucide-react";

interface Certificate {
    certificate_id: string;
    username: string;
    date: string;
    valid: boolean;
    error?: string;
}

const ShowCertificateOnline: React.FC = () => {
    const RATE_LIMIT_DURATION = 60*1; // seconds
    const STORAGE_KEY = 'certificateRateLimitStartTime';
    const [timeLeft, setTimeLeft] = React.useState(() => {
        const storedStartTime = sessionStorage.getItem(STORAGE_KEY);
        if (storedStartTime) {
            const elapsedTime = Math.floor((Date.now() - parseInt(storedStartTime)) / 1000);
            if (elapsedTime >= RATE_LIMIT_DURATION) {
                sessionStorage.removeItem(STORAGE_KEY);
                return 0;
            }
            return Math.max(0, RATE_LIMIT_DURATION - elapsedTime);
        }
        return 0;
    });
    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const { id } = useParams<{ id: string }>();
    const [certificate, setCertificate] = useState<Certificate | null>(null);
    let navigate = useNavigate();

    const formatTime = (seconds: number) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    React.useEffect(() => {
        // Only start timer if we're rate limited
        if (!certificate?.error || certificate.error !== 'Rate limit exceeded') {
            return;
        }

        // Initialize timer if we're just starting rate limit
        if (timeLeft === 0) {
            const storedStartTime = sessionStorage.getItem(STORAGE_KEY);
            if (!storedStartTime) {
                sessionStorage.setItem(STORAGE_KEY, Date.now().toString());
                setTimeLeft(RATE_LIMIT_DURATION);
            }
            return;
        }

        // Handle countdown
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    sessionStorage.removeItem(STORAGE_KEY);
                    window.location.reload();
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft, certificate?.error]);

    const handleRefresh = () => {
        setIsRefreshing(true);
        window.location.reload();
    };

    useEffect(() => {
        // Check if the $id is this format: 12345-12345-12345
        if (id && !/^\d{5}-\d{5}-\d{5}$/.test(id)) {
            setCertificate({certificate_id: "", username: "", date: "", valid: false})
        }else {
            axios.get(`public/verify-certificate/${id}`)
                .then(response => {
                    setCertificate(response.data);
                })
                .catch(error => {
                    if (error.response?.status === 429) {
                        // Store the rate limit start time
                        sessionStorage.setItem(STORAGE_KEY, Date.now().toString());
                        setCertificate({certificate_id: "", username: "", date: "", valid: false, error: "Rate limit exceeded"})
                    } else {
                        setCertificate({certificate_id: "", username: "", date: "", valid: false})
                    }
                });
        }
    }, [id]);

    if (!certificate) {
        return (
            <div className="flex justify-center items-center h-screen bg-gradient-to-br from-gray-50 via-gray-50 to-blue-50 dark:from-gray-900 dark:via-gray-900 dark:to-blue-900">
                <div className="relative">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-400"></div>
                    <div className="absolute inset-0 rounded-full blur-xl bg-blue-500/20 dark:bg-blue-400/20 animate-pulse"></div>
                </div>
            </div>
        );
    }

    const validityColor = certificate.valid ? "green" : "red";
    const validityText = certificate.error ? certificate.error : (certificate.valid ? "Valid" : "Invalid");
    const ValidityIcon = certificate.valid ? CheckCircle : AlertCircle;

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8 transition-colors duration-200">
            <div className="max-w-4xl mx-auto bg-white dark:bg-gray-800 shadow-sm rounded-xl overflow-hidden border border-gray-100 dark:border-gray-700">

                {certificate.valid ? (
                <div className="px-8 py-10 sm:p-10 flex flex-col md:flex-row gap-12">
                    <div className="md:w-1/2 space-y-8">
                        <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider font-medium mb-1">Name</p>
                            <p className="text-xl font-bold text-gray-900 dark:text-white">{certificate.username}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider font-medium mb-1">Certificate ID</p>
                            <p className="text-lg font-mono py-1 rounded-lg text-gray-800 dark:text-white">{certificate.certificate_id}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider font-medium mb-1">Issue Date</p>
                            <p className="text-lg text-gray-800 dark:text-white">{certificate.date}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500 dark:text-gray-400 uppercase tracking-wider font-medium mb-1">Issuing Authority</p>
                            <p className="text-lg text-gray-800 dark:text-white">Crafting AI Prompts</p>
                        </div>

                        <hr className="border-gray-200/50 dark:border-gray-700/50" />

                        <div className="text-gray-600 dark:text-gray-300 mb-8 bg-gray-50 dark:bg-gray-800/50 p-4 rounded-xl border border-gray-100 dark:border-gray-700/50">
                            <p>You can also take this test. <br />
                                Please click on the button below to access the available tests.</p>
                        </div>

                        <div className="flex justify-center">
                            <button
                                onClick={() => navigate('/test')}
                                className="inline-flex items-center px-4 py-2 bg-gray-800 dark:bg-gray-700 border border-transparent rounded-md font-semibold text-sm text-white tracking-wide hover:bg-gray-700 dark:hover:bg-gray-600 active:bg-gray-900 dark:active:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:focus:ring-offset-gray-800 transition-colors duration-200"
                            >
                                Take the test yourself!
                            </button>
                        </div>
                    </div>


                    <div className="md:w-1/2">
                        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 mb-3 w-full flex items-center justify-center gap-3">
                            <div className={`relative inline-flex items-center ${validityColor === 'green' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                                <ValidityIcon className="w-8 h-8" />
                                <div className={`absolute inset-0 ${validityColor === 'green' ? 'text-green-600/10 dark:text-green-400/10' : 'text-red-600/10 dark:text-red-400/10'}`}>
                                    <ValidityIcon className="w-8 h-8 animate-ping" />
                                </div>
                            </div>
                            <span className={`text-lg font-semibold ${validityColor === 'green' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                                {validityText} Certificate
                            </span>
                        </div>
                        <div className="mb-8">
                            <CertificateGenerator
                                name={certificate.username}
                                certificateId={certificate.certificate_id}
                                dateCompleted={certificate.date}
                                showCertificateId={false}
                                showDownload={false}
                                preview={true}
                            />
                        </div>

                        <p className="mb-4 text-center dark:text-white">
                            <strong className="text-gray-900 dark:text-white">{certificate.username}</strong> has completed the test.
                            <br />
                            This certificate is currently <strong className={`text-${validityColor}-600`}>{validityText.toLowerCase()}</strong>.
                        </p>
                        <p className="text-sm text-center text-gray-600 dark:text-gray-400">
                            For the original certificate, please contact the user.
                        </p>
                    </div>
                </div>
                    ) :
                    <div className="px-6 py-8 sm:p-8">
                        {certificate.error === 'Rate limit exceeded' ? (
                            <div className="max-w-2xl mx-auto">
                                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-gray-800 dark:to-gray-900 
                                          overflow-hidden rounded-xl shadow-xl p-8">
                                    <div className="text-center space-y-6">
                                        {/* Icon with pulse animation */}
                                        <div className="mx-auto w-20 h-20 bg-blue-100 dark:bg-gray-700 rounded-full flex items-center justify-center 
                                                    animate-pulse mb-2 relative">
                                            <span className="text-4xl">⏳</span>
                                            {/* Circular progress */}
                                            <svg className="absolute inset-0 w-full h-full -rotate-90">
                                                <circle
                                                    className="text-gray-200 dark:text-gray-600"
                                                    strokeWidth="4"
                                                    stroke="currentColor"
                                                    fill="transparent"
                                                    r="38"
                                                    cx="40"
                                                    cy="40"
                                                />
                                                <circle
                                                    className="text-blue-500 dark:text-blue-400"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                    stroke="currentColor"
                                                    fill="transparent"
                                                    r="38"
                                                    cx="40"
                                                    cy="40"
                                                    strokeDasharray={`${2 * Math.PI * 38}`}
                                                    strokeDashoffset={`${2 * Math.PI * 38 * (1 - timeLeft / RATE_LIMIT_DURATION)}`}
                                                    style={{ transition: 'stroke-dashoffset 1s linear' }}
                                                />
                                            </svg>
                                        </div>
                                        
                                        {/* Main heading with gradient text */}
                                        <h3 className="text-3xl font-extrabold bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-400 dark:to-indigo-400 
                                                    bg-clip-text text-transparent">
                                            Easy there! 🚀
                                        </h3>
                                        
                                        {/* Message with better typography and spacing */}
                                        <div className="prose prose-lg dark:prose-invert mx-auto">
                                            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                                                We noticed you're checking certificates quite quickly! To ensure system stability,
                                                please wait a moment before trying again.
                                            </p>
                                            <p className="text-sm text-blue-600 dark:text-blue-400 font-medium">
                                                Auto-refreshing in {formatTime(timeLeft)}
                                            </p>
                                        </div>
                                        
                                        {/* Refresh button with hover effect */}
                                        <div className="mt-8">
                                            <button 
                                                onClick={handleRefresh}
                                                disabled={isRefreshing}
                                                className="group relative inline-flex items-center justify-center px-8 py-3 text-lg 
                                                         font-medium text-white bg-gradient-to-r from-blue-500 to-indigo-600 
                                                         rounded-full overflow-hidden shadow-lg hover:shadow-xl 
                                                         transform hover:scale-105 transition-all duration-300">
                                                <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-indigo-500 to-blue-600 
                                                             opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                                                <span className="relative flex items-center">
                                                    {isRefreshing ? 'Refreshing...' : 'Refresh Now'}
                                                    <svg className={`ml-2 w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        
                                        {/* Additional help text */}
                                        <p className="text-sm text-gray-500 dark:text-gray-400 mt-4">
                                            This helps us maintain a smooth experience for all users.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={`bg-gradient-to-b from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 shadow-xl rounded-2xl overflow-hidden border border-gray-200/50 dark:border-gray-700/50`}>
                                <div className="p-6 relative">
                                    <div className="flex items-center justify-center gap-5">
                                        <div className={`relative`}>
                                            <div className={`absolute inset-0 ${validityColor === 'green' ? 'bg-green-500/10 dark:bg-green-400/10' : 'bg-red-500/10 dark:bg-red-400/10'} blur-2xl rounded-full`}></div>
                                            <div className={`relative flex items-center justify-center rounded-full p-3 ${validityColor === 'green' ? 'bg-gradient-to-br from-green-100 to-emerald-50 dark:from-green-900/50 dark:to-emerald-900/30' : 'bg-gradient-to-br from-red-100 to-rose-50 dark:from-red-900/50 dark:to-rose-900/30'} `}>
                                                <ValidityIcon className={`w-8 h-8 ${validityColor === 'green' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`} />
                                            </div>
                                        </div>
                                        <div className="relative mt-4">
                                            <div className={`absolute inset-0 ${validityColor === 'green' ? 'bg-gradient-to-r from-green-500/10 to-emerald-500/10 dark:from-green-400/10 dark:to-emerald-400/10' : 'bg-gradient-to-r from-red-500/10 to-rose-500/10 dark:from-red-400/10 dark:to-rose-400/10'} blur-xl -skew-y-12 translate-y-1/2`}></div>
                                            <h2 className={`relative text-2xl font-bold ${validityColor === 'green' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                                                {validityText}
                                            </h2>
                                        </div>
                                    </div>
                                    {!certificate.valid && !certificate.error && (
                                        <div className="mt-5 text-center">
                                            <p className="text-sm text-gray-600 dark:text-gray-400 backdrop-blur px-4 py-2 rounded-lg inline-block">
                                                This certificate is not valid. Please contact the issuer for more information.
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className={`h-1 w-full ${validityColor === 'green' ? 'bg-gradient-to-r from-green-400/50 via-emerald-500/50 to-green-400/50 dark:from-green-500/50 dark:via-emerald-400/50 dark:to-green-500/50' : 'bg-gradient-to-r from-red-400/50 via-rose-500/50 to-red-400/50 dark:from-red-500/50 dark:via-rose-400/50 dark:to-red-500/50'}`}></div>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    );
}

export default ShowCertificateOnline;