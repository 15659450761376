import React from 'react';
import { PromptCard } from "./PromptCard";
import { SectionHeader } from "./SectionHeader";
import { NoPromptFound } from "./NoPromptFound";
import { GridItem } from "./types/GridItem";
import { Section } from "./types/Section";
import { ViewMode } from "./types/ViewMode";
import { Tool } from "./types/Tool";

interface PromptSectionsProps {
    section: Section;
    sectionPrompts: GridItem[];
    viewMode: ViewMode;
    copiedId: string | null;
    mergedPrompts: GridItem[];
    tools: Tool[];
    handleCopy: (prompt: string, id: string) => void;
    handleAddToMerged: (prompt: GridItem) => void;
    handleDeletePrompt: (id: string) => void;
    handleEditPrompt: (prompt: GridItem) => void;
    handleOpenModal: (section: Section) => void;
    togglePromptPublish?: (promptId: string) => void;
}

export const PromptSections: React.FC<PromptSectionsProps> = ({
    section,
    sectionPrompts,
    viewMode,
    copiedId,
    mergedPrompts,
    tools,
    handleCopy,
    handleAddToMerged,
    handleDeletePrompt,
    handleEditPrompt,
    handleOpenModal,
    togglePromptPublish
}) => {
    return (
        <div key={section} className="space-y-3">
            <SectionHeader section={section} />
            
            {sectionPrompts.length === 0 ? (
                <NoPromptFound section={section} handleOpenModal={handleOpenModal} />
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                    {sectionPrompts.map(prompt => (
                        <PromptCard
                            key={prompt.id}
                            prompt={prompt}
                            viewMode={viewMode}
                            copiedId={copiedId}
                            mergedPrompts={mergedPrompts}
                            tools={tools}
                            handleCopy={handleCopy}
                            handleAddToMerged={handleAddToMerged}
                            handleDeletePrompt={handleDeletePrompt}
                            handleEditPrompt={handleEditPrompt}
                            togglePublish={togglePromptPublish}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}