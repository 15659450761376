import React, { useState, useEffect, useRef } from 'react';
import { GridItem } from './types/GridItem';
import { Tool } from './types/Tool';
import { ViewMode } from './types/ViewMode';
import { ToolBadge } from './ToolBadge';
import { formatTextWithVariables } from '../common/FormatTextWithVariables';

interface PromptCardProps {
    prompt: GridItem;
    viewMode: ViewMode;
    copiedId: string | null;
    mergedPrompts: GridItem[];
    tools: Tool[];
    handleCopy: (prompt: string, id: string) => void;
    handleAddToMerged: (prompt: GridItem) => void;
    handleDeletePrompt: (id: string) => void;
    handleEditPrompt: (prompt: GridItem) => void;
    togglePublish?: (id: string) => void;
}



export const PromptCard: React.FC<PromptCardProps> = ({ 
    prompt, 
    viewMode, 
    copiedId, 
    mergedPrompts, 
    tools,
    handleCopy, 
    handleAddToMerged, 
    handleDeletePrompt,
    handleEditPrompt,
    togglePublish,
}) => {
    // State to control the dropdown menu
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    
    // Handle clicks outside the menu to close it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        };
        
        // Add event listener when menu is open
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        
        // Clean up the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);
    
    // Custom function to handle edit prompt that ensures menu is closed
    const handleEditPromptClick = (e: React.MouseEvent, promptData: GridItem) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Close the menu
        setIsMenuOpen(false);
        
        // Add a small delay before handling the edit action
        setTimeout(() => {
            handleEditPrompt(promptData);
        }, 10);
    };
    
    // Custom function to handle toggle publish that ensures menu is closed
    const handleTogglePublishClick = (e: React.MouseEvent, promptId: string) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Close the menu
        setIsMenuOpen(false);
        
        // Add a small delay before handling the toggle action
        setTimeout(() => {
            if (togglePublish) {
                togglePublish(promptId);
            }
        }, 10);
    };
    
    // Function to handle delete prompt that ensures menu is closed
    const handleDeletePromptClick = (e: React.MouseEvent, promptId: string) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Close the menu
        setIsMenuOpen(false);
        
        // Add a small delay before handling the delete action
        setTimeout(() => {
            handleDeletePrompt(promptId);
        }, 10);
    };
    
    const truncateText = (text: string, maxLength: number = 100) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    if (viewMode === 'table') {
        return (
            <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                <td className="px-4 py-3 font-medium text-gray-900 dark:text-white">
                    <div className="flex items-center gap-2">
                        {prompt.title}
                        {prompt.type === 'example' && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                                bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400"
                            >
                                Example
                            </span>
                        )}
                        {/* Template Badge */}
                        {prompt.type === 'template' && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-cyan-50 text-cyan-600 dark:bg-cyan-900/20 dark:text-cyan-400"
                            >
                                Template
                            </span>
                        )}
                        {/* Published Badge */}
                        {prompt.is_published && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-emerald-50 text-emerald-600 dark:bg-emerald-900/20 dark:text-emerald-400"
                            >
                                Published
                            </span>
                        )}
                    </div>
                </td>
                <td className="px-4 py-3 text-gray-600 dark:text-gray-300">
                    <div className="group relative">
                        <span className="line-clamp-2 group-hover:line-clamp-none">
                        {formatTextWithVariables(prompt.prompt)}
                        </span>
                        {prompt.prompt.length > 100 && (
                            <div className="absolute left-0 right-0 bottom-0 h-6 bg-gradient-to-t from-white dark:from-gray-800 group-hover:hidden" />
                        )}
                    </div>
                </td>
                <td className="px-4 py-3">
                    {prompt.labels.map(label => (
                        <span 
                            key={label}
                            className="inline-flex mb-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                bg-gray-100 text-gray-600
                                dark:bg-gray-700 dark:text-gray-300 mr-1"
                        >
                            {label}
                        </span>
                    ))}
                </td>
                <td className="px-4 py-3">
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => handleCopy(prompt.prompt, prompt.id)}
                            className="p-1 text-blue-600 hover:text-blue-700 dark:text-blue-400"
                            title="Copy Prompt"
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" 
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => handleAddToMerged(prompt)}
                            className={`p-1 ${
                                mergedPrompts.some(p => p.id === prompt.id)
                                    ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed' 
                                    : 'text-blue-600 hover:text-blue-700 dark:text-blue-400'
                            }`}
                            title="Add to Merged"
                            disabled={mergedPrompts.some(p => p.id === prompt.id)}
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                        </button>
                        {togglePublish && prompt.is_owner && (
                            <button
                                onClick={() => togglePublish(prompt.id)}
                                className="p-1 text-blue-600 hover:text-blue-700 dark:text-blue-400"
                                title={prompt.is_published ? "Unpublish Prompt" : "Publish Prompt"}
                            >
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    {prompt.is_published ? (
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    ) : (
                                        <>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </>
                                    )}
                                </svg>
                            </button>
                        )}
                        {prompt.is_owner && (
                            <>
                                <button
                                    onClick={() => handleEditPrompt(prompt)}
                                    className="p-1 text-blue-600 hover:text-blue-700 dark:text-blue-400"
                                    title="Edit Prompt"
                                >
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" 
                                        />
                                    </svg>
                                </button>
                                <button
                                    onClick={() => handleDeletePrompt(prompt.id)}
                                    className="p-1 text-red-600 hover:text-red-700 dark:text-red-400"
                                    title="Delete Prompt"
                                >
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                                        />
                                    </svg>
                                </button>
                            </>
                        )}
                    </div>
                </td>
            </tr>
        );
    }

    // Return grid view
    return(
        <div 
            key={prompt.id}
            className="prompt-card bg-white dark:bg-gray-800 rounded-xl p-5 shadow-sm
                border border-gray-100 dark:border-gray-700
                hover:shadow-lg hover:border-blue-200 dark:hover:border-blue-800 transition-all duration-300
                flex flex-col relative overflow-hidden group"
            style={{
                backgroundImage: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.05))'
            }}
        >
            {/* Decorative gradient element */}
            <div className="absolute -top-10 -right-10 w-20 h-20 bg-gradient-to-br from-blue-400/10 to-purple-500/10 rounded-full blur-xl transform transition-all duration-500 ease-in-out group-hover:scale-150 group-hover:opacity-70"></div>
            <div className="space-y-2 mb-3">
                <div className="flex justify-between items-start relative z-10">
                    <div className="flex flex-wrap gap-2">
                        {/* Section Label */}
                        <span className={`inline-block px-2.5 py-0.5 text-xs font-medium rounded-full text-white
                            shadow-sm transform transition-transform duration-200 hover:scale-105
                            ${prompt.section === 'Context' ? 'bg-gradient-to-r from-red-500 to-red-600 dark:from-red-600 dark:to-red-700' : ''}
                            ${prompt.section === 'Register' ? 'bg-gradient-to-r from-purple-500 to-purple-600 dark:from-purple-600 dark:to-purple-700' : ''}
                            ${prompt.section === 'Acting Role' ? 'bg-gradient-to-r from-green-500 to-green-600 dark:from-green-600 dark:to-green-700' : ''}
                            ${prompt.section === 'Format' ? 'bg-gradient-to-r from-yellow-500 to-yellow-600 dark:from-yellow-600 dark:to-yellow-700' : ''}
                            ${prompt.section === 'Task' ? 'bg-gradient-to-r from-blue-500 to-blue-600 dark:from-blue-600 dark:to-blue-700' : ''}
                            ${!['Context', 'Register', 'Acting Role', 'Format', 'Task'].includes(prompt.section) ? 'bg-gradient-to-r from-gray-500 to-gray-600 dark:from-gray-600 dark:to-gray-700' : ''}
                        `}
                        >
                            {prompt.section}
                        </span>
                        
                        {/* Example Badge */}
                        {prompt.type === 'example' && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-gradient-to-r from-green-500 to-green-600 text-white dark:from-green-600 dark:to-green-700
                                shadow-sm transform transition-transform duration-200 hover:scale-105"
                            >
                                Example
                            </span>
                        )}

                         {/* Template Badge */}
                         {prompt.type === 'template' && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-gradient-to-r from-cyan-500 to-cyan-600 text-white dark:from-cyan-600 dark:to-cyan-700
                                shadow-sm transform transition-transform duration-200 hover:scale-105"
                            >
                                Template
                            </span>
                        )}
                        
                        {/* Published Badge */}
                        {prompt.is_published && (
                            <span className="inline-block px-2.5 py-0.5 text-xs font-medium rounded-full
                                bg-gradient-to-r from-emerald-500 to-emerald-600 text-white dark:from-emerald-600 dark:to-emerald-700
                                shadow-sm transform transition-transform duration-200 hover:scale-105"
                            >
                                Published
                            </span>
                        )}
                    </div>
                    
                    {prompt.is_owner && (
                    <div className="relative inline-block text-left" style={{ zIndex: 99 }} ref={menuRef}>
                        <button 
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsMenuOpen(!isMenuOpen);
                            }}
                            className="p-2 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300 
                                rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-200 
                                transform hover:scale-110 hover:shadow-sm relative z-[99]"
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                            </svg>
                        </button>
                        
                        {isMenuOpen && (
                            <div 
                                ref={menuRef}
                                className="absolute right-0 mt-2 w-48 origin-top-right divide-y border border-gray-100 dark:border-gray-700 rounded-md bg-white dark:bg-gray-800 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none z-[100] animate-fadeIn backdrop-blur-sm bg-opacity-95 dark:bg-opacity-95"
                                style={{ pointerEvents: 'auto' }}
                            >
                                <div className="px-1 py-1">
                                    <button
                                        onClick={(e) => handleEditPromptClick(e, prompt)}
                                        className="group flex w-full items-center rounded-md px-3 py-3 text-sm text-gray-700 dark:text-gray-300 hover:bg-gradient-to-r hover:from-blue-50 hover:to-indigo-50 dark:hover:from-blue-900/20 dark:hover:to-indigo-900/20 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-200"
                                    >
                                        <svg className="mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                        Edit Prompt
                                    </button>
                                    
                                    {togglePublish && (
                                        <button
                                            onClick={(e) => handleTogglePublishClick(e, prompt.id)}
                                            className="group flex w-full items-center rounded-md px-3 py-3 text-sm text-gray-700 dark:text-gray-300 hover:bg-gradient-to-r hover:from-blue-50 hover:to-indigo-50 dark:hover:from-blue-900/20 dark:hover:to-indigo-900/20 hover:text-blue-600 dark:hover:text-blue-400 transition-all duration-200"
                                        >
                                            <svg className="mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                {prompt.is_published ? (
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                ) : (
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                )}
                                                {prompt.is_published ? null : (
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                )}
                                            </svg>
                                            {prompt.is_published ? 'Unpublish' : 'Publish'}
                                        </button>
                                    )}
                                    
                                    <button
                                        onClick={(e) => handleDeletePromptClick(e, prompt.id)}
                                        className="group flex w-full items-center rounded-md px-3 py-3 text-sm text-red-600 dark:text-red-400 hover:bg-gradient-to-r hover:from-red-50 hover:to-pink-50 dark:hover:from-red-900/20 dark:hover:to-pink-900/20 hover:text-red-700 dark:hover:text-red-500 transition-all duration-200"
                                    >
                                        <svg className="mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                        Delete Prompt
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                   )}
                </div>

                {/* Tool badges */}
                {prompt.toolId && tools.length > 0 && <ToolBadge toolId={prompt.toolId} tools={tools} />}
                
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white break-words mt-2 relative z-[5]">
                    {prompt.title}
                </h3>
            </div>

            <div className="prompt-content relative z-[5]">
                <p className="text-gray-600 dark:text-gray-300 text-sm mb-3 line-clamp-3 hover:line-clamp-none transition-all duration-200">
                    {formatTextWithVariables(prompt.prompt)}
                </p>
            </div>

            <div className="flex flex-wrap gap-1.5 mb-4 relative z-[5]">
                    {prompt.labels.map(label => (
                        <span 
                            key={label}
                            className="px-2 py-0.5 text-xs font-medium rounded-full
                                bg-gray-100 text-gray-600 hover:bg-gray-200
                                dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600
                                transform transition-all duration-200 hover:scale-105 hover:shadow-sm"
                        >
                            {label}
                        </span>
                    ))}
            </div>

            <div className="flex gap-2 mt-auto pt-2 border-t border-gray-100 dark:border-gray-700 relative z-[5]">
                <button
                    onClick={() => handleCopy(prompt.prompt, prompt.id)}
                    className={`flex items-center justify-center gap-1 px-3 py-1.5 rounded-full text-xs font-medium
                        transition-all duration-300 w-1/3 shadow-sm hover:shadow transform hover:scale-105
                        ${copiedId === prompt.id
                            ? 'bg-gradient-to-r from-green-500 to-green-600 text-white dark:from-green-600 dark:to-green-700'
                            : 'bg-gradient-to-r from-blue-500 to-blue-600 text-white dark:from-blue-600 dark:to-blue-700 hover:from-blue-600 hover:to-blue-700 dark:hover:from-blue-500 dark:hover:to-blue-600'
                        }`}
                >
                    <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        {copiedId === prompt.id ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                        )}
                    </svg>
                </button>
                <button
                    onClick={() => handleAddToMerged(prompt)}
                    className={`flex items-center justify-center gap-1 px-3 py-1.5 rounded-full text-xs font-medium
                        transition-colors duration-200 w-2/3
                        ${mergedPrompts.some(p => p.id === prompt.id)
                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-700 dark:text-gray-500'
                            : 'bg-blue-50 text-blue-600 hover:bg-blue-100 dark:bg-blue-900/20 dark:text-blue-400 dark:hover:bg-blue-900/30'
                        }`}
                    disabled={mergedPrompts.some(p => p.id === prompt.id)}
                >
                    <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        {mergedPrompts.some(p => p.id === prompt.id) ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        )}
                    </svg>
                    <span className="ml-1">Add to Recipe</span>
                </button>
            </div>
        </div>
    );
}