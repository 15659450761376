enum RoleEnum {
    SuperAdmin = 10,
    Admin = 8,
    Moderator = 5,
    Trainer = 3,
    ProUser = 2,
    DefaultUser = 1,
    Registered = 0,
}

export default RoleEnum;