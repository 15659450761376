import useSWR from 'swr'
import axios from '../libs/axios'
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import RoleEnum from "../components/enums/RoleEnum";
import toast from 'react-hot-toast';

export const useAuth = ({middleware = 'auth', redirectIfAuthenticated = false} = {}) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [authError, setAuthError] = React.useState(null);

    let navigate = useNavigate();
    let params = useParams();

    const {data: user, error, mutate} = useSWR('/user', () => {
        return axios
            .get('/user')
            .then(res => {
                const userData = res.data;
                if (userData.profile && userData.profile.show_examples) {
                    localStorage.setItem('safetyTermsInjections', 'true');
                } else {
                    localStorage.removeItem('safetyTermsInjections');
                }
                return userData;
            })
            .catch(error => {
                setAuthError(error.response?.status);
                // Handle rate limit separately from auth errors
                if (error.response?.status === 429) {
                    const storedTime = sessionStorage.getItem('rateLimitStartTime');
                    const now = Date.now();
                    
                    // Check if we have a stored time that's still valid (less than 60 seconds old)
                    if (storedTime) {
                        const elapsedTime = Math.floor((now - parseInt(storedTime)) / 1000);
                        if (elapsedTime >= 60) {
                            // Time is stale, remove it and set new time
                            sessionStorage.removeItem('rateLimitStartTime');
                            sessionStorage.setItem('rateLimitStartTime', now.toString());
                        }
                        // If time is not stale, keep using the existing time
                    } else {
                        // No stored time, set new one
                        sessionStorage.setItem('rateLimitStartTime', now.toString());
                    }
                    return { isRateLimited: true };
                }
                
                if (error.response?.status === 401) {
                    if (localStorage.getItem('username') !== null && localStorage.getItem('username') !== "") {
                        localStorage.setItem('username', "");
                        localStorage.setItem('open_tickets', 0);
                    }
                    if (middleware !== 'guest' && window.location.pathname !== "/login" && window.location.pathname !== "/register" && window.location.pathname !== "/forgot-password" && window.location.pathname !== "/reset-password") {
                        logout();
                    }
                    return null;
                }
                
                if (error.response?.status !== 409) {
                    //throw error // Put off to remove logging to console.
                    //mutate('/verify-email')
                }

                return null;
            });
    }, {
        revalidateIfStale: true,
        revalidateOnFocus: true,
        onSuccess: () => setIsLoading(false),
        onError: () => setIsLoading(false)
    })

    const csrf = () => axios.get('/sanctum/csrf-cookie')

    const register = async ({setErrors, ...props}) => {
        await csrf()
        setErrors([])
        axios
            .post('/register', props)
            .then(() => mutate().then(() => {
                navigate('/dashboard/overview');  // Redirect to index page
            }))
            .catch(error => {
                //if (error.response.status !== 422) throw error
                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const login = async ({setErrors, setStatus, ...props}) => {
        await csrf()
        setErrors([])
        setStatus(null)
        axios
            .post('/login', props)
            .then(() => {
                window.location.reload(); // Reload so the page post is gone
                mutate();
                
            })
            .catch(error => {
                //if (error.response.status !== 422) throw error
                if (error.response.data.errors) {
                    setErrors(Object.values(error.response.data.errors).flat())
                }else if(error.response.data.message) {
                    setErrors(Object.values([error.response.data.message]).flat());
                }else {
                    setErrors(Object.values(["Unknown error"]).flat());
                }
            });

    }

    const forgotPassword = async ({setErrors, setStatus, email}) => {
        await csrf()
        setErrors([])
        setStatus(null)
        axios
            .post('/forgot-password', {email})
            .then(response => setStatus(response.data.status))
            .catch(error => {
                //if (error.response.status !== 422) throw error
                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resetPassword = async ({setErrors, setStatus, ...props}) => {
        await csrf()
        setErrors([])
        setStatus(null)
        axios
            .post('/reset-password', {token: params.token, ...props})
            .then(response => navigate(`/login?reset=${  btoa(response.data.status)}`))
            .catch(error => {
                //if (error.response.status !== 422) throw error
                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resendEmailVerification = ({setStatus}) => {
        axios
            .post('/email/verification-notification')
            .then(response => setStatus(response.data.status))
            .catch(error => {
                if (error.response && error.response.data) {
                    if (error.response.data.code === 100) {
                        toast.error(error.response.data.message);
                        setStatus('resend');
                    } else {
                        setStatus(error.response.data.message || 'Failed to resend verification email');
                    }
                } else {
                    setStatus('Failed to resend verification email. Please try again.');
                }
            });
    }

    const logout = async () => {
        unsetAuthenticationSession();
        
        if (!error) {
            try {
                await axios.post('/logout');
                mutate();
            } catch (error) {
                // Ignore other errors during logout
            }
        }

        if (window.location.pathname !== '/login') {
            window.location.pathname = '/login'
        }
    }

    const unsetAuthenticationSession = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('open_tickets');
        localStorage.removeItem('total_open_tickets');
        localStorage.removeItem('total_pending_tickets');
        localStorage.removeItem('safetyTermsInjections');
    }


    // Update loading state when user or error changes
    useEffect(() => {
        if (user || error) {
            setIsLoading(false);
        }
    }, [user, error]);

    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) navigate(redirectIfAuthenticated)
        if (middleware === 'auth' && error) logout()
        if (middleware === 'trainer') {
            if (user && user.role < RoleEnum.Trainer) {
                navigate('/dashboard')
            }else if(error){
                logout();
            }
        }
        if (middleware === 'moderator') {
            if (user && user.role < RoleEnum.Moderator) {
                navigate('/dashboard')
            }else if(error){
                logout();
            }
        }
        if (middleware === 'admin' && !error) {
            if (user && user.role < RoleEnum.Admin) {
                navigate('/dashboard')
            }
        }

        if (user) {
            localStorage.setItem('username', user.name);
            if (user.open_tickets !== undefined) {
                localStorage.setItem('open_tickets', user.open_tickets);
            }
            if (user.total_open_tickets !== undefined) {
                localStorage.setItem('total_open_tickets', user.total_open_tickets);
            }
            if (user.total_pending_tickets !== undefined) {
                localStorage.setItem('total_pending_tickets', user.total_pending_tickets);
            }
        }else {
            unsetAuthenticationSession();
            setAuthError(401);
        }

        setIsLoading(false);
    }, [user, error])

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
        csrf,
        isLoading,
        authError
    }
}