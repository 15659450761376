import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FiAward, FiBook, FiZap, FiShield, FiMessageSquare, FiX, FiTarget, FiCpu, FiArrowLeft, FiCheckCircle, FiUpload, FiLink, FiExternalLink, FiThumbsUp, FiThumbsDown } from 'react-icons/fi';
import { HandCoins } from 'lucide-react';
import axios from "../../../libs/axios";
import RoleEnum from '../../enums/RoleEnum';
import toast from 'react-hot-toast';

export default function ChallengeSubmission({ challenge, isOpen, onClose, onSubmit, onReview, isSubmitting, isCompleted = false, submissionData = null, user }) {
  const [submission, setSubmission] = useState("");
  const [submissionUrl, setSubmissionUrl] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [localSubmissionData, setLocalSubmissionData] = useState(submissionData);
  const [adminFeedback, setAdminFeedback] = useState('');
  const [isReviewing, setIsReviewing] = useState(false);
  
  // Get icon based on skill focus
  const getSkillIcon = () => {
    switch(challenge?.skill_focus) {
      case 'knowledge': return FiBook;
      case 'application': return FiZap;
      case 'recognition': return FiTarget;
      case 'ethical_use': return FiShield;
      case 'risks': return FiShield;
      case 'developments': return FiCpu;
      default: return FiAward;
    }
  };
  
  const handleSubmit = async () => {
    if (!submission.trim()) return;
    
    const submit = await onSubmit({
      challenge_id: challenge.id,
      submission_text: submission,
      submission_url: submissionUrl,
      xp_awarded: challenge.xp_reward,
      completion_date: new Date().toISOString()
    });

    isCompleted = submit.submission?.is_completed;
    
    if (submit && submit?.submission) {
      setLocalSubmissionData(submit);
      resetForm();
      setIsSuccess(true);
    }
  };
  
  const resetForm = () => {
    setSubmission("");
    setSubmissionUrl("");
    setIsSuccess(false);
  };
  
  const handleReview = async (isApproved) => {
    setIsReviewing(true);

    try {
      const response = await onReview({
        id: localSubmissionData.id,
        adminFeedback,
        isApproved
      });

      if (response && response?.submission) {
        setLocalSubmissionData({
          ...localSubmissionData,
          submission: response.submission
        });
        
        onClose();
      }
    } catch (error) {
      toast.error('Failed to review submission');
    } finally {
      setIsReviewing(false);
    }
  };
  
  // Update localSubmissionData when the prop changes
  useEffect(() => {
    setLocalSubmissionData(submissionData);
  }, [submissionData]);
  
  const handleClose = () => {
    resetForm();
    onClose();
  };
  
  const Icon = getSkillIcon();
  
  if (!challenge) return null;
  
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-gray-100 dark:bg-gradient-to-br dark:from-indigo-900 dark:to-purple-900 dark:text-white border border-indigo-500/90 rounded-xl max-w-4xl w-full overflow-auto max-h-[90vh] transform transition-all shadow-xl">
                <button
                  onClick={handleClose}
                  className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 dark:text-white/70 dark:hover:text-white transition-colors"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                {isSuccess ? (
                  <div className="py-8 px-6 animate-in fade-in duration-300">
                    <div className="flex flex-col items-center text-center">
                      <div className="bg-gradient-to-br from-amber-400 to-amber-600 p-4 rounded-full mb-4 animate-in zoom-in duration-300 delay-150">
                        <FiAward className="h-12 w-12 text-white" />
                      </div>
                      
                      <h2 className="text-2xl font-bold mb-2 animate-in slide-in-from-bottom duration-300 delay-200">
                        {localSubmissionData && localSubmissionData.submission && localSubmissionData.submission.is_completed ? "Challenge Complete!" : "Submission Pending"}
                      </h2>
                      
                      <p className="dark:text-indigo-200 mb-6 animate-in fade-in duration-300 delay-300">
                        Congratulations! 
                        {localSubmissionData && localSubmissionData.submission && localSubmissionData.submission.is_completed ?
                          <span> You've completed this challenge! You've earned {challenge.xp_reward} XP{challenge.badge_id && (" and unlocked a new badge")}.</span>
                        : <span> You've submitted your response. {challenge.xp_awarded}</span>
                        }
                      </p>
                      
                      {false && (
                      <div className="mb-8 bg-white/10 p-6 rounded-lg w-full max-w-md animate-in slide-in-from-bottom duration-300 delay-400">
                        <div className="text-center">
                          <h3 className="font-bold dark:text-amber-300 mb-2">New Badge Unlocked</h3>
                          <div className="w-24 h-24 bg-gradient-to-br from-purple-600 to-pink-600 rounded-full mx-auto flex items-center justify-center mb-3">
                            <Icon className="h-12 w-12 dark:text-white" />
                          </div>
                          <p className="font-bold dark:text-white">{challenge.title} Badge</p>
                          <p className="text-sm dark:text-indigo-200 mt-1">Keep up the great work!</p>
                        </div>
                      </div>)}
                      
                      <button 
                        onClick={handleClose} 
                        className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md flex items-center"
                      >
                        <FiCheckCircle className="h-4 w-4 mr-2" />
                        Continue Learning
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="p-6">
                      <div className="flex items-center gap-3 mb-5">
                        <div className="bg-indigo-600/60 dark:bg-white/20 p-2 rounded-lg">
                          <Icon className="h-5 w-5 text-white" />
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold dark:text-white m-0 p-0">{challenge.title}</h2>
                            {isCompleted && (
                              <span className={`text-xs px-2.5 py-1 rounded-md ${localSubmissionData?.award_given ? 'bg-green-600 text-white' : 'bg-red-600 text-white'}`}>
                                {localSubmissionData?.award_given ? 'Completed' : 'Not Awarded'}
                              </span>
                            )}
                            {!isCompleted && submissionData && (
                              <span className="bg-orange-500 text-white text-xs px-2.5 py-1 rounded-md">Submitted</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <p className="dark:text-indigo-200 text-gray-900 mb-6 text-left">
                        {challenge.description}
                      </p>
                    
                      <div className="mt-2 text-sm">
                        <div className={`grid ${challenge.tool ? 'grid-cols-1 md:grid-cols-12' : 'grid-cols-2'} gap-4 mb-4`}>
                          <div className="md:col-span-7 bg-gray-400/10 dark:bg-white/10 p-6 rounded-lg">
                            <h3 className="font-medium dark:text-white mb-3 text-base text-left">Challenge Requirements:</h3>
                            <p className="dark:text-indigo-200 text-gray-900 mb-6 text-left">{challenge.requirements || "Not specified"}</p>
                            
                            <h3 className="font-medium dark:text-white mb-3 text-base text-left">Completion Criteria:</h3>
                            <p className="dark:text-indigo-200 text-gray-900 text-left">{challenge.completion_criteria || "Not specified"}</p>
                          </div>
                          
                          {challenge.tool && (
                            <div className="md:col-span-5">
                              <div className="bg-gray-400/10 dark:bg-white/10 backdrop-blur-sm p-5 rounded-xl dark:border border-indigo-500/30 h-full transition-all duration-300 hover:shadow-lg hover:shadow-indigo-500/10 flex flex-col">
                                <div className="flex items-center mb-3">
                                  <h3 className="font-medium dark:text-white text-base mb-0">Recommended Tool</h3>
                                  {challenge.tool.verified === 1 && (
                                  <span className="ml-auto inline-flex items-center text-xs bg-green-600 text-white dark:bg-green-500/20 dark:text-green-300 px-2 py-0.5 rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>
                                      Verified
                                    </span>
                                  )}
                                </div>

                                <hr className="my-3 dark:border-indigo-500/80 border-sky-700/20" />
                                
                                <div className="flex justify-between items-start gap-3 mb-3">
                                  <h4 className="text-lg font-semibold dark:text-white mb-0">{challenge.tool.name}</h4>
                                  <a 
                                    href={`/tools/tool/${challenge.tool.id}/${challenge.tool.name}#tools`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="flex items-center gap-1 px-3 py-1 rounded-full text-xs font-medium
                                      transition-all duration-200 
                                      bg-sky-500/20 text-sky-800 hover:bg-sky-500/30 hover:-translate-y-0.5
                                      dark:bg-indigo-500/20 dark:text-indigo-300 hover:dark:bg-indigo-500/30 hover:-translate-y-0.5
                                      border border-indigo-500/30"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    Tool page
                                    <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                  </a>
                                </div>
                                
                                <p className="dark:text-indigo-200 text-sm text-left mb-4" style={{
                                  WebkitLineClamp: 3,
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}>{challenge.tool.desc}</p>
                                
                                <div className="flex-grow"></div>
                                
                                <div className="flex flex-wrap gap-2 mt-auto pt-3">
                                  {challenge.tool.privacy && (
                                    <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                      <FiShield className="h-3.5 w-3.5 mr-1" />
                                      Privacy Risk: {challenge.tool.privacy.charAt(0).toUpperCase() + challenge.tool.privacy.slice(1)}
                                    </span>
                                  )}
                                  
                                  {challenge.tool.pricing && (
                                    <div>
                                      {challenge.tool.pricing === "free" && (
                                        <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                          <HandCoins className="w-3 h-3 mr-1" /> Free
                                        </span>
                                      )}
                                      {challenge.tool.pricing === "paid" && (
                                        <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                          <HandCoins className="w-3 h-3 mr-1" /> Paid
                                        </span>
                                      )}
                                      {challenge.tool.pricing === "both" && (
                                        <div className="flex space-x-2">
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Free
                                          </span>
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Paid
                                          </span>
                                        </div>
                                      )}
                                      {challenge.tool.pricing === "trail" && (
                                        <div className="flex space-x-2">
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Trial
                                          </span>
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Paid
                                          </span>
                                        </div>
                                      )}
                                      {challenge.tool.pricing === "free-paid" && (
                                        <div className="flex space-x-2">
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Free
                                          </span>
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Paid
                                          </span>
                                        </div>
                                      )}
                                      {challenge.tool.pricing === "free-trail-paid" && (
                                        <div className="flex space-x-2">
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Free
                                          </span>
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Trial
                                          </span>
                                          <span className="inline-flex items-center text-xs bg-gray-400/30 text-gray-800 dark:bg-indigo-900/30 dark:text-indigo-300 px-2 py-1 rounded-md">
                                            <HandCoins className="w-3 h-3 mr-1" /> Paid
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {challenge.needs_review === true && !isCompleted && (
                            <div className="w-full mb-3">
                              <div className="group relative text-left bg-gradient-to-r from-orange-500/10 to-orange-400/10 dark:from-orange-500/15 dark:to-orange-400/15 rounded-lg p-5 border border-orange-500/20 dark:border-orange-500/30 shadow-sm hover:shadow-md transition-all duration-200">
                                <div className="flex items-center space-x-2">
                                  <div className="flex-shrink-0 p-1.5 bg-orange-500/20 dark:bg-orange-500/25 rounded-full border border-orange-500/30 dark:border-orange-500/40 group-hover:border-orange-500/50">
                                    <FiShield className="h-4 w-4 text-orange-500 dark:text-white" />
                                  </div>
                                  <div className="flex-1 min-w-0 pl-4">
                                    <p className="text-xs mb-0 font-medium text-orange-700 dark:text-white">
                                      Review Required
                                    </p>
                                    <p className="text-[12px] mb-0 text-orange-700/80 dark:text-white/80 truncate">
                                      This challenge will be reviewed before XP is awarded.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      
                        {isCompleted || submissionData ? (
                          <>
                            <div className={`bg-gradient-to-r ${isCompleted && challenge?.completion?.award_given ? 'from-green-600/20 to-indigo-600/20 border-green-500/30' : isCompleted && !challenge?.completion?.award_given ? 'from-red-600/20 to-red-600/20 border-red-500/30' : 'from-orange-500/20 to-amber-500/20 border-orange-500/30'} rounded-lg p-4 mb-5 border`}>
                              <div className="flex justify-between items-center">
                                <div className="flex items-center gap-2">
                                  <div className={`bg-gradient-to-br ${isCompleted && challenge?.completion?.award_given ? 'from-green-400 to-green-600' : isCompleted && !challenge?.completion?.award_given ? 'from-red-400 to-red-600' : 'from-orange-400 to-amber-600'} h-8 w-8 rounded-full flex items-center justify-center shadow-lg`}>
                                    {isCompleted && challenge?.completion?.award_given ? (
                                      <FiCheckCircle className="h-4 w-4 dark:text-white" />
                                    ) : isCompleted && !challenge?.completion?.award_given ? (
                                      <FiX className="h-4 w-4 dark:text-white" />
                                    ) : (
                                      <FiUpload className="h-4 w-4 dark:text-white" />
                                    )}
                                  </div>
                                  <h3 className="font-medium text-gray-900 dark:text-white text-base mb-0">{isCompleted ? !challenge?.completion?.award_given ? 'Callenge Rejected' : 'Challenge Completed' : 'Challenge Submitted'}</h3>
                                </div>
                                <div className="flex items-center gap-2">
                                  <span className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-xs px-3 py-1.5 rounded-full font-medium flex items-center shadow-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                                    </svg>
                                    {!challenge?.completion?.award_given ? 'Not awarded: ' + challenge.xp_reward + ' XP' : isCompleted ? '+'+ challenge.xp_reward + ' XP' : 'Pending '+ challenge.xp_reward + ' XP'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            
                            <h3 className="font-medium dark:text-white text-base mb-3">Your Submission</h3>
                            <div className="bg-white/10 backdrop-blur-sm border border-gray-200 dark:border-indigo-500/30 rounded-xl p-5 mb-4 shadow-inner">
                              <p className="dark:text-indigo-100 text-gray-900 mb-0 whitespace-pre-line">
                                {submissionData && submissionData.submission_text ? submissionData.submission_text : "You've completed this challenge successfully!"}
                              </p>
                            </div>
                            
                            {submissionData?.submission_url && (
                              <div className="mt-5">
                                <div className="flex items-center gap-2 mb-2">
                                  <FiLink className="h-4 w-4 text-indigo-300" />
                                  <span className="text-sm font-medium dark:text-white">Submitted Link</span>
                                </div>
                                <a 
                                  href={submissionData.submission_url} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="bg-white/10 hover:bg-white/20 transition-all duration-300 p-3 rounded-lg flex items-center gap-2 text-indigo-200 hover:text-white border border-indigo-500/20 hover:border-indigo-500/40 hover:-translate-y-0.5"
                                >
                                  <span className="truncate dark:text-indigo-100 text-gray-900">{submissionData.submission_url}</span>
                                  <FiExternalLink className="h-4 w-4 flex-shrink-0 ml-auto" />
                                </a>
                              </div>
                            )}

                            {submissionData?.review && (
                              <div className="mt-5">
                                <div className="flex items-center gap-2 mb-2">
                                  <FiMessageSquare className="h-4 w-4 text-indigo-300" />
                                  <span className="text-sm font-medium dark:text-white">Feedback</span>
                                </div>

                                <div className="bg-white/10 backdrop-blur-sm border border-gray-200 dark:border-indigo-500/30 rounded-xl p-5 mb-4 shadow-inner">
                                  <p className="dark:text-indigo-100 text-gray-900 mb-0 whitespace-pre-line">
                                    {submissionData.review}
                                  </p>
                              </div>
                              </div>
                            )}
                            
                            {submissionData?.completion_date && (
                              <div className="mt-4 flex items-center gap-2">
                                <div className="dark:bg-indigo-500/20 bg-slate-500 text-white dark:text-indigo-300 text-xs px-3 py-1.5 rounded-full flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                  </svg>
                                  {submissionData.is_completed ? 'Completed on' : 'Submitted on'} {new Intl.DateTimeFormat('en-GB', {day: 'numeric', month: 'long', year: 'numeric'}).format(new Date(submissionData.completion_date))}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="mb-3 flex justify-between items-center">
                              <h3 className="font-medium dark:text-white text-base mb-0 p-0">Your Submission</h3>
                              <span className="bg-indigo-700/80 dark:bg-indigo-700/50 text-white text-xs px-3 py-1.5 rounded-md font-medium">
                                Reward: {challenge.xp_reward} XP
                              </span>
                            </div>
                            
                            <textarea
                              placeholder="Describe how you completed this challenge..."
                              className="min-h-32 w-full bg-white/5 border border-sky-700 dark:border-white/20 dark:placeholder:text-indigo-300/50 dark:text-white p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400/50 resize-none"
                              value={submission}
                              onChange={(e) => setSubmission(e.target.value)}
                            />
                            
                            <div className="mt-5">
                              <label className="text-sm font-medium dark:text-white flex items-center gap-2 mb-2">
                                <FiLink className="h-4 w-4 dark:text-indigo-300" />
                                Add a link (optional)
                              </label>
                              <input
                                placeholder="Link to your work or solution..."
                                className="w-full bg-white/5 border border-sky-700 dark:border-white/20 dark:placeholder:text-indigo-300/50 dark:text-white p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400/50"
                                value={submissionUrl}
                                onChange={(e) => setSubmissionUrl(e.target.value)}
                                type="url"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    
                    <div className="p-6 border-t dark:border-indigo-700/50 flex flex-col gap-4">
                      {/* Regular Submit/Back Button */}
                      <div className="flex justify-end">
                        {isCompleted || submissionData ? (
                          <button
                            onClick={handleClose}
                            className={`w-full ${isCompleted ? 'bg-green-600 hover:bg-green-700' : 'bg-orange-500 hover:bg-orange-600'} text-white px-4 py-3 rounded-lg flex items-center justify-center font-medium transition-colors`}
                          >
                            <FiArrowLeft className="h-4 w-4 mr-2" />
                            Back to Challenges
                          </button>
                        ) : (
                          <button
                            onClick={handleSubmit}
                            disabled={isSubmitting || !submission.trim()}
                            className={`w-full bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-3 rounded-lg flex items-center justify-center font-medium transition-colors ${(!submission.trim() || isSubmitting) ? 'opacity-50 cursor-not-allowed' : ''}`}
                          >
                            {isSubmitting ? (
                              <>
                                <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-opacity-20 border-t-white rounded-full"></div>
                                Submitting...
                              </>
                            ) : (
                              <>
                                <FiUpload className="h-4 w-4 mr-2" />
                                Submit Challenge
                              </>
                            )}
                          </button>
                        )}
                      </div>

                      {/* Admin Review Section */}
                      {!isCompleted && submissionData && user && user.role >= RoleEnum.Moderator && (
                        <>
                        <hr />
                        <div className="border border-gray-200 dark:border-indigo-700/50 rounded-lg p-4 mb-2">
                          <h3 className="font-medium text-gray-900 dark:text-white text-base mb-3">Review</h3>
                          <textarea
                            placeholder="Provide feedback for this submission..."
                            className="min-h-24 w-full bg-white/5 border border-sky-700 dark:border-white/20 dark:placeholder:text-indigo-300/50 dark:text-white p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-400/50 resize-none mb-3"
                            value={adminFeedback}
                            onChange={(e) => setAdminFeedback(e.target.value)}
                          />
                          <div className="flex gap-3">
                            <button
                              onClick={() => handleReview(true)}
                              disabled={isReviewing}
                              className="flex-1 bg-green-600 hover:bg-green-700 text-white px-4 py-3 rounded-lg flex items-center justify-center font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              {isReviewing ? (
                                <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-opacity-20 border-t-white rounded-full"></div>
                              ) : (
                                <FiThumbsUp className="h-4 w-4 mr-2" />
                              )}
                              Approve & Award XP
                            </button>
                            <button
                              onClick={() => handleReview(false)}
                              disabled={isReviewing}
                              className="flex-1 bg-red-600 hover:bg-red-700 text-white px-4 py-3 rounded-lg flex items-center justify-center font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              {isReviewing ? (
                                <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-opacity-20 border-t-white rounded-full"></div>
                              ) : (
                                <FiThumbsDown className="h-4 w-4 mr-2" />
                              )}
                              Decline
                            </button>
                          </div>
                        </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}