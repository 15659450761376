import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect} from "react";
import ForceAuthentication from "../../components/auth/ForceAuthentication";
import QRCodeGenerator from "../../components/QRCodeGenerator";
import RoleEnum from "../../components/enums/RoleEnum";
import VerifyCertificateForm from "../../components/dashboard/dashboard/verify-certificate-form";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import BaseButton from "../../components/base/Button";

const Trainer = () => {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Trainer"
    }, []);

    return (
        <ForceAuthentication
            requireRole={RoleEnum.Trainer}
            accessDeniedTitle="Trainer Access Only"
            accessDeniedMessage="You need trainer privileges to access this page."
            redirectIfNotAuthenticated="/dashboard">
            {({ user }) => (
                <AppLayout
                    header={
                        <h2 className="font-semibold text-xl text-gray-800 dark:text-white leading-tight">
                            Trainer Dashboard
                        </h2>
                    }>
                <div className="py-8 mx-3 lg:mx-0">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                        <DashboardCard className="md:col-span-2 mb-4 overflow-hidden">
                            <div className="flex items-center gap-6 rounded-xl">
                                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#0c66ee] dark:to-[#468ef9] flex items-center justify-center">
                                    <span className="text-2xl font-bold text-white dark:text-black">
                                        {user?.name?.[0]?.toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                                        Welcome to the training center, <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:bg-gradient-to-r dark:from-[#0c66ee] dark:to-[#468ef9]">{user?.name}</span>
                                    </h1>
                                    <p className="text-gray-600 dark:text-gray-300 mt-1">Here are some tools for trainers!</p>
                                </div>
                            </div>
                        </DashboardCard>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <DashboardCard className='mb-4 overflow-hidden'>
                                    <h2 id="quick-buttons" className="text-[2rem] mb-4 dark:text-white">
                                        Trainer tools
                                    </h2>
                                    <BaseButton 
                                        url={"/dashboard/ai-act/"} 
                                        styles={"px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-colors"}
                                    >
                                        AI Act Center
                                    </BaseButton>  
                                </DashboardCard>
                                <DashboardCard className="overflow-hidden">
                                    <VerifyCertificateForm />
                                </DashboardCard>
                            </div>
                            <div className="flex flex-col">
                                <DashboardCard 
                                    className="overflow-hidden" 
                                    boxPadding={false}
                                >
                                    <QRCodeGenerator />
                                </DashboardCard>
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
            )}
        </ForceAuthentication>
    )
}

export default Trainer
