import React, {useEffect, useState} from "react";
import DocumentationNav from "../DocumentationNav";
import RequireAuth from "../../../components/auth/RequireAuth";
import BaseButton from "../../../components/base/Button";

function ChatGPTDocumentation() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - ChatGPT"
  }, []);
  
  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="chatgpt">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="Applications" />

              <div className={"col-span-12 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section lg:col-span-9"}
                   id="techniques"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <RequireAuth
                    id="chatgpt"
                    sectionDescription="Learn how to use ChatGPT with the Crafting AI Prompts Framework."
                    title="ChatGPT Documentation"
                    onAuthenticated={() => setIsAuthenticated(true)}
                  >
                  <h1 className="text-bold"><span className="text-header-gradient">ChatGPT</span></h1>
                  <p>Before you start using ChatGPT, we highly recommend following the below steps so you're not sharing any confidential information.</p>

                  <p>To start, open ChatGPT, and move to the top right corner. There you'll see a profile icon. Click on it, and the menu opens.</p>
                  <img src={require("../../../assets/img/applications/chatgpt/settings.png")} alt="Settings" />

                  <p>Next, click on "Settings" and then "Data Controls". Here you can turn off the "Improve the model for everyone" option. This will prevent ChatGPT from sharing your data with the model for training purposes.</p>
                  <img src={require("../../../assets/img/applications/chatgpt/improve-model-off.png")} alt="Improve model off" />

                  <p>Finally, click on "Settings" and then "Personalization". Here you can turn off the "Memory" option. This will prevent ChatGPT from saving your conversation history for later use.</p>
                  <img src={require("../../../assets/img/applications/chatgpt/memory-off.png")} alt="Memory off" />
                  
                  <p>
                    It's also important to note that if you turn off the "Memory" option, this will prevent any potential memory injections.
                    Memory injections are a type of prompt injection attack where an attacker could potentially inject code into your conversation history.
                    This means that if you turn off the "Memory" option, you will not be affected by any potential memory injections in the future, and you will have more control over your conversation history.
                  </p>

                  <BaseButton url={"/tools#tools"} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                    Check our toollist for more options!      
                  </BaseButton>
                  
                </RequireAuth>

              </div>

        </div>
      </section>
  );
}
export default ChatGPTDocumentation;
