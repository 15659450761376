import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../../hooks/auth';
import AppLayout from '../../../components/dashboard/Layouts/AppLayout';
import axios from '../../../libs/axios';
import RoleEnum from '../../../components/enums/RoleEnum';
import { Shield, GraduationCap, User, UserPlus, UserCog } from 'lucide-react';
import RoleLabel from '../../../components/dashboard/RoleLabel';
import TicketList from './ticket-list';
import DashboardCard from '../../../components/dashboard/dashboard/DashboardCard';

const UserEdit = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { user, csrf } = useAuth({ middleware: 'auth' });
    const [userData, setUserData] = useState(null);

    const replyToTicket = async (ticketId) => {
        // TODO
        toast.error('Reply to ticket functionality is not implemented yet');
    };

    const closeTicket = async (ticketId) => {
        try {
            await csrf();
            const response = await axios.post(`/admin/ticket/${ticketId}/close`);
            
            // Update the ticket status in the local state
            setUserData(prevState => ({
                ...prevState,
                tickets: prevState.tickets.map(ticket =>
                    ticket.id === ticketId ? { ...ticket, status: 2 } : ticket
                )
            }));

            toast.success('Ticket closed successfully');
        } catch (error) {
            toast.error('Failed to close ticket');
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                await csrf();
                
                const response = await axios.get(`/admin/user/${userId}`);
                setUserData(response.data);
            } catch (error) {
                navigate('/dashboard/admin');
            }
        };

        if (userId) {
            fetchUser();
        }
    }, [userId]);

    async function upgradeAccountToTakeTests(userID, roleID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/upgrade-user-role/${userID}/${roleID}`);
            setUserData(prevState => ({
                ...prevState,
                ...response.data,
            }));

            toast.success('Account role update successfully');
        } catch (error) {
            toast.error('Failed to update account role');
         }
    }

    async function banAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/ban-user/${userID}`);
            setUserData(prevState => ({
                ...prevState,
                ...response.data,
            }));
            toast.success('User banned successfully');
        } catch (error) {
            toast.error('Failed to ban user');
        }
    }

    async function unBanAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/unban-user/${userID}`);
            setUserData(response.data);
            toast.success('User unbanned successfully');
        } catch (error) {
            //console.error(error);
        }
    }

    if (!userData) {
        return (
            <AppLayout>
                <div className="flex justify-center items-center min-h-screen">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
            </AppLayout>
        );
    }

    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl dark:text-white leading-tight">
                    User Profile: {userData.name}
                </h2>
            }>
            <div className="py-8 mx-3 lg:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <DashboardCard 
                        className={`mb-4 ${userData.banned_user ? 'bg-red-50/30 dark:bg-red-950/30' : ''}`}
                    >
                        {/* User Info Card */}
                        <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600 flex justify-between items-center">
                            <h3 className="text-xl font-semibold text-gray-800 dark:text-white">User Information</h3>
                            <button
                                onClick={() => userData.banned_user ? unBanAccount(userData.id) : banAccount(userData.id)}
                                className={`
                                    inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium shadow-sm
                                    transition-all duration-200
                                    ${userData.banned_user
                                        ? 'bg-gradient-to-br from-green-50/90 to-green-50/50 dark:from-green-900/20 dark:to-green-900/10 ' +
                                          'text-green-700 dark:text-green-400 ' +
                                          'ring-1 ring-green-100 dark:ring-green-800 ' +
                                          'hover:shadow-md hover:ring-green-200 dark:hover:ring-green-700/50'
                                        : 'bg-gradient-to-br from-red-50/90 to-red-50/50 dark:from-red-900/20 dark:to-red-900/10 ' +
                                          'text-red-700 dark:text-red-400 ' +
                                          'ring-1 ring-red-100 dark:ring-red-800 ' +
                                          'hover:shadow-md hover:ring-red-200 dark:hover:ring-red-700/50'
                                    }
                                `}
                            >
                                {userData.banned_user ? (
                                    <>
                                        <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                        <span>Unban User</span>
                                    </>
                                ) : (
                                    <>
                                        <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                        </svg>
                                        <span>Ban User</span>
                                    </>
                                )}
                            </button>
                        </div>
                        <div className="p-6 grid grid-cols-1 md:grid-cols-3 gap-6">
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">ID</p>
                                <p className="text-base font-semibold text-gray-900 dark:text-white">{userData.id}</p>
                            </div>
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Name</p>
                                <p className="text-base font-semibold text-gray-900 dark:text-white">{userData.name}</p>
                            </div>
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Email</p>
                                <p className="text-base font-semibold text-gray-900 dark:text-white">
                                    {userData.email}
                                    {userData.email_verified ? (
                                        <span 
                                            className="inline-flex items-center text-green-500 ml-2" 
                                            title="Email verified"
                                        >
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                className="h-4 w-4"     
                                                viewBox="0 0 24 24" 
                                                fill="none" 
                                                stroke="currentColor" 
                                                strokeWidth="2"
                                            >
                                                <path 
                                                    strokeLinecap="round" 
                                                    strokeLinejoin="round" 
                                                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" 
                                                />
                                            </svg>
                                        </span>
                                    ) : (
                                        <span 
                                            className="inline-flex items-center text-red-500 ml-2" 
                                            title="Email not verified"
                                        >
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                className="h-4 w-4" 
                                                viewBox="0 0 24 24" 
                                                fill="none" 
                                                stroke="currentColor" 
                                                strokeWidth="2"
                                            >
                                                <path 
                                                    strokeLinecap="round" 
                                                    strokeLinejoin="round" 
                                                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" 
                                                />
                                            </svg>
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Role</p>
                                <RoleLabel role={userData.role} />
                            </div>
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Created At</p>
                                <p className="text-base font-semibold text-gray-900 dark:text-white">
                                    {new Date(userData.created_at).toLocaleString()}
                                </p>
                            </div>
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Status</p>
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                    ${userData.banned_user ? 
                                        'bg-red-100 text-red-800' : 
                                        'bg-green-100 text-green-800'}`}>
                                    {userData.banned_user ? 'Banned' : 'Active'}
                                </span>
                            </div>

                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Profile ID</p>
                                <a href={`${window.location.origin}/tools/profile/${userData.profile?.profile_id}`} className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                    ${userData.profile?.show_profile ? 
                                        'bg-green-100 text-green-800' : 
                                        'bg-red-100 text-red-800'}`}>
                                    {userData.profile?.profile_id ? userData.profile?.profile_id : 'none'}
                                </a>
                            </div>
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Tools Visibility</p>
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                    ${userData.profile?.show_tool_profile ? 
                                        'bg-green-100 text-green-800' : 
                                        'bg-red-100 text-red-800'}`}>
                                    {userData.profile?.show_tool_profile ? 'Visible' : 'Disabled'}
                                </span>
                            </div>
                            <div className="space-y-2">
                                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Accepted Terms</p>
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                    ${userData.profile?.show_examples ? 
                                        'bg-green-100 text-green-800' : 
                                        'bg-red-100 text-red-800'}`}>
                                    {userData.profile?.show_examples ? 'Yes' : 'No'}
                                </span>
                            </div>
                        </div>
                    </DashboardCard>

                    {/* Role Management Section */}
                    {user?.role >= RoleEnum.SuperAdmin && (
                        <DashboardCard className='mb-4'>
                            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Role Management</h3>
                            </div>
                            <div className="p-6">
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
                                    <button
                                        onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Registered)}
                                        disabled={userData.banned_user}
                                        className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                            userData.banned_user || userData.role >= RoleEnum.Registered
                                            ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                            : 'bg-green-100 dark:bg-green-800 text-green-700 dark:text-green-300 hover:bg-green-200 dark:hover:bg-green-700'
                                        }`}
                                    >
                                        <User className="w-5 h-5" />
                                        <span>Registered</span>
                                    </button>

                                    <button
                                        onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.DefaultUser)}
                                        disabled={userData.banned_user}
                                        className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                            userData.banned_user || userData.role >= RoleEnum.DefaultUser
                                            ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                            : 'bg-blue-100 dark:bg-blue-800 text-blue-700 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-700'
                                        }`}
                                    >
                                        <UserPlus className="w-5 h-5" />
                                        <span>DefaultUser</span>
                                    </button>

                                    <button
                                        onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Trainer)}
                                        disabled={userData.banned_user}
                                        className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                            userData.banned_user || userData.role >= RoleEnum.Trainer
                                            ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                            : 'bg-orange-100 dark:bg-orange-800 text-orange-700 dark:text-orange-300 hover:bg-orange-200 dark:hover:bg-orange-700'
                                        }`}
                                    >
                                        <GraduationCap className="w-5 h-5" />
                                        <span>Trainer</span>
                                    </button>

                                    <button
                                        onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Moderator)}
                                        disabled={userData.banned_user}
                                        className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                            userData.banned_user || userData.role >= RoleEnum.Moderator
                                            ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                            : 'bg-purple-100 dark:bg-purple-800 text-purple-700 dark:text-purple-300 hover:bg-purple-200 dark:hover:bg-purple-700'
                                        }`}
                                    >
                                        <UserCog className="w-5 h-5" />
                                        <span>Moderator</span>
                                    </button>

                                    <button
                                        onClick={() => upgradeAccountToTakeTests(userData.id, RoleEnum.Admin)}
                                        disabled={userData.banned_user}
                                        className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
                                            userData.banned_user || userData.role >= RoleEnum.Admin
                                            ? 'bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-300 cursor-not-allowed'
                                            : 'bg-red-100 dark:bg-red-800 text-red-700 dark:text-red-300 hover:bg-red-200 dark:hover:bg-red-700'
                                        }`}
                                    >
                                        <Shield className="w-5 h-5" />
                                        <span>Admin</span>
                                    </button>
                                </div>
                            </div>
                        </DashboardCard>
                    )}

                    {/* Test attemts */}
                    {userData.tests && userData.tests.length > 0 && (
                        <DashboardCard className='mb-4'>
                            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Test Attempts</h3>
                            </div>
                            <div className="p-6">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {userData.tests.map(test => {
                                        const passed = parseFloat(test.score) >= 85;
                                        return (
                                            <div 
                                                key={test.id} 
                                                className={`
                                                    relative overflow-hidden rounded-xl border transition-all duration-200
                                                    ${passed 
                                                        ? 'bg-gradient-to-br from-green-50 to-green-50/50 dark:from-green-900/20 dark:to-green-900/5 border-green-100 dark:border-green-900/30 hover:border-green-200 dark:hover:border-green-800/50' 
                                                        : 'bg-gradient-to-br from-red-50 to-red-50/50 dark:from-red-900/20 dark:to-red-900/5 border-red-100 dark:border-red-900/30 hover:border-red-200 dark:hover:border-red-800/50'}
                                                `}
                                            >
                                                <div className="
                                                    absolute top-3 left-5
                                                    inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-md
                                                    bg-gradient-to-r from-indigo-500/[0.08] to-purple-500/[0.05] dark:from-indigo-400/[0.12] dark:to-purple-400/[0.08]
                                                    ring-1 ring-indigo-500/[0.15] dark:ring-indigo-400/20
                                                    group transition-all duration-200
                                                    hover:from-indigo-500/[0.12] hover:to-purple-500/[0.08] dark:hover:from-indigo-400/[0.15] dark:hover:to-purple-400/[0.1]
                                                ">
                                                    <span className="text-indigo-600/70 dark:text-indigo-300/70 text-[11px] font-medium tracking-wide">CAIP</span>
                                                    <span className="text-indigo-700 dark:text-indigo-200 text-xs font-semibold">{test.test_id === 1 ? '1 - IPE' : test.test_id}</span>
                                                </div>

                                                {/* Status Badge */}
                                                <div className={`
                                                    absolute top-3 right-5 px-3 py-1 rounded-full text-sm font-medium
                                                    ${passed
                                                        ? 'bg-green-100 dark:bg-green-900/40 text-green-700 dark:text-green-400'
                                                        : 'bg-red-100 dark:bg-red-900/40 text-red-700 dark:text-red-400'}
                                                `}>
                                                    {passed ? 'Passed' : 'Failed'}
                                                </div>

                                                <div className="pb-6 pt-12 px-4">
                                                    {/* Score and Status */}
                                                    <div className="text-center mb-4">
                                                        <div className={`text-4xl font-bold mb-2 ${passed ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                                                            {test.score}%
                                                        </div>
                                                    </div>

                                                    {/* Test Details */}
                                                    <div className="space-y-3">

                                                        {/* Answer Stats */}
                                                        <div className="flex flex-wrap gap-2 text-sm">
                                                            <span className="px-2 py-1 rounded-md bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-400">
                                                                {test.correct_answers} correct
                                                            </span>
                                                            {test.wrong_answers > 0 && (
                                                                <span className="px-2 py-1 rounded-md bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-400">
                                                                    {test.wrong_answers} wrong
                                                                </span>
                                                            )}
                                                            {test.not_filled_in > 0 && (
                                                                <span className="px-2 py-1 rounded-md bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400">
                                                                    {test.not_filled_in} skipped
                                                                </span>
                                                            )}
                                                        </div>

                                                        {/* Date and Certificate */}
                                                        <div className="pt-3 mt-3 border-t border-gray-200 dark:border-gray-700/50 space-y-2">
                                                            <div className="text-sm text-gray-600 dark:text-gray-400 ml-2">
                                                                {test.finished_at ? new Date(test.finished_at).toLocaleString() : 'In Progress'}
                                                            </div>
                                                            {test.certificate_id && (
                                                                <a
                                                                    href={`/certificate/show/${test.certificate_id}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="inline-flex items-center gap-1.5 px-3 py-1.5 text-sm text-blue-700 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20 rounded-md hover:bg-blue-100 dark:hover:bg-blue-900/30 transition-colors"
                                                                >
                                                                    <span>View Certificate</span>
                                                                    <span className="text-xs text-blue-600 dark:text-blue-500">
                                                                        {new Date(test.certificate_date).toLocaleDateString()}
                                                                    </span>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </DashboardCard>
                    )}

                    {/* Tickets Section */}
                    {userData.tickets && userData.tickets.length > 0 && (
                        <DashboardCard>
                            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Tickets</h3>
                            </div>
                            <TicketList 
                                tickets={userData.tickets}
                                replyToTicket={replyToTicket}
                                closeTicket={closeTicket}
                                ticketsPerPage={3}
                            />
                        </DashboardCard>
                    )}

                     {/* Back Button */}
                     <div className="mt-6 flex justify-end">
                        <button
                            onClick={() => navigate('/dashboard/admin')}
                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-900"
                        >
                            Back to Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default UserEdit; 