import React from 'react';
import { FaBook, FaTools, FaEye, FaBalanceScale, FaExclamationTriangle, FaChartLine } from 'react-icons/fa';
import SkillSlider from './SkillSlider';

/**
 * SkillAssessment Component
 * 
 * A component for displaying and managing a set of skill sliders.
 * Features:
 * - Organized display of all skill categories
 * - Consistent styling and layout
 * - Handles skill value changes
 * 
 * @param {Object} skillValues - Object containing the current values for all skills
 * @param {Function} handleSkillChange - Function to call when a skill value changes
 * @returns {JSX.Element}
 */
const SkillAssessment = ({ skillValues, handleSkillChange }) => {
    return (
        <div className="h-full">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Skill Self Assessment</h3>
            <div className="space-y-4">
                <SkillSlider 
                    label="Knowledge" 
                    value={skillValues.knowledge} 
                    onChange={(value) => handleSkillChange('knowledge', value)}
                    description="Understanding of the foundation of Generative AI and Prompt Engineering"
                    icon={FaBook}
                />
                <SkillSlider 
                    label="Application" 
                    value={skillValues.application} 
                    onChange={(value) => handleSkillChange('application', value)}
                    description="Ability to apply prompt techniques in practical scenarios"
                    icon={FaTools}
                />
                <SkillSlider 
                    label="Recognition" 
                    value={skillValues.recognition} 
                    onChange={(value) => handleSkillChange('recognition', value)}
                    description="Identifying appropriate prompt patterns for specific tasks"
                    icon={FaEye}
                />
                <SkillSlider 
                    label="Ethical Use" 
                    value={skillValues.ethical_use} 
                    onChange={(value) => handleSkillChange('ethical_use', value)}
                    description="Understanding ethical implications of AI prompts"
                    icon={FaBalanceScale}
                />
                <SkillSlider 
                    label="Risks" 
                    value={skillValues.risks} 
                    onChange={(value) => handleSkillChange('risks', value)}
                    description="Awareness of potential risks and mitigation strategies"
                    icon={FaExclamationTriangle}
                />
                <SkillSlider 
                    label="Developments" 
                    value={skillValues.developments} 
                    onChange={(value) => handleSkillChange('developments', value)}
                    description="Keeping up with latest developments in prompt engineering"
                    icon={FaChartLine}
                />
            </div>
        </div>
    );
};

export default SkillAssessment;
