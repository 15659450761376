import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import React, {useEffect, useState, useRef} from "react";
import { FiDownload, FiSave, FiCheck } from 'react-icons/fi';
import RoleEnum from "../../components/enums/RoleEnum";
import ForceAuthentication from '../../components/auth/ForceAuthentication';
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import SkillAssessment from '../../components/dashboard/skills/SkillAssessment';
import SkillRadarChart from '../../components/dashboard/skills/SkillRadarChart';
import { createSkillMatrixDownloader, addSkillMatrixStyles } from '../../components/dashboard/skills/SkillMatrixDownloader';
import { NavLink } from 'react-router-dom';
import axios from "../../libs/axios";
import toast from 'react-hot-toast';

const SkillsContent = ({ user }) => {
    // Reference for the chart container
    const chartRef = useRef(null);
    
    // State for saving status
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [originalSkillValues, setOriginalSkillValues] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    
    // Function to download the skill matrix as an image
    const downloadSkillMatrix = createSkillMatrixDownloader(user);
    
    // Add necessary styles for skill matrix download when component mounts
    useEffect(() => {
        // Initial style application
        addSkillMatrixStyles();
        
        // Cleanup function
        return () => {
            const styleElement = document.getElementById('skill-matrix-styles');
            if (styleElement) {
                document.head.removeChild(styleElement);
            }
        };
    }, []);
    
    const [skillValues, setSkillValues] = useState({
        knowledge: 0,
        application: 0,
        recognition: 0,
        ethical_use: 0,
        risks: 0,
        developments: 0
    });

    const handleSkillChange = (skill, value) => {
        setSkillValues(prev => {
            const newValues = {
                ...prev,
                [skill]: value
            };
            
            // Check if values have changed from original
            if (originalSkillValues) {
                const hasChanged = Object.keys(newValues).some(
                    key => newValues[key] !== originalSkillValues[key]
                );
                setHasChanges(hasChanged);
            }
            
            return newValues;
        });
    };
    
    // Function to save skill assessment
    const saveSkillAssessment = async () => {
        // Don't save if there are no changes
        if (!hasChanges) return;
        
        setIsSaving(true);
        setSaveError(null);
        
        try {
            const response = await axios.post('/skills/assessment', skillValues);
            if (response.status === 200) {
                toast.success('Skill assessment saved successfully!');
                // Update original values to match current values
                setOriginalSkillValues({...skillValues});
                setHasChanges(false);
            }
        } catch (error) {
            toast.error(`Failed to save skill assessment. Please try again. ${error.response?.data?.error || ''}`);
        } finally {
            setIsSaving(false);
        }
    };
    
    // Fetch user's skill assessment if available
    useEffect(() => {
        const fetchSkillAssessment = async () => {
            try {
                const response = await axios.get('/skills/assessment');
                if (response.status === 200 && response.data.data) {
                    const fetchedValues = {
                        knowledge: response.data.data.knowledge,
                        application: response.data.data.application,
                        recognition: response.data.data.recognition,
                        ethical_use: response.data.data.ethical_use,
                        risks: response.data.data.risks,
                        developments: response.data.data.developments
                    };
                    setSkillValues(fetchedValues);
                    setOriginalSkillValues(fetchedValues);
                    setHasChanges(false);
                }
            } catch (error) {
                // If 404, it means no assessment found yet, which is fine
                if (error.response && error.response.status !== 404) {
                    console.error('Error fetching skill assessment:', error);
                }
            }
        };
        
        fetchSkillAssessment();
    }, []);

    // Main data fetching effect
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Skills";
    }, [user]);

    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-900 dark:text-white leading-tight">
                    Prompt Engineering Skills <span className="bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-100 font-semibold px-2.5 py-1 rounded-md ml-2 text-xs">BETA</span>
                </h2>
            }
        >
            <div className="bg-gray-50/50 dark:bg-gray-900 py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/* Welcome Section */}
                    <div className="grid gap-4 mb-4 md:grid-cols-4">
                        <DashboardCard className="md:col-span-2 overflow-hidden">
                            <div className="flex items-center gap-6 rounded-xl">
                                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#0c66ee] dark:to-[#468ef9] flex items-center justify-center">
                                    <span className="text-2xl font-bold text-white dark:text-black">
                                        {user?.name?.[0]?.toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                                       Your <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:bg-gradient-to-r dark:from-[#0c66ee] dark:to-[#468ef9]">Skill</span> assessment
                                    </h1>
                                    <p className="text-gray-600 mt-1 dark:text-gray-300">Track your progress and growth!</p>
                                </div>
                            </div>
                        </DashboardCard>
                        <DashboardCard className="md:col-span-2 overflow-hidden">
                            <div className="grid grid-cols-1 md:grid-cols-3">
                            <div className="rounded-xl p-2 col-span-1">
                            <NavLink 
                                to={"/dashboard/prompt-challenges"}
                                className="group flex items-center justify-between px-6 py-4 rounded-lg bg-gradient-to-r from-[#468ef9] to-[#0c66ee] 
                                    hover:from-[#3d7ed9] hover:to-[#0a56c7] transition-all duration-300 transform hover:-translate-y-0.5"
                            >
                                <span className="text-white font-semibold">Practice</span>
                                <svg className="w-5 h-5 text-white transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </NavLink>
                            </div>
                            </div>
                        </DashboardCard>
                    </div>
                    <div className="grid gap-4 mb-4 md:grid-cols-4">
                        <DashboardCard className="md:col-span-2 overflow-hidden">
                            <SkillAssessment 
                                skillValues={skillValues} 
                                handleSkillChange={handleSkillChange} 
                            />
                            <div className="mt-6 flex justify-center">
                                <button 
                                    onClick={saveSkillAssessment}
                                    disabled={isSaving || !hasChanges}
                                    className={`flex items-center gap-2 px-6 py-2.5 ${(isSaving || !hasChanges) ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-[#468ef9] to-[#0c66ee] text-white'} text-white rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200`}
                                >
                                    {isSaving ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Saving...
                                        </>
                                    ) : (
                                        <>
                                            <FiSave className="text-lg" />
                                            <span>Save Assessment</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </DashboardCard>
                        <DashboardCard className="md:col-span-2 overflow-hidden">
                            <div className="h-full">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Skill Matrix</h3>
                                <div id="skill-radar-container" className="relative flex flex-col items-center">
                                    <SkillRadarChart data={skillValues} />
                                </div>
                                <div className="flex justify-center mt-4">
                                    <button 
                                        onClick={() => downloadSkillMatrix()}
                                        className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] text-white rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200"
                                    >
                                        <FiDownload className="text-lg" />
                                        <span>Download Skill Matrix</span>
                                    </button>
                                </div>
                            </div>
                        </DashboardCard>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

const Skills = () => {
    return (
        <ForceAuthentication
            requireRole={RoleEnum.DefaultUser}
            accessDeniedTitle="No Access"
            accessDeniedMessage="You must validate your email to access this page."
        >
            {({ user }) => <SkillsContent user={user} />}
        </ForceAuthentication>
    );
};

export default Skills;
