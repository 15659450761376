import { FiAward, FiUpload, FiArrowRight, FiCheckCircle, FiZap, FiTarget, FiShield, FiBook, FiCpu, FiAlertCircle, FiUser } from 'react-icons/fi';

// Challenge Card Component
const ChallengeCard = ({ challenge, isCompleted, onStartChallenge, onViewCompletion, promptEngineerLevel }) => {
    const isSubmitted = Boolean(challenge.submitted) && !isCompleted;
    const isLocked = challenge.locked;
    // Get icon based on skill focus
    const getSkillIcon = () => {
        switch(challenge.skill_focus) {
            case 'knowledge': return <FiBook className="h-5 w-5 text-white" />;
            case 'application': return <FiZap className="h-5 w-5 text-white" />;
            case 'recognition': return <FiTarget className="h-5 w-5 text-white" />;
            case 'ethical_use': return <FiShield className="h-5 w-5 text-white" />;
            case 'risks': return <FiShield className="h-5 w-5 text-white" />;
            case 'developments': return <FiCpu className="h-5 w-5 text-white" />;
            default: return <FiAward className="h-5 w-5 text-white" />;
        }
    };
    
    // Get background color based on difficulty
    const getDifficultyColor = () => {
        if (challenge.locked?.requirements?.roleRequirement){
            return "from-gray-600 to-gray-700 dark:from-gray-700 dark:to-gray-800";
        }
        if (challenge.locked) {
            return "from-gray-600 to-gray-700 dark:from-gray-700 dark:to-gray-800";
        }
        switch(challenge.difficulty) {
            case 'beginner': return "from-blue-600 to-blue-700 dark:from-blue-700 dark:to-blue-800";
            case 'intermediate': return "from-green-600 to-green-700 dark:from-green-700 dark:to-green-800";
            case 'advanced': return "from-purple-600 to-purple-700 dark:from-purple-700 dark:to-purple-800";
            default: return "from-gray-600 to-gray-700 dark:from-gray-700 dark:to-gray-800";
        }
    };
    
    // Get skill name
    const getSkillName = () => {
        switch(challenge.skill_focus) {
            case 'knowledge': return "Knowledge";
            case 'application': return "Application";
            case 'recognition': return "Recognition";
            case 'ethical_use': return "Ethical Use";
            case 'risks': return "Risk Awareness";
            case 'developments': return "Developments";
            default: return challenge.skill_focus;
        }
    };
    
    return (
        <div className={`h-full relative transition-all duration-300 ${isCompleted ? '' : 'hover:-translate-y-1'}`}>
            <div className={`h-full border dark:border-gray-500 bg-white dark:bg-gray-800 rounded-xl shadow-md overflow-hidden flex flex-col`}>
                {isLocked && challenge.locked?.requirements?.roleRequirement && (
                    <div className='absolute right-5 top-5 z-20' style={{ opacity: 1 }}>
                        <span className="bg-blue-500 dark:bg-blue-800 text-white text-xs px-2 py-0.5 rounded-md font-medium">PRO Users Only</span>
                    </div>
                )}
                <div className={`bg-gradient-to-r ${getDifficultyColor()} pb-2 p-4 ${isLocked && challenge.locked?.requirements?.roleRequirement && 'opacity-40'}`}>
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <div className="bg-white/20 dark:bg-black/20 p-2 rounded-lg mr-3">
                                {getSkillIcon()}
                            </div>
                            <div className="flex-1">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <h3 className="font-bold text-white text-[16px] mb-0">{challenge.title}</h3>
                                    </div>
                                    {(isCompleted && challenge?.completion?.award_given === 1) && (
                                        <span className="bg-green-600 text-white text-xs px-2.5 py-1 rounded-md ml-2">Completed</span>
                                    )}
                                    
                                    {(isCompleted && challenge?.completion?.award_given === 0) && (
                                        <span className="bg-indigo-600 text-white text-xs px-2.5 py-1 rounded-md ml-2">Not Awarded</span>
                                    )}
                                    {isSubmitted && (
                                        <span className="bg-orange-500 text-white text-xs px-2.5 py-1 rounded-md ml-2">Submitted</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-2 mt-2 ml-11">
                            <span className="bg-white/20 dark:bg-black/20 text-white text-xs px-2 py-0.5 rounded-md">
                                {challenge.difficulty.charAt(0).toUpperCase() + challenge.difficulty.slice(1)}
                            </span>
                            <span className="bg-white/20 dark:bg-black/20 text-white text-xs px-2 py-0.5 rounded-md">
                                {getSkillName()}
                            </span>
                        </div>
                    </div>
                </div>
                
                <div className={`p-4 flex-grow ${isLocked && challenge.locked?.requirements?.roleRequirement && 'opacity-40'}`}>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">{challenge.description}</p>
                    
                    <div className="text-xs text-gray-500 dark:text-gray-400 space-y-2">
                        <div className="flex items-start">
                            <span className="font-medium min-w-20">Reward:</span>
                            <span>{challenge.xp_reward} XP</span>
                            {challenge.needs_review === 1 && !isCompleted && (
                                <span className="bg-orange-500 dark:bg-orange-400 text-white dark:text-white text-[9px] ml-4 -mt-0.5 px-2 py-0.5 rounded-md ml-1">
                                    (after review)
                                </span>
                            )}
                        </div>
                        {!isLocked && (
                            <div className="flex items-start">
                                <span className="font-medium min-w-20">Goal:</span>
                                <span className="line-clamp-2">{challenge.requirements}</span>
                            </div>
                        )}
                        {challenge?.submitted?.user?.name && (
                            <div className="flex items-center gap-2 mt-2">
                                <FiUser className="w-4 h-4" />
                                <div className="flex items-center gap-1.5 bg-white/10 dark:bg-black/10 rounded-lg px-2.5 py-1">
                                    <span className="text-xs">#</span>
                                    <span className="text-sm font-medium">{challenge.submitted.user.id}</span>
                                    <span className="text-sm font-medium">{challenge.submitted.user.name}</span>
                                </div>
                            </div>
                        )}
                        {isLocked && (
                            <div className="flex items-start text-red-400">
                                <span className="font-medium min-w-20">Locked:</span>
                                <div className="flex flex-col gap-1">
                                    {challenge.locked?.requirements?.date && (
                                        <span>
                                            Available on {new Date(challenge.locked.requirements.date).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                                        </span>
                                    )}
                                    {challenge.locked?.requirements?.levelRequirement > promptEngineerLevel.currentLevel && (
                                        <span>
                                            Level {challenge.locked.requirements.levelRequirement} required
                                        </span>
                                    )}
                                    {challenge.locked?.requirements?.roleRequirement && (
                                        <span>
                                            <span className="bg-blue-500 dark:bg-blue-800 text-white text-xs px-2 py-0.5 rounded-md font-medium">PRO Users Only</span>
                                        </span>
                                    )}
                                    {challenge.locked?.requirements?.XPRequirement && (
                                        <span>
                                            {new Intl.NumberFormat('en-US').format(challenge.locked.requirements.XPRequirement)} XP required
                                        </span>
                                    )}
                                    {challenge.locked?.requirements?.unlock_requirement && (
                                        <span>
                                            {challenge.locked.requirements.unlock_requirement}
                                        </span>
                                    )}
                                    
                                </div>
                            </div>
                        )}
                        {!isLocked && challenge.tool && (
                            <div className="mt-3 pt-3 ">
                                <div className="flex items-start">
                                    <span className="font-medium min-w-20">Tool:</span>
                                    <div className="flex flex-col">
                                        <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium bg-purple-100 dark:bg-purple-700 text-white-700 dark:text-white">
                                            {challenge.tool.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                
                <div className={`p-4 pt-2 ${isLocked && challenge.locked?.requirements?.roleRequirement && 'opacity-40'}`}>
                    {isLocked ? (
                        <button
                            disabled
                            className="w-full py-2 px-4 rounded-lg font-medium text-white flex items-center justify-center bg-gray-500 dark:bg-gray-700 opacity-80 cursor-not-allowed"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>
                            Locked Challenge
                        </button>
                    ) : (
                        <button
                            onClick={() => (isCompleted || isSubmitted) ? onViewCompletion(challenge) : onStartChallenge(challenge)}
                            className={`w-full py-2 px-4 rounded-lg font-medium text-white flex items-center justify-center ${
                                isCompleted 
                                    ? 'bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800' 
                                    : isSubmitted
                                        ? 'bg-gradient-to-r from-orange-500 to-amber-500 hover:from-orange-600 hover:to-amber-600'
                                        : 'bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3b7ad9] hover:to-[#0b5ad4]'
                            }`}
                        >
                            {isCompleted ? (
                                <>
                                    <FiCheckCircle className="h-4 w-4 mr-2" />
                                    View Completion
                                </>
                            ) : isSubmitted ? (
                                <>
                                    <FiUpload className="h-4 w-4 mr-2" />
                                    Show Submission
                                </>
                            ) : (
                                <>
                                    Start Challenge
                                    <FiArrowRight className="h-4 w-4 ml-2" />
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChallengeCard;