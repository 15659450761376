import React, { useRef, useState, useEffect } from 'react';
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, Radar, PolarRadiusAxis, Tooltip } from 'recharts';
import { motion } from 'framer-motion';

/**
 * SkillRadarChart component displays a radar chart visualization of user skills
 * with animations, tooltips, and responsive design.
 * 
 * @param {Object} props - Component props
 * @param {Object} props.data - Object containing skill values (knowledge, application, etc.)
 * @returns {JSX.Element} - Rendered component
 */
const SkillRadarChart = ({ data }) => {
    // Reference for the chart
    const chartRef = useRef(null);
    const [animatedData, setAnimatedData] = useState([
        { subject: 'Knowledge', value: 0, fullMark: 5 },
        { subject: 'Application', value: 0, fullMark: 5 },
        { subject: 'Recognition', value: 0, fullMark: 5 },
        { subject: 'Ethical Use', value: 0, fullMark: 5 },
        { subject: 'Risks', value: 0, fullMark: 5 },
        { subject: 'Developments', value: 0, fullMark: 5 }
    ]);
    
    // State to track dark mode
    const [isDarkMode, setIsDarkMode] = useState(false);
    
    // Effect to detect dark mode
    useEffect(() => {
        // Initial check
        setIsDarkMode(document.documentElement.classList.contains('dark'));
        
        // Create a MutationObserver to watch for class changes on the html element
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setIsDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });
        
        // Start observing
        observer.observe(document.documentElement, { attributes: true });
        
        // Cleanup
        return () => observer.disconnect();
    }, []);
    
    // Ensure at least one non-zero value for the radar to render properly
    const hasNonZeroValue = Object.values(data).some(val => val > 0);
    
    const targetData = [
        { subject: 'Knowledge', value: data.knowledge, fullMark: 5 },
        { subject: 'Application', value: data.application, fullMark: 5 },
        { subject: 'Recognition', value: data.recognition, fullMark: 5 },
        { subject: 'Ethical Use', value: data.ethical_use, fullMark: 5 },
        { subject: 'Risks', value: data.risks, fullMark: 5 },
        { subject: 'Developments', value: data.developments, fullMark: 5 }
    ];
    
    useEffect(() => {
        // Animate the data values
        const animationDuration = 1500; // 1.5 seconds
        const stepTime = 20; // Update every 20ms
        const steps = animationDuration / stepTime;
        
        let currentStep = 0;
        
        const timer = setInterval(() => {
            currentStep++;
            
            const progress = Math.min(1, currentStep / steps);
            
            // Interpolate values with easing
            const easeOutCubic = (x) => 1 - Math.pow(1 - x, 3);
            const easedProgress = easeOutCubic(progress);
            
            const newData = targetData.map((item, index) => ({
                subject: item.subject,
                value: easedProgress * item.value,
                fullMark: item.fullMark
            }));
            
            setAnimatedData(newData);
            
            if (currentStep >= steps) {
                clearInterval(timer);
            }
        }, stepTime);
        
        return () => clearInterval(timer);
    }, [data]);

    // Custom tooltip component
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            // Get skill level based on value
            const getSkillLevel = (value) => {
                const val = Math.round(value);
                switch (val) {
                    case 0: return "Novice";
                    case 1: return "Beginner";
                    case 2: return "Intermediate";
                    case 3: return "Advanced";
                    case 4: return "Expert";
                    case 5: return "Master";
                    default: return "Not Rated";
                }
            };
            
            const skillLevel = getSkillLevel(payload[0].value);
            
            return (
                <motion.div 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-white/95 dark:bg-gray-800/95 p-4 rounded-xl shadow-xl border border-gray-100 dark:border-gray-700 backdrop-blur-sm"
                    style={{
                        boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div className="flex items-center gap-2">
                        <div className="w-2 h-2 -mt-2 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee]"></div>
                        <h3 className="text-sm mb-2 font-semibold text-gray-900 dark:text-white">{payload[0].payload.subject}</h3>
                    </div>
                    
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-600 dark:text-gray-300">Score</span>
                            <span className="font-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee]">
                                {payload[0].value.toFixed(1)}
                                <span className="text-gray-500 dark:text-gray-400 text-xs font-normal"> / 5</span>
                            </span>
                        </div>
                        
                        <div className="mt-1 w-full bg-gray-200 dark:bg-gray-700 rounded-full h-1.5">
                            <div 
                                className="h-1.5 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee]" 
                                style={{ width: `${(payload[0].value / 5) * 100}%` }}
                            ></div>
                        </div>
                        
                        <div className="flex justify-between items-center mt-1">
                            <span className="text-xs text-gray-500 dark:text-gray-400 mr-4">Level</span>
                            <span className="text-xs font-medium px-2 py-0.5 rounded-full bg-gradient-to-r from-[#468ef9]/80 to-[#0c66ee]/80 text-white">
                                {skillLevel}
                            </span>
                        </div>
                    </div>
                </motion.div>
            );
        }
        return null;
    };

    return (
        <motion.div
            ref={chartRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="relative h-[500px] w-full pb-4"
        >
            {/* Decorative gradient background */}
            <div className="absolute inset-0 rounded-xl blur-xl"></div>
            
            <ResponsiveContainer width="100%" height="100%">
                <RadarChart cx="50%" cy="50%" outerRadius="70%" data={animatedData} startAngle={90} endAngle={-270}>
                    <defs>
                        <linearGradient id="radarGradient" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stopColor="#468ef9" stopOpacity={0.8} />
                            <stop offset="100%" stopColor="#0c66ee" stopOpacity={0.8} />
                        </linearGradient>
                        <filter id="glow" height="300%" width="300%" x="-100%" y="-100%">
                            <feGaussianBlur stdDeviation="5" result="coloredBlur" />
                            <feMerge>
                                <feMergeNode in="coloredBlur" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    
                    <PolarGrid 
                        stroke="rgba(156, 163, 175, 0.3)" 
                        className="dark:stroke-gray-500"
                    />
                    
                    <PolarAngleAxis 
                        dataKey="subject" 
                        tick={{ 
                            fontSize: 12,
                            fontWeight: 500,
                            className: 'fill-black'
                        }}
                    />
                    
                    <PolarRadiusAxis 
                        domain={[0, 5]} 
                        tickCount={6} 
                        tick={({ payload, x, y }) => {
                            return (
                                <g transform={`translate(${x},${y})`}>
                                    <text 
                                        x={0} 
                                        y={0} 
                                        textAnchor="middle" 
                                        fill={isDarkMode ? '#d1d5db' : 'rgba(107, 114, 128, 0.8)'}
                                        fontSize={10}
                                        dy={3}
                                    >
                                        {payload.value}
                                    </text>
                                </g>
                            );
                        }}
                        stroke="rgba(156, 163, 175, 0.3)"
                        className="dark:stroke-gray-400"
                        angle={30}
                    />
                    
                    <Tooltip content={<CustomTooltip />} />
                    
                    <Radar
                        name="Skills"
                        dataKey="value"
                        stroke="url(#radarGradient)"
                        fill="url(#radarGradient)"
                        fillOpacity={0.5}
                        filter="url(#glow)"
                        className="transition-all duration-300 hover:fill-opacity-70"
                        isAnimationActive={true}
                        animationBegin={0}
                        animationDuration={1500}
                        animationEasing="ease-out"
                        dot={true}
                        activeDot={{
                            r: 6,
                            strokeWidth: 2,
                            stroke: '#fff',
                            fill: '#0c66ee',
                            className: 'animate-pulse'
                        }}
                    />
                </RadarChart>
            </ResponsiveContainer>
            
            {/* Legend */}
            <div className="absolute bottom-0 left-0 right-0 flex justify-center pb-2">
                <div className="legend-container flex items-center gap-2 text-xs text-gray-600 dark:text-gray-400 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm px-4 py-2 rounded-full shadow-sm">
                    <div className="w-3 h-3 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee]"></div>
                    <span className="font-medium">Self Assessment</span>
                </div>
            </div>
        </motion.div>
    );
};

export default SkillRadarChart;
