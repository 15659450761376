import React, {useState, useEffect, useRef, useMemo} from 'react';
import { Clock, Bookmark, CheckCircle, Menu } from 'lucide-react';
import {useAuth} from "../../hooks/auth";
import BaseButton from "../../components/base/Button";
import axios from "../../libs/axios";
import RoleEnum from "../../components/enums/RoleEnum";

interface Question {
    id: number;
    question: string;
    answers: string[];
    correctAnswer?: number;
}

// Mock API functions (unchanged)
// const mockQuestions: Question[] = Array.from({ length: 3 }, (_, i) => ({
//     id: i + 1,
//     question: `What is the capital of Country ${i + 1}?`,
//     answers: ['City A', 'City B', 'City C', 'City D'],
//     correctAnswer: Math.floor(Math.random() * 4),
// }));

const QuizApp: React.FC = () => {
    // Must be authenticated
    const { user } = useAuth({ middleware: 'auth', redirectIfAuthenticated: false });
    const [isLoading, setIsLoading] = useState(true);

    const [amountOfQuestions, setAmountOfQuestions] = useState<number>(0); // Fetch from backend the amount of questions in the test.
    const [daysBlockedFailedTest, setDaysBlockedFailedTest] = useState<number>(0); // Fetch from backend the amount of questions in the test.
    const [canStartTest, setCanStartTest] = useState<boolean>(true); // Fetch from backend the amount of questions in the test.
    const [scoreNeededToCompleteTest, setScoreNeededToCompleteTest] = useState<number>(0);
    const [totalTime, setTotalTime] = useState<number>(600);
    const [questions, setQuestions] = useState<Question[]>([]); // Get the questions from the backend.

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [furthestQuestionIndex, setFurthestQuestionIndex] = useState<number>(0);
    const [userAnswers, setUserAnswers] = useState<Record<number, string>>({});
    const [bookmarks, setBookmarks] = useState<Record<number, boolean>>({});
    const [timeLeft, setTimeLeft] = useState<number>(totalTime);
    const [quizStarted, setQuizStarted] = useState<boolean>(false);
    const [quizSubmitted, setQuizSubmitted] = useState<boolean>(false);

    const [score, setScore] = useState<number | null>(null);

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [resultErrorMessage, setResultErrorMessage] = useState<string>("");
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

    const fetchQuestions = async (): Promise<Question[]> => {
        const response = await axios.get("/quiz/questions");
        return response.data;
    };

    const fetchQuizSettings = async (): Promise<number> => {
       try {
            const response = await axios.get("/quiz/settings");
            setScoreNeededToCompleteTest(response.data.minimumScore);
            setDaysBlockedFailedTest(response.data.daysBlockedAfterFailedTest);
            setAmountOfQuestions(response.data.amountOfQuestions);
            setTotalTime(response.data.secondsToCompleteTest);
            setTimeLeft(response.data.secondsToCompleteTest);
            setCanStartTest(response.data.canStartTest);
            return response.data.amountOfQuestions;
        } catch (error: any) {
            if (error?.response && error?.response.status === 401) {
                window.location.href = '/login';
            } else {
                // Do nothing
            }
            return 0; // Add this line
        }
    };

    const submitQuiz = async (answers: Record<number, string>): Promise<{ score: number, errorMessage?: string }> => {
        const response = await axios.post("/quiz/result", answers);
        if (response.data.errorMessage) {
            return { score: 0, errorMessage: response.data.errorMessage };
        }
        return { score: response.data.score };
    };

    const apiCallMadeRef = useRef(false);

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Test";

        const fetchData = async () => {
            if (apiCallMadeRef.current) return;
            apiCallMadeRef.current = true;

            try {
                await fetchQuizSettings();
            } catch (error) {
                return;
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const startQuiz = () => {
       document.title = "Crafting AI Prompts Framework - Test - In progress";

       fetchQuestions().then((questions) => setQuestions(questions))
        .catch((error) => {
            const errorMessage = error.response?.data?.error || 'An error occurred';
            setResultErrorMessage(errorMessage);
        });
        setQuizStarted(true);
        localStorage.setItem('testStarted', 'true');

        // Scroll to the #quiz-started element and center it
        window.location.hash = '#quiz-started';
    };

    const handleAnswer = (answer: string) => {
        //const answerChar = String.fromCharCode(65 + answer);
        setUserAnswers({ ...userAnswers, [currentQuestion.id]: answer });
    };

    const handleNext = () => {
        const currentQuestion = questions[currentQuestionIndex];
        if (currentQuestionIndex < questions.length - 1 && userAnswers[currentQuestion.id] !== undefined) {
            const nextIndex = currentQuestionIndex + 1;
            setCurrentQuestionIndex(nextIndex);
            setFurthestQuestionIndex(Math.max(furthestQuestionIndex, nextIndex));
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const toggleBookmark = () => {
        setBookmarks({ ...bookmarks, [currentQuestionIndex]: !bookmarks[currentQuestionIndex] });
    };

    const getBookmarkedQuestions = () => {
        return Object.entries(bookmarks)
            .filter(([_, isBookmarked]) => isBookmarked)
            .map(([index]) => parseInt(index) + 1);
    };

    const currentQuestion = questions[currentQuestionIndex];
    const timePercentage = (timeLeft / totalTime) * 100;
    const bookmarkedQuestions = getBookmarkedQuestions();


    const handleSubmit = async () => {
        document.title = "Crafting AI Prompts Framework - Test - Result";
        closeConfirmDialog();
        setQuizSubmitted(true);

        // Before submitting the userAnswers, we need to add all the questions that are not answered to this array with an empty string as value.
        const addEmptyAnswers: Record<number, string> = {}

        if (Object.keys(userAnswers).length < questions.length) {
            for (let i = 0; i < questions.length; i++) {
                if (userAnswers[questions[i].id] === undefined) {
                    addEmptyAnswers[questions[i].id] = "";
                }
            }
        }

        // Add the addEmptyAnswers to the userAnswers
        const updatedUserAnswers = { ...userAnswers, ...addEmptyAnswers };

        const result = await submitQuiz(updatedUserAnswers);
        if (result.errorMessage) {
            setResultErrorMessage(result.errorMessage);
        }
        setScore(result.score);
    }

    const handleQuestionClick = (index: number) => {
        if (index <= furthestQuestionIndex) {
            setCurrentQuestionIndex(index);
            setShowMenu(false);
        }
    };

    const openConfirmDialog = () => {
        setShowConfirmDialog(true);
    };

    const closeConfirmDialog = () => {
        document.documentElement.classList.remove('overflow-hidden');
        setShowConfirmDialog(false);
    };

    function formatTime(totalSeconds: number): string {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        let timeString = '';

        if (hours > 0) { timeString += `${hours} hour${hours > 1 ? 's' : ''} `; }
        if (minutes > 0) { timeString += `${minutes} minute${minutes > 1 ? 's' : ''} `; }

        if (seconds > 0) {
            if (minutes > 0) { timeString += `and `; }
            timeString += `${seconds} second${seconds > 1 ? 's' : ''}`;
        }

        return timeString.trim();
    }

    const dialogContent = useMemo(() => {
        if (!showConfirmDialog) {
            return null;
        }

        document.documentElement.classList.add('overflow-hidden');

        return(<div id="default-modal" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed z-50 justify-center bg-black/90 items-center w-full inset-0 h-full max-h-full px-5">
            <div className="relative p-4 w-full max-w-4xl max-h-full m-auto">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-900">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <span className="text-xl font-semibold text-gray-900 dark:text-white">
                          Are you sure to submit the form?
                        </span>
                        <button type="button" onClick={() => closeConfirmDialog() } className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">You're about to submit the form. Please note that there is no turning back.
                            <br />To summarize:</p>

                        {bookmarkedQuestions.length > 0 ?
                            <ul className="px-10 list-disc leading-relaxed text-gray-500 dark:text-gray-400">
                                <li className="pb-4">You have still <span className={"text-sky-600 text-bold"}>{bookmarkedQuestions.length}</span> bookmarked questions. <br />
                                    The following questions are still bookmarked:</li>
                                <ul className={"px-10 list-disc leading-relaxed text-gray-500 dark:text-gray-400"}>
                                    {bookmarkedQuestions.map((question, index) => (
                                        <li key={index}><span className={"text-sky-600"}>Question: {question}</span></li>
                                    ))}
                                </ul>
                            </ul> :
                            <>
                                <p className="text-base leading-relaxed text-green-800 dark:text-green-700">You did answer all questions, and have no bookmarked questions left.</p>
                            </>
                        }
                    </div>
                    <div className="p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">Are you sure you want to submit your results?</p>
                        <div className={"flex items-center"}>
                            <button data-modal-hide="default-modal" onClick={() => { handleSubmit(); }} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mr-3">Yes, submit</button>
                            <button data-modal-hide="default-modal" onClick={() => { closeConfirmDialog() }} type="button"
                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-slate-900 rounded-lg
                                        border border-gray-200 hover:bg-slate-900 hover:text-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100
                                        dark:focus:ring-gray-700 dark:bg-slate-900 dark:text-white dark:border-gray-600 dark:hover:text-white dark:hover:bg-slate-700">
                                No, go back to the test
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }, [showConfirmDialog, bookmarkedQuestions]);

    const questionList = useMemo(() => {
        return questions.map((q, index) => (
            <li
                key={q.id}
                className={`p-2 rounded cursor-pointer transition-colors duration-200 ${
                    q.id === currentQuestion.id
                        ? 'bg-blue-500 text-white'
                        : index <= furthestQuestionIndex
                            ? userAnswers[q.id] !== undefined
                                ? 'hover:bg-blue-600 bg-blue-900 text-white' // Question done
                                : 'hover:bg-blue-900 bg-gray-400 text-white' // Current Question
                            : 'bg-gray-300 cursor-not-allowed ' // Question not yet reached
                }`}
                onClick={() => handleQuestionClick(index)}
            >
                <div className={`flex items-center justify-between ${index > furthestQuestionIndex ? `cursor-not-allowed` : ``}` }>
                    <span>Question {index + 1}</span>
                    <div className="flex space-x-1">
                        {bookmarks[index] && <Bookmark className="h-4 w-4" />}
                        {userAnswers[q.id] !== undefined && <CheckCircle className="h-4 w-4" />}
                    </div>
                </div>
            </li>
        ));
    }, [questions, currentQuestion?.id, furthestQuestionIndex, userAnswers, bookmarks]);

    useEffect(() => {
        if (quizStarted && timeLeft > 0 && !quizSubmitted) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else if (timeLeft === 0 && !quizSubmitted && quizStarted) {
            handleSubmit();
        }
    }, [timeLeft, quizSubmitted, quizStarted]);

    if (isLoading || amountOfQuestions === 0 || daysBlockedFailedTest === 0 || scoreNeededToCompleteTest === 0) {
        return <div className="flex justify-center items-center h-screen text-white">Loading...</div>;
    }

    if (!quizStarted) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 p-4 text-gray-800">
                <div className={"max-w-6xl p-5"}>
                    <h1 className="text-3xl font-bold mb-4 text-blue-700">Crafting AI Prompts Framework Test</h1>
                    <p className="text-lg mb-6">
                        Welcome to Level 1 of the Crafting AI Prompts Framework documentation test.<br />
                        This assessment will measure your knowledge of:
                    </p>
                    <ul className={"list-disc list-inside px-3 mb-6"}>
                        <li className={"mb-3"}>The Crafting AI Prompts Framework (IPE)</li>
                        <li className={"mb-3"}>Prompt Engineering</li>
                        <li className={"mb-3"}>Techniques</li>
                        <li className={"mb-3"}>Adversarial Prompting</li>
                    </ul>
                    <p className="text-lg mb-6">
                        By starting the test, you agree to the <a href="/test/terms"
                                                                  className="text-blue-500 underline">terms and
                        conditions</a>. <br />The test comprises <span
                        className="text-sky-700 text-bold">{amountOfQuestions} questions</span>, and to pass, you need a score of <span className="text-sky-700 text-bold">{scoreNeededToCompleteTest}%</span> or higher.
                    </p>
                    <h3 className="text-3xl font-bold mb-4 text-blue-700">Preparation</h3>
                    <p className="text-lg mb-6">
                        Before starting, please ensure you have thoroughly reviewed the Crafting AI Prompts Framework
                        documentation or participated in relevant training. If you do not pass, you will need to
                        wait <span className="text-sky-700 text-bold">{daysBlockedFailedTest} days</span> before attempting the test again.
                    </p>
                    <h2 className="text-3xl font-bold mb-4 text-blue-700">How It Works</h2>
                    <p className="text-lg mb-6">
                        Your remaining time is displayed in the top left corner, with a progress bar above each
                        question. You can navigate through the questions using the left-side menu. Note that you must
                        answer the current question before moving on to the next one. You can bookmark questions for
                        review, and you won’t be able to submit the test until you’ve reviewed all bookmarked questions
                        or confirmed submission. Bookmarked questions will be marked in the menu.
                        The current question number is displayed in the top right corner.
                    </p>
                    <h2 className="text-3xl font-bold mb-4 text-blue-700">Time to Complete the Test</h2>
                    <p className="text-lg mb-6">
                        You will have <span className="text-sky-700 text-bold">{formatTime(totalTime)}</span> to complete
                        the test. Make sure you are ready and in a distraction-free environment before starting.</p>

                    <p className="text-lg mb-6">Please note: Do not close the test window during the test, as doing so will result in a <span className="text-sky-700 text-bold">0% score</span>,
                        and you will need to wait <span className="text-sky-700 text-bold">{daysBlockedFailedTest} days</span> before attempting
                        the test again.
                    </p>

                    {user?.role >= RoleEnum.DefaultUser ?
                        canStartTest ?
                            acceptedTerms ?
                                <button
                                    onClick={startQuiz}
                                    className="px-6 py-3 mr-5 mb-5 bg-green-700 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                                >
                                    Start test (warning: timer will start!)
                                </button> :
                                <button
                                    onClick={() => setAcceptedTerms(true)}
                                    className="px-6 py-3 mr-5 mb-5 bg-green-700 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                                >
                                    I accept the terms
                                </button>
                            : <p className={"text-red-700"}>You're not able to start this test. Please contact support if you think this is wrong.</p>
                        : <p className={"text-red-700"}>You're not able to start this test as you don't have the rights. Please contact support.</p>
                    }

                    <BaseButton
                        url={"/dashboard"}
                        //styles="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                        styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                        Back to Dashboard
                    </BaseButton>
                </div>
            </div>
        );
    }

    if (questions.length === 0) {
        if (resultErrorMessage !== "") {
            return <>
                <div className="flex justify-center items-center h-screen text-white flex-col">
                    <div className={"mb-8"}>{resultErrorMessage}</div>
                    <BaseButton
                        url={"/dashboard"}
                        styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white flex-row">
                        Back to Dashboard
                    </BaseButton>
                </div>
            </>;
        }
        return <div className="flex justify-center items-center h-screen text-white">Loading...</div>;
    }

    if (quizSubmitted) {
        return (
            <div className="flex justify-center items-center h-screen p-4 bg-gradient-to-b from-blue-100 to-blue-200">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                    <h2 className="text-2xl font-bold text-center mb-4 text-blue-700">Quiz Completed</h2>
                    {score !== null && resultErrorMessage === "" ? (
                        <div className="text-center">
                            <p className="text-6xl font-bold mb-4 text-blue-500">{score}%</p>
                            <p className="text-xl text-gray-800">Great job on completing the test!</p>

                            {score >= scoreNeededToCompleteTest ?
                                <>
                                    <hr />
                                    <h2 className={"text-green-800"}>Congratulations!</h2>
                                    <p className="text-green-800">You passed the test! <br />
                                        You can view your results within 2 minutes in your account. Please go to the dashboard on this website (when logged in) for more information.</p>
                                </> :
                                <>
                                    <hr />
                                    <h2 className={"text-red-800"}>Sorry,</h2>
                                    <p className="text-red-800">You didn't pass the test. To pass the test you need a score of 85% or higher. <br />Please try again in a month.</p>
                                    <p className="text-gray-800">Before you try again, we highly recommend reviewing the documentation again on our website.</p>
                                </>
                            }

                            <BaseButton
                                url={"/dashboard"}
                                //styles="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                                styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                                Back to Dashboard
                            </BaseButton>
                        </div>
                    ) : resultErrorMessage !== "" ?
                        (<p className="text-center text-red-800">Something went wrong! It seems like we were not able to calculate the score! Please contact us with code: 000001R</p>) : (
                        <p className="text-center">Calculating your score...</p>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-screen bg-gradient-to-b from-blue-50 to-blue-100" id={"quiz-started"}>
            <div className="flex items-center justify-between p-4 bg-white shadow-md">
                <button onClick={() => setShowMenu(!showMenu)} className="lg:hidden">
                    <Menu className="h-6 w-6 text-gray-800" />
                </button>
                <div className="flex items-center space-x-2">
                    <Clock className="h-5 w-5 text-blue-500" />
                            <span className={`font-medium ${timePercentage <= 10 ? 'animate-pulse text-red-500' : 'text-gray-800'}`}>
                        Time left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
                    </span>
                </div>
                <span className="font-medium text-gray-800">Question {currentQuestionIndex + 1} of {questions.length}</span>
            </div>
            <div className="flex flex-1 overflow-hidden">
                {(showMenu || window.innerWidth >= 1024) && (
                    <div className="w-full lg:w-1/4 p-4 border-r overflow-y-auto bg-white max-w-[25rem]">
                        <h2 className="text-xl font-bold mb-4 text-blue-700">Questions</h2>
                        <ul className="space-y-2 mb-4">
                            {questionList}
                        </ul>
                    </div>
                )}
                <div className="flex-1 p-4 overflow-y-auto">
                    <div className="h-2 bg-gray-200 rounded-full mb-4">
                        <div
                            className={`h-full rounded-full transition-all duration-500 ${
                                timePercentage > 20 ? 'bg-green-700' : timePercentage > 10 ? 'bg-orange-500' : 'bg-red-700'
                            }`}
                            style={{ width: `${timePercentage}%` }}
                        ></div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl text-blue-700 " style={{ flexBasis: '90%' }}>{currentQuestion.question}</h2>
                            <div style={{ width: '5%', height: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginLeft: "2rem" }}>
                            {bookmarks[currentQuestionIndex] && <Bookmark className={"text-yellow-900"} size={40} />}
                        </div>
                        </div>
                        <div className="space-y-4">
                            {currentQuestion.answers.map((answer, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleAnswer(answer)}
                                    className={`w-full p-3 text-left rounded-lg transition- duration-200 ${
                                        userAnswers[currentQuestion.id] === answer
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-gray-200 text-gray-800 hover:bg-blue-900 hover:text-white'
                                    }`}
                                >
                                    {String.fromCharCode(65 + index)}. <span style={{ transition: 'none' }} dangerouslySetInnerHTML={{ __html: answer }} />
                                </button>
                            ))}
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 mt-6">
                            <button
                                onClick={handlePrevious}
                                disabled={currentQuestionIndex === 0}
                                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
                            >
                                Previous
                            </button>
                            <button
                                onClick={toggleBookmark}
                                className={`flex items-center px-4 py-2 border rounded-lg ` + (bookmarks[currentQuestionIndex] ? `border-green-800 text-green-800 hover:bg-green-200` : `border-blue-500 text-blue-500 hover:bg-blue-100`)}
                            >
                                {bookmarks[currentQuestionIndex] ? <CheckCircle /> : <Bookmark />}
                                <span className={"ml-2"}>{bookmarks[currentQuestionIndex] ? 'Remove Bookmark' : 'Bookmark'}</span>
                            </button>
                            {currentQuestionIndex === questions.length - 1 ? (
                                <button
                                    onClick={openConfirmDialog}
                                    className="px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-800"
                                >
                                    Submit Quiz
                                </button>
                            ) : (
                               <button
                                onClick={handleNext}
                                disabled={userAnswers[currentQuestion.id] === undefined}
                                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Next
                            </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            { dialogContent }
        </div>
    );
};

export default QuizApp;