import { Zap, Target, Shield, Star, Book, Trophy, MessageCircle, Share, Award, Crown } from 'lucide-react';

export const achievements = [
    {
        id: 'quick_learner',
        name: 'Quick Learner',
        description: 'Completed profile setup',
        icon: Zap,
        unlocked: false, // Always unlocked as they have a profile
        color: 'text-blue-500'
    },
    {
        id: 'ticket_solver',
        name: 'Ticket Solver',
        description: 'Closed 5 tickets',
        icon: Target,
        unlocked: false, // This will be updated based on closedTicketsCount
        color: 'text-green-500'
    },
    {
        id: 'security_first',
        name: 'Security First',
        description: 'Reviewed Terms of Service',
        icon: Shield,
        unlocked: false, // This will be updated based on localStorage
        color: 'text-purple-500'
    },
    {
        id: 'first_rating',
        name: 'First Rating',
        description: 'Rated your first tool',
        icon: Star,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-orange-500'
    },
    {
        id: 'rating_enthusiast',
        name: 'Rating Enthusiast',
        description: 'Rated 10 tools',
        icon: Book,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-indigo-500'
    },
    {
        id: 'rating_expert',
        name: 'Rating Expert',
        description: 'Rated 30 tools',
        icon: Trophy,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-yellow-500'
    },
    {
        id: 'first_review',
        name: 'First Review',
        description: 'Reviewed your first tool',
        icon: Star,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-orange-500'
    },
    {
        id: 'review_enthusiast',
        name: 'Review Enthusiast',
        description: 'Reviewed 10 tools',
        icon: MessageCircle,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-indigo-500'
    },
    {
        id: 'review_expert',
        name: 'Review Expert',
        description: 'Reviewed 20 tools',
        icon: Trophy,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-yellow-500'
    },    
    {
        id: 'share_tools',
        name: 'Share Tools',
        description: 'Share your tools with the community',
        icon: Share,
        unlocked: false, // This will be updated based on ratingsCount
        color: 'text-green-500'
    },  
    {
        id: 'first_certificate',
        name: 'First Certificate',
        description: 'Earned your first certificate',
        icon: Award,
        unlocked: false, // This will be updated based on certificateCount
        color: 'text-yellow-500'
    },
    {
        id: 'master',
        name: 'Master',
        description: 'Earned all certificates',
        icon: Crown,
        unlocked: false, // This will be updated based on certificateCount
        color: 'text-yellow-500'
    },
];
