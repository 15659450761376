const ProfileStats = ({ user, ticketsCount, closedTicketsCount, certificateCount, ratingsCount, toolReviewCount }) => {
    return(<div className="rounded-xl bg-gray-50 dark:bg-gray-800/50 border border-gray-200 dark:border-gray-700/50 p-6 shadow-sm">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-6">Your Activity Statistics</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="bg-white dark:bg-gray-700/70 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:scale-105">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                            {ticketsCount}
                        </span>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                            Active Tickets
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-gray-700/70 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:scale-105">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                            {closedTicketsCount}
                        </span>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                            Tickets Closed
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-gray-700/70 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:scale-105">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <span className="text-2xl font-bold text-emerald-600 dark:text-emerald-400 ml-5">
                            {certificateCount}
                        </span>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                            Certificates Earned
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-gray-700/70 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:scale-105">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                            {ratingsCount}
                        </span>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                            Tools Rated
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-gray-700/70 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:scale-105">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        <span className="text-2xl font-bold text-sky-600 dark:text-sky-400 ml-5">
                            {toolReviewCount}
                        </span>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-600 dark:text-gray-300 pt-4 pl-4">
                            Tools Reviewed
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-gray-700/70 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:scale-105">
                <div className="flex flex-col">
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">
                        Profile Created
                    </p>
                    <div className="flex items-center gap-2">
                        <svg 
                            className="w-5 h-5 text-emerald-500 dark:text-emerald-400" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
                            />
                        </svg>
                        <span className="text-md font-bold text-gray-900 dark:text-white">
                            {new Date(user.created_at).toLocaleDateString('en-US', { 
                                year: 'numeric', 
                                month: 'long', 
                                day: 'numeric' 
                            })}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
}

export default ProfileStats;