import { useState, useEffect } from "react";
import axios from "../../libs/axios";
import { DropResult } from 'react-beautiful-dnd';
import { useAuth } from "../../hooks/auth";
import { NewPrompt } from "../../components/PromptLibrary/types/NewPrompt";
import { PromptLibraryPrompt } from "../../components/PromptLibrary/types/PromptLibraryPrompt";
import { Tool } from "../../components/PromptLibrary/types/Tool";
import { Section } from "../../components/PromptLibrary/types/Section";
import { AddPromptModal } from "../../components/PromptLibrary/modals/AddPromptModal";
import { RecipeFormModal } from "../../components/PromptLibrary/modals/RecipeFormModal";
import { GridItem } from "../../components/PromptLibrary/types/GridItem";
import { ViewMode } from "../../components/PromptLibrary/types/ViewMode";
import { ChatboxMergedPrompts } from "../../components/PromptLibrary/ChatboxMergedPrompts";
import { ChatboxNewPrompt } from "../../components/PromptLibrary/ChatboxNewPrompt";
import { SearchOptions } from "../../components/PromptLibrary/SearchOptions";
import { NewPromptButton } from "../../components/PromptLibrary/NewPromptButton";
import { filterPrompts } from "../../components/PromptLibrary/functions/FilterPrompts";
import { Recipe } from "../../components/PromptLibrary/types/Recipe";
import { ShowSections } from "../../components/PromptLibrary/ShowSections";
import { DeleteRecipeModal } from "../../components/PromptLibrary/modals/DeleteRecipeModal";
import { DeletePromptModal } from "../../components/PromptLibrary/modals/DeleteModal";
import { EditPromptModal } from "../../components/PromptLibrary/modals/EditPromptModal";
import { TemplateValuesModal } from "../../components/PromptLibrary/modals/TemplateValuesModal";
import { ShowTableLayout } from "../../components/PromptLibrary/ShowTableLayout";
import { ShowNoSections } from "../../components/PromptLibrary/ShowNoSections";
import { PROMPT_SECTIONS_MERGED_PROMPTS } from "../../components/PromptLibrary/constants/sections";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
function PromptLibrary() {
    const [prompts, setPrompts] = useState<PromptLibraryPrompt[]>([]);

    // State for merged prompts
    const [mergedPrompts, setMergedPrompts] = useState<PromptLibraryPrompt[]>([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedSection, setSelectedSection] = useState<Section>("Context");
    const sectionOrder = ["Context", "Register", "Acting Role", "Format", "Task"];
    const [viewMode, setViewMode] = useState<ViewMode>('grid');
    const [copiedId, setCopiedId] = useState<string | null>(null);

    const [tools, setTools] = useState<Tool[]>([]);
    const [isLoadingTools, setIsLoadingTools] = useState(true);
    const [toolsError, setToolsError] = useState(false);
    const [allToolsSelected, setAllToolsSelected] = useState(true);
    const [allLabelsSelected, setAllLabelsSelected] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTools, setSelectedTools] = useState<string[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

    const [recipes, setRecipes] = useState<Recipe[]>([]);
    const [isRecipeModalOpen, setIsRecipeModalOpen] = useState(false);

    const [showPublishedRecipes, setShowPublishedRecipes] = useState(true);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [promptToDelete, setPromptToDelete] = useState<string | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [promptToEdit, setPromptToEdit] = useState<PromptLibraryPrompt | null>(null);
    
    // Template values modal state
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const [currentTemplateText, setCurrentTemplateText] = useState('');
    const [currentPromptId, setCurrentPromptId] = useState('');

    const [isDeleteRecipeModalOpen, setIsDeleteRecipeModalOpen] = useState(false);
    const [recipeToDelete, setRecipeToDelete] = useState<string | null>(null);
    const [showSections, setShowSections] = useState(false);

    // Fetch tools and prompts on component mount
    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingTools(true);
            try {
                // Fetch tools
                const toolsResponse = await axios.get('/public/tools');
                setTools(toolsResponse.data.ratings);
                setToolsError(false);
                
                // Fetch prompts
                try {
                    const promptsResponse = await axios.get('/prompt-library/prompts');
                    setPrompts(promptsResponse.data);
                } catch (promptError) {
                    console.error('Error fetching prompts:', promptError);
                }
                
                // Fetch recipes
                try {
                    const recipesResponse = await axios.get('/prompt-library/recipes');
                    setRecipes(recipesResponse.data);
                } catch (recipeError) {
                    console.error('Error fetching recipes:', recipeError);
                }
            } catch (error) {
                setToolsError(true);
            } finally {
                setIsLoadingTools(false);
            }
        };
    
        fetchData();
    }, []);

    const addPromptHeader = (text: string) => {
        const components = {
            context: text.includes('<Context>'),
            register: text.includes('<Register>'),
            actingRole: text.includes('<Acting Role>'),
            format: text.includes('<Format>'),
            task: text.includes('<Task>'),
        };
            
        let header = '';
        if (components.register) { header += 'Use the <Register> to define the register of the output, '; }
        if (components.actingRole) { header += 'the <Acting Role> how you should act, '; }
        if (components.format) { header += 'the <Format> how you should format the output, '; }
        if (components.task) { header += 'the <Task> what you should do. '; }
        if (components.context) { header += 'Finally use the <Context> to do the task.'; }
        return header ? `${header}\n\n${text}` : text;
    }

    // Check if text contains template placeholders
    const containsTemplatePlaceholders = (text: string): boolean => {
        const placeholderRegex = /\{\{([^{}]+)\}\}/g;
        return placeholderRegex.test(text);
    };

    const handleCopy = (text: string, promptId: string) => {
        // Add the prompt header
        const processedText = addPromptHeader(text);
        
        // Check if the prompt contains template placeholders
        if (containsTemplatePlaceholders(processedText)) {
            // Open the template values modal
            setCurrentTemplateText(processedText);
            setCurrentPromptId(promptId);
            setIsTemplateModalOpen(true);
        } else {
            // No templates, copy directly
            navigator.clipboard.writeText(processedText);
            setCopiedId(promptId);
            setTimeout(() => {
                setCopiedId(null);
            }, 2000);
        }
    };
    
    // Handle the submission of the template values modal
    const handleTemplateSubmit = (filledTemplate: string) => {
        navigator.clipboard.writeText(filledTemplate);
        setCopiedId(currentPromptId);
        setTimeout(() => {
            setCopiedId(null);
        }, 2000);
    };

    const handleAddToMerged = (prompt: PromptLibraryPrompt) => {
        const sectionOrder = PROMPT_SECTIONS_MERGED_PROMPTS;
        
        // If there are no merged prompts, just add the new one
        if (mergedPrompts.length === 0) {
            setMergedPrompts([prompt]);
            return;
        }
    
        // Find where this section should be inserted
        const promptSectionIndex = sectionOrder.indexOf(prompt.section);
        const insertIndex = mergedPrompts.findIndex(p => 
            sectionOrder.indexOf(p.section) > promptSectionIndex
        );
    
        // If no later sections found, add to the end
        if (insertIndex === -1) {
            setMergedPrompts([...mergedPrompts, prompt]);
            return;
        }
    
        // Insert at the correct position
        const newMergedPrompts = [...mergedPrompts];
        newMergedPrompts.splice(insertIndex, 0, prompt);
        setMergedPrompts(newMergedPrompts);
    };

    const handleRemoveFromMerged = (promptId: string) => {
        setMergedPrompts(mergedPrompts.filter(p => p.id !== promptId));
    };

    const getMergedText = () => {
        return mergedPrompts
            .map(prompt => `<${prompt.section}>${prompt.prompt}</${prompt.section}>`)
            .join('\n\n');
    };

    const handleAddPrompt = async (newPrompt: NewPrompt) => {
        try {
            // Add loading state if needed
            const response = await axios.post('/prompt-library/prompts', newPrompt);
            const prompt: PromptLibraryPrompt = response.data;
            
            setPrompts(prevPrompts => {
                const updatedPrompts = [...prevPrompts, prompt];
                return updatedPrompts;
            });            
            setIsAddModalOpen(false);
            
            setTimeout(() => {
                document.getElementById(prompt.id)?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        } catch (error) {
            console.error('Error adding prompt:', error);
        }
    };

    const handleOpenModal = (section: Section) => {
        setSelectedSection(section);
        setIsAddModalOpen(true);
    };

    const resetFilters = () => {
        setSearchQuery('');
        setSelectedTools([]);
        setSelectedLabels([]);
        setAllToolsSelected(true);
        setAllLabelsSelected(true);
    };

    const getUniqueLabels = () => {
        const labels = new Set<string>();
        prompts.forEach(prompt => {
            if (prompt.labels && prompt.labels.length > 0) {
                prompt.labels.forEach(label => labels.add(label));
            }
        });
        return Array.from(labels);
    };

    const getUsedTools = () => {
        const usedToolIds = new Set(prompts
            .map(prompt => prompt.toolId)
            .filter(toolId => toolId && toolId !== '')); // Filter out empty tool IDs
        
        return tools.filter(tool => usedToolIds.has(String(tool.id))); // Convert tool.id to string for comparison
    };

    const handleDeletePrompt = async (promptId: string) => {
        setPromptToDelete(promptId);
        setIsDeleteModalOpen(true);
    };

    const handleEditPrompt = async (prompt: PromptLibraryPrompt) => {
        setPromptToEdit(prompt);
        setIsEditModalOpen(true);
    };

    const handleSaveEditedPrompt = async (updatedPrompt: PromptLibraryPrompt) => {
        try {
            const response = await axios.put(`/prompt-library/prompts/${updatedPrompt.id}`, updatedPrompt);
            setPrompts(prevPrompts => {
                return prevPrompts.map(p => p.id === updatedPrompt.id ? response.data : p);
            });
        } catch (error) {
            console.error('Error updating prompt:', error);
        }
        
        // Also update the prompt in mergedPrompts if it exists there
        setMergedPrompts(prevMerged => {
            return prevMerged.map(p => p.id === updatedPrompt.id ? updatedPrompt : p);
        });
    };

    // Add the confirmation handler
    const confirmDelete = async () => {
        if (promptToDelete) {
            try {
                // Call the API to delete the prompt
                await axios.delete(`/prompt-library/prompts/${promptToDelete}`);
                
                // Update the UI by removing the deleted prompt
                setPrompts(prompts.filter(p => p.id !== promptToDelete));
                setMergedPrompts(mergedPrompts.filter(p => p.id !== promptToDelete));
            } catch (error) {
                console.error('Failed to delete prompt:', error);
            }
        }
        setIsDeleteModalOpen(false);
        setPromptToDelete(null);
    };

    // Define the function inside PromptLibrary
    const handleSaveRecipe = async (
        title: string, 
        description: string, 
        isPublished: boolean,
        modelParams: {
            temperature: number;
            maxTokens: number;
            topP: number;
            frequencyPenalty: number;
            presencePenalty: number;
        }
    ) => {
        try {
            // Prepare the recipe data
            const recipeData = {
                title,
                description,
                prompts: mergedPrompts.map(prompt => prompt.id),
                isPublished,
                modelParams
            };
            
            // Send the recipe to the API
            const response = await axios.post('/prompt-library/recipes', recipeData);
            const newRecipe: Recipe = response.data;
        
            // Update the UI with the new recipe
            setRecipes(prevRecipes => [...prevRecipes, newRecipe]);
            setMergedPrompts([]); // Clear merged prompts
            setPromptToEdit(null);
            setIsRecipeModalOpen(false); // Close modal
        } catch (error) {
            console.error('Error saving recipe:', error);
        }
    };

    // Add a type guard function
    const isRecipe = (item: GridItem | Recipe): item is Recipe => {
        return 'prompts' in item;
    };

    // Update the handleLoadRecipe function
    const handleLoadRecipe = (item: GridItem | Recipe) => {
        if (!isRecipe(item)) {
            // It's a GridItem, find the corresponding Recipe
            const recipe = recipes.find(r => r.id === item.id);
            if (recipe) {
                setMergedPrompts(recipe.prompts);
            }
        } else {
            // It's a Recipe
            setMergedPrompts(item.prompts);
        }
    };

    // Update the delete handler
    const handleDeleteRecipe = (recipeId: string) => {
        setRecipeToDelete(recipeId);
        setIsDeleteRecipeModalOpen(true);
    };

    // Add the confirmation handler
    const confirmDeleteRecipe = async () => {
        if (recipeToDelete) {
            try {
                // Call the API to delete the recipe
                await axios.delete(`/prompt-library/recipes/${recipeToDelete}`);
                
                // Update the UI by removing the deleted recipe
                setRecipes(recipes.filter(recipe => recipe.id !== recipeToDelete));
            } catch (error) {
                console.error('Error deleting recipe:', error);
            }
        }
        setIsDeleteRecipeModalOpen(false);
        setRecipeToDelete(null);
    };

    // Update the getFilteredRecipes function to consider recipe ownership
    const getFilteredRecipes = () => {
        return recipes.filter(recipe => {
            // Show the recipe if:
            // 1. showPublishedRecipes is true (show all published recipes) OR
            // 2. The recipe is not published OR
            // 3. The recipe is owned by the current user
            if (!showPublishedRecipes && recipe.isPublished && !recipe.is_owner) {
                return false;
            }

            // Then apply the rest of the filters
            const matchesSearch = recipe.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                recipe.prompts.some(prompt => 
                    prompt.prompt.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    prompt.title.toLowerCase().includes(searchQuery.toLowerCase())
                );

            const matchesLabels = selectedLabels.length === 0 || 
                recipe.prompts.some(prompt => 
                    prompt.labels.some(label => selectedLabels.includes(label))
                );

            const matchesTools = selectedTools.length === 0 || 
                recipe.prompts.some(prompt => 
                    selectedTools.includes(prompt.toolId)
                );

            return matchesSearch && matchesLabels && matchesTools;
        });
    };
    
    // Filter prompts based on ownership and published status
    const getFilteredPrompts = () => {
        return prompts.filter(prompt => {
            // Show the prompt if:
            // 1. showPublishedRecipes is true (show all published prompts) OR
            // 2. The prompt is not published OR
            // 3. The prompt is owned by the current user
            if (!showPublishedRecipes && prompt.is_published && !prompt.is_owner) {
                return false;
            }
            
            // Return true to include this prompt
            return true;
        });
    };

    const handleClearMerged = () => {
        setMergedPrompts([]);
    };

    // Helper function to get all items for the grid view
    const getAllItems = (): GridItem[] => {
        // Get regular prompts and cast them to GridItem
        const regularPrompts: GridItem[] = filterPrompts({
            prompts: getFilteredPrompts(), // Use filtered prompts
            searchQuery,
            selectedTools,
            selectedLabels
        }).map(prompt => ({
            ...prompt,
            isRecipe: false
        }));
        
        // Get recipes and format them like prompts
        const recipePrompts: GridItem[] = getFilteredRecipes().map(recipe => ({
            id: recipe.id,
            title: recipe.title,
            prompt: `Recipe with ${recipe.prompts.length} prompts`,
            section: "Recipes" as Section,
            labels: recipe.prompts.flatMap(p => p.labels),
            toolId: recipe.prompts[0]?.toolId || "",
            isRecipe: true
        }));

        // Combine and sort all items
        return [...regularPrompts, ...recipePrompts]
            .sort((a, b) => {
            const aIndex = sectionOrder.indexOf(a.section);
            const bIndex = sectionOrder.indexOf(b.section);
            const aType = a.type || 'component';
            const bType = b.type || 'component';
            const typeOrder = {
                'component': 0,
                'example': 1,
                'template': 2,
                undefined: 0  // treat undefined as 'component'
            };
            return aIndex - bIndex || typeOrder[aType] - typeOrder[bType];
        });
    };

    const toggleRecipePublish = async (recipeId: string) => {
        try {
            // Find the current recipe
            const recipe = recipes.find(r => r.id === recipeId);
            if (!recipe) return;
            
            // Toggle the published status
            const newPublishedStatus = !recipe.isPublished;
            
            // Call the API to update the recipe
            await axios.put(`/prompt-library/recipes/${recipeId}/publish`, {
                isPublished: newPublishedStatus
            });
            
            // Update the UI
            setRecipes(recipes.map(r => 
                r.id === recipeId 
                    ? { ...r, isPublished: newPublishedStatus }
                    : r
            ));
        } catch (error) {
            console.error('Error toggling recipe publish status:', error);
        }
    };
    
    const togglePromptPublish = async (promptId: string) => {
        try {
            // Find the current prompt
            const prompt = prompts.find(p => p.id === promptId);
            if (!prompt) return;
            
            // Toggle the published status
            const newPublishedStatus = !prompt.is_published;
            
            // Call the API to update the prompt
            await axios.put(`/prompt-library/prompts/${promptId}/publish`, {
                isPublished: newPublishedStatus
            });
            
            // Update the UI
            setPrompts(prompts.map(p => 
                p.id === promptId 
                    ? { ...p, is_published: newPublishedStatus }
                    : p
            ));
        } catch (error) {
            console.error('Error toggling prompt publish status:', error);
        }
    };

    // Update the onDragEnd function
    const onDragEnd = (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        const newPrompts = Array.from(mergedPrompts);
        const [removed] = newPrompts.splice(result.source.index, 1);
        newPrompts.splice(result.destination.index, 0, removed);
        
        setMergedPrompts(newPrompts);
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
                    <DashboardCard 
                        title="" 
                        action={null} 
                        className="md:col-span-2 overflow-hidden mb-4"
                    >
                        <div className="flex items-center gap-6 rounded-xl">
                            <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#0c66ee] dark:to-[#468ef9] flex items-center justify-center">
                                <span className="text-2xl font-bold text-white dark:text-black">
                                    P
                                </span>
                            </div>
                            <div>
                                <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                                    Prompt <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:bg-gradient-to-r dark:from-[#0c66ee] dark:to-[#468ef9]">Library</span>
                                </h1>
                                <p className="text-gray-600 dark:text-gray-300 mt-1">Store and manage your prompts using the Crafting AI Prompts Framework.</p>
                            </div>
                        </div>
                    </DashboardCard>
                    <DashboardCard 
                        title="Quick Actions" 
                        action={null} 
                        className="md:col-span-1 overflow-hidden mb-4"
                    >
                        <div className="flex flex-wrap gap-2">
                            <NewPromptButton handleOpenModal={handleOpenModal} />
                        </div>
                    </DashboardCard>
                </div>

                <DashboardCard 
                    title="Search & Filters" 
                    action={null} 
                    className="overflow-hidden mb-4"
                >
                    <SearchOptions
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        selectedTools={selectedTools}
                        setSelectedTools={setSelectedTools}
                        selectedLabels={selectedLabels}
                        setSelectedLabels={setSelectedLabels}
                        showSections={showSections}
                        setShowSections={setShowSections}
                        showPublishedRecipes={showPublishedRecipes}
                        setShowPublishedRecipes={setShowPublishedRecipes}
                        allToolsSelected={allToolsSelected}
                        setAllToolsSelected={setAllToolsSelected}
                        allLabelsSelected={allLabelsSelected}
                        setAllLabelsSelected={setAllLabelsSelected}
                        getUsedTools={getUsedTools}
                        getUniqueLabels={getUniqueLabels}
                        resetFilters={resetFilters}
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                    />
                </DashboardCard>

                {/* Sections */}
                <DashboardCard 
                    title={
                        <div className="flex items-center justify-between w-full">
                            <span>Prompt Collection</span>
                            {isLoadingTools && (
                                <span className="text-sm text-green-500 font-medium animate-pulse ml-2">Loading...</span>
                            )}
                        </div>
                    } 
                    action={null} 
                    className="overflow-hidden mb-4"
                >
                    <div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden">
                        {showSections && viewMode === 'grid' ? (
                            <ShowSections
                                prompts={getFilteredPrompts()}
                                tools={tools}
                                recipes={getFilteredRecipes()}
                                viewMode={viewMode}
                                copiedId={copiedId}
                                mergedPrompts={mergedPrompts}
                                searchQuery={searchQuery}
                                selectedTools={selectedTools}
                                selectedLabels={selectedLabels}
                                handleCopy={handleCopy}
                                handleLoadRecipe={handleLoadRecipe}
                                toggleRecipePublish={toggleRecipePublish}
                                togglePromptPublish={togglePromptPublish}
                                handleDeleteRecipe={handleDeleteRecipe}
                                filterPrompts={filterPrompts}
                                handleOpenModal={handleOpenModal}   
                                handleDeletePrompt={handleDeletePrompt} 
                                handleAddToMerged={handleAddToMerged}
                                handleEditPrompt={handleEditPrompt}
                            />
                        ) : viewMode === 'table' ? (
                            <ShowTableLayout
                                viewMode={viewMode}
                                copiedId={copiedId}
                                mergedPrompts={mergedPrompts}
                                tools={tools}
                                recipes={getFilteredRecipes()}
                                handleCopy={handleCopy}
                                handleAddToMerged={handleAddToMerged}
                                handleDeletePrompt={handleDeletePrompt}
                                handleEditPrompt={handleEditPrompt}
                                handleOpenModal={handleOpenModal}
                                handleLoadRecipe={handleLoadRecipe}
                                toggleRecipePublish={toggleRecipePublish}
                                togglePromptPublish={togglePromptPublish}
                                handleDeleteRecipe={handleDeleteRecipe}
                                getAllItems={getAllItems}
                                getFilteredRecipes={getFilteredRecipes}
                            />
                        ) : (
                            /* No Sections */
                            <ShowNoSections
                                viewMode={viewMode}
                                copiedId={copiedId}
                                mergedPrompts={mergedPrompts}
                                tools={tools}
                                recipes={getFilteredRecipes()}
                                handleCopy={handleCopy}
                                handleAddToMerged={handleAddToMerged}
                                handleDeletePrompt={handleDeletePrompt}
                                handleEditPrompt={handleEditPrompt}
                                handleOpenModal={handleOpenModal}
                                handleLoadRecipe={handleLoadRecipe}
                                toggleRecipePublish={toggleRecipePublish}
                                togglePromptPublish={togglePromptPublish}
                                handleDeleteRecipe={handleDeleteRecipe}
                                getAllItems={getAllItems}
                                getFilteredRecipes={getFilteredRecipes}
                            />
                        )}
                    </div>
                </DashboardCard>
            </div>
        
            <AddPromptModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSubmit={handleAddPrompt}
                initialSection={selectedSection}
                tools={tools}
                isLoadingTools={isLoadingTools}
            />

            {isDeleteModalOpen && (
                <DeletePromptModal 
                    isOpen={isDeleteModalOpen}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                        setPromptToDelete(null);
                    }}
                    onConfirm={confirmDelete}
                />
            )}
          
            {isRecipeModalOpen && (
                <RecipeFormModal
                    onSave={handleSaveRecipe}
                    onClose={() => setIsRecipeModalOpen(false)}
                />
            )}
            
            {isDeleteRecipeModalOpen && (
                <DeleteRecipeModal 
                    isOpen={isDeleteRecipeModalOpen}
                    onClose={() => {
                        setIsDeleteRecipeModalOpen(false);
                        setRecipeToDelete(null);
                    }}
                    onConfirm={confirmDeleteRecipe}
                />
            )}

            {promptToEdit && (
                <EditPromptModal
                    isOpen={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        setPromptToEdit(null);
                    }}
                    onSave={handleSaveEditedPrompt}
                    prompt={promptToEdit}
                    tools={tools}
                />
            )}
            {/* Chatbox New Prompt Button */}
            <ChatboxNewPrompt 
                onOpenModal={handleOpenModal}
            />
            
            {/* Chatbox Merged Prompts */}
            <ChatboxMergedPrompts 
                mergedPrompts={mergedPrompts}
                copiedId={copiedId}
                onDragEnd={onDragEnd}
                handleRemoveFromMerged={handleRemoveFromMerged}
                handleCopy={handleCopy}
                handleClearMerged={handleClearMerged}
                setIsRecipeModalOpen={setIsRecipeModalOpen}
                getMergedText={getMergedText}
            />
            
            {/* Template Values Modal */}
            <TemplateValuesModal
                isOpen={isTemplateModalOpen}
                onClose={() => setIsTemplateModalOpen(false)}
                onSubmit={handleTemplateSubmit}
                templateText={currentTemplateText}
            />
            </>
    );
}

export default PromptLibrary;