import { Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { PromptLibraryPrompt } from "./types/PromptLibraryPrompt";

interface MergedPromptsProps {
    mergedPrompts: PromptLibraryPrompt[];
    onDragEnd: (result: DropResult) => void;
    handleRemoveFromMerged: (id: string) => void;
    handleCopy: (text: string, id: string) => void;
    handleClearMerged: () => void;
    copiedId: string | null;
    setIsRecipeModalOpen: (isOpen: boolean) => void;
    getMergedText: () => string;
}

export const MergedPrompts: React.FC<MergedPromptsProps> = ({
    mergedPrompts,
    onDragEnd,
    handleRemoveFromMerged,
    handleCopy,
    handleClearMerged,
    copiedId,
    setIsRecipeModalOpen,
    getMergedText
}) => {
    // Add this function at the top of your component
    const reorderPrompts = (list: PromptLibraryPrompt[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    return(
        <>
        <div className="flex justify-end items-center mb-3 gap-2">
            <button
                onClick={() => handleCopy(getMergedText(), 'merged')}
                className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium
                    ${copiedId === 'merged'
                        ? 'bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400'
                        : 'bg-blue-50 text-blue-600 hover:bg-blue-100 dark:bg-blue-900/20 dark:text-blue-400 dark:hover:bg-blue-900/30'
                    } ${mergedPrompts.length < 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={mergedPrompts.length < 2}
                title={mergedPrompts.length < 2 ? 'Add at least 2 prompts' : 'Copy merged prompts'}
            >
                <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    {copiedId === 'merged' ? (
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    ) : (
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                    )}
                </svg>
                <span>Copy</span>
            </button>
            <button
                onClick={() => setIsRecipeModalOpen(true)}
                className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium
                    bg-purple-50 text-purple-600 hover:bg-purple-100 
                    dark:bg-purple-900/20 dark:text-purple-400 dark:hover:bg-purple-900/30
                    transition-colors duration-200 ${mergedPrompts.length < 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={mergedPrompts.length < 2}
                title={mergedPrompts.length < 2 ? 'Add at least 2 prompts' : 'Save as recipe'}
            >
                <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" 
                    />
                </svg>
                <span>Save</span>
            </button>
        </div>
        
        {mergedPrompts.length === 0 ? (
                <p className="text-gray-500 dark:text-gray-400 text-sm italic">
                    Add prompts to merge them together...
                </p>
            ) : (
                <Droppable droppableId="merged-prompts">
                    {(provided) => (
                        <div 
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="space-y-4"
                            key={mergedPrompts.map(p => p.id).join(',')} // Add this key
                        >
                            {mergedPrompts.map((prompt, index) => (
                                <Draggable 
                                    key={prompt.id}
                                    draggableId={String(prompt.id)}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`relative bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4 text-sm
                                                ${snapshot.isDragging ? 'shadow-lg ring-2 ring-blue-500' : ''}`}
                                        >
                                            {/* Add delete button */}
                                            <button
                                                onClick={() => handleRemoveFromMerged(prompt.id)}
                                                className="absolute right-2 top-2 p-1 text-gray-400 hover:text-red-500 
                                                    dark:text-gray-500 dark:hover:text-red-400 transition-colors"
                                                title="Remove from recipe"
                                            >
                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                            
                                            {/* Existing content */}
                                            <div className="absolute left-2 top-1/2 -translate-y-1/2">
                                                <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                                </svg>
                                            </div>
                                            <div className="font-medium text-gray-700 dark:text-gray-300 mb-2 pl-6">
                                                {prompt.section}
                                            </div>
                                            <p className="text-gray-600 dark:text-gray-400 text-xs pl-6">
                                                {prompt.prompt}
                                            </p>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            )}
        {/* Clear button at the bottom */}
        {mergedPrompts.length > 0 && (
            <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700">
                <button
                    onClick={handleClearMerged}
                    className="w-full flex items-center justify-center gap-2 px-3 py-2 rounded-lg text-xs font-medium
                        text-red-600 hover:bg-red-50 dark:text-red-400 dark:hover:bg-red-900/10
                    transition-colors duration-200"
                >
                    <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    Clear All Prompts
                </button>
            </div>
        )}
        </>
    );
}