import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import CertificateGenerator from "./certificate";
import axios from "../../libs/axios";
import {useNavigate} from "react-router-dom";
import RoleEnum from "../../components/enums/RoleEnum";
import Tickets from "../../components/dashboard/dashboard/Tickets";
import TicketStatusEnum from "../../components/enums/TicketStatusEnum";
import LoadingPlaceholderSection from "../../components/sections/LoadingPlaceholderSection";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import ForceAuthentication from '../../components/auth/ForceAuthentication';
import { NavLink } from 'react-router-dom';
import { Users, CheckCircle } from 'lucide-react';

const QuickActionButton = ({ href, children }) => (
    <NavLink
        to={href}
        className="group flex items-center justify-between px-6 py-4 rounded-lg bg-gradient-to-r from-[#468ef9] to-[#0c66ee]
            hover:from-[#3d7ed9] hover:to-[#0a56c7] transition-all duration-300 transform hover:-translate-y-0.5"
    >
        <span className="text-white font-semibold">{children}</span>
        <svg className="w-5 h-5 text-white transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
        </svg>
    </NavLink>
);

const TicketButton = ({ onClick, children, variant = "primary" }) => (
    <button
        onClick={onClick}
        className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg
            transition-all duration-200 hover:-translate-y-0.5 shadow-sm hover:shadow
            ${variant === "primary"
                ? "bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3d7ed9] hover:to-[#0a56c7] text-white"
                : "bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 text-white"
            }`}
    >
        {variant === "primary" ? (
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
        ) : (
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
        )}
        {children}
    </button>
);


const DashboardContent = ({ user }) => {
    const [canStartQuiz, setCanStartQuiz] = useState(false);
    const [certificateOne, setCertificateOne] = useState(false);
    const [hasAccessRequested, setHasAccessRequested] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [newTicketOpen, setNewTicketOpen] = useState(false);
    const [showClosedTickets, setShowClosedTickets] = useState(false);
    const [isLoadingTest, setIsLoadingTest] = useState(true);
    const [notifications, setNotifications] = useState([]);

    const { csrf } = useAuth();

    let navigate = useNavigate();
    const apiCallMadeRef = useRef(false);


    // Wrapper for API calls to handle rate limits
    const safeApiCall = async (apiCall, errorValue = null) => {
        try {
            const result = await apiCall();
            return result;
        } catch (error) {
            if (error.response?.status === 401) {
                navigate('/login');
            }
            return errorValue;
        }
    };

    // Main data fetching effect
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Dashboard";

        const fetchData = async () => {
            if (user) {
                apiCallMadeRef.current = true;

                setNotifications([]);

                if (!user.email_verified) {
                    setNotifications([...notifications, {
                        title: "Verify Your Email",
                        description: "Please verify your email address.",
                        link: "/dashboard/profile"
                    }]);
                }

                try {
                    await csrf();

                    // Fetch data in parallel with rate limit handling
                    const [tickets, canStartQuizResponse, hasCertificateOneResponse] = await Promise.all([
                        safeApiCall(getUserTickets, []),
                        safeApiCall(getCanStartQuiz, false),
                        safeApiCall(getHasCertificateOne, false)
                    ]);

                    setTickets(tickets);
                    if (tickets?.length > 0) {
                        const accessRequestedTicket = tickets.filter(
                            ticket => ticket.category === "REQUEST_TEST_ACCESS" &&
                            ticket.status !== TicketStatusEnum.Closed
                        );
                        setHasAccessRequested(accessRequestedTicket.length > 0);
                    }
                    setCanStartQuiz(canStartQuizResponse);
                    setCertificateOne(hasCertificateOneResponse);
                    setIsLoadingTest(false);

                } catch (error) {
                    if (error.response?.status === 401) {
                        navigate('/login');
                    }
                }
            }
        };

        fetchData();
    }, [user]);

    const getCanStartQuiz = async () => {
        const response = await axios.get("/quiz/user-can-start");
        return response.data.userCanStartQuiz;
    }

    const getHasCertificateOne = async () => {
        const response = await axios.post("/quiz/verify-certificate");
        return response.data;
    }

    const requestTestAccess = async () => {
        try {
            await csrf();
            const response = await axios.post(`/quiz/request-access`, { user_id: user.id });
            if (response.data.category === "REQUEST_TEST_ACCESS") {
                setHasAccessRequested(true);

                setTickets(prevTickets => [...prevTickets, response.data]);
            }
            return response.data;
        }catch(error) {
            return 0;
        }
    }

    const getUserTickets = async () => {
        const response = await axios.get(`/ticket/get`);
        return response.data;
    }

    const NewTicketForm = () => {
        const [title, setTitle] = useState('');
        const [description, setDescription] = useState('');
        const [isSubmitting, setIsSubmitting] = useState(false);

        const onSubmit = async (event) => {
            event.preventDefault();
            setIsSubmitting(true);

            try {
                await csrf();
                const response = await axios.post('/ticket/new', { title, description })
                    .then(response => {
                        setTickets(prevTickets => [...prevTickets, response.data]);
                        setIsSubmitting(false);
                        setTitle('');
                        setDescription('');
                        setNewTicketOpen(false);
                    })
                    .catch(error => {});
            } catch (error) {
                // Handle error
            }
        };

        return(
            <div>
                <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-[#468ef9]/5 to-[#0c66ee]/5 rounded-3xl blur-xl"></div>
                    <div className="relative bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-2xl p-6 ring-1 ring-gray-900/5 dark:ring-white/10">
                        <p className="text-sm text-gray-600 dark:text-gray-400 mb-6 flex items-center">
                            <svg className="w-4 h-4 mr-2 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Please note: you can only open 2 tickets
                        </p>
                        <form onSubmit={onSubmit} className="space-y-6">
                            <div className="space-y-2">
                                <label htmlFor="title" className="block text-sm font-medium bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#468ef9] dark:to-[#0c66ee] bg-clip-text text-transparent">
                                    Title
                                </label>
                                <div className="relative group fancy-input-glow">
                                    <div className="absolute -inset-0.5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] rounded-xl blur opacity-0 group-hover:opacity-20 transition duration-500"></div>
                                    <input
                                        id="title"
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder="Enter ticket title"
                                        className="relative block w-full px-4 py-3 rounded-xl bg-white dark:bg-gray-800
                                            text-gray-900 dark:text-white border border-gray-200 dark:border-gray-600
                                            transition-all duration-300
                                            focus:ring-2 focus:ring-[#468ef9]/20 focus:border-transparent dark:focus:border-transparent
                                            dark:focus:ring-[#468ef9]/30
                                            hover:border-transparent dark:hover:border-transparent
                                            placeholder-gray-400 dark:placeholder-gray-500 fancy-hover"
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="description" className="block text-sm font-medium bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#468ef9] dark:to-[#0c66ee] bg-clip-text text-transparent">
                                    Description
                                </label>
                                <div className="relative group fancy-input-glow">
                                    <div className="absolute -inset-0.5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] rounded-xl blur opacity-0 group-hover:opacity-20 transition duration-500"></div>
                                    <textarea
                                        id="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Describe your issue in detail"
                                        rows="4"
                                        className="relative block w-full px-4 py-3 rounded-xl bg-white dark:bg-gray-800
                                            text-gray-900 dark:text-white border border-gray-200 dark:border-gray-600
                                            transition-all duration-300
                                            focus:ring-2 focus:ring-[#468ef9]/20 focus:border-transparent dark:focus:border-transparent
                                            dark:focus:ring-[#468ef9]/30
                                            hover:border-transparent dark:hover:border-transparent
                                            placeholder-gray-400 dark:placeholder-gray-500
                                            resize-none fancy-hover"
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                disabled={!title || !description}
                                className={`relative w-full group overflow-hidden px-6 py-3 rounded-xl font-medium text-sm
                                    transition-all duration-300 transform hover:-translate-y-0.5
                                    ${(!title || !description)
                                        ? 'bg-gray-200 dark:bg-gray-700 text-gray-400 dark:text-gray-500 cursor-not-allowed'
                                        : 'bg-gradient-to-r from-[#468ef9] to-[#0c66ee] text-white'
                                    }`}>
                                <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] opacity-0 group-hover:opacity-50 animate-shine"></span>
                                <span className="relative flex items-center justify-center">
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Submit Ticket
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    const CurrentTicketSection = () => {
        return(
            <div>
                {showClosedTickets ?
                    <>
                        <p className="dark:text-white">Below you can find your last closed tickets.</p>
                        <Tickets tickets={tickets}
                                 showOnlyStatus={TicketStatusEnum.Closed}
                             showMax={5} />
                    </> :
                    <>
                        <p className="dark:text-white">Below you can find your last open tickets.</p>

                        {user?.role === RoleEnum.Registered ?
                            <Tickets tickets={tickets}
                                     filterStatus={TicketStatusEnum.Closed}
                                     filterCategory={"REQUEST_TEST_ACCESS"}
                                     showMax={5}/>
                            :
                            <Tickets tickets={tickets}
                                     filterStatus={TicketStatusEnum.Closed}
                            showMax={5} />
                        }
                    </>}
            </div>
        );
    }

    const HideShowTickets = () => {
        const closedTicketsCount = tickets.filter(ticket => ticket.status === TicketStatusEnum.Closed).length;

        return (
            <button
                onClick={() => setShowClosedTickets(!showClosedTickets)}
                className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg
                    transition-all duration-200 hover:-translate-y-0.5 shadow-sm hover:shadow
                    ${showClosedTickets
                        ? "bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3d7ed9] hover:to-[#0a56c7] text-white"
                        : "bg-gray-100 hover:bg-gray-200 text-gray-700"
                    }`}
            >
                {showClosedTickets ? (
                    <>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                        Open tickets
                    </>
                ) : (
                    <>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                        Closed tickets
                        {closedTicketsCount > 0 && (
                            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none
                                bg-gray-200 text-gray-700 rounded-full"
                            >
                                {closedTicketsCount}
                            </span>
                        )}
                    </>
                )}
            </button>
        );
    };

    const CancelCreateTicket = () => {
        // Check if last ticket is closed
        const canCreateTicket = tickets && tickets.filter(ticket => ticket.status !== TicketStatusEnum.Closed).length < 2;

        if (!canCreateTicket) return null;

        return (
            <TicketButton
                onClick={() => setNewTicketOpen(!newTicketOpen)}
                variant={newTicketOpen ? "secondary" : "primary"}
            >
                {newTicketOpen ? "Cancel ticket" : "New ticket"}
            </TicketButton>
        );
    };

    const ShowCertificateOrTest = () => {
        if (certificateOne.certificate === "valid") {
            return <CertificateGenerator name={certificateOne?.user} certificateId={certificateOne?.id}
                                         dateCompleted={certificateOne?.date}/>
        }

        if (certificateOne.certificate === "in-progress" || certificateOne.certificate === "failed") {
            return (
                <div className="space-y-6">
                    {certificateOne.certificate === "in-progress" ? (
                        <div className={`space-y-4 bg-blue-50 dark:bg-gray-800 border border-blue-100 dark:border-gray-600 rounded-lg p-6`}>
                            <div className="flex items-start gap-3">
                                <div className={`p-2 bg-blue-100 dark:bg-gray-700 rounded-full`}>
                                    <svg className="w-5 h-5 text-blue-600 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <div className="space-y-2">
                                    <p className="font-medium text-blue-900 dark:text-white">
                                        You currently have a <span className="font-bold">test in progress</span>!
                                    </p>
                                    <p className="text-blue-700 dark:text-gray-300">
                                        Make sure to keep the test window open and do not close it.
                                    </p>
                                    <p className="text-sm text-blue-600 dark:text-gray-400">
                                        If you did close it, please reach out to support or wait until the time runs out and come back for your result.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : certificateOne.certificate === "failed" && (
                        <div className={`space-y-4 bg-red-50 dark:bg-gray-800 border border-red-100 dark:border-gray-600 rounded-lg p-6`}>
                            <div className="flex items-start gap-3">
                                <div className={`p-2 bg-red-100 dark:bg-gray-700 rounded-full`}>
                                    <svg className="w-5 h-5 text-red-600 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <div className="space-y-3">
                                    <div className="space-y-1">
                                        <p className="font-medium text-red-900 dark:text-white">
                                            Test attempt failed
                                        </p>
                                        <p className="text-red-700 dark:text-gray-300">
                                            You attempted the CAIP - 1 (IPE) test on{' '}
                                            <span className="font-semibold">{certificateOne?.date}</span> and received a score of{' '}
                                            <span className="font-bold">{certificateOne?.score}%</span>.
                                        </p>
                                    </div>
                                    <div className={`bg-white/50 dark:bg-gray-800 rounded-lg p-4 border border-red-100 dark:border-gray-600`}>
                                        <p className="text-red-600 dark:text-gray-400">
                                            Next attempt available on{' '}
                                            <span className="font-semibold">{certificateOne?.retake_date}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    }

    const NotificationCard = ({title, description, done, link}) => {
        return(
            <div
                onClick={() => navigate(link)}
                className="group relative px-4 py-3 border-b last:border-b-0 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200 cursor-pointer"
            >
                <div className="flex items-center gap-3">
                    <div className="relative">
                        {done ? (
                            <div className="w-10 h-10 rounded-full bg-gradient-to-r from-green-100 to-green-50 dark:from-green-900/20 dark:to-green-900/10 flex items-center justify-center">
                                <CheckCircle className="w-5 h-5 text-green-600 dark:text-green-400 transition-all duration-300 group-hover:text-green-700 dark:group-hover:text-green-300" />
                            </div>
                        ) : (
                            <div className="w-10 h-10 rounded-full bg-gradient-to-r from-blue-100 to-blue-50 dark:from-blue-900/20 dark:to-blue-900/10 flex items-center justify-center">
                                <CheckCircle className="w-5 h-5 text-blue-600 dark:text-blue-400 transition-all duration-300 group-hover:text-blue-700 dark:group-hover:text-blue-300" />
                            </div>
                        )}
                    </div>
                    <div className="flex-1 min-w-0">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col">
                                <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium text-gray-900 dark:text-white truncate group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-colors duration-200">
                                        {title}
                                    </span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="text-xs text-gray-500 pt-1 dark:text-gray-400 truncate group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors duration-200">
                                        {description}
                                    </span>
                                    <span className="text-xs text-gray-400 dark:text-gray-500 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ml-1">
                                        Go to... →
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                {done ? (
                                    <>
                                        <span className="w-1.5 h-1.5 rounded-full bg-green-300 dark:bg-green-600 transition-colors duration-300 group-hover:bg-green-400 dark:group-hover:bg-green-500"></span>
                                        <span className="text-xs text-green-500 dark:text-green-400 group-hover:text-green-600 dark:group-hover:text-green-300 transition-colors duration-200">
                                            DONE
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="w-1.5 h-1.5 rounded-full bg-blue-300 dark:bg-blue-600 transition-colors duration-300 group-hover:bg-blue-400 dark:group-hover:bg-blue-500"></span>
                                        <span className="text-xs text-blue-500 dark:text-blue-400 group-hover:text-blue-600 dark:group-hover:text-blue-300 transition-colors duration-200">
                                            TODO
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-900 dark:text-white leading-tight">
                    Dashboard
                </h2>
            }
        >
            <div className="bg-gray-50/50 dark:bg-gray-900 py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/* Welcome Section */}
                    <div className="grid gap-4 mb-4 md:grid-cols-3">
                        <DashboardCard className="md:col-span-2 overflow-hidden">
                            <div className="flex items-center gap-6 rounded-xl">
                                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#0c66ee] dark:to-[#468ef9] flex items-center justify-center">
                                    <span className="text-2xl font-bold text-white dark:text-black">
                                        {user?.name?.[0]?.toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                                        Welcome back, <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:bg-gradient-to-r dark:from-[#0c66ee] dark:to-[#468ef9]">{user?.name}</span>
                                    </h1>
                                    <p className="text-gray-600 mt-1 dark:text-gray-300">Track your progress in the Crafting AI Prompts Framework</p>
                                </div>
                            </div>
                        </DashboardCard>

                        <div className="flex flex-col gap-3">
                            <QuickActionButton href="/documentation/framework">
                                Documentation
                            </QuickActionButton>
                            <QuickActionButton href="/tools">
                                Tools
                            </QuickActionButton>
                            <QuickActionButton href="/dashboard/prompt-library">
                                <span className="flex items-center gap-1">
                                    Prompt Library
                                    <span className="ml-4 text-xs font-semibold bg-green-100 text-green-800 px-1.5 py-0.5 rounded-full">
                                        New
                                    </span>
                                </span>
                            </QuickActionButton>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="grid gap-4 md:grid-cols-2">
                        {/* Left Column */}
                        <div className="space-y-6">
                            {isLoadingTest ? (
                                <LoadingPlaceholderSection />
                            ) : user?.role < RoleEnum.DefaultUser ? (
                                <DashboardCard
                                    title={
                                        <span className="flex items-center gap-2">
                                            <span>Certification Test & Account Upgrade</span>
                                        </span>
                                    }
                                >
                                    {hasAccessRequested !== undefined ? (
                                        hasAccessRequested ? (
                                            <div className="space-y-4">
                                                <div className="p-4 bg-blue-50 border border-blue-100 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                                                    <div className="flex items-start gap-3">
                                                        <div className="p-2 bg-blue-100 rounded-full shrink-0 dark:bg-gray-700">
                                                            <svg className="w-5 h-5 text-blue-600 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                        </div>
                                                        <div className="space-y-3">
                                                            <h3 className="text-lg font-semibold text-blue-900 dark:text-white pt-1 pl-2">
                                                                Your test access and account upgrade request is being reviewed
                                                            </h3>
                                                            <p className="text-blue-700 dark:text-gray-300 text-sm gap-2 pl-2 pr-2">
                                                                Please check back in 1-3 days. We try to accept this request within 24 hours, you'll receive an email confirmation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Tickets
                                                    tickets={tickets}
                                                    category="REQUEST_TEST_ACCESS"
                                                    filterStatus={TicketStatusEnum.Closed}
                                                />
                                            </div>
                                        ) : (
                                            <div className="space-y-4">
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Ready to test your knowledge or want to upgrade your account so you can rate and add tools? Validate your account, to gain access.
                                                </p>
                                                <button
                                                    onClick={() => navigate('/register/verify/send')}
                                                    className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-[#468ef9] to-[#0c66ee]
                                                        text-white font-medium rounded-lg transition-all duration-300 hover:-translate-y-0.5 dark:from-[#0c66ee] dark:to-[#468ef9] dark:text-black"
                                                >
                                                    Validate account
                                                </button>

                                                <p className="text-gray-600 dark:text-gray-300">Do you've problems validating your account? Please create a support ticket. You can find the support tickets on the right side of this block.</p>
                                                {/* <button
                                                    onClick={() => requestTestAccess()}
                                                    className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-[#468ef9] to-[#0c66ee]
                                                        text-white font-medium rounded-lg transition-all duration-300 hover:-translate-y-0.5 dark:from-[#0c66ee] dark:to-[#468ef9] dark:text-black"
                                                >
                                                    Request Access
                                                </button> */}
                                            </div>
                                        )
                                    ) : (
                                        <LoadingPlaceholderSection />
                                    )}
                                </DashboardCard>
                            ) : canStartQuiz ? (
                                <DashboardCard
                                    title={
                                        <span className="flex items-center gap-2">
                                            <span>Start Your Certification Test</span>
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400">
                                                Ready
                                            </span>
                                        </span>
                                    }
                                >
                                    <div className="space-y-4">
                                        <p className="text-gray-600 dark:text-gray-300">
                                            You have been granted access to take the certification test. Click below to begin.
                                        </p>
                                        <button
                                            onClick={() => navigate('/dashboard/quiz')}
                                            className="group flex items-center justify-between px-6 py-4 rounded-lg bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#0c66ee] dark:to-[#468ef9]
                                                hover:from-[#3d7ed9] hover:to-[#0a56c7] transition-all duration-300 transform hover:-translate-y-0.5 dark:text-black"
                                        >
                                            <span className="text-white font-semibold dark:text-white">Start Test</span>
                                            <svg className="w-5 h-5 text-white dark:text-white transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                            </svg>
                                        </button>
                                    </div>
                                </DashboardCard>
                            ) : null}

                            {user?.role >= RoleEnum.DefaultUser && certificateOne.certificate && certificateOne.certificate !== "none" && (
                                <DashboardCard
                                    title={
                                        <span className="flex items-center gap-2">
                                            <span>Your Certificate</span>
                                            {certificateOne.certificate === "valid" ? (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400">
                                                    Valid
                                                </span>
                                            ) : certificateOne.certificate === "failed" ? (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400">
                                                    Failed
                                                </span>
                                            ) : certificateOne.certificate === "in-progress" && (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-400">
                                                    In Progress
                                                </span>
                                            )}
                                        </span>
                                    }
                                >
                                    <ShowCertificateOrTest />
                                </DashboardCard>
                            )}
                        </div>

                        <div className="grid gap-4">
                        {notifications.length > 0 && (
                            <DashboardCard className="">
                                <h2 id="notifications" className="text-xl dark:text-white flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <span className="font-semibold">Notifications</span>
                                    </div>
                                </h2>
                                <div className="max-h-[17rem] overflow-y-auto hover:max-h-[calc(10*2.875rem)] transition-[max-height] duration-500 ease-in-out scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-track-transparent hover:scrollbar-thumb-gray-300 dark:hover:scrollbar-thumb-gray-600 overflow-hidden">
                                    {notifications.map((notification, index) => (
                                        <NotificationCard key={index} title={notification?.title} description={notification?.description} done={0} link={notification?.link} />
                                    ))}
                                </div>
                            </DashboardCard>
                        )}

                            {/* Right Column - Tickets */}
                            <DashboardCard
                                title="Support Tickets"
                                action={
                                    <div className="flex items-center gap-2">
                                    <HideShowTickets />
                                    <CancelCreateTicket />
                                    </div>
                                }
                            >
                                <div className="space-y-4">
                                    {newTicketOpen ? (
                                    <NewTicketForm />
                                    ) : (
                                    <CurrentTicketSection />
                                    )}
                                </div>
                            </DashboardCard>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

const Dashboard = () => {
    return (
        <ForceAuthentication>
            {({ user }) => <DashboardContent user={user} />}
        </ForceAuthentication>
    );
};

export default Dashboard;
