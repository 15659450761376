import { useEffect, useState } from "react";
import { CategoryBadge } from "../CategoryBadge/CategoryBadge";
import { Tool } from "../PromptLibrary/types/Tool";
import { StarRating } from "./StarRating";
import axios from "../../libs/axios";
import { ToolSpecifications } from "./interfaces/ToolSpecifications";
import Pagination from '../dashboard/dashboard/Pagination';
import React from "react";
import { GlobeIcon, GraduationCap, HandCoins, ShareIcon, ShieldCheckIcon } from "lucide-react";
import ReactCountryFlag from "react-country-flag";
import { useParams } from "react-router-dom";
import { Tooltip } from "../../pages/Tools";

interface Review {
    id: string;
    rating: number;
    user: {
        isCurrentUser?: boolean;
        isAdmin?: boolean;
        isTrainer?: boolean;
        name: string;
    }
    votes?: {
        up?: number;
        down?: number;
        userUp?: boolean;
        userDown?: boolean;
    }
    comment: string;
    created_at: string;
}

export const DetailedView = ({ 
    tool, 
    onClose,
    onRatingSubmit,
    onDelete,
    user,
    ratingViewMode,
    profileUser
}: { 
    tool: Tool, 
    onClose: () => void,
    onRatingSubmit: () => Promise<void>,
    onDelete?: (toolId: string) => Promise<void>,
    user: any,
    ratingViewMode?: string,
    profileUser?: any
}) => {
    // Initialize userRatings with tool's existing userRatings if they exist
    const [userRatings, setUserRatings] = useState<{[category: string]: number}>(tool.userRatings || {});
    const [initialRatings, setInitialRatings] = useState<{[category: string]: number}>({});
    const [isRatingMode, setIsRatingMode] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [toolTip, setToolTip] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDeleteReviewConfirm, setShowDeleteReviewConfirm] = useState(false);

    // State for collapsible sections
    const [isSpecificationsOpen, setIsSpecificationsOpen] = useState(false);
    const [isCategoriesRatingsOpen, setIsCategoriesRatingsOpen] = useState(true);
    const [isReviewsOpen, setIsReviewsOpen] = useState(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const [userReviewRating, setUserReviewRating] = useState(5);
    const [userReviewComment, setUserReviewComment] = useState('');
    const [reviews, setReviews] = useState<Review[]>([]);
    const [isEditingReview, setIsEditingReview] = useState(false);
    const [userReview, setUserReview] = useState<Review | null>(null);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { userName } = useParams<{ userName?: string }>();

    // State to hold specifications
    const [toolSpecifications, setToolSpecifications] = useState<ToolSpecifications[]>([]); 

    // Fetch initial ratings
    useEffect(() => {        
        const fetchUserRatings = async () => {
            // Only fetch ratings if user exists and has sufficient role
            if (user?.role >= 2) {
                try {
                    const response = await axios.get(`/tools/${tool.id}/user-ratings`);
                    setUserRatings(response.data);
                    setInitialRatings(response.data); // Store initial state
                } catch (error: any) {
                    //console.error('Error fetching user ratings:', error);
                }
            }
        };

        fetchUserRatings();
    }, [tool.id, user]); // Add user as dependency

    // Handle submit all ratings
    const handleSubmitRatings = async () => {
        // Get only the changed ratings and filter out zeros
        const changedRatings = Object.entries(userRatings).reduce((acc, [category, rating]) => {
            // Include rating only if:
            // 1. It's different from initial rating
            // 2. It's not zero (unrated)
            // 3. Either there was no initial rating or the initial rating was different
            if (rating !== 0 && (!initialRatings[category] || initialRatings[category] !== rating)) {
                acc[category] = rating;
            }
            return acc;
        }, {} as {[category: string]: number});

        // Only submit if there are changes
        if (Object.keys(changedRatings).length === 0) {
            setIsRatingMode(false);
            return;
        }

        setIsSaving(true);
        try {
            await axios.post(`/tools/${tool.id}/rate`, {
                ratings: changedRatings
            });
            await onRatingSubmit(); // Wait for the tools to refresh
            setIsRatingMode(false);
        } catch (error: any) {
        } finally {
            setIsSaving(false);
        }
    };

    // Add handleRatingUpdate function
    const handleRatingUpdate = (category: string, rating: number) => {
        setUserRatings(prev => ({
            ...prev,
            [category]: rating
        }));
    };

    // Add interactive star rating component
    const InteractiveStarRating = ({ category, rating }: { category: string, rating: number }) => {
        const [hover, setHover] = useState<number | null>(null);
        
        return (
            <div className="flex items-center gap-1">
                {[1, 2, 3, 4, 5].map((star) => (
                    <button
                        key={star}
                        onClick={() => handleRatingUpdate(category, star)}
                        onMouseEnter={() => setHover(star)}
                        onMouseLeave={() => setHover(null)}
                        disabled={isSaving}
                        className={`w-6 h-6 ${isSaving ? 'cursor-wait' : 'cursor-pointer'}`}
                    >
                        <svg
                            className={`w-full h-full transition-colors ${
                                (hover !== null ? star <= hover : star <= (userRatings[category] || 0))
                                    ? 'text-yellow-400'
                                    : 'text-gray-300 dark:text-gray-600'
                            }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path 
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            />
                        </svg>
                    </button>
                ))}
            </div>
        );
    };

    // Collapsible section component
    const CollapsibleSection = ({ 
        title, 
        isOpen, 
        toggleOpen, 
        children,
        icon
    }: { 
        title: string, 
        isOpen: boolean, 
        toggleOpen: () => void, 
        children: React.ReactNode,
        icon?: React.ReactNode
    }) => (
        <div className="border border-gray-200 dark:border-gray-700 rounded-xl shadow-sm overflow-hidden mb-4 transition-all duration-300 ease-in-out">
            <div 
                className={`
                    flex items-center justify-between px-4 cursor-pointer 
                    ${isOpen 
                        ? 'bg-gray-50 dark:bg-blue-900/20' 
                        : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50'}
                    transition-colors duration-200
                `}
                onClick={toggleOpen}
            >
                <div className="flex items-center space-x-2 min-w-0">
                    {icon && (
                        <span className="flex-shrink-0 text-gray-600 dark:text-gray-300 self-center">
                            {icon}
                        </span>
                    )}
                    <h3 className="text-sm font-semibold mt-5 pl-2 text-gray-800 dark:text-gray-200 truncate self-center">
                        {title}
                    </h3>
                </div>
                <svg 
                    className={`
                        w-5 h-5 text-gray-500 dark:text-gray-400 flex-shrink-0
                        transform transition-transform duration-300 
                        ${isOpen ? 'rotate-180' : ''}
                    `} 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </div>
            {isOpen && (
                <div className="p-4 bg-white dark:bg-gray-800 border-t border-gray-100 dark:border-gray-700 animate-fade-in">
                    {children}
                </div>
            )}
        </div>
    );

    // Custom SVG icons instead of react-icons
    const SpecificationsIcon = (
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
        </svg>
    );
    
    const RatingsIcon = (
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
    );
    
    const ReviewsIcon = (
        <svg className="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"/>
        </svg>
    );

    // Add click handler for the overlay
    const handleOverlayClick = (e: React.MouseEvent) => {
        // Only close if clicking the overlay itself, not its children
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    // Update function to check user role using the user object
    const canUserRate = () => {
        return user && user?.role >= 1 && (tool.canEdit === undefined || tool.canEdit === true);
    };

    // Add delete handler
    const handleDelete = async () => {
        if (!onDelete) return;
        setIsDeleting(true);
        try {
            await onDelete(tool.id);
            onClose();
        } catch (error: any) {
            console.error('Error deleting tool:', error);
        } finally {
            setIsDeleting(false);
        }
    };
    
    useEffect(() => {
        const specifications = getToolSpecifications(); // Call once on mount
        setToolSpecifications(specifications);
    }, []); // Empty dependency array ensures it runs once

    const getToolSpecifications = () => {
        // Return empty array if no specifications are needed
        if (!tool.specifications) {
            return [];
        }else {
            return []; // TODO
        }
    };

    // Add useEffect to handle body scroll lock
    useEffect(() => {
        // Disable scrolling on body when modal is open
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when modal is closed
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    useEffect(() => {
        loadReviews();
    }, [tool.id]); // Only load reviews once when component mounts

    // Function to load reviews
    const loadReviews = async () => {
        try {
            const response = user ? await axios.get(`/public/tools/${tool.id}/reviews`) : await axios.get(`/public/tools/${tool.id}/reviews/${userName}`);
            setReviews(response.data.reviews);
            // Find user's review if it exists
            const userReview = response.data.reviews.find((review: Review) => review.user.isCurrentUser);
            if (userReview) {
                setUserReview(userReview);
            }
        } catch (error: any) {
            // ERROR
        }
    };

    // Function to handle review submission
    const handleReviewSubmit = async () => {
        try {
            setIsSaving(true);
            if (isEditingReview && userReview) {
                // Update existing review
                await axios.put(`/tools/${tool.id}/reviews/${userReview.id}`, {
                    rating: userReviewRating,
                    comment: userReviewComment,
                });
            } else {
                // Create new review
                await axios.post(`/tools/${tool.id}/reviews`, {
                    rating: userReviewRating,
                    comment: userReviewComment,
                });
            }
            await loadReviews();
            setIsReviewModalOpen(false);
            setIsEditingReview(false);
            setUserReviewRating(5);
            setUserReviewComment('');
        } catch (error: any) {
            console.error('Error submitting review:', error);
        } finally {
            setIsSaving(false);
        }
    };

    // Function to handle review deletion
    const handleDeleteReview = async () => {
        if (!userReview) return;
        
        try {
            setIsDeleting(true);
            await axios.delete(`/tools/${tool.id}/reviews/${userReview.id}`);
            await loadReviews();
            setShowDeleteReviewConfirm(false);
            setUserReview(null);
        } catch (error: any) {
            console.error('Error deleting review:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    // Function to handle adding/editing review
    const handleAddReview = () => {
        if (userReview) {
            // If editing existing review, pre-fill the form
            setUserReviewRating(userReview.rating);
            setUserReviewComment(userReview.comment);
            setIsEditingReview(true);
        } else {
            // If adding new review, reset the form
            setUserReviewRating(5);
            setUserReviewComment('');
            setIsEditingReview(false);
        }
        setIsReviewModalOpen(true);
    };

    // Function to handle section toggles
    const handleSectionToggle = (section: 'specifications' | 'ratings' | 'reviews') => {
        switch (section) {
            case 'specifications':
                setIsSpecificationsOpen(!isSpecificationsOpen);
                setIsCategoriesRatingsOpen(false);
                setIsReviewsOpen(false);
                break;
            case 'ratings':
                setIsSpecificationsOpen(false);
                setIsCategoriesRatingsOpen(!isCategoriesRatingsOpen);
                setIsReviewsOpen(false);
                break;
            case 'reviews':
                setIsSpecificationsOpen(false);
                setIsCategoriesRatingsOpen(false);
                setIsReviewsOpen(!isReviewsOpen);
                break;
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 5;

    // Calculate pagination values
    const indexOfLastReview = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstReview = indexOfLastReview - ITEMS_PER_PAGE;
    const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
    const totalPages = Math.ceil(reviews.length / ITEMS_PER_PAGE);

    // Function to handle page change
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

   const reviewUpDownVote = async (reviewId: string, voteType: 'up' | 'down') => {
       const reviewIndex = reviews.findIndex(r => r.id === reviewId);
       if (reviewIndex === -1) return;
   
       const newReview = { ...reviews[reviewIndex] };
       const newVotes = { ...newReview.votes };
   
       // Prepare the vote type for the API
       const voteTypeApi = voteType === 'up' ? 'like' : 'dislike';
   
       try {
           // Check if the user has already voted
           const hasUserVoted = newReview.votes?.userUp || newReview.votes?.userDown;
   
           if (hasUserVoted) {
               // If the user is changing their vote or removing it
               if (voteType === 'up') {
                   if (newReview.votes?.userUp) {
                       // Remove upvote
                       await axios.delete(`/tools/${tool.id}/reviews/${reviewId}/vote`, {
                           data: { user_id: user.id },
                       });
                   } else {
                       // Change from downvote to upvote
                       await axios.post(`/tools/${tool.id}/reviews/${reviewId}/vote`, {
                           user_id: user.id,
                           vote_type: voteTypeApi,
                       });
                   }
               } else {
                   if (newReview.votes?.userDown) {
                       // Remove downvote
                       await axios.delete(`/tools/${tool.id}/reviews/${reviewId}/vote`, {
                           data: { user_id: user.id },
                       });
                   } else {
                       // Change from upvote to downvote
                       await axios.post(`/tools/${tool.id}/reviews/${reviewId}/vote`, {
                           user_id: user.id,
                           vote_type: voteTypeApi,
                       });
                   }
               }
           } else {
               // First time voting
               await axios.post(`/tools/${tool.id}/reviews/${reviewId}/vote`, {
                   user_id: user.id,
                   vote_type: voteTypeApi,
               });
           }
   
           // Update local state based on the vote
           if (voteType === 'up') {
               if (newReview.votes?.userUp) {
                   newVotes.up = newVotes.up === undefined ? 0 : newVotes.up - 1; // Remove upvote
                   newReview.votes = { ...newVotes, userUp: undefined, userDown: newReview.votes.userDown }; // Reset userUp
               } else if (newReview.votes?.userDown) {
                   newVotes.down = newVotes.down === undefined ? 0 : newVotes.down - 1; // Remove downvote
                   newVotes.up = newVotes.up === undefined ? 1 : newVotes.up + 1; // Add upvote
                   newReview.votes = { ...newVotes, userUp: true, userDown: false };
               } else {
                   newVotes.up = newVotes.up === undefined ? 1 : newVotes.up + 1; // First time upvoting
                   newReview.votes = { ...newVotes, userUp: true, userDown: false };
               }
           } else {
               if (newReview.votes?.userDown) {
                   newVotes.down = newVotes.down === undefined ? 0 : newVotes.down - 1; // Remove downvote
                   newReview.votes = { ...newVotes, userDown: undefined, userUp: newReview.votes.userUp }; // Reset userDown
               } else if (newReview.votes?.userUp) {
                   newVotes.up = newVotes.up === undefined ? 0 : newVotes.up - 1; // Remove upvote
                   newVotes.down = newVotes.down === undefined ? 1 : newVotes.down + 1; // Add downvote
                   newReview.votes = { ...newVotes, userUp: false, userDown: true };
               } else {
                   newVotes.down = newVotes.down === undefined ? 1 : newVotes.down + 1; // First time downvoting
                   newReview.votes = { ...newVotes, userUp: false, userDown: true };
               }
           }
   
           const newReviews = [...reviews];
           newReviews[reviewIndex] = newReview;
           setReviews(newReviews);
       } catch (error: any) {
            if (error.response) {
                if (error.response.status === 429) {
                    setErrorMessage("Too many attempts. Please wait a moment before voting again.");
                } else if (error.response.status === 403) {
                    setErrorMessage("You don't have permission to vote on this review.");
                } else {
                    setErrorMessage("An error occurred. Please try again.");
                }
            } else {
                setErrorMessage("An error occurred. Please try again.");
            }
        }
   };

    return (
        <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
            onClick={handleOverlayClick}
        >
            <div className="bg-white dark:bg-gray-800 rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto p-6 space-y-6">
                {/* Back button */}
                <div className="flex items-center justify-between">
                    <button
                        onClick={onClose}
                        className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                        Back to Tools
                    </button>

                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(window.location.href + '#tools')
                                .then(() => {
                                    setToolTip("Copied link!");
                                    setTimeout(() => setToolTip(""), 2000);
                                });
                        }}
                        className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white relative"
                    >
                        <ShareIcon className="w-5 h-5" />
                        Share tool
                        {toolTip && (
                            <div className="absolute block top-5 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white p-1 rounded text-xs">
                                {toolTip}
                            </div>
                        )}
                    </button>
                </div>

                {/* Tool content */}
                <div className="space-y-6">
                    <div className="flex justify-between items-start">
                        <div className="flex flex-col gap-2">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{tool.name}</h2>
                        </div>
                        <button 
                            onClick={() => window.open(tool.url, '_blank', 'noopener,noreferrer')}
                            className="flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium
                                bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                                focus:ring-blue-500 transition-colors duration-200"
                        >
                            Visit Tool
                            <svg 
                                className="w-4 h-4" 
                                fill="none" 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" 
                                />
                            </svg>
                        </button>
                    </div>

                    <p className="text-gray-600 dark:text-gray-300">{tool.desc}</p>

                   {/* Pricing Information */}
                   {tool.pricing && (
                       <div className="flex items-center justify-between mt-2">
                            <span className="text-gray-900 dark:text-white">Pricing: &nbsp;</span>
                           <div className="flex space-x-2">
                               {tool.pricing === "free" && (
                                   <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                                        <HandCoins className="w-3 h-3 mr-2" />
                                       Free
                                   </span>
                               )}
                               {tool.pricing === "paid" && (
                                   <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                        <HandCoins className="w-3 h-3 mr-2" />
                                       Paid
                                   </span>
                               )}
                               {tool.pricing === "both" && (
                                   <div className="flex space-x-2">
                                       <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                                            <HandCoins className="w-3 h-3 mr-2" />
                                           Free
                                       </span>
                                       <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                            <HandCoins className="w-3 h-3 mr-2" />
                                           Paid
                                       </span>
                                   </div>
                               )}
                               {tool.pricing === "trail" && (
                                   <div className="flex space-x-2">
                                       <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200">
                                            <HandCoins className="w-3 h-3 mr-2" />
                                           Trial
                                       </span>
                                   </div>
                               )}
                               {tool.pricing === "free-trail-paid" && (
                                   <div className="flex space-x-2">
                                       <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                                            <HandCoins className="w-3 h-3 mr-2" />
                                           Free
                                       </span>
                                       <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200">
                                            <HandCoins className="w-3 h-3 mr-2" />
                                           Trial
                                       </span>
                                       <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                            <HandCoins className="w-3 h-3 mr-2" />
                                           Paid
                                       </span>
                                   </div>
                               )}
                           </div>
                       </div>
                   )}

                   {tool.privacy && (
                       <div className="flex flex-wrap justify-between">
                           <span className="text-gray-900 dark:text-white">Privacy Risk Score:</span>
                           <div className="flex space-x-2">
                               {tool.privacy === "minimal" && (
                                   <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                                        <ShieldCheckIcon className="w-3 h-3 mr-2" />
                                       Minimal
                                   </span>
                               )}
                               {tool.privacy === "limited" && (
                                   <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200">
                                        <ShieldCheckIcon className="w-3 h-3 mr-2" />
                                       Limited
                                   </span>
                               )}
                               {tool.privacy === "high" && (
                                   <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                                        <ShieldCheckIcon className="w-3 h-3 mr-2" />
                                       High
                                   </span>
                               )}
                           </div>
                       </div>
                   )}

                    {tool.dataCountry && Object.keys(tool.dataCountry).length > 0 && (
                        <div className="flex flex-wrap justify-between">
                            <span className="text-gray-900 dark:text-white">Data stored in:</span>
                            <div className="flex space-x-2">
                                {tool.dataCountry.map((country, index) => (
                                    <span key={index} className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 border-gray-200 text-gray-800 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300 transition duration-200 hover:shadow-md">
                                        {country.toUpperCase() === "WORLD" ? (
                                            <GlobeIcon className="w-3 h-3" />
                                        ) : (
                                            <ReactCountryFlag countryCode={country} className="pt-[0.02rem] -ml-[0.05rem] w-3 h-3" />
                                        )}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}


                    {/* Specifications Section */}
                    {toolSpecifications.length > 0 && (
                    <CollapsibleSection
                        title="Specifications"
                        isOpen={isSpecificationsOpen}
                        toggleOpen={() => handleSectionToggle('specifications')}
                        icon={SpecificationsIcon}
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {toolSpecifications.map((categoryObj, index) => {
                                const category = Object.keys(categoryObj)[0];
                                const categoryData = categoryObj[category];
                                
                                return (
                                    <div key={index} className="space-y-3 bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4 shadow-sm">
                                        <div className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2 border-b pb-1 border-gray-200 dark:border-gray-700">
                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </div>
                                        <div className="space-y-2">
                                            {Object.entries(categoryData).map(([name, value], entryIndex) => (
                                                <div key={entryIndex} className="flex justify-between items-center">
                                                    <span className="text-sm text-gray-600 dark:text-gray-400">{name}</span>
                                                    {typeof value === 'boolean' ? (
                                                        value ? (
                                                            <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        ) : (
                                                            <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        )
                                                    ) : (
                                                        <span className="text-sm text-gray-600 dark:text-gray-400">{value}</span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </CollapsibleSection>)}

                    {/* Categories & Ratings Section */}
                    <CollapsibleSection
                        title="Categories & Ratings"
                        isOpen={isCategoriesRatingsOpen}
                        toggleOpen={() => handleSectionToggle('ratings')}
                        icon={RatingsIcon}
                    >
                        <div className="space-y-4">
                            <div className="grid gap-3">
                                {Object.entries(
                                    ratingViewMode === "profile" ? (tool.profileRatings || {}) : 
                                    ratingViewMode === "our" ? (tool.ourRatings || {}) : 
                                        (tool.ratings || {}))
                                    .sort(([, a], [, b]) => b - a) // Sort by rating value (highest first)
                                    .map(([category, avgRating], idx) => {
                                        const showSetRating = ratingViewMode === "user" ? userRatings[category] : avgRating;
                                        return (
                                        <div key={idx} className="flex flex-col gap-2 p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                                            <div className="flex items-center justify-between gap-4">
                                                <CategoryBadge category={category} />
                                                <div className="flex items-center gap-4">
                                                    <div className="flex flex-col items-end">
                                                        <span className="text-xs text-gray-500 dark:text-gray-400">
                                                            {ratingViewMode === "profile" && profileUser ? profileUser.name : 
                                                            ratingViewMode === "our" ? "Crafting AI" : 
                                                            ratingViewMode === "user" ? "You" :
                                                            "Average"
                                                            }
                                                        </span>
                                                        <StarRating rating={showSetRating} />
                                                    </div>
                                                    {isRatingMode && (
                                                        <div className="flex flex-col items-end ml-5">
                                                            <span className="text-xs text-gray-500 dark:text-gray-400">
                                                                Your Rating
                                                            </span>
                                                            <InteractiveStarRating 
                                                                category={category} 
                                                                rating={userRatings[category] || 0} 
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    })}
                            </div>

                            <div className="flex items-center justify-end">
                                <div className="flex items-center gap-2">
                                    {canUserRate() ? (
                                        <button
                                            onClick={() => setIsRatingMode(!isRatingMode)}
                                            className="flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium
                                                transition-colors
                                                bg-blue-50 text-blue-600 hover:bg-blue-100
                                                dark:bg-blue-500/10 dark:text-blue-400 dark:hover:bg-blue-500/20"
                                        >
                                            {isRatingMode ? 'Cancel Rating' : 'Rate Tool'}
                                            <svg 
                                                className="w-4 h-4" 
                                                fill="currentColor" 
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path 
                                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118l-2.8-2.034c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" 
                                                />
                                            </svg>
                                        </button>
                                    ) : (
                                        <div className="text-sm text-gray-500 dark:text-gray-400 italic">
                                            You don't have permission to rate this tool.
                                        </div>
                                    )}
                                </div>
                                {/* Submit button - only show when in rating mode and at least one rating is set */}
                                {isRatingMode && Object.keys(userRatings).length > 0 && (
                                        <button
                                            onClick={handleSubmitRatings}
                                            disabled={isSaving}
                                            className="flex items-center gap-2 px-6 py-2 rounded-full text-sm font-medium
                                                transition-all duration-200
                                                bg-blue-600 text-white hover:bg-blue-700
                                                disabled:bg-blue-300 disabled:cursor-not-allowed
                                                dark:disabled:bg-blue-800 ml-4"
                                        >
                                            {isSaving ? 'Submitting...' : 'Submit Ratings'}
                                            {!isSaving && (
                                                <svg 
                                                    className="w-4 h-4" 
                                                    fill="none" 
                                                    stroke="currentColor" 
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path 
                                                        strokeLinecap="round" 
                                                        strokeLinejoin="round" 
                                                        strokeWidth={2} 
                                                        d="M5 13l4 4L19 7" 
                                                    />
                                                </svg>
                                            )}
                                        </button>
                                )}
                            </div>
                        </div>
                    </CollapsibleSection>

                    {/* Reviews Section */}
                    <CollapsibleSection
                        title="User Tool Reviews"
                        isOpen={isReviewsOpen}
                        toggleOpen={() => handleSectionToggle('reviews')}
                        icon={ReviewsIcon}
                    >
                        <div className="p-4">
                            <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center gap-2 text-sm">
                                <span className="text-black dark:text-white">Average Rating:</span>
                                <StarRating rating={currentReviews.length > 0 ? (currentReviews.reduce((total, review) => total + review.rating, 0) / currentReviews.length) : 0} />
                            </div>
                                <div>
                                    {user && !userReview && canUserRate() && (
                                        <button
                                            onClick={handleAddReview}
                                            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                                        >
                                            Write a Review
                                        </button>
                                    )}
                                    {user && user.role < 1 && (
                                        <span className="text-sm text-gray-500 dark:text-gray-400">
                                            You don't have permission to write a review.
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="mt-6 space-y-4">
                                {errorMessage && (
                                    <div className="text-red-500 mb-2">
                                        {errorMessage}
                                    </div>
                                )}
                               {currentReviews
                                   .sort((a, b) => {
                                       if (a.user?.isCurrentUser) return -1; // Put the user's review on top
                                       if (b.user?.isCurrentUser) return 1;
                                       const aScore = (a.votes?.up || 0) - (a.votes?.down || 0);
                                       const bScore = (b.votes?.up || 0) - (b.votes?.down || 0);
                                       const scoreDiff = bScore - aScore;
                                       const dateDiff = new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                                       return scoreDiff !== 0 ? scoreDiff : dateDiff; // First by score, then by date
                                   })
                                   .map((review) => (
                                    <div key={review.id} 
                                    className={`p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 
                                        ${review.user?.isCurrentUser ? 'bg-sky-100 dark:bg-sky-900' : 
                                        // review.user?.isAdmin ? 'bg-purple-100 dark:bg-purple-900' :
                                        // review.user?.isTrainer ? 'bg-red-100 dark:bg-red-900' : 
                                        'bg-white dark:bg-gray-800'}
                                        `}
                                    >
                                        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between mb-2">
                                            <div className="flex items-center">
                                                <span className="text-sm font-medium text-gray-900 dark:text-white flex items-center">
                                                    {review.user.isAdmin && (
                                                        <Tooltip title="Admin">
                                                            <ShieldCheckIcon className="w-4 h-4 text-purple-400 mr-2" />
                                                        </Tooltip>
                                                    )}
                                                    {review.user.isTrainer && (
                                                        <Tooltip title="Trainer">
                                                            <GraduationCap className="w-4 h-4 text-red-400 mr-2" />
                                                        </Tooltip>
                                                    )}
                                                    {review.user.name}
                                                </span>
                                                <span className="mx-2 text-gray-400">•</span>
                                                <div className="flex items-center">
                                                    {[...Array(5)].map((_, index) => (
                                                        <svg
                                                            key={index}
                                                            className={`w-4 h-4 ${
                                                                index < review.rating ? 'text-yellow-400' : 'text-gray-300 dark:text-gray-600'
                                                            }`}
                                                            fill="currentColor"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                        </svg>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
                                                {review?.user?.isCurrentUser && (
                                                    <div className="flex items-center gap-2">
                                                        <button
                                                            onClick={() => {
                                                                setIsEditingReview(true);
                                                                setUserReviewRating(review.rating);
                                                                setUserReviewComment(review.comment);
                                                                setIsReviewModalOpen(true);
                                                            }}
                                                            className="text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                                                        >
                                                            Edit
                                                        </button>
                                                        <span className="text-gray-300 dark:text-gray-600">|</span>
                                                        <button
                                                            onClick={() => setShowDeleteReviewConfirm(true)}
                                                            className="text-sm text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                )}
                                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                                    {new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(new Date(review.created_at))}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="whitespace-pre-wrap text-gray-600 dark:text-gray-300">
                                            {review.comment.split("\n").map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <div className="flex items-center justify-end text-sm text-gray-500 dark:text-gray-400 mt-4">
                                            {/* Thumbs up and thumbs down Button */}
                                            {canUserRate() &&
                                                <>
                                                    <button
                                                        onClick={() => reviewUpDownVote(review.id, 'up')}
                                                        className={`p-1 rounded ${review.votes?.userUp ? 'bg-green-200 dark:bg-green-800 text-white' : ''} ${review.user.isCurrentUser || !canUserRate() ? 'opacity-30 cursor-not-allowed' : ''}`}
                                                        disabled={review.user.isCurrentUser || !canUserRate()}
                                                    >
                                                        👍
                                                    </button>
                                                    <span className="mx-1">{review.votes?.up || 0}</span>
                                                    <button
                                                        onClick={() => reviewUpDownVote(review.id, 'down')}
                                                        className={`ml-2 p-1 rounded ${review.votes?.userDown ? 'bg-red-200 dark:bg-red-800 text-white' : ''} ${review.user.isCurrentUser || !canUserRate() ? 'opacity-30 cursor-not-allowed' : ''}`}
                                                        disabled={review.user.isCurrentUser || !canUserRate()}
                                                    >
                                                        👎
                                                    </button>
                                                    <span className="mx-1">{review.votes?.down || 0}</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                ))}
                                {/* Pagination */}
                                {totalPages > 1 && (
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                )}
                                {reviews.length === 0 && (
                                    <div className="text-center py-8">
                                        {profileUser?.name ? 
                                        (<p className="text-gray-600 dark:text-gray-400 mb-4">No reviews yet. {canUserRate() ? 'Be the first to share your experience!' : `${profileUser?.name} did not yet submit any reviews.`}</p>)
                                         : (<p className="text-gray-600 dark:text-gray-400 mb-4">No reviews yet. {canUserRate() ? 'Be the first to share your experience!' : 'Reviews will appear here once users start sharing their experiences.'}</p>)
                                        }
                                        
                                        {canUserRate() && (
                                            <button
                                                onClick={handleAddReview}
                                                className="px-6 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                                            >
                                                Write a Review
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </CollapsibleSection>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            {tool.verified === 0 && canUserRate() && (
                                <button
                                    onClick={() => setShowDeleteConfirm(true)}
                                    className="flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium
                                        bg-red-100 text-red-600 hover:bg-red-200
                                        dark:bg-red-900/20 dark:text-red-400 dark:hover:bg-red-900/40
                                        transition-colors duration-200"
                                >
                                    <svg 
                                        className="w-4 h-4" 
                                        fill="none" 
                                        stroke="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth={2} 
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                                        />
                                    </svg>
                                    Delete Tool
                                </button>
                            )}
                        </div>
                        {tool.verified === 0 && user && (
                                <div className="flex items-center justify-end gap-2 text-gray-600 dark:text-gray-300">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="text-sm text-gray-500 dark:text-gray-400">Edit feature will be added soon.</span>
                                </div>
                            )}
                    </div>
                </div>
            </div>

            {/* Add Delete Confirmation Modal */}
            {showDeleteConfirm && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div 
                        className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-md w-full"
                        onClick={e => e.stopPropagation()}
                    >
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                            Delete Tool
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400 mb-6">
                            Are you sure you want to delete "{tool.name}"? This action cannot be undone.
                        </p>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                disabled={isDeleting}
                                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                            >
                                {isDeleting ? 'Deleting...' : 'Delete Tool'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Review Confirmation Modal */}
            {showDeleteReviewConfirm && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div 
                        className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md"
                        onClick={e => e.stopPropagation()}
                    >
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Delete Review</h3>
                        <p className="text-gray-600 dark:text-gray-400 mb-6">
                            Are you sure you want to delete your review? This action cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => setShowDeleteReviewConfirm(false)}
                                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDeleteReview}
                                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                            >
                                Delete Review
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Review Modal */}
            {isReviewModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                            {isEditingReview ? 'Edit Review' : 'Add Review'}
                        </h3>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Rating</label>
                            <div className="flex space-x-2">
                                {[1, 2, 3, 4, 5].map((rating) => (
                                    <button
                                        key={rating}
                                        onClick={() => setUserReviewRating(rating)}
                                        className="focus:outline-none"
                                    >
                                        <svg
                                            className={`w-6 h-6 ${
                                                rating <= userReviewRating ? 'text-yellow-400' : 'text-gray-300 dark:text-gray-600'
                                            }`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Comment</label>
                            <div className="relative">
                                <textarea
                                    value={userReviewComment}
                                    onChange={(e) => setUserReviewComment(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                                    rows={4}
                                    placeholder="Share your thoughts about this tool..."
                                />
                                {userReviewComment.length > 0 && (
                                    <div className={`text-sm mt-1 text-right ${
                                        userReviewComment.length > 1000 
                                            ? 'text-red-500' 
                                            : userReviewComment.length >= 950 
                                                ? 'text-orange-500' 
                                                : 'text-gray-500'
                                    }`}>
                                        {userReviewComment.length > 1000 
                                            ? `Character limit exceeded by ${userReviewComment.length - 1000} characters` 
                                            : userReviewComment.length >= 950 
                                                ? `${1000 - userReviewComment.length} characters remaining` 
                                                : `${userReviewComment.length}/1000 characters`}
                                    </div>
                                )}
                            </div>
                        </div>
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                            Please note: your name and review will be displayed publicly.
                        </p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => { setIsReviewModalOpen(false); setIsEditingReview(false); }}
                                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            >
                                Cancel
                            </button>
                           <button
                               onClick={handleReviewSubmit}
                               disabled={userReviewComment.length > 1000}
                               className={`px-4 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                   userReviewComment.length > 1000
                                       ? 'bg-gray-400 cursor-not-allowed'
                                       : 'bg-blue-600 hover:bg-blue-700'
                               }`}
                           >
                               Submit Review
                           </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
