import React from 'react';
import { Section } from './types/Section';
import { GridItem } from './types/GridItem';
import { Recipe } from './types/Recipe';
import { ViewMode } from './types/ViewMode';
import { SectionHeader } from './SectionHeader';
import { NoPromptFound } from './NoPromptFound';
import { PromptCard } from './PromptCard';
import { PromptRecipeCard } from './PromptRecipeCard';

interface ShowTableLayoutProps {
    viewMode: ViewMode;
    copiedId: string | null;
    mergedPrompts: GridItem[];
    tools: any[];
    recipes: Recipe[];
    handleCopy: (prompt: string, id: string) => void;
    handleAddToMerged: (prompt: GridItem) => void;
    handleDeletePrompt: (id: string) => void;
    handleEditPrompt: (prompt: GridItem) => void;
    handleOpenModal: (section: Section) => void;
    handleLoadRecipe: (recipe: Recipe) => void;
    toggleRecipePublish: (recipeId: string) => void;
    togglePromptPublish?: (promptId: string) => void;
    handleDeleteRecipe: (recipeId: string) => void;
    getAllItems: () => GridItem[];
    getFilteredRecipes: () => Recipe[];
}

export const ShowTableLayout: React.FC<ShowTableLayoutProps> = ({
    viewMode,
    copiedId,
    mergedPrompts,
    tools,
    recipes,
    handleCopy,
    handleAddToMerged,
    handleDeletePrompt,
    handleEditPrompt,
    handleOpenModal,
    handleLoadRecipe,
    toggleRecipePublish,
    togglePromptPublish,
    handleDeleteRecipe,
    getAllItems,
    getFilteredRecipes
}) => {
    return (
        <div className="space-y-8 mt-8">
            {getAllItems().length > 0 ? (
                <>
                <table className="w-full text-sm text-left">
                    <thead className="text-xs text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-800/50">
                        <tr>
                            <th className="px-4 py-3">Title</th>
                            <th className="px-4 py-3">Prompt</th>
                            <th className="px-4 py-3">Labels</th>
                            <th className="px-4 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(["Context", "Register", "Acting Role", "Format", "Task"] as const).map((section) => {
                            const sectionItems = getAllItems().filter(item => item.section === section);
                            return (
                                <React.Fragment key={section}>
                                    <tr className="bg-gray-50 dark:bg-gray-800/50">
                                        <th colSpan={4} className="px-4 py-2 text-left text-sm font-medium text-white dark:text-gray-300 bg-blue-600 dark:bg-gray-700">
                                            {section}
                                        </th>
                                    </tr>
                                    {sectionItems.length === 0 ? (
                                        <tr key={`${section}-empty`}>
                                            <td colSpan={4} className="px-4 py-8 text-center text-sm text-gray-500 dark:text-gray-400">
                                                <div className="flex flex-col items-center gap-2">
                                                    <p className="text-gray-500 dark:text-gray-400">
                                                        No prompts found in {section} section
                                                    </p>
                                                    <button
                                                        onClick={() => handleOpenModal(section)}
                                                        className="inline-flex items-center gap-1.5 px-3 py-1.5 text-xs font-medium
                                                            text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300
                                                            bg-blue-50 hover:bg-blue-100 dark:bg-blue-900/20 dark:hover:bg-blue-900/30
                                                            rounded-full transition-colors duration-200"
                                                    >
                                                        <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                                        </svg>
                                                        Add {section} prompt
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : (
                                        sectionItems.map((item: GridItem) => (
                                            <PromptCard
                                                key={item.id}
                                                prompt={item}
                                                viewMode={viewMode}
                                                copiedId={copiedId}
                                                mergedPrompts={mergedPrompts}
                                                tools={tools}
                                                handleCopy={handleCopy}
                                                handleAddToMerged={handleAddToMerged}
                                                handleDeletePrompt={handleDeletePrompt}
                                                handleEditPrompt={handleEditPrompt}
                                                togglePublish={togglePromptPublish}
                                            />
                                        ))
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    {getFilteredRecipes().length > 0 && (
                        <tbody key="recipes">
                            <tr>
                                <td colSpan={4}>
                                    <div className="w-full col-span-full">
                                        <div className="space-y-3">
                                            <SectionHeader section="Recipes" />
                                            <PromptRecipeCard
                                                recipes={recipes}
                                                viewMode={viewMode}
                                                copiedId={copiedId}
                                                handleCopy={handleCopy}
                                                handleLoadRecipe={handleLoadRecipe}
                                                toggleRecipePublish={toggleRecipePublish}
                                                handleDeleteRecipe={handleDeleteRecipe}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
                </>
            ) : (
                <NoPromptFound section="Context" handleOpenModal={handleOpenModal} />
            )}
        </div>
    );
};