import ContentAccordion from "../../components/ContentAccordion";
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";
import PromptEngineeringCatLabel from "../../components/labels/PromptEngineeringCatLabel";
import PromptComponentLabel from "../../components/labels/PromptComponentLabel";
import BaseButton from "../../components/base/Button";
import PromptExampleChat from "../../components/documentation/PromptExampleChat";

function VisualFrameworkDocumentation() {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - IVPE Framework";
    }, []);

    /*
* Only show the handout version of the page.
* */
    if (window.location.hash.includes("#handout-ivpeframework")) {
        sessionStorage.setItem('handout-ivpeframework', "true");
    }

    //const theLLM = "the LLM"; // Not yet used for this documentation section.
    const docLink = "/documentation/framework/visual";

    const interactiveTip = [
        {
            title: "Strategy: Seed number and GenID",
            hashID: "format-default-formatting",
            docLink: docLink,
            description:
                <>
                <p>Working extensively with images often involves an interactive approach where requesting the 'Seed number' or 'Gen ID' becomes quite useful. (If you're unfamiliar with seed numbers, there will be more information available in the updated version of the framework, which includes seed numbers for image creation.)</p>
                <p>To streamline this process, you can incorporate a directive into your Custom Instructions for ChatGPT, or include it in every prompt, stating:</p> 
                <PromptExampleChat 
                    title="When creating images, also return the seed number and Gen ID."
                    userPrompt="... When creating images, also return the seed number and Gen ID. ..."
                />
                <p><strong>The difference:</strong></p>
                <p className="p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950"><strong>Gen ID (Generation ID)</strong>: This is a unique identifier assigned to each image generated by DALL·E 3. It's used to reference and retrieve the specific image from the system. The Gen ID helps in tracking, sharing, and managing images within the platform, ensuring that every creation can be distinctly recognized and accessed.</p>
                <p className="p-4 m-4 bg-sky-100 rounded-md dark:bg-sky-950"><strong>Seed ID</strong>: The Seed ID, often referred to simply as the "seed," is related to the randomness used in the generation process of an image.
                In generative models like DALL·E 3, randomness plays a crucial role in creating varied and unique outputs.
                <strong>The seed</strong> determines the starting point of this randomness, and using the same seed with the same input prompt will theoretically produce the same image every time. It's a way to introduce controlled variability in the model's output and can be used to replicate results or ensure consistency across different generation attempts.</p>
                <p>This not only aids in reproducing or referencing specific images but also enhances the interactive experience. However, it's important to remember that when you use DALL·E 3 to generate a new image based on your prompt, it still operates under certain parameters, such as temperature settings. This means that while you can guide the creative process by referencing previous images, the system will interpret your prompt within the bounds of these parameters to create something new. This balance between control and creativity is what makes using DALL·E 3 a unique and engaging experience.</p>
                <p>Similarly, this concept of specifying return formats can be applied to other outputs. For instance, when ChatGPT generates tables, you could request that each table includes a unique ID as the first column. This practice ensures consistency and ease of reference across different types of outputs, making your interactions with the AI more efficient and tailored to your specific needs.</p>
            </>,
            fireIcon: true,
        },
        {
            title: "Strategy: Enhance generated images via ChatGPT",
            hashID: "format-default-formatting",
            docLink: docLink,
            description:
                <>
                    <p>The true potential of ChatGPT is realized when you also use IPE to modify images after they are generated. If you've created an image that meets your expectations, you can enhance it further without any external software. Simply ask ChatGPT to apply filters using Python.</p>
                    <PromptExampleChat 
                        title="Enhance the image with Python"
                        userPrompt="Use Python to add a Grayscale color filter to it. Display the original picture, and new picture side by side."
                    />
                    <p>If you're satisfied with the code, you can request a download link to obtain the new image.</p>
                    <PromptExampleChat 
                        title="Create download"
                        userPrompt="Make it available for download."
                    />
                    <p>Examples of filters to apply are: Blur effects (Gaussian Blur, Box Blur, Median Blur), Sharpening, Edge Detection (Sobel filter, Canny Edge Detection), Color Filters (Grayscale, Sepia, Thresholding), Contrast Adjustment, Brightness Adjustment, Rotation and cropping, resizing and scaling, Adding text or overlays, Morphological Transformations (Erosion, Dilation).</p>
                </>,
            fireIcon: true,
        }
    ];

    const nonDisclosureTip = [
        {
            title: "Copyright considerations",
            hashID: "n-copyright-considerations",
            docLink: docLink,
            description: "<p>While tools like DALL·E 3 offer the ability to generate images <a href='https://help.openai.com/en/articles/6425277-can-i-sell-images-i-create-with-dall-e' target=\"_blank\" class=\"text-blue-500 text-bold\" title='OpenAI - Can I sell images I create with DALL·E?'>for commercial use</a>, it is paramount to ensure that these images are free of copyrighted elements. Users must exercise due diligence to confirm that the generated content does not inadvertently include copyrighted material or trademarks that could lead to legal issues. This includes closely reviewing the images for any recognizable features or designs that are protected by copyright. To adhere to legal standards and maintain ethical usage, always verify that your content complies with all applicable copyright laws before usage, especially in professional or commercial settings. Ensuring careful scrutiny of visual content helps prevent copyright infringement and protects against potential legal complications.</p>" +
                "<p>Additional note on Acting Roles: When utilizing the 'Acting Role' component, as detailed in the section on assigning stylistic or artistic influences, be aware of the potential for including copyrighted elements. For example, emulating a 'Marvel style' might inadvertently incorporate signature superhero elements that are copyrighted. It is vital to review any content created under specific acting roles or artistic influences to ensure that no copyrighted materials are used, thus maintaining compliance with copyright laws and safeguarding against legal issues.</p>",
            alertIcon: true,
        }
    ]

    const improveTip = [
        {
            title: "<span>Strategy: Handling text within images</span>",
            hashID: "ai-handling-text-within-images",
            docLink: docLink,
            description: `<p>Text embedded in images often poses challenges for models tasked with image generation, leading to inaccuracies. To mitigate this, it's advisable to exclude any text from images in your initial requests. Alternatively, tools like Canva offer a useful "grab text" feature, allowing you to modify any text in images post-generation. This approach ensures clarity and correctness in the visual content you create.</p>`,
            fireIcon: true,
        }
    ];

    interface ElementDescriptionProps {
        title: string,
        description:string,
    }

    const ElementDescription: React.FC<ElementDescriptionProps> = ({title, description}) => {
        return(<div className={"flex"}>
                <div className={"min-w-[120px] w-[20%] py-2"}><PromptComponentLabel text={title} /></div>
                <div className={"flex-1 py-4 px-4"}>{description}</div>
            </div>);
    }


    return(
        <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="documentation">
            <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
                <DocumentationNav page="VisualFramework" />
                <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white" id="framework"
                     data-aos="fade-up"
                     data-aos-delay="100">
                    <div className={"block flex col-2 mb-1"} id="interactive-prompt-engineering">
                        <h1 className={"mb-1"}><span className="text-header-gradient">CRAFTING AI</span> prompts framework</h1>
                        <div className={"mt-3 ml-4 pt-1"}>
                            <PromptEngineeringCatLabel category="IVPE" />
                        </div>
                    </div>
                    <p>The <strong className="dark:text-white">CRAFTING AI prompts framework (IVPE)</strong> is designed to empower users in effectively utilizing AI for image and video generation tasks while ensuring ethical and responsible use. It consists of three essential parts: <strong className="dark:text-white">CRAFT</strong>, <strong className="dark:text-white">ING</strong>, and <strong className="dark:text-white">AI</strong>. Let's explore each component in detail:</p>
                    <p><strong><a href={"/framework/visual#craft"} className="text-bold underline dark:text-white">CRAFT</a></strong>: The crafting phase focuses on providing specific details and context to enhance the quality of visual outputs. By defining detailed visual contexts, artistic registers, and specific formats, users guide the AI to better understand the visual intentions and nuances, resulting in images and videos that accurately reflect creative visions. Specifying artistic influences, technical settings, and the primary task objectives helps the AI to align closely with the intended visual style and functional requirements.</p>
                    <p><strong><a href={"/framework/visual#validate"} className="text-bold underline dark:text-white">ING</a></strong>: The validation phase promotes interactive engagement and integrates legal, security, and privacy considerations. Through fostering iterative feedback and adjustments, users refine the visual outputs in collaboration with the AI, ensuring that the results align with ethical and responsible practices. By being mindful of non-disclosure agreements, data security, and privacy guidelines, prompts maintain compliance, protect sensitive visual content, and establish clear goals. This approach enables users to derive meaningful visuals while upholding confidentiality and achieving desired outcomes.</p>
                    <p><strong><a href={"/framework/visual#enhance"} className="text-bold underline dark:text-white">AI</a></strong>: The enhancement phase emphasizes staying proactive and embracing new possibilities. Users are encouraged to adapt their strategies by validating responses, making necessary adjustments, and refining prompts based on initial results. By continuously improving prompts and leveraging the interactive capabilities of AI, users can maximize the potential of AI tools and generate the best possible visual outcomes.</p>

                    <p>By adhering to the CRAFTING AI prompts framework, users can effectively utilize the capabilities of AI tools for image and video generation to improve visual content and enhance overall creative engagement.</p>
                    <p>Let's delve into each aspect of the framework to gain a deeper understanding.</p>

                    <hr />

                    <h2 id="craft"><span className="text-header-gradient">Crafting</span> phase</h2>
                    <p id="craft-c"><strong className="dark:text-white">C - Context</strong>: For effective visual content creation, it's crucial to provide a detailed description of the scene to be depicted. This includes specifying the setting, characters, time period, and any relevant background story or overarching theme. Understanding the context helps in creating an image that accurately reflects your vision. Additionally, clarify the purpose or message of the image. Whether it's to convey a narrative, illustrate a concept, or evoke specific emotions, knowing the purpose guides the creative direction and ensures the final product resonates with your intended audience.</p>

                    <div className={"p-2"}>
                        <ElementDescription title={"[Describe the scene]"} description={"Provides details about what specifically is happening in the image. This should include the main actions or events."} />
                        <ElementDescription title={"[Specify setting]"} description={"Outlines where the image is taking place, adding background and environmental context."} />
                        <ElementDescription title={"[Describe characters or subjects]"} description={"Details about the main figures in the image; who they are, what they look like, and what they are doing."} />
                        <ElementDescription title={"[State the purpose of the Image]"} description={"Explains what the image is intended to convey or achieve, defining the overarching goal or message."} />
                    </div>

                    <p id="craft-r"><strong className="dark:text-white">R - Register</strong>: When creating visual content, specifying the artistic register is essential. This involves choosing the range of artistic styles and techniques to be used, from cartoonish to photorealistic or avant-garde. Detailing the technique variety—whether a mix or a focus on a specific method—helps in achieving the desired artistic expression. Describe the mood, tone, and color scheme to set the emotional and visual tone of the image. Indicating a preferred visual style, such as realistic, abstract, or whimsical, further refines the artistic direction.</p>

                    <div className={"p-2"}>
                        <ElementDescription title={"[Specify Artistic Style]"} description={"Indicates the style in which the image should be created, such as impressionistic, realistic, abstract, etc."} />
                        <ElementDescription title={"[Specify Color Palette]"} description={"Defines the colors to be used, which helps set the visual tone and mood."} />
                        <ElementDescription title={"[Describe Mood/Tone]"} description={"Describes the emotional atmosphere or feeling the image should evoke, such as cozy, inviting, somber, etc."} />
                    </div>

                    <p id="craft-a"><strong className="dark:text-white">A - Acting Role / Aesthetics</strong>: In the context of visual content, assign an 'Acting Role' by specifying a style or influence of a known artist or artistic movement that the image should emulate.
                        This could involve adopting the distinctive techniques or visual flair of renowned artists.
                        For example, you might request an image that reflects the surrealistic elements of Salvador Dalí or the bold simplicity of Andy Warhol.
                        This approach not only pays homage to great artists but also leverages their unique styles to enhance the aesthetic appeal of your image.
                    </p>
                    <div className={"p-2"}>
                        <ElementDescription title={"[Artist or Artistic Movement]"} description={"Specifies the artistic influence or style to be emulated in the image, directing the visual approach and techniques to be used."} />
                        <ElementDescription title={"[AESTHETIC ELEMENTS]"} description={"Detail the specific aesthetic qualities that enhance the image’s visual appeal, incorporating stylization, weirdness, and variety. " +
                            "Stylization should be aligned with the intended artistic direction, ranging from detailed realism to broad abstract forms. Incorporate elements of weirdness to introduce unique or unconventional aspects that challenge traditional norms and add intrigue. Ensure the image features a variety of visual elements, such as different textures, a dynamic color range, and a mix of geometric and organic shapes, to keep the viewer engaged and enrich the visual narrative."} />
                    </div>

                    <p id="craft-f"><strong className="dark:text-white">F - Format</strong>: Clearly define the technical aspects of the image's format. Specify the dimensions and orientation—whether landscape, portrait, or square. If a particular aspect ratio is needed, such as 16:9 for wide-screen or 1:1 for a square format, mention this to ensure the image fits perfectly in the intended medium or platform. This attention to format is crucial for meeting specific layout or design requirements.</p>
                    <div className={"p-2"}>
                        <ElementDescription title={"[Specify Orientation]"} description={"Determines whether the image should be portrait, landscape, or square."} />
                        <ElementDescription title={"[Specify Aspect Ratio]"} description={"Sets the proportions of the image, such as 16:9, 4:3, etc."} />
                        <ElementDescription title={"[Specify Image Type]"} description={"Sets the style of image you want to generate, such as: Photo types (editorial Photography, Documentary Photography, Landscape Photography, Cinematic), illustration, drawing etc."} />
                        <ElementDescription title={"[Specify Lighting Conditions]"} description={"Describes the lighting setup or natural lighting conditions to capture in the image, such as bright, soft, dramatic, backlit, etc. This is essential for setting the mood and visibility in the image. Also using the Time of Day is a useful method such as Golden Hour, Blue Hour or Midday."} />
                        <ElementDescription title={"[Specify Camera Settings]"} description={"Details specific camera (specify a well-known camera) and settings to be used, like aperture, shutter speed, ISO, Shot type (low angle, hero shot, eye level, top down, closeup), lens type (ultra wide angle lens, standard lens) and focus type. These settings are critical for achieving the desired clarity, depth of field (Shallow DoF, Moderate DoF), and motion effects."} />
                    </div>

                    <p id="craft-t"><strong className="dark:text-white">T - Task</strong>: Articulate the specific goal or primary task of the image. Describe the key elements or focal points that must be included, and provide any special instructions or elements that need to be featured. This clarity in defining the task ensures that the image not only meets artistic and stylistic expectations but also fulfills its intended function, whether it's to advertise, educate, or entertain.</p>
                    <div className={"p-2"}>
                        <ElementDescription title={"[Objective]"} description={"Clearly state the specific goal or the primary task of the image. What is the main focus or the key element that must be included?"} />
                        <ElementDescription title={"[Instructions]"} description={"Clearly specify you want to generate an image and list any specific instructions or elements that must be featured in the image."} />
                        <ElementDescription title={"[Parameters]"} description={"Indicate which version of the image creation model should be used, if relevant. This can be crucial for leveraging specific technological advancements or ensuring compatibility with certain styles or techniques. Also keep in mind that for some models, it's necessary to specify parameters such as style, character references, image weights, quality, and similar aspects."} />
                    </div>

                    <div className={"pb-8"}>
                        <p className={"mb-8"}>Extra resources:</p>
                            <a href="https://docs.midjourney.com/docs/parameter-list" target="_blank" rel="noreferrer"
                                className="max-w-full px-8 py-4 ml-4 rounded-md border border-[#0c66ee] dark:text-white hover:shadow-md hover:shadow-[#0c66ee]/50">Midjourney parameter list</a>
                    </div>

                    <hr />

                    <h2 id="validate"><span className="text-header-gradient">Validation</span> phase</h2>
                    <p id="validate-i"><strong className="dark:text-white">I - Interactive</strong>: Crafting prompts that encourage interactive engagement is crucial for effective image and video generation. This involves creating prompts that allow for iterative feedback and adjustments, enabling you to refine visual elements and achieve the desired outcomes. The interactive nature of these prompts enhances the creative process, allowing you to explore different visual ideas, request modifications, and perfect the final output. By fostering a dynamic exchange, you can ensure that the generated images or videos are more aligned with your vision, incorporating specific feedback and changes as needed.</p>
                    <ContentAccordion items={interactiveTip} grayTitle={true} />

                    <p id="validate-n"><strong className="dark:text-white">N - Non-disclosure</strong>: When generating images or videos, it's essential to incorporate legal, security, and privacy considerations, especially when handling sensitive visual content. Protecting this information and ensuring compliance with non-disclosure agreements (NDAs) is critical. Specify any restrictions related to data security and privacy to maintain ethical practices in visual content creation. Before submitting your prompt, ensure that all sensitive elements are properly handled to prevent unwanted exposure or breaches, thus safeguarding confidentiality in the process.</p>
                    <ContentAccordion items={nonDisclosureTip} grayTitle={true} />

                    <p id="validate-g"><strong className="dark:text-white">G - Goal-driven</strong>: Clearly defining your objectives in the prompts for image or video generation is key to obtaining precise and relevant outputs. Specify your desired visual outcomes and any particular elements that need emphasis, allowing the generation tools to align their output with your specifications. This goal-driven approach ensures the tools understand your intent and focus on producing visuals that precisely meet your needs. After submitting your prompt, it's important to review the generated content to ensure it meets your expectations. If necessary, adjust the formatting and technical parameters to refine the visuals and enhance their suitability for your specific objectives.</p>

                    <hr />

                    <h2 id="enhance"><span className="text-header-gradient">Enhancement</span> phase</h2>
                    <p id="enhance-ai"><strong className="dark:text-white">AI - Adapt and Improve</strong>: As AI tools evolve rapidly, it's important to adapt and
                        improve your strategy and prompts. The "AI" aspect of the framework reminds you to refine your
                        output by asking follow-up questions and adapting your approach based on the initial results. <strong>Validate the response</strong> and make adjustments as necessary.
                        Additionally, continuously improving your
                        prompts and embracing new possibilities is crucial for generating the best possible outcomes.
                        Stay proactive and adaptable to ensure ongoing success with these powerful tools.</p>

                    <ContentAccordion items={improveTip} grayTitle={true} />

                    <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                        src={require("../../assets/img/framework/framework-overview.png")} alt="Framework overview"/></p></div>

                    <p>It is not necessary to format your prompt following each step individually, but it is important to include all the elements of the framework within your prompt. A valid prompt that encompasses all parts of the framework could be:</p>

                    <PromptExampleChat
                        title="Example of prompt template"
                        userPrompt="Acting in the role of [Artist or Artistic Movement], create an image that captures the essence of [Describe the Scene]. Use a [Specify Orientation] orientation with an [Specify Aspect Ratio] aspect ratio. Employ a [Specify Artistic Style] with a [Specify Color Palette] to evoke a [Describe Mood/Tone]. Ensure the lighting is [Specify Lighting Conditions] and adjust camera settings to [Specify Camera Settings] to enhance the visual impact. The context is set in [Specify Setting] with [Describe Characters or Subjects], reflecting the purpose of [State the Purpose/Message of the Image]."
                        showActions={true}
                    />

                    <p>So for example, to fill the above one in:</p>

                    <PromptExampleChat
                        title="Example of prompt"
                        userPrompt="Acting in the role of Vincent van Gogh, create an image that captures the essence of a bustling modern city cafe, in a portrait orientation with a 4:3 aspect ratio. Use an impressionistic style with a warm color palette to evoke a cozy, inviting atmosphere.
                        The context is a contemporary setting with diverse patrons enjoying their evening, reflecting the purpose of highlighting the cafe's welcoming environment."
                        showActions={true}
                    />

                    <p>Please be aware that this is an iterative process, which means that after the enhancement phase, you
                        will return to the crafting phase. You can adapt and improve the generated output by writing another
                        prompt , utilizing the interactive (i) approach to refine it towards your desired goal (g), while
                        considering the non-disclosure (n) constraints. In your follow-up prompt, you can build upon the
                        previous output without reiterating all of the CRAFT elements. Instead, concentrate on the elements
                        that will improve and contribute to the output's enhancement (AI).</p>
                    <p><i>Released: Apr 24, 2024 - Last updated: Apr 25, 2024</i></p>

                    <hr />

                    <p>Now that you understand the basics of crafting effective prompts, it's time to take your skills to the next level. Let's dive into advanced prompt engineering techniques and explore how you can harness the full potential of AI.</p>
                    <BaseButton url={"/documentation/prompt-techniques#prompt-techniques"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                        Next: Prompt Techniques
                    </BaseButton>
                </div>
            </div>
        </section>);
}

export default VisualFrameworkDocumentation;