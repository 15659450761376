import React from 'react';
import { motion } from 'framer-motion';
import CustomSlider from './CustomSlider';

/**
 * SkillSlider Component
 * 
 * A component for displaying and updating individual skill assessments.
 * Features:
 * - Animated card with hover effects
 * - Skill level badge with appropriate color coding
 * - Description of the skill
 * - Custom slider for adjusting skill level
 * - Icon representation for each skill
 * 
 * @param {string} label - The name of the skill
 * @param {number} value - Current value of the skill (0-5)
 * @param {function} onChange - Function to call when skill value changes
 * @param {string} description - Description of what the skill represents
 * @param {Component} icon - Icon component to display with the skill
 * @returns {JSX.Element}
 */
const SkillSlider = ({ label, value, onChange, description, icon: Icon }) => {
    const getSkillLevel = (value) => {
        switch (value) {
            case 0: return "Novice";
            case 1: return "Beginner";
            case 2: return "Intermediate";
            case 3: return "Advanced";
            case 4: return "Expert";
            case 5: return "Master";
            default: return "Not Rated";
        }
    };

    const getBadgeColor = (value) => {
        switch (value) {
            case 0: return "bg-gray-500 text-white";
            case 1: return "bg-gray-600 text-white";
            case 2: return "bg-gradient-to-r from-purple-600 to-purple-700 text-white";
            case 3: return "bg-gradient-to-r from-green-600 to-green-700 text-white";
            case 4: return "bg-gradient-to-r from-sky-600 to-sky-700 text-white";
            case 5: return "bg-gradient-to-r from-yellow-600 to-yellow-700 text-white";
            default: return "bg-gray-500 text-white";
        }
    };

    return (
        <motion.div 
            className="space-y-3 bg-white dark:bg-gray-800/50 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-gray-800/80 transition-colors border border-gray-100 dark:border-gray-700"
            whileHover={{ 
                y: -3,
                boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                transition: { type: "spring", stiffness: 300, damping: 20 }
            }}
        >
            <div className="flex justify-between items-center">
                <div className="text-md font-medium text-gray-900 dark:text-white flex items-center">
                    {Icon && <Icon className="w-4 h-4 mr-2 text-[#468ef9] dark:text-[#0c66ee]" />}
                    {label}
                </div>
                <motion.div
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <span 
                        className={`${getBadgeColor(value)} text-xs font-medium px-2.5 py-0.5 rounded-full transition-colors duration-300`}
                    >
                        {getSkillLevel(value)}
                    </span>
                </motion.div>
            </div>
            <p className="text-xs text-gray-600 dark:text-gray-400">{description}</p>
            <div className="relative">
                <div className="absolute -top-2 inset-x-0 flex justify-between text-xs text-gray-500 dark:text-gray-400">
                    {[0, 1, 2, 3, 4, 5].map(num => (
                        <span key={num} className="w-4 text-center">{num}</span>
                    ))}
                </div>
                <div className="pt-4">
                    <CustomSlider
                        value={value}
                        onChange={onChange}
                        max={5}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default SkillSlider;
