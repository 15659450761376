import React, { useState } from "react";
import { AlertTriangle, Check, Clock, ChevronDown } from "lucide-react";
import Button from "../base/Button";

interface Test {
    test: string;
    description: string;
    available: string;
    amountOfQuestions: number | null;
    timeToCompleteMins: number | null;
    isAvailable: boolean;
    comingSoon: boolean;
    testLink?: string;
    testDetailsLink?: string;
}

interface TestGroup {
    testName: string;
    tests: Test[];
}

interface TestListProps {
    tableData: TestGroup[];
}

const TestList: React.FC<TestListProps> = ({ tableData }) => {
    const [openGroup, setOpenGroup] = useState<number | null>(0);
    const [openTest, setOpenTest] = useState<number>(0);

    const handleGroupClick = (index: number) => {
        if (openGroup === index) {
            setOpenGroup(null);
            setOpenTest(0);
        } else {
            setOpenGroup(index);
            setOpenTest(0);
        }
    };

    const handleTestClick = (index: number) => {
        setOpenTest(index);
    };

    return (
        <div className="space-y-1 ml-4 mt-8 mb-8 ">
            {tableData.map((data, groupIndex) => (
                <div key={groupIndex}>
                    <h2
                        className="text-xl mb-0 bg-sky-600 text-white p-4 rounded-t-lg flex justify-between items-center cursor-pointer transition-colors duration-300 ease-in-out hover:bg-sky-700"
                        onClick={() => handleGroupClick(groupIndex)}
                        aria-controls={`collapse-group-${groupIndex}`}
                        aria-expanded={openGroup === groupIndex}
                    >
                        {data.testName}
                        <ChevronDown
                            size={24}
                            className={`transform transition-transform duration-300 ease-in-out ${
                                openGroup === groupIndex ? 'rotate-180' : ''
                            }`}
                        />
                    </h2>
                    <div
                        className={`mt-0 overflow-hidden transition-all duration-300 ease-in-out ${
                            openGroup === groupIndex ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
                        }`}
                    >
                        {data.tests.map((test, testIndex) => (
                            <div
                                key={testIndex}
                                className={`p-4 rounded-lg cursor-pointer transition-colors duration-300 ease-in-out ${
                                    testIndex % 2 === 0 ? 'bg-gray-100 dark:bg-slate-800' : 'bg-white dark:bg-slate-700'
                                } ${openTest === testIndex ? 'shadow-md' : ''} hover:bg-gray-200 dark:hover:bg-slate-600`}
                                onClick={() => handleTestClick(testIndex)}
                            >
                                <h3 className="text-lg font-bold mb-2 flex justify-between items-center">
                                    {test.test}
                                    <span className="col-span-2 text-sm">
                                        <div className="flex items-center">
                                            <span className="font-semibold">Available: &nbsp;&nbsp;</span>
                                            {test.isAvailable ? (
                                                <>
                                                    <Check className="mr-2" color="green" size={16} />
                                                    <span className={"font-normal"}>{test.available}</span>
                                                </>
                                            ) : test.comingSoon ? (
                                                <>
                                                    <Clock className="mr-2" color="orange" size={16} />
                                                    <span className={"font-normal"}>{test.available}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <AlertTriangle className="mr-2" color="red" size={16} />
                                                    <span className={"font-normal"}>{test.available}</span>
                                                </>
                                            )}
                                        </div>
                                    </span>

                                    {data.tests.length > 1 ? (
                                        <ChevronDown
                                            size={20}
                                            className={`transform transition-transform duration-300 ease-in-out ${
                                                openTest === testIndex ? 'rotate-180' : ''
                                            }`}
                                        />
                                    ) : <>&nbsp;</>}
                                </h3>
                                <div
                                    className={`mt-5 overflow-hidden transition-all duration-300 ease-in-out ${
                                        openTest === testIndex ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                                    }`}
                                >
                                    <p className="mb-4">{test.description}</p>
                                    <div className="grid grid-cols-2 gap-2 text-sm mb-5">
                                        {test.amountOfQuestions !== null && (
                                            <div>
                                                <span className="font-semibold">Questions:</span> {test.amountOfQuestions}
                                            </div>
                                        )}
                                        {test.timeToCompleteMins !== null && (
                                            <div>
                                                <span className="font-semibold">Time:</span> {test.timeToCompleteMins} minutes
                                            </div>
                                        )}
                                    </div>

                                    {!localStorage.getItem("username") && test.testLink ? (
                                        <>
                                            <Button url={"/login"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white mr-5">Login</Button>
                                            {test.testDetailsLink && (
                                                <Button url={test.testDetailsLink} styles="max-w-full px-8 py-4 border border-[#0c66ee]">Test details</Button>
                                            )}
                                        </>
                                    ) : test.testLink && (
                                        <>
                                            <Button url={test.testLink} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white mr-5">Go to test</Button>
                                            {test.testDetailsLink && (
                                                <Button url={test.testDetailsLink} styles="max-w-full px-8 py-4 border border-[#0c66ee]">Test details</Button>
                                            )}
                                        </>)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TestList;