import { useState, useEffect, useRef } from "react";
import { Section } from "../types/Section";
import { Tool } from "../types/Tool";
import { PROMPT_SECTIONS } from "../constants/sections";

export type PromptType = 'component' | 'example' | 'template';

export interface PromptFormData {
    title: string;
    prompt: string;
    section: Section;
    labels: string[];
    toolId: string;
    type: PromptType;
}

interface PromptFormProps {
    formData: PromptFormData;
    onFormDataChange: (formData: PromptFormData) => void;
    tools: Tool[];
    isLoadingTools?: boolean;
}

// Template Editor Component for displaying template variables as badges
interface TemplateEditorProps {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    className: string;
    isDarkMode: boolean;
}

const TemplateEditor: React.FC<TemplateEditorProps> = ({
    value,
    onChange,
    placeholder,
    className,
    isDarkMode
}) => {
    const editorRef = useRef<HTMLTextAreaElement>(null);
    
    // Extract template variables from text
    const extractVariables = (text: string): string[] => {
        const matches = text.match(/\{\{([^{}]+)\}\}/g) || [];
        return matches.map(match => match.slice(2, -2).trim());
    };
    
    // Count the number of template variables
    const variables = extractVariables(value);
    
    // Handle textarea input changes
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };
    
    // Handle backspace to delete entire template variable
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const textarea = e.currentTarget;
        const cursorPos = textarea.selectionStart;
        const text = textarea.value;
        
        // If backspace and cursor is at a position right after a template variable
        if (e.key === 'Backspace' && cursorPos > 0) {
            const textBeforeCursor = text.substring(0, cursorPos);
            const match = textBeforeCursor.match(/\{\{[^{}]+\}\}$/); // Check if cursor is right after a template variable
            
            if (match) {
                e.preventDefault(); // Prevent default backspace behavior
                
                // Remove the entire template variable
                const matchStart = textBeforeCursor.length - match[0].length;
                const newValue = text.substring(0, matchStart) + text.substring(cursorPos);
                onChange(newValue);
                
                // Set caret position to where the variable started
                setTimeout(() => {
                    if (editorRef.current) {
                        editorRef.current.selectionStart = matchStart;
                        editorRef.current.selectionEnd = matchStart;
                    }
                }, 0);
            }
        }
    };
    
    return (
        <div className="template-editor-container relative">
            {/* Regular textarea */}
            <textarea
                ref={editorRef}
                className={className}
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                style={{ 
                    minHeight: '6rem',
                    width: '100%'
                }}
            />
            
            {/* Variable badges below textarea */}
            {variables.length > 0 && (
                <div className="template-variables-list mt-2 flex flex-wrap gap-2">
                    {variables.map((variable, index) => (
                        <div 
                            key={`${variable}-${index}`}
                            className="template-badge flex items-center gap-1.5 px-2.5 py-1.5 rounded-md text-xs font-medium hover:shadow-md hover:-translate-y-0.5 transition-all duration-200"
                            style={{
                                background: isDarkMode ? 'rgba(59, 130, 246, 0.15)' : 'rgba(59, 130, 246, 0.08)',
                                borderColor: isDarkMode ? 'rgba(59, 130, 246, 0.3)' : 'rgba(59, 130, 246, 0.2)',
                                color: isDarkMode ? '#60a5fa' : '#3b82f6',
                                border: '1px solid',
                                transition: 'all 0.2s ease',
                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                                transform: 'translateY(0)',
                            }}
                        >
                            <svg className="h-3.5 w-3.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                            </svg>
                            {variable}
                        </div>
                    ))}
                </div>
            )}
            
            {/* Variable counter badge */}
            {variables.length > 0 && (
                <div className="absolute -top-6 right-0 mt-1 mr-1 px-3 py-1 text-xs font-medium rounded-full text-white shadow-md bg-gradient-to-r from-blue-500 to-blue-600 transform transition-all duration-200 hover:scale-105 hover:shadow-lg">
                    <span className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                        </svg>
                        {variables.length} Variable{variables.length !== 1 ? 's' : ''}
                    </span>
                </div>
            )}
        </div>
    );
};

export const PromptForm = ({ 
    formData, 
    onFormDataChange,
    tools,
    isLoadingTools = false
}: PromptFormProps) => {
    const [newLabel, setNewLabel] = useState('');
    const [isDarkMode, setIsDarkMode] = useState(false);
    
    // Check if dark mode is enabled
    useEffect(() => {
        const checkDarkMode = () => {
            const isDark = document.getElementById('root')?.classList.contains('dark') || false;
            setIsDarkMode(isDark);
        };
        
        checkDarkMode();
        
        // Set up an observer to detect changes to the dark mode
        const observer = new MutationObserver(checkDarkMode);
        const rootElement = document.getElementById('root');
        
        if (rootElement) {
            observer.observe(rootElement, { attributes: true, attributeFilter: ['class'] });
        }
        
        return () => observer.disconnect();
    }, []);

    const addLabel = () => {
        const trimmedLabel = newLabel.trim().toLowerCase();
        if (trimmedLabel && !formData.labels.includes(trimmedLabel)) {
            onFormDataChange({
                ...formData,
                labels: [...formData.labels, trimmedLabel]
            });
            setNewLabel('');
        }
    };

    const handleLabelKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ((e.key === ' ' || e.key === ',' || e.key === 'Enter') && !newLabel.trim()) {
            e.preventDefault();
            return;
        }

        if (e.key === ' ' || e.key === ',' || e.key === 'Enter') {
            e.preventDefault();
            addLabel();
        }
    };

    const handleLabelBlur = () => {
        addLabel();
    };

    const removeLabel = (labelToRemove: string) => {
        onFormDataChange({
            ...formData,
            labels: formData.labels.filter(label => label !== labelToRemove)
        });
    };

    return (
        <div className="space-y-4">
            <div className="flex items-center space-x-3">
                <label htmlFor="type" className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Type
                </label>
                <div className="flex items-center gap-3">
                    <button
                        type="button"
                        onClick={() => onFormDataChange({ ...formData, type: 'component' })}
                        className={`px-3 py-1.5 text-sm font-medium rounded-md transition-all duration-200
                            ${formData.type === 'component'
                                ? isDarkMode ? 'bg-blue-900/30 text-blue-400' : 'bg-blue-100 text-blue-700'
                                : isDarkMode ? 'text-gray-400 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'
                            }`}
                    >
                        Component
                    </button>
                    <button
                        type="button"
                        onClick={() => onFormDataChange({ ...formData, type: 'example' })}
                        className={`px-3 py-1.5 text-sm font-medium rounded-md transition-all duration-200
                            ${formData.type === 'example'
                                ? isDarkMode ? 'bg-blue-900/30 text-blue-400' : 'bg-blue-100 text-blue-700'
                                : isDarkMode ? 'text-gray-400 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'
                            }`}
                    >
                        Example
                    </button>
                    <button
                        type="button"
                        onClick={() => onFormDataChange({ ...formData, type: 'template' })}
                        className={`px-3 py-1.5 text-sm font-medium rounded-md transition-all duration-200
                            ${formData.type === 'template'
                                ? isDarkMode ? 'bg-blue-900/30 text-blue-400' : 'bg-blue-100 text-blue-700'
                                : isDarkMode ? 'text-gray-400 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'
                            }`}
                    >
                        Template
                    </button>
                </div>
            </div>

            <div className="space-y-3">
                <div>
                    <label className={`block text-sm font-medium mb-1.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        Title
                    </label>
                    <input
                        type="text"
                        value={formData.title}
                        onChange={e => onFormDataChange({ ...formData, title: e.target.value })}
                        className={`w-full px-3 py-2 rounded-lg border text-sm focus:border-transparent transition-all duration-200 focus:ring-2 ${isDarkMode ? 'border-gray-700 bg-gray-800 text-white focus:ring-blue-400' : 'border-gray-200 bg-white text-gray-900 focus:ring-blue-500'}`}
                        placeholder="Enter prompt title..."
                        required
                    />
                </div>

                <div>
                    <label className={`block text-sm font-medium mb-1.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        Prompt ({formData.type === 'component' ? 'Component' : formData.type === 'example' ? 'Example' : 'Template'})
                    </label>
                    {formData.type === 'template' ? (
                        <TemplateEditor 
                            value={formData.prompt}
                            onChange={value => onFormDataChange({ ...formData, prompt: value })}
                            placeholder="Add template variable in {{description}}"
                            className={`w-full px-3 py-2 rounded-lg border text-sm h-24 focus:border-transparent transition-all duration-200 focus:ring-2 ${isDarkMode ? 'border-gray-700 bg-gray-800 text-white focus:ring-blue-400' : 'border-gray-200 bg-white text-gray-900 focus:ring-blue-500'}`}
                            isDarkMode={isDarkMode}
                        />
                    ) : (
                        <textarea
                            value={formData.prompt}
                            onChange={e => onFormDataChange({ ...formData, prompt: e.target.value })}
                            className={`w-full px-3 py-2 rounded-lg border text-sm h-24 focus:border-transparent transition-all duration-200 focus:ring-2 ${isDarkMode ? 'border-gray-700 bg-gray-800 text-white focus:ring-blue-400' : 'border-gray-200 bg-white text-gray-900 focus:ring-blue-500'}`}
                            placeholder="Enter prompt content..."
                            required
                        />
                    )}
                </div>

                <div>
                    <label className={`block text-sm font-medium mb-1.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        Section
                    </label>
                    <select
                        value={formData.section}
                        onChange={e => onFormDataChange({ ...formData, section: e.target.value as Section })}
                        className={`w-full px-3 py-2 rounded-lg border text-sm focus:border-transparent transition-all duration-200 focus:ring-2 ${isDarkMode ? 'border-gray-700 bg-gray-800 text-white focus:ring-blue-400' : 'border-gray-200 bg-white text-gray-900 focus:ring-blue-500'}`}
                    >
                        {PROMPT_SECTIONS.map(section => (
                            <option key={section} value={section}>
                                {section}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label className={`block text-sm font-medium mb-1.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        Labels <span className="text-xs font-light">(optional)</span>
                    </label>
                    <div className="space-y-2">
                        <div className="flex flex-wrap gap-2">
                            {formData.labels.map(label => (
                                <span
                                    key={label}
                                    className={`inline-flex items-center px-2.5 py-1 rounded-full text-sm
                                        ${isDarkMode ? 'bg-blue-900/30 text-blue-400' : 'bg-blue-100 text-blue-700'}`}
                                >
                                    {label}
                                    <button
                                        type="button"
                                        onClick={() => removeLabel(label)}
                                        className={`ml-1.5 ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-800'}`}
                                    >
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </span>
                            ))}
                        </div>
                        <input
                            type="text"
                            value={newLabel}
                            onChange={e => setNewLabel(e.target.value)}
                            onKeyDown={handleLabelKeyDown}
                            onBlur={handleLabelBlur}
                            className={`w-full px-3 py-2 rounded-lg border text-sm focus:border-transparent transition-all duration-200 focus:ring-2 ${isDarkMode ? 'border-gray-700 bg-gray-800 text-white focus:ring-blue-400' : 'border-gray-200 bg-white text-gray-900 focus:ring-blue-500'}`}
                            placeholder="Type and press Enter to add labels..."
                        />
                    </div>
                </div>

                <div>
                    <label className={`block text-sm font-medium mb-1.5 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        Tool <span className="text-xs font-light">(optional)</span>
                    </label>
                    <select
                        value={formData.toolId}
                        onChange={e => onFormDataChange({ ...formData, toolId: e.target.value })}
                        className={`w-full px-3 py-2 rounded-lg border text-sm focus:border-transparent transition-all duration-200 focus:ring-2 ${isDarkMode ? 'border-gray-700 bg-gray-800 text-white focus:ring-blue-400' : 'border-gray-200 bg-white text-gray-900 focus:ring-blue-500'}`}
                        disabled={isLoadingTools}
                    >
                        <option value="">No Tool</option>
                        {tools.map((tool) => (
                            <option key={tool.id} value={tool.id}>
                                {tool.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};
