import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface TemplateValue {
    placeholder: string;
    value: string;
}

interface TemplateValuesModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (filledTemplate: string) => void;
    templateText: string;
}

export const TemplateValuesModal = ({ isOpen, onClose, onSubmit, templateText }: TemplateValuesModalProps) => {
    const [templateValues, setTemplateValues] = useState<TemplateValue[]>([]);
    const [isDarkMode, setIsDarkMode] = useState(false);
    
    // Extract template placeholders when the modal opens or template changes
    useEffect(() => {
        if (isOpen && templateText) {
            // Find all placeholders in the format {{any text here}} - supports single words, multi-word phrases, or any text between double curly braces
            const placeholderRegex = /\{\{([^{}]+)\}\}/g;
            const matches: RegExpMatchArray[] = [];
            let match: RegExpMatchArray | null;
            
            // Manually collect all matches
            while ((match = placeholderRegex.exec(templateText)) !== null) {
                matches.push(match);
            }
            
            // Create unique list of placeholders and trim any whitespace
            const uniquePlaceholders = Array.from(new Set(matches.map(match => match[1].trim())));
            
            // Create template values array
            const values = uniquePlaceholders.map(placeholder => ({
                placeholder,
                value: ''
            }));
            
            setTemplateValues(values);
        }
    }, [isOpen, templateText]);
    
    // Check if dark mode is enabled
    useEffect(() => {
        const checkDarkMode = () => {
            const isDark = document.getElementById('root')?.classList.contains('dark') || false;
            setIsDarkMode(isDark);
        };
        
        checkDarkMode();
        
        // Set up an observer to detect changes to the dark mode
        const observer = new MutationObserver(checkDarkMode);
        const rootElement = document.getElementById('root');
        
        if (rootElement) {
            observer.observe(rootElement, { attributes: true, attributeFilter: ['class'] });
        }
        
        return () => observer.disconnect();
    }, [isOpen]);
    
    const handleValueChange = (index: number, value: string) => {
        const newValues = [...templateValues];
        newValues[index].value = value;
        setTemplateValues(newValues);
    };
    
    const handleSubmit = (keepTemplates: boolean = false) => {
        let filledTemplate = templateText;
        
        if (!keepTemplates) {
            // Replace all placeholders with their values
            templateValues.forEach(({ placeholder, value }) => {
                const regex = new RegExp(`\{\{${placeholder}\}\}`, 'g');
                filledTemplate = filledTemplate.replace(regex, value || `{{${placeholder}}}`);
            });
        }
        
        onSubmit(filledTemplate);
        onClose();
    };
    
    const handleKeepTemplates = () => {
        handleSubmit(true);
    };
    
    const allFieldsFilled = templateValues.every(({ value }) => value.trim() !== '');
    
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={`w-full max-w-md transform overflow-hidden rounded-2xl p-7 text-left align-middle shadow-xl transition-all backdrop-blur-sm ring-1 ${isDarkMode ? 'bg-gradient-to-br from-gray-800/95 to-gray-900/95 ring-white/10 text-white' : 'bg-gradient-to-br from-white/95 to-gray-50/95 ring-black/5 text-gray-900'}`}>
                                <Dialog.Title
                                    as="h3"
                                    className={`text-xl font-semibold leading-6 mb-5 ${isDarkMode ? 'text-white' : 'text-gray-900'} border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pb-3`}
                                >
                                    Fill in Template Values
                                </Dialog.Title>
                                
                                {templateValues.length > 0 ? (
                                    <div className="mt-6 space-y-5">
                                        {templateValues.map((template, index) => (
                                            <div key={index} className="space-y-2">
                                                <label 
                                                    htmlFor={`template-${index}`} 
                                                    className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-1.5`}
                                                >
                                                    {template.placeholder}
                                                </label>
                                                <div className={`relative rounded-lg shadow-sm ${isDarkMode ? 'shadow-gray-900/30' : 'shadow-gray-200/60'}`}>
                                                    <input
                                                        type="text"
                                                        id={`template-${index}`}
                                                        value={template.value}
                                                        onChange={(e) => handleValueChange(index, e.target.value)}
                                                        className={`w-full px-4 py-2.5 rounded-lg border focus:ring-2 focus:outline-none transition-all duration-200 ${
                                                            isDarkMode 
                                                                ? 'border-gray-700 bg-gray-800/80 text-white focus:ring-blue-500/70 focus:border-blue-600' 
                                                                : 'border-gray-300 bg-white text-gray-900 focus:ring-blue-500/50 focus:border-blue-400'
                                                        }`}
                                                        placeholder={`Enter value for ${template.placeholder}`}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className={`mt-4 p-4 rounded-lg ${isDarkMode ? 'bg-gray-700/50 text-gray-300' : 'bg-gray-50 text-gray-500'}`}>
                                        <p className="text-sm flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            No template placeholders found in this prompt.
                                        </p>
                                    </div>
                                )}

                                <div className="mt-8 flex flex-wrap justify-end gap-4">
                                    <button
                                        type="button"
                                        onClick={onClose}
                                        className={`px-5 py-2.5 text-sm font-medium rounded-lg transition-all duration-200 border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isDarkMode ? 'text-gray-300 border-gray-700 hover:bg-gray-700/50 focus:ring-offset-gray-800' : 'text-gray-700 border-gray-200 hover:bg-gray-50 hover:border-gray-300'}`}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleSubmit(false)}
                                        disabled={templateValues.length === 0}
                                        className={`px-5 py-2.5 text-sm font-medium text-white rounded-lg transition-all duration-200 shadow-sm hover:shadow transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                                            templateValues.length === 0
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : isDarkMode
                                                    ? 'bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 focus:ring-offset-gray-800'
                                                    : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700'
                                        }`}
                                    >
                                        {allFieldsFilled ? 'Copy Filled Template' : 'Copy with Placeholders'}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
