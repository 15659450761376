import html2canvas from 'html2canvas';

/**
 * Utility function to download the skill radar chart as an image
 * 
 * @param {Object} user - The user object containing name and other details
 * @returns {Function} - A function that when called will generate and download the skill matrix image
 */
export const createSkillMatrixDownloader = (user) => {
    return () => {
        // Ensure styles are applied before creating the export container
        addSkillMatrixStyles();
        
        // Create a special container for export that we'll remove later
        const exportContainer = document.createElement('div');
        exportContainer.id = 'skill-export-container';
        exportContainer.className = 'skill-export-container';
        document.body.appendChild(exportContainer);
        
        // Force a reflow to ensure styles are applied
        void exportContainer.offsetHeight;
        
        // Clone the radar chart
        const originalChart = document.getElementById('skill-radar-container');
        if (!originalChart) {
            console.error('Could not find the skill radar container');
            document.body.removeChild(exportContainer);
            return;
        }
        
        // Set dark mode class if needed
        if (document.documentElement.classList.contains('dark')) {
            exportContainer.classList.add('dark');
        }
        
        // Create export content with improved styling
        exportContainer.innerHTML = `
            <div class="export-content">
                <div class="export-header">
                    <div class="export-logo">
                        <img src="/crafting-ai-logo.svg" alt="Crafting AI Logo" class="logo-icon" />
                    </div>
                    <h2>Skill Self Assessment - Crafting AI Prompts</h2>
                </div>
                
                <div class="export-chart">
                    ${originalChart.querySelector('.recharts-wrapper').outerHTML}
                </div>
                
                <div class="export-footer">
                    <div class="export-legend">
                        <div class="legend-dot"></div>
                        <span>Self Assessment: ${user?.name || 'User'}</span>
                    </div>
                    <div class="export-date">${new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}. craftingaiprompts.org</div>
                </div>
            </div>
        `;
        
        // Small delay to ensure styles are applied
        setTimeout(() => {
            // Render the chart to image
            html2canvas(exportContainer, {
                backgroundColor: document.documentElement.classList.contains('dark') ? '#1e293b' : '#ffffff',
                scale: 3, // Higher resolution for better quality
                logging: false,
                useCORS: true,
                allowTaint: true
            }).then(canvas => {
                // Create a download link
                const link = document.createElement('a');
                link.download = `skill-matrix-${new Date().toISOString().split('T')[0]}.png`;
                link.href = canvas.toDataURL('image/png');
                link.click();
                
                // Clean up
                document.body.removeChild(exportContainer);
            }).catch(err => {
                console.error('Error generating skill matrix image:', err);
                // Clean up on error
                document.body.removeChild(exportContainer);
            });
        }, 100); // Small delay to ensure styles are applied
    };
};

/**
 * Add necessary CSS styles for the skill matrix download
 * This function should be called once when the component mounts
 */
export const addSkillMatrixStyles = () => {
    // Check if styles already exist and remove if they exist (to ensure fresh styles)
    const existingStyles = document.getElementById('skill-matrix-styles');
    if (existingStyles) {
        document.head.removeChild(existingStyles);
    }
    
    // Create style element
    const styleElement = document.createElement('style');
    styleElement.id = 'skill-matrix-styles';
    
    // Add styles for the export container
    styleElement.innerHTML = `
    .skill-export-container {
        position: fixed;
        top: -9999px;
        left: -9999px;
        width: 1000px;
        height: 800px;
        z-index: -1;
        overflow: hidden;
        background-color: #ffffff;
    }
    
    .dark .skill-export-container {
        background-color: #1e293b;
    }
    
    .export-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 40px;
        box-sizing: border-box;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    }
    
    .export-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-bottom: 2px solid #e5e7eb;
    }
    
    .dark .export-header {
        border-bottom-color: #374151;
    }
    
    .export-logo {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }
    
    .export-header h2 {
        font-size: 28px;
        font-weight: 700;
        margin: -20px 0 0;
        color: #0c66ee;
        flex: 1;
    }
    
    .export-chart {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        position: relative;
    }
    
    .export-chart::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba(70, 142, 249, 0.05), rgba(12, 102, 238, 0.05));
        border-radius: 16px;
        z-index: -1;
    }
    
    .dark .export-chart::before {
        background: linear-gradient(135deg, rgba(70, 142, 249, 0.1), rgba(12, 102, 238, 0.1));
    }
    
    .export-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        margin-top: 12px;
        border-top: 1px solid #e5e7eb;
    }
    
    .dark .export-footer {
        border-top-color: #374151;
    }
    
    .export-legend {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 9999px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        font-size: 14px;
        color: #4b5563;
    }
    
    .dark .export-legend {
        background: rgba(31, 41, 55, 0.8);
        color: #d1d5db;
    }
    
    .legend-dot {
        width: 12px;
        height: 12px;
        margin-top:10px;
        border-radius: 50%;
        background: linear-gradient(to right, #468ef9, #0c66ee);
    }
    
    .export-date {
        font-size: 14px;
        color: #6b7280;
        font-style: italic;
    }
    
    .dark .export-date {
        color: #9ca3af;
    }
    
    /* Ensure the chart looks good */
    .export-chart .recharts-wrapper {
        margin: 0 auto;
    }
    
    .export-chart .recharts-surface {
        overflow: visible;
    }
`;
    
    // Add styles to document head
    document.head.appendChild(styleElement);
};

export default {
    createSkillMatrixDownloader,
    addSkillMatrixStyles
};
