import React, { useState } from 'react';
import { Section } from './types/Section';

interface ChatboxNewPromptProps {
    onOpenModal: (section: Section) => void;
}

export const ChatboxNewPrompt: React.FC<ChatboxNewPromptProps> = ({ onOpenModal }) => {
    const [isOpen, setIsOpen] = useState(false);
    const sections: Section[] = ["Context", "Register", "Acting Role", "Format", "Task"];

    const toggleChatbox = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="fixed bottom-3 right-[5.5rem] z-50 p-4 flex flex-col items-end">
            {/* Chatbox */}
            {isOpen && (
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-4 w-64 overflow-hidden border border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out">
                    <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-gray-700">
                        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                            New Prompt
                        </h2>
                    </div>
                    <div className="p-4 max-h-[70vh] overflow-y-auto">
                        <div className="flex flex-col space-y-2">
                            {sections.map((section) => (
                                <button
                                    key={section}
                                    onClick={() => {
                                        onOpenModal(section);
                                        setIsOpen(false);
                                    }}
                                    className="w-full text-left px-4 py-2 rounded-md transition-all duration-200 
                                    bg-gradient-to-r from-blue-50 to-purple-50 hover:from-blue-100 hover:to-purple-100 
                                    dark:from-blue-900/20 dark:to-purple-900/20 dark:hover:from-blue-900/30 dark:hover:to-purple-900/30
                                    text-gray-800 dark:text-white border border-gray-200 dark:border-gray-700 hover:shadow-md"
                                >
                                    {section}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Toggle Button */}
            <button
                onClick={toggleChatbox}
                className={`flex items-center justify-center p-3 rounded-full shadow-lg transition-all duration-300
                ${isOpen 
                    ? 'bg-red-500 hover:bg-red-600 text-white' 
                    : 'bg-gradient-to-r from-green-500 to-teal-600 hover:from-green-600 hover:to-teal-700 text-white'
                }`}
                title={isOpen ? "Close New Prompt" : "Create New Prompt"}
            >
                {isOpen ? (
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                ) : (
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                )}
            </button>
        </div>
    );
};
