import React, { Fragment, useState } from 'react';
import { Menu, MenuButton, MenuItems, MenuItem, Transition } from '@headlessui/react';
import { Recipe } from './types/Recipe';
import { ViewMode } from './types/ViewMode';
import { formatTextWithVariables } from '../common/FormatTextWithVariables';

interface PromptRecipeCardProps {
    recipes: Recipe[];
    viewMode: ViewMode;
    copiedId: string | null;
    handleCopy: (prompt: string, id: string) => void;
    handleLoadRecipe: (recipe: Recipe) => void;
    toggleRecipePublish: (recipeId: string) => void;
    handleDeleteRecipe: (recipeId: string) => void;
}

const ModelParamBadge: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
    <div className="inline-flex items-center gap-1.5 px-2 py-1 bg-gray-50 dark:bg-gray-700/50 rounded-md">
        <span className="text-xs font-medium text-gray-500 dark:text-gray-400">{label}:</span>
        <span className="text-xs font-semibold text-gray-700 dark:text-gray-300">{value}</span>
    </div>
);

export const PromptRecipeCard: React.FC<PromptRecipeCardProps> = ({
    recipes,
    viewMode,
    copiedId,
    handleCopy,
    handleLoadRecipe,
    toggleRecipePublish,
    handleDeleteRecipe
}) => {
    const [expandedParams, setExpandedParams] = useState<string[]>([]);
    const [expandedPrompts, setExpandedPrompts] = useState<string[]>([]);



    const toggleParams = (recipeId: string) => {
        setExpandedParams(prev => 
            prev.includes(recipeId) 
                ? prev.filter(id => id !== recipeId)
                : [...prev, recipeId]
        );
    };

    const togglePrompts = (recipeId: string) => {
        setExpandedPrompts(prev => 
            prev.includes(recipeId) 
                ? prev.filter(id => id !== recipeId)
                : [...prev, recipeId]
        );
    };

    if (viewMode === 'table') {
        return (
            <div className="w-full">
                <table className="w-full text-sm text-left">
                    <thead className="text-xs text-gray-700 dark:text-gray-300 bg-gray-50 dark:bg-gray-800/50">
                        <tr>
                            <th className="px-4 py-3">Title</th>
                            <th className="px-4 py-3">Description</th>
                            <th className="px-4 py-3">Prompts</th>
                            <th className="px-4 py-3">Status</th>
                            <th className="px-4 py-3">Created</th>
                            <th className="px-4 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 dark:divide-gray-700">
                        {recipes.map(recipe => (
                            <tr key={recipe.id} className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                                <td className="px-4 py-3 font-medium text-gray-900 dark:text-white">
                                    <div className="truncate max-w-[200px]" title={recipe.title}>
                                        {recipe.title}
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-gray-600 dark:text-gray-300">
                                    {recipe.description}
                                </td>
                                <td className="px-4 py-3 text-gray-600 dark:text-gray-300">
                                    {recipe.prompts.length} prompts
                                </td>
                                <td className="px-4 py-3">
                                    <span className={`inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium
                                        ${recipe.isPublished 
                                            ? 'bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400'
                                            : 'bg-gray-100 text-gray-600 dark:bg-gray-700 dark:text-gray-400'
                                        }`}
                                    >
                                        <span className={`w-1.5 h-1.5 rounded-full
                                            ${recipe.isPublished ? 'bg-green-500' : 'bg-gray-500'}`} 
                                        />
                                        {recipe.isPublished ? 'Published' : 'Draft'}
                                    </span>
                                </td>
                                <td className="px-4 py-3 text-gray-600 dark:text-gray-300">
                                    {new Date(recipe.createdAt).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-3">
                                    <div className="flex items-center gap-2">
                                        <button
                                            onClick={() => handleCopy(recipe.prompts.map(p => `<${p.section}>${p.prompt}</${p.section}>`).join('\n\n'), recipe.id)}
                                            className={`p-1 ${copiedId === recipe.id ? 'text-green-600 dark:text-green-400' : 'text-blue-600 hover:text-blue-700 dark:text-blue-400'}`}
                                            title="Copy Recipe"
                                        >
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                {copiedId === recipe.id ? (
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                ) : (
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                                                )}
                                            </svg>
                                        </button>
                                        {recipe.is_owner && (
                                            <>
                                            <button
                                                onClick={() => handleLoadRecipe(recipe)}
                                                className="p-1 text-blue-600 hover:text-blue-700 dark:text-blue-400"
                                                title="Load Recipe"
                                            >
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" 
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() => toggleRecipePublish(recipe.id)}
                                            className={`p-1 ${recipe.isPublished ? 'text-green-600 hover:text-green-700 dark:text-green-400' : 'text-gray-600 hover:text-gray-700 dark:text-gray-400'}`}
                                            title={recipe.isPublished ? "Unpublish Recipe" : "Publish Recipe"}
                                        >
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                    d="M5 13l4 4L19 7" 
                                                />
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() => handleDeleteRecipe(recipe.id)}
                                            className="p-1 text-red-600 hover:text-red-700 dark:text-red-400"
                                            title="Delete Recipe"
                                        >
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                                                />
                                            </svg>
                                        </button>
                                        </>)}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4">
            {recipes.map(recipe => (
                <div 
                    key={recipe.id}
                    className="group bg-white dark:bg-gray-800 rounded-xl shadow-sm
                        border border-gray-100 dark:border-gray-700
                        hover:shadow-lg hover:border-blue-200 dark:hover:border-blue-800
                        transition-all duration-300 relative overflow-hidden"
                    style={{
                        backgroundImage: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.05))'
                    }}
                >
                    {/* Decorative gradient element */}
                    <div className="absolute -top-10 -right-10 w-20 h-20 bg-gradient-to-br from-blue-400/10 to-purple-500/10 rounded-full blur-xl transform transition-all duration-500 ease-in-out group-hover:scale-150 group-hover:opacity-70"></div>
                    {/* Card Header */}
                    <div className="p-6 pb-4">
                        {/* Status Badge - Positioned at top */}
                        <div className="flex justify-between items-start mb-4">
                            <span className={`inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium
                                ${recipe.isPublished 
                                    ? 'bg-green-50 text-green-600 dark:bg-green-900/20 dark:text-green-400'
                                    : 'bg-gray-100 text-gray-600 dark:bg-gray-700 dark:text-gray-400'
                                }`}
                            >
                                <span className={`w-1.5 h-1.5 rounded-full ${recipe.isPublished ? 'bg-green-500' : 'bg-gray-500'}`}/>
                                {recipe.isPublished ? 'Published' : 'Draft'}
                            </span>


                            <button
                                onClick={() => handleCopy(recipe.prompts.map(p => `<${p.section}>${p.prompt}</${p.section}>`).join('\n\n'), `recipe_${recipe.id}`)}
                                className={`flex items-center justify-center gap-1 px-3 py-1.5 -mt-1 rounded-full text-xs font-medium
                                    transition-all duration-300 w-1/3 shadow-sm hover:shadow transform hover:scale-105
                                    ${copiedId === `recipe_${recipe.id}`
                                        ? 'bg-gradient-to-r from-green-500 to-green-600 text-white dark:from-green-600 dark:to-green-700'
                                        : 'bg-gradient-to-r from-blue-500 to-blue-600 text-white dark:from-blue-600 dark:to-blue-700 hover:from-blue-600 hover:to-blue-700 dark:hover:from-blue-500 dark:hover:to-blue-600'
                                    }`}
                            >
                                <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    {copiedId === `recipe_${recipe.id}` ? (
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                    ) : (
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                                    )}
                                </svg> <span className='ml-1'>{copiedId === `recipe_${recipe.id}` ? 'Copied!' : 'Copy'}</span>
                            </button>

                            {/* Menu Dropdown */}
                            {recipe.is_owner && (
                            <Menu as="div" className="relative -mt-1">
                                <MenuButton className="opacity-40 group-hover:opacity-100 p-1.5 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg
                                    text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300
                                    transition-all duration-200 ease-in-out">
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                        />
                                    </svg>
                                </MenuButton>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <MenuItems 
                                        static
                                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-lg
                                            bg-white dark:bg-gray-800 shadow-lg border border-gray-100 dark:border-gray-700
                                            focus:outline-none"
                                    >
                                        <div className="p-1">
                                            <MenuItem>
                                                {({ active, close }) => (
                                                    <button
                                                        onClick={() => {
                                                            toggleRecipePublish(recipe.id);
                                                            close();
                                                        }}
                                                        className={`${
                                                            active ? 'bg-gray-50 dark:bg-gray-700/50' : ''
                                                        } group flex w-full items-center rounded-md px-3 py-2 text-sm
                                                            ${recipe.isPublished
                                                                ? 'text-orange-600 dark:text-orange-400'
                                                                : 'text-green-600 dark:text-green-400'
                                                            }`}
                                                    >
                                                        {recipe.isPublished ? (
                                                            <>
                                                                <svg className="w-4 h-4 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                                        d="M18 9l-7 7-7-7" 
                                                                    />
                                                                </svg>
                                                                Unpublish
                                                            </>
                                                        ) : (
                                                            <>
                                                                <svg className="w-4 h-4 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                                        d="M5 13l4 4L19 7" 
                                                                    />
                                                                </svg>
                                                                Publish
                                                            </>
                                                        )}
                                                    </button>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({ active, close }) => (
                                                    <button
                                                        onClick={() => {
                                                            handleLoadRecipe(recipe);
                                                            close();
                                                        }}
                                                        className={`${
                                                            active ? 'bg-gray-50 dark:bg-gray-700/50' : ''
                                                        } group flex w-full items-center rounded-md px-3 py-2 text-sm text-blue-600 dark:text-blue-400`}
                                                    >
                                                        <svg className="w-4 h-4 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" 
                                                            />
                                                        </svg>
                                                        Load Recipe
                                                    </button>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({ active, close }) => (
                                                    <button
                                                        onClick={() => {
                                                            handleDeleteRecipe(recipe.id);
                                                            close();
                                                        }}
                                                        className={`${
                                                            active ? 'bg-gray-50 dark:bg-gray-700/50' : ''
                                                        } group flex w-full items-center rounded-md px-3 py-2 text-sm text-red-600 dark:text-red-400`}
                                                    >
                                                        <svg className="w-4 h-4 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                                                            />
                                                        </svg>
                                                        Delete
                                                    </button>
                                                )}
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </Transition>
                            </Menu>
                            )}
                        </div>

                        {/* Title and Date */}
                        <div className="min-w-0">
                            <h3 className="text-lg font-medium text-gray-900 dark:text-white truncate" title={recipe.title}>
                                {recipe.title}
                            </h3>
                            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                                Created {new Date(recipe.createdAt).toLocaleDateString()}
                            </p>
                            {/* Description */}
                        <p className="text-sm text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
                            {recipe.description}
                        </p>
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="h-px bg-gray-100 dark:bg-gray-700" />

                    {/* Card Content */}
                    <div className="p-6 pt-4">
                    {/* Prompts List */}
                        <div className="space-y-3">
                            <button
                                onClick={() => togglePrompts(recipe.id)}
                                className="w-full flex items-center justify-between text-xs font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300
                                    transition-colors duration-200"
                            >
                                <span className="flex items-center gap-2">
                                    <span>Included Prompts</span>
                                    {!expandedPrompts.includes(recipe.id) && (
                                        <span className="px-1.5 py-0.5 rounded-full text-xs bg-gray-100 dark:bg-gray-700">
                                            {recipe.prompts.length}
                                        </span>
                                    )}
                                </span>
                                <svg 
                                    className={`w-4 h-4 transition-transform duration-200 ${
                                        expandedPrompts.includes(recipe.id) ? 'transform rotate-180' : ''
                                    }`}
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>
                            <div className={`
                                grid transition-all duration-200 ease-in-out
                                ${expandedPrompts.includes(recipe.id) 
                                    ? 'grid-rows-[1fr] opacity-100' 
                                    : 'grid-rows-[0fr] opacity-0'}`}
                            >
                                <div className="overflow-hidden">
                                    <div className="space-y-2">
                                        {recipe.prompts.map(prompt => (
                                            <div 
                                                key={prompt.id}
                                                className="flex items-start gap-2 text-sm text-gray-600 dark:text-gray-300 rounded-lg
                                                    bg-gray-50 dark:bg-gray-700/50 p-2"
                                            >
                                                <span className={`w-2 h-2 rounded-full mt-1.5 flex-shrink-0
                                                    ${prompt.section === 'Context' ? 'bg-red-500' : ''}
                                                    ${prompt.section === 'Register' ? 'bg-purple-500' : ''}
                                                    ${prompt.section === 'Acting Role' ? 'bg-green-500' : ''}
                                                    ${prompt.section === 'Format' ? 'bg-yellow-500' : ''}
                                                    ${prompt.section === 'Task' ? 'bg-blue-500' : ''}
                                                `}/>
                                                <div className="min-w-0 flex-1">
                                                    <div className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-0.5">
                                                        {prompt.section}
                                                    </div>
                                                    <div className="text-sm text-gray-600 dark:text-gray-300 break-words">
                                                        {formatTextWithVariables(prompt.prompt)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Model Parameters */}
                        {Object.keys(recipe.modelParams).length > 0 && (
                            <div className="space-y-2">
                                <button
                                    onClick={() => toggleParams(recipe.id)}
                                    className="w-full flex items-center justify-between text-xs font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300
                                        transition-colors duration-200"
                                >
                                    <span className="flex items-center gap-2">
                                        <span>Model Parameters</span>
                                        {!expandedParams.includes(recipe.id) && (
                                            <span className="px-1.5 py-0.5 rounded-full text-xs bg-gray-100 dark:bg-gray-700">
                                                {Object.keys(recipe.modelParams).length}
                                            </span>
                                        )}
                                    </span>
                                    <svg 
                                        className={`w-4 h-4 transition-transform duration-200 ${
                                            expandedParams.includes(recipe.id) ? 'transform rotate-180' : ''
                                        }`}
                                        fill="none" 
                                        stroke="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                <div className={`
                                    grid transition-all duration-200 ease-in-out
                                    ${expandedParams.includes(recipe.id) 
                                        ? 'grid-rows-[1fr] opacity-100' 
                                        : 'grid-rows-[0fr] opacity-0'}`}
                                >
                                    <div className="overflow-hidden">
                                        <div className="flex flex-wrap gap-2">
                                            {recipe.modelParams.model && (
                                                <ModelParamBadge label="Model" value={recipe.modelParams.model} />
                                            )}
                                            {recipe.modelParams.responseFormat && (
                                                <ModelParamBadge label="Format" value={recipe.modelParams.responseFormat} />
                                            )}
                                            {typeof recipe.modelParams.temperature === 'number' && (
                                                <ModelParamBadge label="Temp" value={recipe.modelParams.temperature.toFixed(1)} />
                                            )}
                                            {typeof recipe.modelParams.maxTokens === 'number' && (
                                                <ModelParamBadge label="Max Tokens" value={recipe.modelParams.maxTokens} />
                                            )}
                                            {typeof recipe.modelParams.topP === 'number' && (
                                                <ModelParamBadge label="Top P" value={recipe.modelParams.topP.toFixed(1)} />
                                            )}
                                            {typeof recipe.modelParams.frequencyPenalty === 'number' && (
                                                <ModelParamBadge label="Freq Penalty" value={recipe.modelParams.frequencyPenalty.toFixed(1)} />
                                            )}
                                            {typeof recipe.modelParams.presencePenalty === 'number' && (
                                                <ModelParamBadge label="Pres Penalty" value={recipe.modelParams.presencePenalty.toFixed(1)} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};