import React from 'react';

const DashboardCard = ({ children, className = "", title, action, boxPadding = true }) => (
    <div className={`bg-white dark:bg-gray-800 overflow-hidden rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 ${className}`}>
        {title && (
            <div className="px-6 py-4 border-b border-gray-100 dark:border-gray-700 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{title}</h2>
                {action && (
                    <div className="flex items-center gap-3">{action}</div>
                )}
            </div>
        )}
        <div className={boxPadding ? "p-6" : ""}>
            <div className="relative">{children}</div>
        </div>
    </div>
);

export default DashboardCard;