import React, { useState, useEffect, useMemo } from 'react';
import {
    MessageCircle,
    XCircle,
    Search,
    Users,
    Filter,
    Clock,
    Tag,
    AlertCircle
} from 'lucide-react';
import { Button } from "@headlessui/react";
import { Link } from "react-router-dom";
import TicketStatusEnum from "../../../components/enums/TicketStatusEnum";
import StatusLabel from '../../../components/dashboard/StatusLabel';
import Pagination from '../../../components/dashboard/dashboard/Pagination';

const TicketList = ({ tickets = [], replyToTicket, closeTicket, ticketsPerPage = 9 }) => {
    const [ticketSortField, setTicketSortField] = useState('status');
    const [ticketSortDirection, setTicketSortDirection] = useState('asc');
    const [ticketCurrentPage, setTicketCurrentPage] = useState(1);
    const [showClosedTickets, setShowClosedTickets] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    // Add helper function for date check
    const isWithinLastThreeDays = (dateString) => {
        const date = new Date(dateString);
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        return date >= threeDaysAgo;
    };

    // Reset pagination when filters change
    useEffect(() => {
        setTicketCurrentPage(1);
    }, [showClosedTickets, searchQuery]);

    // First sort the complete list
    const sortedTickets = useMemo(() => {
        if (!Array.isArray(tickets) || !tickets.length) return [];
        
        return [...tickets].sort((a, b) => {
            if (ticketSortField === 'status') {
                // Sort by status first, then by ID for consistent ordering
                const aStatus = Number(a?.status) || 0;
                const bStatus = Number(b?.status) || 0;
                if (aStatus !== bStatus) {
                    return ticketSortDirection === 'asc' ? aStatus - bStatus : bStatus - aStatus;
                }
                // If statuses are equal, sort by ID
                return ticketSortDirection === 'asc' ? Number(a.id) - Number(b.id) : Number(b.id) - Number(a.id);
            }

            // For other fields
            const aValue = a[ticketSortField];
            const bValue = b[ticketSortField];
            
            if (ticketSortField === 'status') {
                // For status, we want open tickets (status 0) to appear before closed tickets (status 1)
                // regardless of sort direction
                const statusOrder = ticketSortDirection === 'asc' ? [0, 1] : [1, 0];
                const aIndex = statusOrder.indexOf(a.status);
                const bIndex = statusOrder.indexOf(b.status);
                
                if (aIndex !== bIndex) {
                    return aIndex - bIndex;
                }
                // If statuses are equal, sort by ID (newest first)
                return Number(b.id) - Number(a.id);
            }
            
            // String comparison for non-numeric fields
            return ticketSortDirection === 'asc' 
                ? String(aValue).localeCompare(String(bValue))
                : String(bValue).localeCompare(String(aValue));
        });
    }, [tickets, ticketSortField, ticketSortDirection]);

    // Then filter by status and search
    const filteredAndSearchedTickets = useMemo(() => {
        if (!Array.isArray(sortedTickets)) return [];
        
        // First filter by status (Closed = 2)
        const statusFiltered = sortedTickets.filter(ticket => {
            // Ensure we have a valid ticket object with a status
            if (!ticket || typeof ticket.status === 'undefined') return false;
            
            // Convert status to number and handle filtering
            const status = Number(ticket.status);
            return showClosedTickets ? status === 2 : status !== 2;
        });

        // Then filter by search query
        if (!searchQuery?.trim()) return statusFiltered;

        const query = searchQuery.toLowerCase().trim();
        return statusFiltered.filter(ticket => 
            (ticket?.user?.name || '').toLowerCase().includes(query) ||
            (ticket?.description || '').toLowerCase().includes(query) ||
            (ticket?.category || '').toLowerCase().includes(query)
        );
    }, [sortedTickets, showClosedTickets, searchQuery]);

    // Finally paginate
    const totalTicketPages = useMemo(() => 
        Math.max(1, Math.ceil((filteredAndSearchedTickets?.length || 0) / ticketsPerPage))
    , [filteredAndSearchedTickets, ticketsPerPage]);

    const currentTickets = useMemo(() => {
        if (!Array.isArray(filteredAndSearchedTickets)) return [];

        // Ensure we're on a valid page
        const validPage = Math.min(ticketCurrentPage, totalTicketPages);
        const ticketIndexOfLastItem = validPage * ticketsPerPage;
        const ticketIndexOfFirstItem = ticketIndexOfLastItem - ticketsPerPage;

        return filteredAndSearchedTickets.slice(ticketIndexOfFirstItem, ticketIndexOfLastItem);
    }, [filteredAndSearchedTickets, ticketCurrentPage, totalTicketPages, ticketsPerPage]);

    const formatDate = (date) => {
        return new Date(date).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    return (
        <div className="space-y-4">
            {/* Search and Filter Controls */}
            <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                <div className="flex flex-col md:flex-row md:items-center gap-3">
                    <div className="flex flex-wrap items-center gap-3 flex-grow">
                        {/* Sort Controls */}
                        <div className="flex flex-wrap gap-2">
                            {[
                                { id: 'id', label: 'ID' },
                                { id: 'created_at', label: 'Date' },
                                { id: 'category', label: 'Category' },
                                { id: 'status', label: 'Status' }
                            ].map(option => (
                                <button
                                    key={option.id}
                                    onClick={() => {
                                        if (ticketSortField === option.id) {
                                            setTicketSortDirection(current => current === 'asc' ? 'desc' : 'asc');
                                        } else {
                                            setTicketSortField(option.id);
                                            setTicketSortDirection('asc');
                                        }
                                    }}
                                    className={`px-3 py-2 rounded-full text-sm font-medium transition-all duration-200
                                        ${ticketSortField === option.id
                                            ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300'
                                            : 'bg-gray-100 text-gray-600 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                                        }`}
                                >
                                    {option.label}
                                    {ticketSortField === option.id && (
                                        <span className="ml-1">
                                            {ticketSortDirection === 'asc' ? '↑' : '↓'}
                                        </span>
                                    )}
                                </button>
                            ))}
                        </div>

                        {/* Show Closed Tickets Toggle */}
                        <button
                            onClick={() => setShowClosedTickets(!showClosedTickets)}
                            className={`px-4 py-1.5 rounded-full text-sm font-medium transition-all duration-200 flex items-center gap-2
                                ${showClosedTickets
                                    ? 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400 shadow-sm'
                                    : 'bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700/50'
                                }`}
                        >
                            <Filter className="w-4 h-4" />
                            Show Closed
                        </button>

                        {searchQuery && (
                            <button
                                onClick={() => setSearchQuery('')}
                                className="px-4 py-1.5 rounded-full text-sm font-medium transition-all duration-200 flex items-center gap-2
                                    bg-red-100 text-red-700 dark:bg-red-900/30 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-900/40 shadow-sm"
                            >
                                <XCircle className="w-4 h-4" />
                                Clear Search
                            </button>
                        )}
                    </div>

                    {/* Search Bar */}
                    <div className="relative md:w-64 lg:w-72 shrink-0 md:ml-auto">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search tickets..."
                            className="w-full px-4 py-2 pl-10 text-sm bg-gray-50 dark:bg-gray-700/50 border border-gray-200 dark:border-gray-600 rounded-lg
                                placeholder-gray-500 dark:placeholder-gray-400
                                text-gray-900 dark:text-white"
                        />
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                    </div>
                </div>
            </div>

            {/* Ticket Cards Grid */}
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                {(!Array.isArray(currentTickets) || currentTickets.length === 0) ? (
                    <div className="col-span-full p-6 text-center bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-800/95 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700/50">
                        <div className="flex flex-col items-center gap-3">
                            <AlertCircle className="w-12 h-12 text-gray-400 dark:text-gray-500" />
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                                    No tickets found
                                </h3>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {searchQuery ? 'No tickets match your search query.' :
                                     showClosedTickets ? 'No closed tickets found.' :
                                     'No open tickets available.'}
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    currentTickets.map((ticket) => (
                        <div
                            key={ticket.id}
                            className={`group relative rounded-lg transition-all duration-200
                                ${ticket.status === TicketStatusEnum.Closed ? 'opacity-75' : ''}
                                ${isWithinLastThreeDays(ticket.updated_at) ? 'ring-2 ring-blue-500/20' : ''}`}
                        >
                            {/* Background with gradient */}
                            <div className="absolute inset-0 rounded-lg bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-800/95 border border-gray-200 dark:border-gray-700/50 group-hover:border-gray-300 dark:group-hover:border-gray-600 group-hover:shadow-lg transition-all duration-200" />
                            {/* Glass effect overlay */}
                            <div className="absolute inset-0 rounded-lg bg-white/50 dark:bg-gray-800/50 backdrop-blur-[1px] opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
                            <div className="relative p-4 flex flex-col gap-3 h-full">
                                {/* Header */}
                                <div className="flex flex-col gap-2">
                                    {/* Title and Status */}
                                    <div className="flex items-start justify-between gap-2">
                                        <h3 className="text-base font-semibold text-gray-900 dark:text-white leading-snug">
                                            {ticket?.title || 'Untitled Ticket'}
                                        </h3>
                                        <StatusLabel status={Number(ticket?.status) || 0} />
                                    </div>
                                </div>

                                {/* Description */}
                                <div className="flex-grow">
                                    <p className="text-gray-600 dark:text-gray-400 text-sm">
                                        {ticket?.description || 'No description available'}
                                    </p>
                                </div>

                                {/* Timestamps and Author */}
                                <div className="flex flex-col gap-1.5 mt-auto pt-3">
                                    {/* Author info */}
                                    <div className="flex items-center justify-between text-xs rounded-md px-3 py-2 border bg-gradient-to-r from-purple-50 to-purple-50/50 dark:from-purple-900/20 dark:to-purple-900/10 text-purple-600 dark:text-purple-400 border-purple-100 dark:border-purple-900/30">
                                        <div className="flex items-center gap-3">
                                            <Link
                                                to={`/dashboard/admin/user/${ticket?.user_id || 0}`}
                                                className="flex items-center gap-1.5"
                                            >
                                                <Users className="w-3.5 h-3.5 text-purple-500 dark:text-purple-400" />
                                                <span className="text-purple-600 dark:text-purple-400">{ticket?.user?.name || 'Unknown User'}</span>
                                            </Link>
                                        </div>
                                        <div className="flex items-center divide-x divide-purple-200 dark:divide-purple-800">
                                            <div className="flex items-center gap-1.5 pr-2">
                                                <span className="text-purple-400 dark:text-purple-500">User</span>
                                                <span className="font-medium tabular-nums">{ticket?.user_id || '-'}</span>
                                            </div>
                                            <div className="flex items-center gap-1.5 pl-2">
                                                <span className="text-purple-400 dark:text-purple-500">Ticket</span>
                                                <span className="font-medium tabular-nums">{ticket?.id || '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Update timestamp if different from creation */}
                                    {ticket?.updated_at && ticket?.created_at && ticket.updated_at !== ticket.created_at && (
                                        <div 
                                            className={`
                                                flex items-center justify-between text-xs rounded-md px-2.5 py-1.5 border
                                                ${isWithinLastThreeDays(ticket.updated_at)
                                                    ? 'bg-gradient-to-r from-blue-50 to-blue-50/50 dark:from-blue-900/20 dark:to-blue-900/10 text-blue-600 dark:text-blue-400 border-blue-100 dark:border-blue-900/30'
                                                    : 'bg-gradient-to-r from-gray-50 to-gray-50/50 dark:from-gray-800/50 dark:to-gray-800/30 text-gray-500 dark:text-gray-400 border-gray-100 dark:border-gray-700/30'
                                                }
                                            `}
                                        >
                                            <div className="flex items-center gap-1.5">
                                                <Clock className="w-3.5 h-3.5" />
                                                <span>Updated</span>
                                            </div>
                                            <time dateTime={ticket.updated_at} className="tabular-nums font-medium">
                                                {formatDate(ticket.updated_at)}
                                            </time>
                                        </div>
                                    )}
                                    {/* Creation timestamp */}
                                    {ticket?.created_at && (
                                        <div className="flex items-center justify-between text-xs text-gray-500 dark:text-gray-400 bg-gradient-to-r from-gray-50/80 to-gray-50/30 dark:from-gray-800/50 dark:to-gray-800/30 rounded-md px-2.5 py-1.5 border border-gray-100 dark:border-gray-700/30 hover:border-gray-200 dark:hover:border-gray-600 transition-colors">
                                            <div className="flex items-center gap-1.5">
                                                <Clock className="w-3.5 h-3.5" />
                                                <span>Created</span>
                                            </div>
                                            <time dateTime={ticket.created_at} className="tabular-nums font-medium">
                                                {formatDate(ticket.created_at)}
                                            </time>
                                        </div>
                                    )}
                                </div>

                                {/* Actions */}
                                <div className="flex items-center justify-between pt-2 mt-2 border-t border-gray-100 dark:border-gray-700/30">
                                    {/* Category Tag */}
                                    <div className="flex items-center gap-1.5 px-2.5 py-1 bg-gradient-to-r from-gray-50 to-gray-50/50 dark:from-gray-800/50 dark:to-gray-800/30 rounded-md text-xs font-medium text-gray-600 dark:text-gray-400 border border-gray-100 dark:border-gray-700/30 transition-colors hover:border-gray-200 dark:hover:border-gray-600">
                                        <Tag className="w-3.5 h-3.5" />
                                        <span>
                                            {ticket?.category ? 
                                                (ticket.category === 'REQUEST_TEST_ACCESS' ? 'TEST_ACCESS' : ticket.category.toUpperCase())
                                                : 'UNKNOWN'
                                            }
                                        </span>
                                    </div>

                                    {/* Actions */}
                                    <div className="flex items-center gap-2">
                                    {Number(ticket?.status) !== 2 && (
                                        <Button
                                            onClick={() => closeTicket(ticket.id)}
                                            className="p-2 text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                                        >
                                            <XCircle className="w-5 h-5" />
                                        </Button>
                                    )}
                                    {((ticket?.updates?.length > 0) || Number(ticket?.status) !== 2) && (
                                        <Button
                                            onClick={() => replyToTicket(ticket.id)}
                                            className="relative p-2 text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                                        >
                                            <MessageCircle className="w-5 h-5 pt" />
                                            {ticket?.updates?.length > 0 && (
                                                <span className="absolute top-2.5 right-2.5 bg-blue-500 text-white rounded-full w-4 h-4 text-xs flex items-center justify-center">
                                                    {ticket.updates.length}
                                                </span>
                                            )}
                                        </Button>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Pagination - only show if we have tickets */}
            {Array.isArray(filteredAndSearchedTickets) && filteredAndSearchedTickets.length > 0 && (
                <div className="mt-6 flex justify-center">
                    <Pagination
                        currentPage={Math.min(ticketCurrentPage, totalTicketPages)}
                        totalPages={totalTicketPages}
                        onPageChange={(page) => setTicketCurrentPage(Math.min(page, totalTicketPages))}
                    />
                </div>
            )}
        </div>
    );
};

export default TicketList;
