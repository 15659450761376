import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import AppLayout from '../../components/dashboard/Layouts/AppLayout';

interface ForceAuthenticationProps {
    children: React.ReactNode | ((props: { user: any }) => React.ReactNode);
    /**
     * Optional role requirement. If provided, will check if user's role meets this requirement.
     * Can be either a role enum value or a function that takes the user's role and returns a boolean.
     */
    requireRole?: number | ((role: number) => boolean);
    /** Optional custom title for the access denied page */
    accessDeniedTitle?: string;
    /** Optional custom message for the access denied page */
    accessDeniedMessage?: string;

    redirectIfNotAuthenticated?: string;
}

export default function ForceAuthentication({ 
    children,
    requireRole,
    redirectIfNotAuthenticated,
    accessDeniedTitle = 'Access Denied',
    accessDeniedMessage = 'You don\'t have permission to access this page.',
}: ForceAuthenticationProps) {
    const { user, isLoading } = useAuth({ middleware: 'auth', redirectIfAuthenticated: false });

    // Check for rate limit first
    if (user?.isRateLimited) {
        return <RateLimitState />;
    }

    // Show loading state while checking authentication
    if (isLoading) {
        return <AuthenticationLoadingState />;
    }

    // Check role requirement if specified
    if (requireRole !== undefined && user) {
        const userRole = user.role;
        if (userRole === undefined) {
            return <AuthenticationLoadingState />;
        }

        const hasRequiredRole = typeof requireRole === 'function'
            ? requireRole(userRole)
            : userRole >= requireRole;

        if (!hasRequiredRole) {
            if (redirectIfNotAuthenticated) {
                window.location.href = redirectIfNotAuthenticated;
            }
            return <AccessDeniedState title={accessDeniedTitle} message={accessDeniedMessage} />;
        }
    }

    // Render children with user data
    return !isLoading && user ? (
        typeof children === 'function' ? 
            <>{children({ user })}</> : 
            <>{children}</>
    ) : null;
}

// Loading state component with delay to prevent flashing
function AuthenticationLoadingState() {
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        // Only show loading state if it's been loading for more than 500ms
        const timer = setTimeout(() => setShowLoading(true), 500);
        return () => clearTimeout(timer);
    }, []);

    // Don't render anything during the delay
    //if (!showLoading) return null;
    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 dark:text-white leading-tight">
                    {showLoading && "Authenticating..."}
                </h2>
            }>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow-sm sm:rounded-lg p-6">
                        <div className="flex items-center justify-center">
                            {showLoading && <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div> }
                            <span className="ml-3 text-gray-600 dark:text-gray-400">{showLoading && "Checking authentication..."}</span>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

// Access denied state component
function AccessDeniedState({ title, message }: { title: string; message: string }) {
    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 dark:text-white leading-tight">
                    {title}
                </h2>
            }>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white dark:bg-gray-800 overflow-hidden shadow-sm sm:rounded-lg p-6">
                        <p className="text-gray-900 dark:text-gray-100">{message}</p>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

// Rate limit state component with a friendly message
function RateLimitState() {
    const RATE_LIMIT_DURATION = 60; // seconds
    const STORAGE_KEY = 'rateLimitStartTime';

    // Initialize time left based on stored start time or create new start time
    const [timeLeft, setTimeLeft] = useState(() => {
        const storedStartTime = sessionStorage.getItem(STORAGE_KEY);
        if (storedStartTime) {
            const elapsedTime = Math.floor((Date.now() - parseInt(storedStartTime)) / 1000);
            // If more than RATE_LIMIT_DURATION seconds have passed, clear it
            if (elapsedTime >= RATE_LIMIT_DURATION) {
                sessionStorage.removeItem(STORAGE_KEY);
                return 0; // This will trigger a refresh and get a new time from the auth hook
            }
            return Math.max(0, RATE_LIMIT_DURATION - elapsedTime);
        }
        return 0; // Will trigger refresh and get new time from auth hook
    });

    const [isRefreshing, setIsRefreshing] = useState(false);

    useEffect(() => {
        // If no time left, refresh to get new time from auth hook
        if (timeLeft === 0) {
            window.location.reload();
            return;
        }

        const timer = setInterval(() => {
            const storedStartTime = sessionStorage.getItem(STORAGE_KEY);
            if (storedStartTime) {
                const elapsedTime = Math.floor((Date.now() - parseInt(storedStartTime)) / 1000);
                
                // If more than RATE_LIMIT_DURATION seconds have passed, clear and refresh
                if (elapsedTime >= RATE_LIMIT_DURATION) {
                    sessionStorage.removeItem(STORAGE_KEY);
                    setTimeLeft(0);
                    return;
                }
                
                const remainingTime = Math.max(0, RATE_LIMIT_DURATION - elapsedTime);
                setTimeLeft(remainingTime);

                if (remainingTime === 0) {
                    sessionStorage.removeItem(STORAGE_KEY);
                    window.location.reload();
                }
            } else {
                // No stored time, refresh to get new time from auth hook
                window.location.reload();
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [timeLeft]);

    const handleRefresh = () => {
        setIsRefreshing(true);
        window.location.reload();
    };

    const formatTime = (seconds: number) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 dark:text-white leading-tight text-center">
                    Rate Limit Reached
                </h2>
            }>
            <div className="py-12">
                <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-gray-800 dark:to-gray-900 
                                  overflow-hidden rounded-xl shadow-xl p-8">
                        <div className="text-center space-y-6">
                            {/* Icon with pulse animation */}
                            <div className="mx-auto w-20 h-20 bg-blue-100 dark:bg-gray-700 rounded-full flex items-center justify-center 
                                        animate-pulse mb-2 relative">
                                <span className="text-4xl">⏳</span>
                                {/* Circular progress */}
                                <svg className="absolute inset-0 w-full h-full -rotate-90">
                                    <circle
                                        className="text-gray-200 dark:text-gray-600"
                                        strokeWidth="4"
                                        stroke="currentColor"
                                        fill="transparent"
                                        r="38"
                                        cx="40"
                                        cy="40"
                                    />
                                    <circle
                                        className="text-blue-500 dark:text-blue-400"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        stroke="currentColor"
                                        fill="transparent"
                                        r="38"
                                        cx="40"
                                        cy="40"
                                        strokeDasharray={`${2 * Math.PI * 38}`}
                                        strokeDashoffset={`${2 * Math.PI * 38 * (1 - timeLeft / 60)}`}
                                        style={{ transition: 'stroke-dashoffset 1s linear' }}
                                    />
                                </svg>
                            </div>
                            
                            {/* Main heading with gradient text */}
                            <h3 className="text-3xl font-extrabold bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-400 dark:to-indigo-400 
                                       bg-clip-text text-transparent">
                                Whoa there, speedy! 🏃‍♂️
                            </h3>
                            
                            {/* Message with better typography and spacing */}
                            <div className="prose prose-lg dark:prose-invert mx-auto">
                                <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                                    Our security system noticed you're moving pretty fast! For everyone's safety,
                                    let's take a quick breather before continuing.
                                </p>
                                <p className="text-sm text-blue-600 dark:text-blue-400 font-medium">
                                    Auto-refreshing in {formatTime(timeLeft)}
                                </p>
                            </div>
                            
                            {/* Refresh button/message with hover effect */}
                            <div className="mt-8">
                                <button 
                                    onClick={handleRefresh} 
                                    disabled={isRefreshing}
                                    className={`group relative inline-flex items-center justify-center px-8 py-3 text-lg 
                                             font-medium text-white bg-gradient-to-r from-blue-500 to-indigo-600 
                                             rounded-full overflow-hidden shadow-lg hover:shadow-xl 
                                             transform hover:scale-105 transition-all duration-300
                                             ${isRefreshing ? 'opacity-75 cursor-not-allowed' : ''}`}>
                                    <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-indigo-500 to-blue-600 
                                                 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                                    <span className="relative flex items-center">
                                        {isRefreshing ? 'Refreshing...' : 'Refresh Now'}
                                        <svg className={`ml-2 w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`} viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            
                            {/* Additional help text */}
                            <p className="text-sm text-gray-500 dark:text-gray-400 mt-4">
                                This helps us maintain a secure environment for everyone.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
