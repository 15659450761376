import React, { useEffect } from 'react';
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import ContactForm from "../components/contact/ContactForm"; // Import the new ContactForm component
import BaseSection from '../components/base/Section';

function Contact() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Contact"

    if (window.location.hash === "#contact") {
      const element = document.getElementById("contact");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

  }, []);

  return (
    <div className="Contact dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Contact"
        heightStyle={"h-[440px]"}
                      buttons={[
                        {title: "Contact", url: "/contact#contact"}
                      ]}
        />

        {/* <!-- Blogs section --> */}
        {/*<section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white">*/}
        <section className="w-full relative flex sm:mx-6 dark:bg-slate-900 dark:text-white">
          <BaseSection>
            <div className="col-span-12 lg:col-span-8 px-6 sm:px-0 py-16 flex flex-col lg:flex-row space-y-4 lg:space-y-0 dark:text-white"
            data-aos="fade-up">
              <div className="w-full lg:w-3/4 px-6 mr-12">
                <h3 id="contact" className='text-center'>
                  <span className="text-header-gradient">Contact</span>!
                </h3>
                <p className="text-lg mb-8">
                  Have questions, need help or anything else? <br />
                  Don't hesitate to reach out!
                </p>

                <div id="contact-form" className="w-full p-6">
                  <ContactForm />
                </div>
                <p className='text-sm italic'>
                  Please note: We store your IP address when using this form for security purposes.
                  You can also reach us via LinkedIn.
                </p>
              </div>
              <div className="hidden lg:block w-1/4 pl-6">
                <img
                  src={require("../assets/img/contact/contact.png")}
                  className="max-w-[450px] m-auto mt-2"
                  alt=""
                />
              </div>
            </div>
          </BaseSection>
        </section>
        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default Contact;
