import React, {useEffect, useRef} from "react";
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import { Link, useLocation } from "react-router-dom";
import { ArrowRight, Clock, Play, Calendar } from "lucide-react";
import BaseSection from "../components/base/Section";

function Resources() {
  const specialBlogs = [{
    id: "1",
    url: "https://www.linkedin.com/pulse/art-prompt-crafting-jeroen-egelmeers",
    title: "The art of Prompt Crafting",
    date: "May 2023",
    special: true,
    targetBlank: true,
    description: "The framework (first blog). First publication ever about this framework.",
    reading_time: "20 minutes",
    image_url: require("../assets/img/blogs/blog1.png"),
    tags: ["framework"],
  },
  {
    id: "2",
    url: "https://www.linkedin.com/pulse/art-prompt-crafting-part-two-jeroen-egelmeers",
    title: "The art of Prompt Crafting - Part Two",
    date: "August 2023",
    special: true,
    targetBlank: true,
    description: "Predictions and prompting techniques",
    reading_time: "20 minutes",
    image_url: require("../assets/img/blogs/techniques.png"),
    tags: ["predictions", "techniques"],
  },
  {
    id: "3",
    title: "The art of Prompt Crafting - Part three", 
    url: "https://www.linkedin.com/pulse/art-prompt-crafting-part-three-jeroen-egelmeers-eafie/",
    date: "October 2023",
    special: true,
    targetBlank: true,
    description: "Prompt architecture (third blog)",
    reading_time: "10 minutes",
    image_url: require("../assets/img/blogs/architecture.png"),
    tags: ["architecture"],
  }];

  const videos = [
    {
      id: "1",
      url: "https://www.youtube.com/watch?v=kjQTp94OZ2g",
      title: "Supercharge Your Productivity: Mastering Prompt Engineering",
      from: "Prompt Engineering Conference",
      on: "Youtube",
      date: "October 2023",
      craftingAI: true,
      targetBlank: true,
      time: "30 minutes",
      image_url: require("../assets/img/blogs/super-charge-productivity.png"),
      tags: ["framework"],
    },
    {
      id: "2",
      url: "https://www.youtube.com/watch?v=dXlblvyK_sw",
      title: "Supercharge Your Productivity: Mastering Prompt Engineering",
      from: "Frontmania",
      on: "Youtube",
      date: "December 2023",
      craftingAI: true,
      targetBlank: true,
      time: "41 minutes",
      image_url: require("../assets/img/blogs/super-charge-productivity.png"),
      tags: ["framework"],
    },
    {
      id: "3",
      url: "https://www.youtube.com/watch?v=pqPhPyRyewc",
      title: "Supercharge Your Productivity: Mastering Prompt Engineering",
      from: "JAVAPRO | JCON World",
      on: "Youtube",
      date: "December 2023",
      craftingAI: true,
      targetBlank: true,
      time: "44 minutes",
      image_url: require("../assets/img/blogs/super-charge-productivity.png"),
      tags: ["framework"],
    },
    {
      id: "4",
      url: "https://www.youtube.com/watch?v=NpYU0L_9w1U",
      title: "Words as Weapons - The Dark arts of Prompt Engineering",
      from: "Prompt Engineering Conference",
      on: "Youtube",
      date: "November 2024",
      craftingAI: true,
      targetBlank: true,
      time: "33 minutes",
      image_url: require("../assets/img/blogs/words-as-weapons.png"),
      tags: ["framework", "adversarial"],
    },
    {
      id: "5",
      url: "https://www.youtube.com/watch?v=69uWV1GkQz4",
      title: "Words as Weapons - The Dark arts of Prompt Engineering",
      from: "Devoxx Belgium",
      on: "Youtube",
      date: "October 2024",
      craftingAI: true,
      targetBlank: true,
      time: "42 minutes",
      image_url: require("../assets/img/blogs/words-as-weapons.png"),
      tags: ["framework", "adversarial"],
    },
  ];

  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
      goToLocation(-120);
  }, [location.hash]);

  function goToLocation(yOffset: number) {

      if (location.hash) {
          lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
      }

      if (lastHash.current && document.getElementById(lastHash.current)) {
          setTimeout(() => {
              const element = document.getElementById(lastHash.current);
              if (element) {
                  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                  window.scrollTo({top: y, behavior: 'smooth'});
              }

              lastHash.current = '';
          }, 50);
      }
  }

  const userIsLoggedIn = () => {
    return !(localStorage.getItem("username") === "" || localStorage.getItem("username") === undefined || localStorage.getItem("username")  === null);
  }


  const BlogCard = ({ blog }: { blog: any }) => {
    return(
        <Link
            key={blog.id}
            to={`${blog.url}`}
            className="block group"
            target={blog.targetBlank ? "_blank" : "_self"}
          >
            <article className="bg-white pb-2 dark:bg-gray-800 text-left rounded-2xl shadow-xl dark:shadow-gray-900 overflow-hidden transform transition-all duration-300 hover:scale-[1.02] hover:shadow-2xl dark:hover:shadow-gray-900 h-full">
            <div className="relative h-64">
                <img
                  src={blog.image_url}
                  alt={blog.title}
                className="w-full h-full object-cover"
                />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent dark:from-gray-900/50">
                {blog.special && (
                  <div className="absolute top-4 right-4 text-white bg-gradient-to-r from-purple-600 to-purple-800 px-4 py-2 rounded-full text-sm font-semibold flex items-center">
                    <span className="mr-2">Special</span>
                    <span className="w-2 h-2 bg-white rounded-full" />
                  </div>
                )}
                <div className="absolute bottom-4 left-4 text-white dark:text-white bg-black/50 dark:bg-gray-900/50 px-3 py-1 rounded-full text-sm flex items-center">
                  <Calendar className="w-4 h-4 mr-2" />
                  {blog.date}
                </div>
                {blog.targetBlank && (
                  <div className="absolute bottom-4 right-4 text-white dark:text-white bg-black/50 dark:bg-gray-900/50 px-3 py-1 rounded-full text-sm flex items-center">
                    <ArrowRight className="w-4 h-4 mr-2 text-purple-600 dark:text-purple-300" />
                    <span className="text-purple-600 dark:text-purple-300 font-semibold">External link</span>
                  </div>
                )}
                </div>
              </div>

              <div className="p-6 mb-4">
                <div className="flex flex-wrap gap-2 mb-4">
                  {blog.tags?.map((tag: string) => (
                    <span
                      key={tag}
                      className="bg-purple-100 dark:bg-purple-500/20 rounded-full px-3 py-1 text-[10px] font-semibold text-purple-700 dark:text-purple-300"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                
                <h2 className="text-xl font-bold mb-2 text-header-gradient transition-colors">
                  {blog.title}
                </h2>
                
                <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
                  {blog.description}
                </p>
              </div>

              <div className="absolute bottom-0 left-0 right-0 p-4">
                <div className="flex items-center justify-between text-sm px-2">
                  <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <Clock className="w-4 h-4 mr-1" />
                    {blog.reading_time || 5} read
                  </div>
                  <div className="flex items-center font-medium text-purple-600 dark:text-purple-300">
                    Read more
                    <ArrowRight className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform" />
                  </div>
                </div>
              </div>
            </article>
          </Link>
    );
  }

  const VideoCard = ({ video }: { video: any }) => {
    return(
        <Link
          key={video.id}
          to={`${video.url}`}
          target="_blank"
          className="block group relative"
        >
          <article className="relative bg-white dark:bg-gray-800 rounded-2xl shadow-xl dark:shadow-gray-900 overflow-hidden transform transition-all duration-300 hover:scale-[1.02] hover:shadow-2xl dark:hover:shadow-gray-900">
            <div className="relative h-80 overflow-hidden">
              <img
                src={video.image_url}
                alt={video.title}
                className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent dark:from-gray-900/40" />
              
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="bg-white/10 dark:bg-gray-900/10 backdrop-blur-md rounded-full p-2">
                  <Play className="w-8 h-8 text-white" />
                </div>
              </div>

              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent dark:from-gray-900/60 p-4">
                <h2 className="text-lg mb-0 font-bold text-white mb-2 text-center line-clamp-2">
                  {video.title}
                </h2>
                <div className="text-sm text-gray-300 flex items-center justify-center gap-2">
                  <span className="text-xs">{video.from}</span>
                  <span className="text-xs">|</span>
                  <span className="text-xs">{video.on}</span>
                </div>
              </div>

              <div className="absolute top-4 right-4 z-10">
                {video.craftingAI && (
                  <div className="text-white bg-gradient-to-r from-purple-600 to-purple-800 px-4 py-2 rounded-full text-sm font-semibold flex items-center">
                    <span className="mr-2">Crafting AI</span>
                    <span className="w-2 h-2 bg-white rounded-full" />
                  </div>
                )}
              </div>
              <div className="absolute top-2 left-4 z-10">
                <div className="flex flex-col items-start gap-1 mt-2">
                  <div className="text-white bg-black/50 dark:bg-gray-900/50 px-3 py-1 rounded-full text-sm flex items-center">
                    <Calendar className="w-4 h-4 mr-2" />
                    {video.date}
                  </div>
                  <div className="text-white bg-black/50 dark:bg-gray-900/50 px-3 py-1 rounded-full text-sm flex items-center">
                    {video.time}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </Link>
    );
  }

  const headerButtons = [
    {title: "Blogs", url: "/resources#blogs"},
    userIsLoggedIn() && {title: "Videos", url: "/resources#videos"}
  ].filter(Boolean) as {title: string; url: string}[];

  return (
    <div className="Resources dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Resources"
          heightStyle={"h-[440px]"}
          buttons={headerButtons}  
        />
        
        <div className="relative max-w-full pb-12 md:mx-4 md:shadow sm:rounded-2xl dark:bg-slate-900 dark:text-white">
        <section className="w-full relative flex dark:bg-slate-900 dark:text-white">
          <BaseSection>
            <div className="col-span-12 mb-12 lg:col-span-8 space-y-6 px-4 sm:px-6 mt-10 flex-1 dark:text-white content-section lg:col-span-12" data-aos="fade-up" data-aos-delay="100">
              <h3 id="blogs">Read more <span className="text-header-gradient">blogs</span>!</h3>
              <p>Explore additional insights on the Crafting AI Prompts Framework in our blog section. While our website now features the most up-to-date information on prompt techniques, models, and architecture, our blog holds special significance as the birthplace of this framework. It’s where it all started, with the first public release of the framework. Alongside inspiring use cases, the blog serves as a valuable reference and a glimpse into the history that shaped our current offerings.</p>
              <p>If you see "special" on the top right corner of the blog item below, it's one of the original blogs where it all started. Meanwhile, many blogs have been written about the framework. All blogs we find or that are sent to us, we'll publish here soon. So feel free to create an account, add a ticket, and send us the URL! If we think it's valuable, we'll publish it.</p>
            </div>
            <div className="hidden lg:block lg:col-span-4 flex mb-8"
                data-aos="fade-up"
                data-aos-delay="100">
              <img
                src={require("../assets/img/blogs/blog.png")}
                className="max-w-[340px] m-auto mt-8"
                alt=""
              />
            </div>
            <div className="w-full col-span-12 pb-16 px-4" data-aos="fade-up"
                      data-aos-delay="100">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {specialBlogs.map((blog) => (
                  <BlogCard key={blog.id} blog={blog} />
                ))}
              </div>
            </div>
          </BaseSection>
        </section>

        {userIsLoggedIn() && (
          <section className="w-full relative flex dark:bg-slate-900 dark:text-white" data-aos="fade-up" data-aos-delay="100">
            <BaseSection>
              <hr className="lg:my-12 my-4 lg:mx-12 mx-4 col-span-12 border-gray-200 dark:border-gray-700" />

              <div className="mb-12 flex col-span-12" id="videos">
              <div className="hidden lg:block lg:col-span-4 flex justify-end items-end">
                <img
                  src={require("../assets/img/blogs/youtube-icon.png")}
                  className="max-w-[340px] m-auto mt-2"
                  alt=""
                />
              </div>
              <div className="col-span-12 mb-12 lg:col-span-8 space-y-6 px-4 sm:px-6 mt-10 flex-1 dark:text-white content-section lg:col-span-12"
                      data-aos="fade-up"
                      data-aos-delay="100">
                <h3 id="videos">
                  Watch <span className="text-header-gradient">videos</span>!
                </h3>
                <p className="mr-12">
                  We, but also a lot of other people are presenting this framework on different platforms and conferences. Recordings will be added here. Are we missing your recording, and would you like it to be added to this list? Please create a ticket on your dashboard, and let us know, so we can add it!
                </p>
                <p className="mr-12">
                  If you see "Crafting AI" on the top right of the video, this indicates that the video is by us or presented by Crafting AI trainer(s).
                </p>
              </div>
            </div>
            <div className="w-full col-span-12 mb-0 mx-auto px-4" data-aos="fade-up"
                      data-aos-delay="100">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {videos
                    .sort((a, b) => {
                      const dateA = new Date(a.date);
                      const dateB = new Date(b.date);
                      return dateB.getTime() - dateA.getTime();
                    })
                    .map((video) => (
                      <VideoCard key={video.id} video={video} />
                    ))}
                </div>
            </div>
          </BaseSection>
        </section>
        )}
        </div>
        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
);}

export default Resources;
