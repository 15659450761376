import React, { useState } from 'react';
import { MergedPrompts } from './MergedPrompts';
import { PromptLibraryPrompt } from './types/PromptLibraryPrompt';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

interface ChatboxMergedPromptsProps {
    mergedPrompts: PromptLibraryPrompt[];
    onDragEnd: (result: DropResult) => void;
    handleRemoveFromMerged: (id: string) => void;
    handleCopy: (text: string, id: string) => void;
    handleClearMerged: () => void;
    copiedId: string | null;
    setIsRecipeModalOpen: (isOpen: boolean) => void;
    getMergedText: () => string;
}

export const ChatboxMergedPrompts: React.FC<ChatboxMergedPromptsProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleChatbox = () => {
        setIsOpen(!isOpen);
    };

    return (
        <DragDropContext onDragEnd={props.onDragEnd}>
            <div className="fixed bottom-3 right-3 z-50 p-4 flex flex-col items-end">
                {/* Chatbox */}
                {isOpen && (
                    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-4 w-80 md:w-96 overflow-hidden border border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out">
                        <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-gray-700">
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                                Prompt Recipe
                            </h2>
                        </div>
                        <div className="p-4 max-h-[70vh] overflow-y-auto">
                            <MergedPrompts {...props} />
                        </div>
                    </div>
                )}

                {/* Toggle Button - Always on the right side */}
                <button
                    onClick={toggleChatbox}
                    className={`flex items-center justify-center p-3 rounded-full shadow-lg transition-all duration-300 relative
                    ${isOpen 
                        ? 'bg-red-500 hover:bg-red-600 text-white' 
                        : 'bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white'
                    }`}
                    title={isOpen ? "Close Prompt Recipe" : "Open Prompt Recipe"}
                >
                    {isOpen ? (
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                            </svg>
                            {props.mergedPrompts.length > 0 && (
                                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                                    {props.mergedPrompts.length}
                                </span>
                            )}
                        </>
                    )}
                </button>
            </div>
        </DragDropContext>
    );
};
