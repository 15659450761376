import React from 'react';
import { isRateLimited, getRateLimitRemaining } from '../utils/rateLimit';

interface RateLimitMessageProps {
    className?: string;
    onExpire?: () => void;
}

const RateLimitMessage: React.FC<RateLimitMessageProps> = ({ className = '', onExpire }) => {
    const [timeLeft, setTimeLeft] = React.useState<number>(60);

    React.useEffect(() => {
        const updateTimeLeft = () => {
            const remaining = getRateLimitRemaining();
            setTimeLeft(remaining);
            
            // Call onExpire when the rate limit expires
            if (remaining === 0 && onExpire) {
                onExpire();
            }
        };

        // Update immediately and then every second
        updateTimeLeft();
        const timer = setInterval(updateTimeLeft, 1000);
        return () => clearInterval(timer);
    }, []);

    if (!isRateLimited()) return null;

    const formatTime = (seconds: number) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <div className={`relative overflow-hidden bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-gray-800 dark:to-gray-900 rounded-xl p-6 mb-4 shadow-xl border-2 animate-pulse-border ${className}`}>
            <div className="relative flex items-center justify-between">
                {/* Left side with icon and message */}
                <div className="flex items-center space-x-6">
                    {/* Icon with pulse animation */}
                    <div className="flex-shrink-0 w-16 h-16 bg-blue-100 dark:bg-gray-700 rounded-full flex items-center justify-center relative">
                        <span className="text-3xl">⏳</span>
                        {/* Circular progress */}
                        <svg className="absolute inset-0 w-full h-full -rotate-90">
                            <circle
                                className="text-gray-200 dark:text-gray-600"
                                strokeWidth="3"
                                stroke="currentColor"
                                fill="transparent"
                                r="31"
                                cx="32"
                                cy="32"
                            />
                            <circle
                                className="text-blue-500 dark:text-blue-400"
                                strokeWidth="3"
                                strokeLinecap="round"
                                stroke="currentColor"
                                fill="transparent"
                                r="31"
                                cx="32"
                                cy="32"
                                strokeDasharray={`${2 * Math.PI * 31}`}
                                strokeDashoffset={`${2 * Math.PI * 31 * (1 - timeLeft / 60)}`}
                                style={{ transition: 'stroke-dashoffset 1s linear' }}
                            />
                        </svg>
                    </div>
                    
                    <div className="space-y-1">
                        <h3 className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-400 dark:to-indigo-400 bg-clip-text text-transparent">
                            Whoa there, speedy! 🏃‍♂️
                        </h3>
                        <p className="text-gray-700 dark:text-gray-300 text-sm leading-relaxed max-w-lg">
                            Our security system noticed you're moving pretty fast! For everyone's safety,
                            let's take a quick breather before continuing.
                        </p>
                    </div>
                </div>
                
                {/* Right side with timer */}
                <div className="flex-shrink-0 ml-6">
                    <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                        {formatTime(timeLeft)}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                        seconds left
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RateLimitMessage;
