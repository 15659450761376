const Button = ({ type = 'submit', className = '', ...props }) => {
  // Default classes that can be overridden
  const defaultClasses = className || 'bg-gray-800 border-transparent text-white hover:bg-gray-700 active:bg-gray-900';
  
  return (
    <button
      type={type}
      className={`inline-flex items-center px-4 py-2 border rounded-md font-semibold text-sm tracking-wide focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-25 transition-colors duration-200 ${defaultClasses}`}
      {...props}
    />
  );
}

export default Button
