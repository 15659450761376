import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import React, {useEffect, useState, Fragment} from "react";
import { FiSearch, FiFilter, FiAward, FiArrowRight, FiCheckCircle, FiZap, FiTarget, FiShield, FiBook, FiCpu, FiExternalLink, FiUpload } from 'react-icons/fi';
import ChallengeSubmission from '../../components/dashboard/challenges/ChallengeSubmission';
import RoleEnum from "../../components/enums/RoleEnum";
import axios from "../../libs/axios";
import ForceAuthentication from '../../components/auth/ForceAuthentication';
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import PromptEngineerLevel from '../../components/dashboard/skills/PromptEngineerLevel';
import toast from 'react-hot-toast';
import ChallengeCard from '../../components/dashboard/challenges/ChallengeCard';
import AddChallengeModal from '../../components/dashboard/challenges/AddChallengeModal';
import ReactConfetti from 'react-confetti';

const PromptChallengesContent = ({ user }) => {
    // State for challenges data from API
    const [challenges, setChallenges] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const [promptEngineerLevel, setPromptEngineerLevel] = useState({
        currentLevel: 1,
        currentXP: 0,
        nextLevelXP: 1000,
        prevLevelXP: 0
    });
    
    const [searchQuery, setSearchQuery] = useState('');
    const [filterDifficulty, setFilterDifficulty] = useState('all');
    const [filterSkill, setFilterSkill] = useState('all');
    const [activeTab, setActiveTab] = useState('available');
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAddChallengeModalOpen, setIsAddChallengeModalOpen] = useState(false);
    const [newChallenge, setNewChallenge] = useState({
        title: '',
        description: '',
        difficulty: 'beginner',
        skill_focus: 'knowledge',
        xp_reward: '',
        requirements: '',
        completion_criteria: '',
        needs_review: false
    });
    const [tools, setTools] = useState([]);
    const [reviewSubmissions, setReviewSubmissions] = useState([]);
    const [isConfettiOpen, setIsConfettiOpen] = useState(false);

    useEffect(() => {
        if (isConfettiOpen) {
            const timer = setTimeout(() => {
                setIsConfettiOpen(false);
            }, 10000);  
            return () => clearTimeout(timer);
        }
    }, [isConfettiOpen]);

    const handleAddChallenge = async () => {
        try {
            const response = await axios.post('/admin/challenges', newChallenge);
            if (response.status === 201) {
                toast.success('Challenge added successfully!');
                setIsAddChallengeModalOpen(false);
                challenges.push({
                    id: response.data.challenge.id,
                    title: response.data.challenge.title,
                    description: response.data.challenge.description,
                    difficulty: response.data.challenge.difficulty,
                    skill_focus: response.data.challenge.skill_focus,
                    xp_reward: response.data.challenge.xp_reward,
                    requirements: response.data.challenge.requirements,
                    completion_criteria: response.data.challenge.completion_creteria
                });

                setNewChallenge({
                    title: '',
                    description: '',
                    difficulty: 'beginner',
                    skill_focus: 'knowledge',
                    xp_reward: '',
                    requirements: '',
                    completion_criteria: '',
                    needs_review: false
                })
            }
        } catch (error) {
            console.log(error);
            toast.error('Someting went wrong while adding the challenge. Please try again later.');
        }
    };
    
    // Get completed and submitted challenges IDs from challenges data
    const completedChallengesIds = React.useMemo(() => {
        return challenges.filter(challenge => challenge.completion).map(challenge => challenge.id);
    }, [challenges]);
    
    const submittedChallengesIds = React.useMemo(() => {
        return challenges.filter(challenge => challenge.submitted && !challenge.completion).map(challenge => challenge.id);
    }, [challenges]);

    // Fetch review submissions for moderators
    const fetchReviewSubmissions = async () => {
        if (user.role >= RoleEnum.Moderator) {
            try {
                const response = await axios.get('/moderator/challenges/submissions/review');
                
                // Convert the object with numeric keys to an array
                const challengesArray = Object.values(response.data.challenges);
                
                if (challengesArray && Array.isArray(challengesArray)) {
                    const transformedChallenges = challengesArray.map(challenge => ({
                        ...challenge,
                        completion: false,
                        submitted: true,
                        needs_review: true,
                        isReview: true,
                        submitted: {
                            ...challenge.submitted,
                            isReview: true
                        }
                    }));
                    setReviewSubmissions(transformedChallenges);
                } else {
                    toast.error('Failed to fetch review submissions: Invalid response format');
                }
            } catch (error) {
                toast.error(error.response?.data?.message || 'Failed to fetch review submissions');
            }
        }
    };

    // Main data fetching effect
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Challenges";
        
        // Fetch user's prompt engineer level data
        const fetchLevelData = async () => {
            try {
                const response = await axios.get('/prompt-challenges/level');
                if (response.data && response.status === 200) {
                    setPromptEngineerLevel({
                        currentLevel: response.data.currentLevel,
                        currentXP: response.data.currentXP,
                        nextLevelXP: response.data.nextLevelXP,
                        prevLevelXP: response.data.prevLevelXP
                    });
                }
            } catch (error) {
                //console.error('Error fetching prompt engineer level data:', error);
            }
        };
        
        // Fetch challenges data
        const fetchChallenges = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/prompt-challenges/challenges');
                if (response.data && response.status === 200) {
                    setChallenges(response.data);
                }
                setLoading(false);
            } catch (error) {
                //console.error('Error fetching challenges:', error);
                setError('Failed to load challenges. Please try again later.');
                setLoading(false);
            }
        };

        // Fetch tools data
        const fetchTools = async () => {
            try {
                const response = await axios.get('/admin/tools');
                if (response.data && response.status === 200) {
                    setTools(response.data);
                }
            } catch (error) {
                //console.error('Error fetching tools:', error);
            }
        };
        
        fetchLevelData();
        fetchChallenges();
        // Only fetch review submissions for moderators
        if (user.role >= RoleEnum.Moderator) {
            fetchReviewSubmissions();
        }
        if (user.role >= RoleEnum.Admin) {
            fetchTools();
        }
    }, [user]);

    // Filter challenges based on active tab and apply search/filters
    const filteredChallenges = React.useMemo(() => {
        const baseChallenges = () => {
            switch (activeTab) {
                case 'available':
                    return challenges.filter(challenge => 
                        !completedChallengesIds.includes(challenge.id) && 
                        !submittedChallengesIds.includes(challenge.id)
                    );
                case 'submitted':
                    return challenges.filter(challenge => 
                        submittedChallengesIds.includes(challenge.id)
                    );
                case 'completed':
                    return challenges.filter(challenge => 
                        completedChallengesIds.includes(challenge.id)
                    );
                case 'review':
                    return reviewSubmissions;
                default:
                    return challenges;
            }
        };

        // Get base challenges based on active tab
        let filtered = baseChallenges();

        // Apply search filter
        if (searchQuery) {
            const searchLower = searchQuery.toLowerCase();
            filtered = filtered.filter(challenge => 
                challenge.title.toLowerCase().includes(searchLower) ||
                challenge.description.toLowerCase().includes(searchLower) ||
                challenge.requirements?.toLowerCase().includes(searchLower)
            );
        }

        // Apply difficulty filter
        if (filterDifficulty !== 'all') {
            filtered = filtered.filter(challenge => 
                challenge.difficulty === filterDifficulty
            );
        }

        // Apply skill filter
        if (filterSkill !== 'all') {
            filtered = filtered.filter(challenge => 
                challenge.skill_focus === filterSkill
            );
        }

        return filtered;
    }, [activeTab, challenges, completedChallengesIds, submittedChallengesIds, reviewSubmissions, searchQuery, filterDifficulty, filterSkill]);

    const handleReview = async (reviewData) => {
        try {
            const response = await axios.post(`/admin/challenges/submissions/${reviewData.id}/review`,
                {
                    admin_feedback: reviewData.adminFeedback,
                    is_approved: reviewData.isApproved
                },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            
            // Update local submission data with the response
            if (response.data && response.data.submission) {
                // update challenges with submission data
                setChallenges(prev => prev.map(challenge => 
                    challenge.submitted?.id === response.data.submission.id 
                        ? {
                            ...challenge,
                            submitted: null,
                            completion: response.data.submission
                        }
                        : challenge
                ));
                
                // Refresh review submissions list
                if (activeTab === 'review' && user.role >= RoleEnum.Moderator) {
                    fetchReviewSubmissions();
                }
                
                // Close the modal
                setIsSubmitModalOpen(false);
                setSelectedChallenge(null);
                
                // Show success message
                if (reviewData.isApproved) {
                    toast.success('Submission accepted and reward given');
                } else {
                    toast.success('Submission rejected');
                }
            }
        } catch ({error, response}) {
            toast.error('Failed to review submission: ' + response.data.message);
        }
    };

    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-900 dark:text-white leading-tight">
                    Prompt Challenges <span className="bg-yellow-100 dark:bg-yellow-600 text-yellow-800 dark:text-yellow-100 font-semibold px-2.5 py-1 rounded-md ml-2 text-xs">Coming soon</span>
                </h2>
            }
        >
            <div className="bg-gray-50/50 dark:bg-gray-900 py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/* Welcome Section */}
                    <div className="grid gap-4 mb-4 md:grid-cols-4">
                        <DashboardCard className="md:col-span-2 overflow-hidden">
                            <div className="flex items-center gap-6 rounded-xl">
                                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#0c66ee] dark:to-[#468ef9] flex items-center justify-center">
                                    <span className="text-2xl font-bold text-white dark:text-black">
                                        {user?.name?.[0]?.toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                                       Prompt <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:bg-gradient-to-r dark:from-[#0c66ee] dark:to-[#468ef9]">Challenges</span>
                                    </h1>
                                    <p className="text-gray-600 mt-1 dark:text-gray-300">Let's learn and grow together, with these prompt challenges! Every week a new challenge!</p>
                                </div>
                            </div>
                        </DashboardCard>
                        <DashboardCard className="md:col-span-2 overflow-hidden">
                            <div className="flex flex-col rounded-xl">
                            <PromptEngineerLevel 
                                    prevLevelXP={promptEngineerLevel.prevLevelXP}
                                    currentXP={promptEngineerLevel.currentXP}
                                    nextLevelXP={promptEngineerLevel.nextLevelXP}
                                    currentLevel={promptEngineerLevel.currentLevel}
                                />
                            </div>
                        </DashboardCard>
                    </div>
                    {/* Challenges Section */}
                    <div className="grid gap-4 mb-4 md:grid-cols-1">
                        <DashboardCard className="md:col-span-1 overflow-hidden">
                            <div className="p-4">
                                <div className="flex justify-between items-center mb-6">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Prompt Engineering Challenges</h3>
                                </div>
                                <div className="mb-6">
                                    {/* Search and Filters */}
                                    <div className="bg-white/10 dark:bg-gray-800/50 backdrop-blur-md rounded-xl">
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                            <div className="relative">
                                                <FiSearch className="absolute left-3 top-3 h-4 w-4 text-gray-500 dark:text-gray-400" />
                                                <input
                                                    placeholder="Search challenges..."
                                                    className="pl-9 w-full bg-white dark:bg-gray-700/30 border border-gray-300 dark:border-gray-600 rounded-lg py-2 px-3 text-gray-700 dark:text-white placeholder:text-gray-500 dark:placeholder:text-gray-400"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </div>
                                            
                                            <div className="flex items-center gap-2">
                                                <FiFilter className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                                                <select 
                                                    value={filterDifficulty} 
                                                    onChange={(e) => setFilterDifficulty(e.target.value)}
                                                    className="w-full bg-white dark:bg-gray-700/30 border border-gray-300 dark:border-gray-600 rounded-lg py-2 px-3 text-gray-700 dark:text-white"
                                                >
                                                    <option value="all">All Difficulties</option>
                                                    <option value="beginner">Beginner</option>
                                                    <option value="intermediate">Intermediate</option>
                                                    <option value="advanced">Advanced</option>
                                                </select>
                                            </div>
                                            
                                            <div className="flex items-center gap-2">
                                                <FiZap className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                                                <select 
                                                    value={filterSkill} 
                                                    onChange={(e) => setFilterSkill(e.target.value)}
                                                    className="w-full bg-white dark:bg-gray-700/30 border border-gray-300 dark:border-gray-600 rounded-lg py-2 px-3 text-gray-700 dark:text-white"
                                                >
                                                    <option value="all">All Skills</option>
                                                    <option value="knowledge">Knowledge</option>
                                                    <option value="application">Application</option>
                                                    <option value="recognition">Recognition</option>
                                                    <option value="ethical_use">Ethical Use</option>
                                                    <option value="risks">Risk Awareness</option>
                                                    <option value="developments">Developments</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Tabs */}
                                <div className="mb-6">
                                    <div className="flex flex-col md:flex-row border-b border-gray-200 dark:border-gray-700">
                                        <button
                                            onClick={() => setActiveTab('available')}
                                            className={`py-2 px-4 font-medium text-sm ${activeTab === 'available' 
                                                ? 'text-blue-600 dark:text-blue-500 border-b-2 border-blue-600 dark:border-blue-500' 
                                                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'}`}
                                        >
                                            <div className="flex items-center gap-2">
                                                <span>Available Challenges</span>
                                                <span className="bg-blue-100 dark:bg-blue-600 text-blue-800 dark:text-blue-100 font-semibold px-2.5 py-0.5 rounded-full">
                                                    {challenges.filter(c => !c.completion && !c.submitted).length}
                                                </span>
                                            </div>
                                        </button>
                                        <button
                                            onClick={() => setActiveTab('submitted')}
                                            className={`py-2 px-4 font-medium text-sm ${activeTab === 'submitted' 
                                                ? 'text-orange-600 dark:text-orange-500 border-b-2 border-orange-600 dark:border-orange-500' 
                                                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'}`}
                                        >
                                            <div className="flex items-center gap-2">
                                                <span>Submitted</span>
                                                <span className="bg-orange-100 dark:bg-orange-600 text-orange-800 dark:text-orange-100 font-semibold px-2.5 py-0.5 rounded-full">
                                                    {submittedChallengesIds.length}
                                                </span>
                                            </div>
                                        </button>
                                        <button
                                            onClick={() => setActiveTab('completed')}
                                            className={`py-2 px-4 font-medium text-sm ${activeTab === 'completed' 
                                                ? 'text-green-600 dark:text-green-500 border-b-2 border-green-600 dark:border-green-500' 
                                                : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'}`}
                                        >
                                            <div className="flex items-center gap-2">
                                                <span>Completed</span>
                                                <span className="bg-green-100 dark:bg-green-600 text-green-800 dark:text-green-100 font-semibold px-2.5 py-0.5 rounded-full">
                                                    {completedChallengesIds.length}
                                                </span>
                                            </div>
                                        </button>
                                        {user.role >= RoleEnum.Moderator && (
                                            <button
                                                onClick={() => setActiveTab('review')}
                                                className={`py-2 px-4 font-medium text-sm ${activeTab === 'review' 
                                                    ? 'text-indigo-600 dark:text-indigo-500 border-b-2 border-indigo-600 dark:border-indigo-500' 
                                                    : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'}`}
                                            >
                                                <div className="flex items-center gap-2">
                                                    <span>Review</span>
                                                    <span className="bg-indigo-100 dark:bg-indigo-600 text-indigo-800 dark:text-indigo-100 font-semibold px-2.5 py-0.5 rounded-full">
                                                        {reviewSubmissions.length}
                                                    </span>
                                                </div>
                                            </button>
                                        )}
                                    </div>
                                </div>

                                {/* Challenge Cards */}
                                <div className={`grid grid-cols-1 ${!loading && 'md:grid-cols-2 lg:grid-cols-3'} gap-6`}>
                                    {loading ? (
                                        <div className="text-center py-12 bg-white/5 dark:bg-gray-800/20 backdrop-blur-md rounded-xl border border-gray-200 dark:border-gray-700">
                                            <div className="animate-pulse flex flex-col items-center">
                                                <div className="h-12 w-12 bg-gray-300 dark:bg-gray-600 rounded-full mb-4"></div>
                                                <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-1/4 mb-2"></div>
                                                <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-1/3 mb-4"></div>
                                                <div className="h-10 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
                                            </div>
                                        </div>
                                    ) : error ? (
                                        <div className="text-center py-12 bg-white/5 dark:bg-gray-800/20 backdrop-blur-md rounded-xl border border-gray-200 dark:border-gray-700">
                                            <FiTarget className="h-12 w-12 text-red-400 dark:text-red-500 mx-auto mb-4" />
                                            <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">Error Loading Challenges</h3>
                                            <p className="text-gray-600 dark:text-gray-400 max-w-md mx-auto">{error}</p>
                                            <button 
                                                onClick={() => window.location.reload()}
                                                className="mt-4 bg-gradient-to-r from-indigo-500 to-purple-600 text-white px-4 py-2 rounded-md hover:from-indigo-600 hover:to-purple-700 transition-all duration-200 shadow-md hover:shadow-lg"
                                            >
                                                Try Again
                                            </button>
                                        </div>
                                    ) : filteredChallenges.map(challenge => (
                                        <ChallengeCard 
                                            key={challenge.id} // Ensure each child has a unique key
                                            challenge={challenge} 
                                            isCompleted={Boolean(challenge.completion)}
                                            onStartChallenge={() => {
                                                setSelectedChallenge(challenge);
                                                setIsSubmitModalOpen(true);
                                            }}
                                            onViewCompletion={() => {
                                                setSelectedChallenge(challenge);
                                                setIsSubmitModalOpen(true);
                                            }}
                                            promptEngineerLevel={promptEngineerLevel}
                                        />
                                    ))
                                    }
                                </div>
                                
                                {/* Challenge Submission Modal */}
                                {selectedChallenge && (
                                    <ChallengeSubmission
                                        challenge={selectedChallenge}
                                        isOpen={isSubmitModalOpen}
                                        onClose={() => {
                                            setIsSubmitModalOpen(false);
                                            setSelectedChallenge(null);
                                        }}
                                        onSubmit={async (submissionData) => {
                                            try {
                                                setIsSubmitting(true);
                                                
                                                // Store submission data
                                                const challengeId = parseInt(selectedChallenge.id); // Convert to number to ensure proper format
                                                const submissionInfo = {
                                                    challenge_id: challengeId,
                                                    submission_text: submissionData.submission_text,
                                                    submission_url: submissionData.submission_url || '',
                                                    completion_date: new Date().toISOString()
                                                };
                                                                                                
                                                try {
                                                    // Create a payload with explicit types
                                                    const payload = {
                                                        challenge_id: challengeId,
                                                        submission_text: String(submissionInfo.submission_text),
                                                        submission_url: submissionInfo.submission_url ? String(submissionInfo.submission_url) : ''
                                                    };
                                                    
                                                    // Make the API call with the payload
                                                    const response = await axios.post('/prompt-challenges/submit', payload, {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Accept': 'application/json'
                                                        }
                                                    });
                                                    
                                                    if (response.data && response.status === 200) {
                                                        // Get the submission data from the response
                                                        const submissionData = response.data.submission || null;
                                                        
                                                        // Update the challenges data with submission information
                                                        setChallenges(prev => prev.map(challenge => 
                                                            challenge.id === selectedChallenge.id 
                                                                ? {
                                                                    ...challenge, 
                                                                    // Set completion or submitted based on is_completed flag
                                                                    ...(submissionData && submissionData.is_completed 
                                                                        ? { completion: submissionData } 
                                                                        : { submitted: submissionData }
                                                                    )
                                                                }
                                                                : challenge
                                                        ));
                                                        
                                                        // Update user's prompt engineer level with data from the backend
                                                        if (response.data.xp_awarded > 0) {
                                                        
                                                        // Show confetti if next level is reached
                                                        if (response.data.current_level > promptEngineerLevel.currentLevel) {
                                                            setIsConfettiOpen(false);
                                                            setTimeout(() => {
                                                                setIsConfettiOpen(true);
                                                                toast.success('🎉 Congratulations! You advanced to level ' + response.data.current_level + '!');
                                                            }, 1000);
                                                        }
                                                        
                                                        setPromptEngineerLevel(prev => ({
                                                            ...prev,
                                                            currentXP: response.data.current_xp, // Use the current XP from the backend
                                                            currentLevel: response.data.current_level, // Use the current level from the backend
                                                            nextLevelXP: response.data.next_level_xp, // Use the next level XP from the backend
                                                            prevLevelXP: prev.currentLevel > prev.currentLevel ? prev.nextLevelXP : prev.prevLevelXP
                                                        }));
                                                        }
                                                        
                                                        return response.data;
                                                    } else {
                                                        return response;
                                                    }
                                                } catch ({error, response}) {
                                                    toast.error(response?.data?.message || 'Something went wrong while submitting your challenge. Please try again later.');
                                                    return response.data;
                                                }
                                            } catch(error) {
                                                toast.error('Something went wrong while submitting your challenge. Please try again later.');
                                            } finally {
                                                setIsSubmitting(false);
                                            }
                                        }}
                                        onReview={handleReview}
                                        isSubmitting={isSubmitting}
                                        isCompleted={Boolean(selectedChallenge.completion)}
                                        submissionData={selectedChallenge.completion || selectedChallenge.submitted || null}
                                        user={user}
                                    />
                                )}

                                {/* Empty state */}
                                {!loading && !error && filteredChallenges.length === 0 && (
                                    <div className="text-center py-12 bg-white/5 dark:bg-gray-800/20 backdrop-blur-md rounded-xl border border-gray-200 dark:border-gray-700">
                                        {activeTab === 'available' ? (
                                            <>
                                                <FiTarget className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
                                                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">{challenges.length > 0 ? "No Available Challenges" : "Coming Soon!"}</h3>
                                                <p className="text-gray-600 dark:text-gray-400 max-w-md mx-auto px-4">
                                                    {searchQuery || filterDifficulty !== 'all' || filterSkill !== 'all'
                                                        ? "No challenges match your current filters. Try changing your search criteria."
                                                        : challenges.length > 0 ? "You've completed all available challenges! Check back later for new ones." 
                                                        : "Get ready — some awesome challenges are heading your way soon! 🚀 Keep an eye on this page because you won’t want to miss the chance to sharpen your skills and master the art of Prompt Engineering in a fun and engaging way. "}
                                                </p>
                                            </>
                                        ) : activeTab === 'submitted' ? (
                                            <>
                                                <FiUpload className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
                                                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">No Submitted Challenges</h3>
                                                <p className="text-gray-600 dark:text-gray-400 max-w-md mx-auto px-4">
                                                    You haven't submitted any challenges yet that are waiting for verification.
                                                </p>
                                                <button 
                                                    className="mt-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3b7ad9] hover:to-[#0b5ad4] text-white font-medium py-2 px-4 rounded-lg"
                                                    onClick={() => setActiveTab('available')}
                                                >
                                                    Browse Available Challenges
                                                </button>
                                            </>
                                        ) : activeTab === 'review' ? (
                                            <>
                                                <FiShield className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
                                                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">No Challenges to Review</h3>
                                                <p className="text-gray-600 dark:text-gray-400 max-w-md mx-auto px-4">
                                                    There are no challenges waiting for review.
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <FiAward className="h-12 w-12 text-gray-400 dark:text-gray-500 mx-auto mb-4" />
                                                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">No Completed Challenges Yet</h3>
                                                <p className="text-gray-600 dark:text-gray-400 max-w-md mx-auto px-4">
                                                    Start completing challenges to see them appear here and earn badges.
                                                </p>
                                                <button 
                                                    className="mt-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] hover:from-[#3b7ad9] hover:to-[#0b5ad4] text-white font-medium py-2 px-4 rounded-lg"
                                                    onClick={() => setActiveTab('available')}
                                                >
                                                    Browse Available Challenges
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            {user?.role >= RoleEnum.Admin && (
                                <div className="fixed bottom-1.5 right-[1.5rem] z-50 p-4 flex flex-col items-end">
                                    <button className="flex items-center justify-center p-3 rounded-full shadow-lg transition-all duration-300
                                        bg-gradient-to-r from-green-500 to-teal-600 hover:from-green-600 hover:to-teal-700 text-white" title="Create New Challenge"
                                        onClick={() => setIsAddChallengeModalOpen(true)}
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                                        </svg>
                                    </button>
                                </div>
                            )}
                        </DashboardCard>
                    </div>
                </div>
            </div>
            {isConfettiOpen && (
                <ReactConfetti
                    width={window.innerWidth}
                    height={document.body.scrollHeight}
                    numberOfPieces={200}
                    recycle={false}
                    colors={['#F59E0B', '#FBBF24', '#FCD34D', '#FDE68A', '#FEF9C3']}
                />
            )}
            <AddChallengeModal
                isOpen={isAddChallengeModalOpen}
                onClose={() => setIsAddChallengeModalOpen(false)}
                onSubmit={handleAddChallenge}
                challenge={newChallenge}
                setChallenge={setNewChallenge}
                tools={tools}
                userRole={user.role}
            />
        </AppLayout>
    );
};

const PromptChallenges = () => {
    return (
        <ForceAuthentication
            requireRole={RoleEnum.DefaultUser}
            accessDeniedTitle="No Access"
            accessDeniedMessage="You must validate your email to access this page."
        >
            {({ user }) => <PromptChallengesContent user={user} />}
        </ForceAuthentication>
    );
};

export default PromptChallenges;
