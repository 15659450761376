import React, {useEffect, useRef} from "react";

// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";
import {useLocation} from "react-router-dom";
import Button from "../components/base/Button";
import TestList from "../components/quiz/TestList";

function CaipTest() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Get certified Prompt Engineering | Crafting AI Prompts"
  }, []);

  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    goToLocation(-130);
  });

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    goToLocation(-20);
  });

  function goToLocation(yOffset: number) {

    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const element = document.getElementById(lastHash.current);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }

        lastHash.current = '';
      }, 50);
    }
  }

  const headerButtons = () => {
    if (localStorage.getItem("username") !== null && localStorage.getItem("username") !== undefined) {
      return [
        {title: "Dashboard", url: "/dashboard/overview"},
        {title: "Take the test", url: "/dashboard/quiz"}
      ]
    }


    return [
      {title: "No account yet? Register", url: "/register"},
      {title: "Login", url: "/login"},
    ]
  }

const tableData = [
  {
    testName: 'CAIP - 1 - Introduction',
    tests: [
      {
        test: 'IPE',
        description: 'Entry level test to demonstrate your ability to craft effective AI prompts (IPE) via the Crafting AI Prompts Framework, Prompt Engineering, Techniques and Adversarial Prompting.',
        available: 'Available',
        amountOfQuestions: 20,
        timeToCompleteMins: 15,
        isAvailable: true,
        comingSoon: false,
        testLink: "/dashboard/quiz",
        testDetailsLink: "/test/caip-1"
      },
      {
        test: 'IVPE',
        description: 'Entry level test to demonstrate your ability to craft effective AI prompts (IVPE) via the Crafting AI Prompts Framework.',
        available: 'Not available',
        amountOfQuestions: null,
        timeToCompleteMins: null,
        isAvailable: false,
        comingSoon: false
      }
    ]
  },
  {
    testName: 'CAIP - 2 - Advanced Techniques',
    tests: [
      {
        test: 'IPE',
        description: 'Advanced level test to demonstrate your ability to craft effective AI prompts (IPE) via the Crafting AI Prompts Framework.',
        available: 'Not available',
        amountOfQuestions: null,
        timeToCompleteMins: null,
        isAvailable: false,
        comingSoon: false
      }
    ]
  },
  {
    testName: 'CAIP - 3 - Prompt Architecture',
    tests: [
      {
        test: 'IPE',
        description: 'Expert level test to demonstrate your ability to craft effective AI prompts (IPE) via the Crafting AI Prompts Framework.',
        available: 'Not available',
        amountOfQuestions: null,
        timeToCompleteMins: null,
        isAvailable: false,
        comingSoon: false
      }
    ]
  }
];

  return (
    <div className=" dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Test - Information"
                      heightStyle={"h-[440px]"}
                      buttons={headerButtons()}
        />

        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 dark:text-white"
          data-aos="fade-up">
            <div className={"max-w-6xl mx-8"} id="test-information">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div>
                <h3>
                Crafting AI Prompts - <span className="text-header-gradient">certification test</span>
              </h3>
                  <p className="mb-8">
                    Congratulations on considering the <strong>FREE</strong> Crafting AI Prompts (CAIP) certification test! <br />Earning this certificate is a significant achievement that demonstrates your ability to craft effective AI prompts.
                  </p>

                  <h2 className="text-2xl font-semibold mb-4">Why take the CAIP Test?</h2>
              <p>
                The CAIP test(s) is not just a formality; it is a rigorous assessment designed to validate your proficiency in creating AI prompts via the Crafting AI Prompts Framework.
                Completing this test successfully proves that you understand the Crafting AI Prompts Framework, Prompt Engineering, Techniques, Risks, Advanced Techniques (level-2), Adversarial Prompting, Prompt Architecture (level-3).
              </p>

              <h2 className="text-2xl font-semibold mb-4">Test Details</h2>
              <p><strong>Test Availability</strong><br />
                Currently, CAIP - 1 is available, and it is the first step in a series of increasingly challenging tests. CAIP - 2 and CAIP - 3 will be available soon, allowing you to further demonstrate and hone your skills.
              </p>
                </div>
                <div className="flex justify-end hidden lg:block">
                  <img
                    src={require("../assets/img/certification/certification.png")}
                    className="w-full"
                    style={{"maxWidth":"550px"}}
                    alt=""
                  />
                </div>
              </div>

              <TestList tableData={tableData} />

              <p className="mb-8"><strong>Test Duration and Passing Criteria</strong><br />
                The test must be completed within the set timeframe. You can look up the amount of time you have per test in the table above, and you need to score at least 85% to pass. This time constraint ensures that only those who are truly adept at crafting AI prompts can achieve certification. The high passing threshold underscores the value and difficulty of the certificate, making it a true indicator of expertise.
              </p>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-4">
              <div className="flex justify-end hidden lg:block">
                  <img
                    src={require("../assets/img/certification/certificate-received.png")}
                    className="w-full"
                    style={{"maxWidth":"550px"}}
                    alt=""
                  />
                </div>
                <div>
                  <h2 className="text-2xl font-semibold mb-4">What you gain</h2>
                  <p className="mb-3">
                    Upon passing, you will receive a Certificate of Achievement in your personal dashboard.
                    You can share this certificate on your LinkedIn profile, resume, or other professional platforms to show your expertise in AI prompt crafting.
                    It signifies that you have met a high standard of excellence in AI prompt crafting, and maybe it opens up new opportunities for you.
                    Each certificate has a unique ID that can be verified on our website to ensure its authenticity.
                  </p>
                  <p className="mb-8">
                    We encourage you to take this opportunity to challenge yourself and prove your abilities. <br />
                    Please note, if you do not pass the test, you can retake it after 14 days. So only start the test when you are ready!
                  </p>

                  <h2 className="text-2xl font-semibold mb-4">How to get certified in Prompt Engineering?</h2>
                  <p>You can register an account on this website, or if you already have an account, log in to your account to access your personal dashboard. Your account must be validated via the link sent to your email. When done, your account will be automatically upgraded to take tests, and access other features like rating and reviewing tools.</p>
                  <p>When your account is validated, the button to start the test will appear in your dashboard. For each test, you'll first see an introduction page explaining what to expect in the test, how much time you have to complete it, and the minimum score required to earn your certificate.</p>
                </div>
              </div>

              <Button url={"/register"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white mr-5">Register</Button>
              <Button url={"/login"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white mr-5 mb-5">Login</Button>
              <Button url={"/test/terms"} styles="max-w-full px-8 py-4 border border-[#0c66ee] dark:text-white">Terms and Conditions</Button>
            </div>
          </div>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default CaipTest;
