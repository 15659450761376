import { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PromptLibraryPrompt } from '../types/PromptLibraryPrompt';
import { Tool } from '../types/Tool';
import { PromptForm, PromptFormData } from '../forms/PromptForm';
import { PromptEditContext } from '../../../pages/dashboard/library';

interface EditPromptModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (updatedPrompt: PromptLibraryPrompt) => void;
    prompt: PromptLibraryPrompt;
    tools: Tool[];
}

export const EditPromptModal = ({ isOpen, onClose, onSave, prompt, tools }: EditPromptModalProps) => {
    // Get the refresh function from context
    const { refreshPromptLibrary } = useContext(PromptEditContext);
    // Track dark mode state
    const [isDarkMode, setIsDarkMode] = useState(false);
    
    // Check if dark mode is enabled
    useEffect(() => {
        const checkDarkMode = () => {
            const isDark = document.getElementById('root')?.classList.contains('dark') || false;
            setIsDarkMode(isDark);
        };
        
        checkDarkMode();
        
        // Set up an observer to detect changes to the dark mode
        const observer = new MutationObserver(checkDarkMode);
        const rootElement = document.getElementById('root');
        
        if (rootElement) {
            observer.observe(rootElement, { attributes: true, attributeFilter: ['class'] });
        }
        
        return () => observer.disconnect();
    }, [isOpen]);
    
    const [formData, setFormData] = useState<PromptFormData>({
        title: prompt.title,
        prompt: prompt.prompt,
        section: prompt.section,
        labels: prompt.labels,
        toolId: prompt.toolId || '',
        type: prompt.type || 'component'
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        
        const updatedPrompt: PromptLibraryPrompt = {
            ...prompt,
            ...formData
        };
        
        onSave(updatedPrompt);
        
        // Trigger a refresh of the PromptLibrary component to update recipes
        refreshPromptLibrary();
        
        onClose();
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={`w-full max-w-2xl transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all backdrop-blur-sm ring-1 ${isDarkMode ? 'bg-gray-800/95 ring-white/5 text-white' : 'bg-white/95 ring-black/5 text-gray-900'}`}>
                                <Dialog.Title
                                    as="h3"
                                    className={`text-lg font-medium leading-6 mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                                >
                                    Edit Prompt
                                </Dialog.Title>

                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <PromptForm
                                        formData={formData}
                                        onFormDataChange={setFormData}
                                        tools={tools}
                                    />

                                    <div className="mt-6 flex justify-end space-x-3">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className={`px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isDarkMode ? 'text-gray-300 hover:bg-gray-700 focus:ring-offset-gray-800' : 'text-gray-700 hover:bg-gray-50'}`}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className={`px-4 py-2 text-sm font-medium text-white rounded-md transition-all duration-200 shadow-sm hover:shadow transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isDarkMode ? 'bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 focus:ring-offset-gray-800' : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700'}`}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
