import React from "react";
// Layouts
import DefaultLayout from "./layouts/default";
// Components
import BaseButton from "./components/base/Button";
import BaseSection from "./components/base/Section";
import BaseAccordion from "./components/base/Accordion";
import ThreePhasesSection from "./components/sections/ThreePhases";
import BackToTop from "./components/base/BackToTop";
import HeaderBanner from "./components/sections/HeaderBanner";
import { Toaster } from 'react-hot-toast';

function App() {
  const accordions = [
    {
      key: "adopt-framework",
      title: "Why adopt this framework?",
      description:
        `<p>It's essential to ensure coherence and clarity across your organization when it comes to crafting prompts. This framework guides you on what elements to include in your prompts and how to consistently structure them throughout your organization. The goal is to achieve uniformity in communication and efficiency in the processes.</p>`,
    },
    {
      key: "non-disclosure-necessary",
      title: "Is the Non-Disclosure clause necessary in a private instance?",
      description:
          `<p>The non-disclosure clause is essential, serving as a reminder to safeguard company data and comply with GDPR and other security protocols. Even in a private environment, it's crucial to maintain these standards. While a private instance provides a controlled setting, the commitment to non-disclosure should remain a top priority at all times for utmost data security and legal compliance.</p>` +
          `<p>Additionally, even if companies restrict their employees to using private instances, they might still use other tools without permission. Some tools offer both private and public versions, which may not differ significantly from each other. Sometimes, it requires logging in with a work account to access the private instance. This must be verified before sharing confidential data. This is yet another reason to always emphasize the "Non-disclosure" component.</p>`,
    },
    {
      key: "support-image-creation",
      title: "Does this framework also support image creation?",
      description: "<p>Yes, this framework focuses on text-based and media interactions. For image interaction, read the IVPE framework section on the framework page.</p>",
    },
    {
      key: "can-i-use-this",
      title: "Can I use this content for my presentation, blog, or other materials?",
      description: `<p>I am delighted when people write about or present the framework and other content from this website.
          Sharing this knowledge helps reach a wider audience and supports the mission.
          However, it is imperative that any use of our materials includes a clear and visible reference to craftingaiprompts.org.
          This applies to all forms of usage, including but not limited to:</p>
          <p>
            <ul class="px-6 list-decimal">
              <li>Articles and blog posts: Ensure you clearly mention and link back to craftingaiprompts.org.</li>
              <li>Presentations and educational materials: Include visible credits to the website in your slides or handouts.</li>
              <li>Adaptations and derivative works: While you are encouraged to write about the content in your own words or adapt it to your presentation style, a visible reference to craftingaiprompts.org is mandatory.</li>
              <li>Please do not copy the complete content verbatim. Instead, interpret it in your own way and present it in a manner that best serves your audience, always giving appropriate credit to our website.</li>
            </ul>
          </p>
          <p>Please read more in our <a href="/content-policy#content-policy" title="Content Usage Policy" class="text-blue-500 text-bold">Content Usage Policy</a>.</p>
          <p>For any queries regarding usage or to seek permission for special cases, <a href="/contact" title="Contact Us" class="text-blue-500 text-bold" target='_blank'>please contact us</a>. If you have seen content from this website used without proper reference or behind a paywall, please let us know!</p>
          <p>Thank you for respecting this work and ensuring that proper attribution is given.</p>`
    },
    {
      key: "founded-framework",
      title: "Who founded the Crafting AI Prompts Framework?",
      description: "<p><a href=\"https://www.jeroenegelmeers.nl/\" title=\"Jeroen Egelmeers - Personal website\" class=\"text-blue-500 text-bold\" target='_blank'>Jeroen Egelmeers</a>, Currently: Prompt Engineering Advocate at Sogeti Netherlands. <a href=\"https://www.linkedin.com/in/jegelmeers/\" title=\"LinkedIn - Jeroen Egelmeers\" class=\"text-blue-500 text-bold\">Follow me via LinkedIn</a> or <a href=\"/contact\" title=\"Contact Us\" class=\"text-blue-500 text-bold\">send me an email</a>.</p>",
    },
    {
      key: "how-to-get-certified",
      title: "Can I get a free certification for the Crafting AI Prompts Framework?",
      description: `<p>Yes! Please visit the <a href="/test" title="Get certified - Prompt Engineering" class="text-blue-500 text-bold">Certification page</a> for more information.</p>`,
    },
    {
      key: "why-the-hedgehogs",
      title: "Fun Fact: Why the hedgehogs?",
      description: `<p>The reason you see hedgehogs everywhere on this website is that the lastname of the founder, Jeroen Egelmeers, starts with "Egel", which is Dutch for "Hedgehog". This is why you'll see it everywhere!</p>`,
    },
  ];

  return (
    <div className="App">
      <Toaster position="top-right" />
      <DefaultLayout>
        <HeaderBanner title="Framework"
                      buttons={[
                        {title: "Get started - Framework", url: "/documentation/framework"},
                        {title: "Techniques", url: "/documentation/prompt-techniques"},
                        {title: "Injections", url: "/documentation/prompt-injections"}
                      ]}
        />

        {/* <!-- Cheatsheet section --> */}
        <section className="w-full my-6">
          <BaseSection>
            <div
                data-aos="fade-right"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-5 hidden lg:block"
            >
              <div className="w-full">
                <img
                    src={require(`./assets/img/homepage/learn-prompting1.png`)}
                    className="w-full"
                    style={{"maxWidth":"450px"}}
                    alt=""/>
              </div>
            </div>
            <div
                data-aos="fade-left"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-7 px-4 sm:px-6 mt-8 dark:text-white"
            >
              <span className="text-base text-gradient font-semibold uppercase mb-4 sm:mb-2">
                Crafting AI Prompts
              </span>
              <h2 className="text-4xl font-semibold">
              <span className="text-header-gradient">Learn</span> Prompt Engineering {" "}
              </h2>
              <div className="space-y-2">
                <p className="text-base/6">
                Are you looking to master Prompt Engineering and harness the power of Generative AI? This website offers everything you need to become an expert in crafting high-quality prompts. From step-by-step guides and framework documentation to advanced techniques and adversarial prompting, we’ve got you covered. Whether you're a beginner or looking to refine your skills, our resources are designed to help you succeed. Plus, earn a certification in prompt engineering to showcase your expertise to employers and clients. Start learning today and take your AI skills to the next level!
                </p>
              </div>
              <div className="space-y-2" style={{"marginTop":"40px"}}>
                <BaseButton url={"/documentation/large-language-models#llms"}
                            styles="mr-5 animate-bounce temporary-bounce hover:animate-none duration-100 px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                  Learn Prompt Engineering!
                </BaseButton>
                <BaseButton url={"/documentation/framework/cheatsheet#cheatsheet"}
                            styles="temporary-bounce hover:animate-none duration-100 px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                  Cheatsheet
                </BaseButton>
              </div>
            </div>
          </BaseSection>
        </section>

        {/*/!* <!-- Latest warnings --> *!/*/}
        <section className="w-full pb-3 dark:text-white dark:bg-slate-900">
          <BaseSection>
            <div className="col-span-12 lg:col-span-6 px-4 sm:px-6 mt-10" data-aos-delay="150" data-aos="fade-left">
              <span className="text-base text-gradient font-semibold uppercase">
                Adversarial Prompting
              </span>
              <h2 className="text-4xl font-semibold">
                Latest{" "}
                <span className="text-header-gradient-warning animate-pulse">Warnings</span>
              </h2>
              <p className={"space-y-2 mb-6"}>
                Make sure to keep up-to-date with the latest information regarding prompt-injections or other important updates.
              </p>
              <div className={"space-y-5"}>
                <BaseButton
                    url={"/documentation/adversarial-prompting/prompt-injections#memory-injection"}
                    styles="w-full px-10 py-4 bg-inherit border border-[#880808] dark:border-[#ffacac] text-base text-[#880808] dark:text-[#ffacac] hover:shadow-[#880808]/50">
                  Memory Manipulation
                </BaseButton>
                <BaseButton
                    url={"/documentation/adversarial-prompting/prompt-jailbreak#ASCII-Art-based-jailbreak"}
                    styles="w-full px-10 py-4 bg-inherit border border-[#880808] dark:border-[#ffacac] text-base text-[#880808] dark:text-[#ffacac] hover:shadow-[#880808]/50">
                  ASCII Art-based Prompt Injection
                </BaseButton>
                <BaseButton
                    url={"/documentation/adversarial-prompting/prompt-injections#image-injection-the-image-injection"}
                    styles="w-full px-10 py-4 bg-inherit border border-[#880808] dark:border-[#ffacac] text-base text-[#880808] dark:text-[#ffacac] hover:shadow-[#880808]/50">
                  Image (Dall-e3) Prompt Injection
                </BaseButton>
                <p>Do you've any important information to share? Get in touch!</p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 hidden lg:block" data-aos-delay="150" data-aos="fade-right">
              <div className="w-full">
                <img
                    src={require("./assets/img/homepage/warnings1.png")}
                    className="w-[95%] m-auto mt-8"
                    style={{"maxWidth":"500px"}}
                    alt=""
                />
              </div>
            </div>
          </BaseSection>
        </section>

        {/* <!-- Prompt challenges section --> */}
        <section className="w-full my-3 dark:text-white dark:bg-slate-900 mb-12">
          <BaseSection>
            <div
                data-aos="fade-right"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-5 hidden lg:block"
            >
              <div className="w-full">
                <img
                    src={require("./assets/img/homepage/prompt-challenges.png")}
                    className="max-w-[400px] m-auto mt-2"
                    alt=""
                />
              </div>
            </div>
            <div
                data-aos="fade-left"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-7 px-4 sm:px-6 mt-8"
            >
              <span className="text-base text-gradient font-semibold uppercase mb-4 sm:mb-2">
                Learn on a fun way!
              </span>
              <h2 className="text-3xl sm:text-4xl font-semibold mb-10 sm:mb-6 dark:text-white">
                Prompt <span className="text-header-gradient">Challenges</span>
              </h2>

              <p className="space-y-2 mb-6">
                Every week, we release new challenges that you can participate in to gain XP and learn. These challenges are designed to
                help you improve your Prompt Engineering skills. Discuss them in your team, and have fun!
              </p>
              <p>
                To participate in the challenges, you need to login to the website. If you don't have an account yet, you can register a new one. Creating an account and participating in the challenges is completely free.
              </p>
              <BaseButton url={"/login"} styles="mr-5 mb-4 px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                Login
              </BaseButton>
              <BaseButton url={"/dashboard/prompt-challenges"} styles="mr-5 px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                Prompt Challenges
              </BaseButton>
            </div>
          </BaseSection>
        </section>

        {/* <!-- Prompt Library section --> */}
        <section className="w-full my-3 dark:text-white dark:bg-slate-900 mb-12">
          <BaseSection>
            <div
                data-aos="fade-left"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-7 px-4 sm:px-6 mt-8"
            >
              <span className="text-base text-gradient font-semibold uppercase mb-4 sm:mb-2">
                Store prompts like a pro!
              </span>
              <h2 className="text-3xl sm:text-4xl font-semibold mb-10 sm:mb-6 dark:text-white">
                Prompt <span className="text-header-gradient">Library</span>
              </h2>

              <p className="space-y-2 mb-6">
                With our Prompt Library, you can create and manage your prompts in a sustainable way. Creating prompt components and recipes can be a pain, but with our library, you can simply drag and drop your prompts together to create new components and recipes. It's that simple! By using our library, you can easily maintain and update your prompts, which will help you to keep your prompts organized and up-to-date.    
              </p>
              <p className="space-y-2 mb-6">
                To use our library, you need to login to the website. If you don't have an account yet, you can register a new one. Creating an account and using the library is completely free.
              </p>
              <BaseButton url={"/login"} styles="mr-5 mb-4 px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                Login
              </BaseButton>
              <BaseButton url={"/dashboard/prompt-library"} styles="mr-5 px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                Prompt Component Library
              </BaseButton>
            </div>

            <div
                data-aos="fade-right"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-5 hidden lg:block"
            >
              <div className="w-full">
                <img
                    src={require("./assets/img/homepage/library.png")}
                    className="max-w-[400px] m-auto mt-2"
                    alt=""
                />
              </div>
            </div>
          </BaseSection>
        </section>

        {/* <!-- FAQ section --> */}
        <section className="w-full my-6">
          <BaseSection>
            <div
                data-aos="fade-right"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-6 hidden lg:block"
            >
              <div className="w-full">
                <img
                    src={require("./assets/img/homepage/crafting-ai-prompts-faq.png")}
                    className="w-full"
                    alt=""
                />
              </div>
            </div>
            <div
                data-aos="fade-left"
                data-aos-delay="150"
                className="col-span-12 lg:col-span-6 px-4 sm:px-6 mt-8"
            >
              <span className="text-base text-gradient font-semibold uppercase mb-4 sm:mb-2">
                Crafting AI Prompts
              </span>
              <h2 className="text-3xl sm:text-4xl font-semibold mb-10 sm:mb-6 dark:text-white">
                Frequently Asked <span className="text-header-gradient">Questions</span>
              </h2>

              <ul className="shadow-box">
                {accordions.map((accordion: any) => {
                  return <BaseAccordion accordion={accordion} key={accordion.key} />;
                })}
              </ul>
            </div>
          </BaseSection>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default App;
