import {ReactComponent as Logo} from "../../assets/img/logo/crafting-ai-logo.svg";
import AuthCard from '../../components/dashboard/AuthCard'
import AuthSessionStatus from '../../components/dashboard/AuthSessionStatus'
import AuthValidationErrors from '../../components/dashboard/AuthValidationErrors'
import Button from '../../components/dashboard/Button'
import GuestLayout from '../../components/dashboard/Layouts/GuestLayout'
import Input from '../../components/dashboard/Input'
import Label from '../../components/dashboard/Label'
import { useAuth } from '../../hooks/auth'
import { useState } from 'react'
import {NavLink} from 'react-router-dom';

const ForgotPassword = () => {
  const { forgotPassword } = useAuth({ middleware: 'guest' })

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)

  const submitForm = event => {
    event.preventDefault()
    forgotPassword({ email, setErrors, setStatus })
  }

  return (
    <GuestLayout>
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 px-4 sm:px-6 lg:px-8 transition-all duration-300">
        <div className="max-w-md w-full space-y-8 bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 transform hover:scale-[1.02] transition-all duration-300 border border-gray-100 dark:border-gray-700">
          <div className="text-center">
            <NavLink to="/" className="inline-block">
              <Logo className="h-12 w-auto mx-auto hover:opacity-80 transition-opacity" />
            </NavLink>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
              Forgot your password?
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              No problem. Enter your email address and we'll send you a password reset link.
            </p>
          </div>

          {/* Session Status */}
          <AuthSessionStatus 
            className="mb-4 p-4 rounded-lg bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400" 
            status={status} 
          />

          {/* Validation Errors */}
          <AuthValidationErrors className="mb-4" errors={errors} />

          <form onSubmit={submitForm} className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm space-y-4">
              {/* Email Address */}
              <div>
                <Label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email address
                </Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  className="appearance-none relative block w-full px-3 py-2 mt-1 border border-gray-300 dark:border-gray-600 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-400 dark:focus:border-blue-400 dark:bg-gray-700 transition-colors duration-200"
                  onChange={event => setEmail(event.target.value)}
                  placeholder="you@example.com"
                  required
                  autoFocus
                />
              </div>
            </div>

            <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-4">
              <NavLink
                to="/login"
                className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300 transition-colors duration-200 w-full sm:w-auto text-center"
              >
                ← Back to login
              </NavLink>
              <Button 
                className="group relative w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 dark:from-blue-600 dark:to-blue-700 dark:hover:from-blue-700 dark:hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transform hover:scale-[1.02] transition-all duration-200"
              >
                Send Reset Link
              </Button>
            </div>
          </form>

          <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
            <NavLink
              to="/"
              className="flex items-center justify-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
              </svg>
              Back to Website
            </NavLink>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}

export default ForgotPassword;
