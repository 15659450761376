import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import React, {useEffect, useState, createContext} from "react";
import RoleEnum from "../../components/enums/RoleEnum";
import ForceAuthentication from '../../components/auth/ForceAuthentication';
import PromptLibrary from '../prompt-library/PromptLibrary';

// Create a context to track prompt component edits
export const PromptEditContext = createContext({
    refreshPromptLibrary: () => {},
});

const LibraryContent = ({ user }) => {
    const [promptLibraryKey, setPromptLibraryKey] = useState(Date.now());

    // Main data fetching effect
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Prompt Library";
    }, [user]);

    // Function to refresh the PromptLibrary component
    const refreshPromptLibrary = () => {
        setPromptLibraryKey(Date.now());
    };

    return (
        <PromptEditContext.Provider value={{ refreshPromptLibrary }}>
            <AppLayout
                    header={
                        <h2 className="font-semibold text-xl text-gray-900 dark:text-white leading-tight">
                            Prompt Library <span className="bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-100 font-semibold px-2.5 py-1 rounded-md ml-2 text-xs">BETA</span>
                        </h2>
                    }
                >
                    <div className="bg-gray-50/50 dark:bg-gray-900 py-8">
                        <PromptLibrary key={promptLibraryKey} />
                    </div>
                </AppLayout>
        </PromptEditContext.Provider>
    );
};

const Library = () => {
    return (
        <ForceAuthentication
            requireRole={RoleEnum.DefaultUser}
            accessDeniedTitle="No Access"
            accessDeniedMessage="You must validate your email to access this page."
        >
            {({ user }) => <LibraryContent user={user} />}
        </ForceAuthentication>
    );
};

export default Library;
