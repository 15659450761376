import AuthValidationErrors from '../../components/dashboard/AuthValidationErrors'
import Button from '../../components/dashboard/Button'
import GuestLayout from '../../components/dashboard/Layouts/GuestLayout'
import Input from '../../components/dashboard/Input'
import Label from '../../components/dashboard/Label'
import { useAuth } from '../../hooks/auth'
import React, {useEffect, useState} from 'react'
import {Link, NavLink} from 'react-router-dom';
import {ReactComponent as Logo} from "../../assets/img/logo/crafting-ai-logo.svg";

const Register = () => {
    const { register } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/dashboard'
    })

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirmation] = useState('')
    const [errors, setErrors] = useState([])
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const submitForm = event => {
        event.preventDefault()
        register({ name, email, password, password_confirmation, setErrors })
    }

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Register"
    }, []);

    return (
        <GuestLayout>
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 px-4 sm:px-6 lg:px-8 transition-all duration-300">
                <div className="max-w-md w-full space-y-8 bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 transform hover:scale-[1.02] transition-all duration-300 border border-gray-100 dark:border-gray-700">
                    <div className="text-center">
                        <Link to="/" className="inline-block">
                            <Logo className="h-12 w-auto mx-auto hover:opacity-80 transition-opacity" />
                        </Link>
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
                            Create your account
                        </h2>
                        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                            Join our community and start your AI prompt crafting journey
                        </p>
                    </div>

                    {/* Validation Errors */}
                    <AuthValidationErrors className="mb-4" errors={errors} />

                    <form onSubmit={submitForm} className="mt-8 space-y-6">
                        <div className="rounded-md shadow-sm space-y-4">
                            {/* Name */}
                            <div>
                                <Label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Full Name <span className="text-xs text-gray-500 dark:text-gray-400">(Will appear on certificates)</span>
                                </Label>
                                <Input
                                    id="name"
                                    type="text"
                                    value={name}
                                    className="appearance-none relative block w-full px-3 py-2 mt-1 border border-gray-300 dark:border-gray-600 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-400 dark:focus:border-blue-400 dark:bg-gray-700 transition-colors duration-200"
                                    onChange={event => setName(event.target.value)}
                                    placeholder="John Doe"
                                    required
                                    autoFocus
                                />
                            </div>

                            {/* Email */}
                            <div>
                                <Label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email address <span className="text-xs text-gray-500 dark:text-gray-400">(validation mail will be sent)</span></Label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={email}
                                    className="appearance-none relative block w-full px-3 py-2 mt-1 border border-gray-300 dark:border-gray-600 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-400 dark:focus:border-blue-400 dark:bg-gray-700 transition-colors duration-200"
                                    onChange={event => setEmail(event.target.value)}
                                    placeholder="you@example.com"
                                    required
                                />
                            </div>

                            {/* Password */}
                            <div>
                                <Label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Password</Label>
                                <Input
                                    id="password"
                                    type="password"
                                    value={password}
                                    className="appearance-none relative block w-full px-3 py-2 mt-1 border border-gray-300 dark:border-gray-600 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-400 dark:focus:border-blue-400 dark:bg-gray-700 transition-colors duration-200"
                                    onChange={event => setPassword(event.target.value)}
                                    placeholder="Enter your password"
                                    required
                                    autoComplete="new-password"
                                />
                            </div>

                            {/* Confirm Password */}
                            <div>
                                <Label htmlFor="password_confirmation" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Confirm Password
                                </Label>
                                <Input
                                    id="password_confirmation"
                                    type="password"
                                    value={password_confirmation}
                                    className="appearance-none relative block w-full px-3 py-2 mt-1 border border-gray-300 dark:border-gray-600 placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-400 dark:focus:border-blue-400 dark:bg-gray-700 transition-colors duration-200"
                                    onChange={event => setPasswordConfirmation(event.target.value)}
                                    placeholder="Confirm your password"
                                    required
                                />
                            </div>
                        </div>

                        {/* Terms */}
                        <div className="flex items-center">
                            <input
                                id="terms"
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 dark:text-blue-400 focus:ring-blue-500 dark:focus:ring-blue-400 border-gray-300 dark:border-gray-600 rounded transition-colors duration-200"
                                onChange={() => setAcceptedTerms(!acceptedTerms)}
                            />
                            <label htmlFor="terms" className="ml-2 text-sm text-gray-600 dark:text-gray-400">
                                I agree to the <a href="/account/terms" className="text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300 transition-colors duration-200">terms and conditions</a>
                            </label>
                        </div>

                        <div>
                            <Button 
                                className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white ${!acceptedTerms ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 dark:from-blue-600 dark:to-blue-700 dark:hover:from-blue-700 dark:hover:to-blue-800'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transform hover:scale-[1.02] transition-all duration-200`}
                                disabled={!acceptedTerms}
                            >
                                Create Account
                            </Button>
                        </div>

                        <div className="text-center">
                            <NavLink
                                to="/login"
                                className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300 transition-colors duration-200"
                            >
                                Already have an account? Sign in
                            </NavLink>
                        </div>
                    </form>

                    <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
                        <NavLink
                            to="/"
                            className="flex items-center justify-center text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 transition-colors duration-200"
                        >
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                            </svg>
                            Back to Website
                        </NavLink>
                    </div>
                </div>
            </div>
        </GuestLayout>
    )
}

export default Register