import React, { useState, useEffect } from 'react';

/**
 * CustomSlider Component
 * 
 * A custom slider UI component with gradient fill and interactive markers.
 * Features:
 * - Gradient-filled track
 * - Interactive markers for each value
 * - Animated thumb
 * - Supports drag and click interactions
 * - Dark mode support
 * 
 * @param {number} value - Current value of the slider
 * @param {function} onChange - Function to call when value changes
 * @param {number} max - Maximum value of the slider (default: 5)
 * @returns {JSX.Element}
 */
const CustomSlider = ({ value, onChange, max = 5 }) => {
    const [localValue, setLocalValue] = useState(value);
    const [isDragging, setIsDragging] = useState(false);
    
    useEffect(() => {
        if (!isDragging) {
            setLocalValue(value);
        }
    }, [value, isDragging]);

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        onChange(localValue);
    };

    const handleChange = (e) => {
        const newValue = parseInt(e.target.value);
        setLocalValue(newValue);
        if (!isDragging) {
            onChange(newValue);
        }
    };

    return (
        <div className="relative w-full h-6 flex items-center">
            {/* Track background */}
            <div className="absolute h-1.5 w-full bg-gray-200 dark:bg-gray-700 rounded-full"></div>
            
            {/* Filled track */}
            <div 
                className="absolute h-1.5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] rounded-full" 
                style={{ width: `${(localValue / max) * 100}%` }}
            ></div>
            
            {/* Markers */}
            {Array.from({ length: max + 1 }).map((_, index) => (
                <div 
                    key={index} 
                    className={`absolute h-3 w-1 rounded-full ${index <= localValue ? 'bg-[#0c66ee]' : 'bg-gray-300 dark:bg-gray-600'}`}
                    style={{ left: `${(index / max) * 100}%`, transform: 'translateX(-50%)' }}
                ></div>
            ))}
            
            {/* Thumb */}
            <div 
                className="absolute h-5 w-5 bg-white dark:bg-gray-800 border-2 border-[#0c66ee] rounded-full shadow-md"
                style={{ 
                    left: `${(localValue / max) * 100}%`, 
                    transform: 'translateX(-50%)',
                    cursor: 'pointer'
                }}
            ></div>
            
            {/* Hidden input for accessibility */}
            <input 
                type="range" 
                min="0" 
                max={max} 
                value={localValue} 
                onChange={handleChange}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                className="absolute w-full h-6 opacity-0 cursor-pointer"
            />
        </div>
    );
};

export default CustomSlider;
