import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';

const QRCodeGenerator = () => {
    const [pathToSource, setPathToSource] = useState('documentation/framework/cheatsheet');
    const [hashToSource, setHashToSource] = useState('cheatsheet');
    const [campaignId, setCampaignId] = useState('');
    const [campaignSource, setCampaignSource] = useState('');
    const [campaignMedium, setCampaignMedium] = useState('qrcode');
    const [campaignName, setCampaignName] = useState('');
    const [url, setUrl] = useState('');
    const [showQRCode, setShowQRCode] = useState(false);

    useEffect(() => {
        updateURL();
    }, [pathToSource, hashToSource, campaignId, campaignSource, campaignMedium, campaignName]);

    const updateURL = () => {
        let newUrl = 'https://craftingaiprompts.org/';
        if (pathToSource) newUrl += pathToSource;

        const params = new URLSearchParams();
        if (campaignId) params.append('utm_id', campaignId);
        if (campaignSource) params.append('utm_source', campaignSource);
        if (campaignMedium) params.append('utm_medium', campaignMedium);
        if (campaignName) params.append('utm_campaign', campaignName);

        if (params.toString()) {
            newUrl += `?${params.toString()}`;
        }

        if (hashToSource) newUrl += `#${hashToSource}`;

        setUrl(newUrl);
    };

    const handleGenerate = () => {
        setShowQRCode(true);
    };

    const handleDownload = () => {
        const svg = document.getElementById('qr-code');
        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
                const pngFile = canvas.toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.download = 'qrcode_'+campaignId+'.png';
                downloadLink.href = pngFile;
                downloadLink.click();
            };
            img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
        }
    };

    return (
        <div className="w-full max-w-3xl mx-auto">
            <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200/70 dark:border-gray-700/50 overflow-hidden shadow-sm hover:shadow-md transition-all duration-300">
                {/* Header */}
                <div className="px-6 py-4 bg-gradient-to-r from-blue-50/50 to-transparent dark:from-blue-900/10 dark:to-transparent border-b border-gray-100/70 dark:border-gray-700/30">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white">QR Code Generator</h2>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">Create custom QR codes for your campaigns</p>
                </div>

                {/* Content */}
                <div className="p-6 space-y-6">
                    {/* URL Configuration */}
                    <div className="space-y-4">
                        <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">URL Configuration</h3>
                        <div className="space-y-3">
                            <input
                                type="text"
                                value={pathToSource}
                                onChange={(e) => setPathToSource(e.target.value)}
                                placeholder="Path to source (optional)"
                                className="w-full px-4 py-2.5 bg-gray-50 dark:bg-gray-900/50 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/30 focus:border-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200"
                            />
                            <input
                                type="text"
                                value={hashToSource}
                                onChange={(e) => setHashToSource(e.target.value)}
                                placeholder="Hash to source (e.g., cheatsheet)"
                                className="w-full px-4 py-2.5 bg-gray-50 dark:bg-gray-900/50 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/30 focus:border-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200"
                            />
                        </div>
                    </div>

                    {/* Campaign Settings */}
                    <div className="space-y-4">
                        <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Campaign Settings</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <input
                                type="text"
                                value={campaignId}
                                onChange={(e) => setCampaignId(e.target.value)}
                                placeholder="Campaign ID (e.g., workshop_COMPNAME_ID)"
                                className="w-full px-4 py-2.5 bg-gray-50 dark:bg-gray-900/50 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/30 focus:border-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200"
                            />
                            <input
                                type="text"
                                value={campaignSource}
                                onChange={(e) => setCampaignSource(e.target.value)}
                                placeholder="Campaign Source (e.g., COMPNAME)"
                                className="w-full px-4 py-2.5 bg-gray-50 dark:bg-gray-900/50 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/30 focus:border-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200"
                            />
                            <input
                                type="text"
                                value={campaignMedium}
                                onChange={(e) => setCampaignMedium(e.target.value)}
                                placeholder="Campaign Medium (e.g., qrcode)"
                                className="w-full px-4 py-2.5 bg-gray-50 dark:bg-gray-900/50 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/30 focus:border-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200"
                            />
                            <input
                                type="text"
                                value={campaignName}
                                onChange={(e) => setCampaignName(e.target.value)}
                                placeholder="Campaign Name"
                                className="w-full px-4 py-2.5 bg-gray-50 dark:bg-gray-900/50 border border-gray-200 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/30 focus:border-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200"
                            />
                        </div>
                    </div>

                    {/* Generated URL */}
                    <div className="space-y-2">
                        <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">Generated URL</h3>
                        <div className="p-4 bg-gray-50 dark:bg-gray-900/50 rounded-lg border border-gray-200 dark:border-gray-700">
                            <a href={url} target="_blank" rel="noreferrer" className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 break-all transition-colors duration-200">
                                {url}
                            </a>
                        </div>
                    </div>

                    {/* QR Code Display */}
                    {showQRCode && (
                        <div className="flex flex-col items-center justify-center p-6 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900/50 dark:to-gray-800 rounded-xl border border-gray-200 dark:border-gray-700">
                            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
                                <QRCodeSVG
                                    id="qr-code"
                                    value={url}
                                    size={200}
                                    level="H"
                                    includeMargin={true}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/* Actions */}
                <div className="px-6 py-4 bg-gray-50 dark:bg-gray-900/50 border-t border-gray-100/70 dark:border-gray-700/30">
                    {!showQRCode ? (
                        <button
                            onClick={handleGenerate}
                            className="w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white py-2.5 px-4 rounded-lg font-medium shadow-sm hover:shadow focus:outline-none focus:ring-2 focus:ring-blue-500/20 dark:focus:ring-blue-500/30 transition-all duration-200"
                        >
                            Generate QR Code
                        </button>
                    ) : (
                        <button
                            onClick={handleDownload}
                            className="w-full bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white py-2.5 px-4 rounded-lg font-medium shadow-sm hover:shadow focus:outline-none focus:ring-2 focus:ring-green-500/20 dark:focus:ring-green-500/30 transition-all duration-200"
                        >
                            Download QR Code
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QRCodeGenerator;