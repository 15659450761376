import React, { useState } from 'react';
import { MessageCircle, Send, User, Bot, Copy, ThumbsUp, Cog } from 'lucide-react';

interface PromptExample1Props {
    userPrompt?: string;
    assistantResponse?: string;
    systemPrompt?: string;
    title?: string;
    description?: string;
    showChat?: boolean;
    showActions?: boolean;
  }

  const PromptExampleChat: React.FC<PromptExample1Props> = ({
    userPrompt,
    assistantResponse,
    systemPrompt,
    title,
    description,
    showChat = false,
    showActions = false,
  }: PromptExample1Props) => {
  const ChatWindow = () => {
    return (   
        <div className={`flex items-center rounded-lg dark:bg-gray-800 dark:text-gray-100 px-3 py-2`}>
            <input
            type="text"
            placeholder="Type your message..."
            className={`flex-1 bg-transparent border-none focus:ring-0 outline-none dark:text-gray-100 dark:placeholder:text-gray-500 text-gray-800 placeholder:text-gray-400`}
            disabled 
            />
            <button className="disabled p-2 rounded-md bg-purple-600 hover:bg-purple-700 text-white transition-colors cursor-not-allowed opacity-50">
                <Send className="h-4 w-4" />
            </button>
        </div>
    );
  };

    const ActionButtons = ({ copyText = '', thumbsUp = false }) => {
        const [tooltip, setTooltip] = useState('');

        const handleCopy = () => {
            navigator.clipboard.writeText(copyText || '')
                .then(() => {
                    setTooltip('Copied to clipboard!');
                    setTimeout(() => setTooltip(''), 2000);
                })
                .catch(err => {
                    setTooltip('Failed to copy!');
                    setTimeout(() => setTooltip(''), 2000);
                });
        };

        return (
            <div className="border-gray-700 flex justify-end space-x-2 relative">
                <button onClick={handleCopy} className="rounded-md text-gray-400 hover:text-gray-800 transition-colors">
                    <Copy className="h-4 w-4" />
                    {tooltip && (
                        <span className="absolute -top-6 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 z-10">
                            {tooltip}
                        </span>
                    )}
                </button>
                {thumbsUp && <button className="p-1 rounded-md text-gray-400 hover:text-gray-800 transition-colors">
                    <ThumbsUp className="h-4 w-4" />
                </button>}
            </div>
        );
    };

  return (
    <div className={`w-full max-w-4xl mb-4 p-1 mx-auto rounded-xl overflow-hidden border shadow-lg dark:border-purple-800 dark:bg-gray-900 bg-white`}>
      {/* Header with terminal-like styling */}
      <div className="bg-gradient-to-br dark:from-indigo-950/90 dark:to-purple-900/90 from-[#468ef9]/70 to-[#0c66ee]/70 p-6 rounded-t-xl">
        <div className="flex items-center space-x-3">
          <div className="flex space-x-2">
            <div className="h-3 w-3 rounded-full bg-red-500 opacity-50"></div>
            <div className="h-3 w-3 rounded-full bg-yellow-500 opacity-50"></div>
            <div className="h-3 w-3 rounded-full bg-green-500 opacity-50"></div>
          </div>
          <div className="flex items-center pl-2">
            <span className="text-lg font-bold text-white dark:text-purple-400">{title}</span>
          </div>
        </div>
      </div>

      {/* Chat Content */}
      <div className={`p-6 dark:bg-gray-900 bg-gray-50`}>

      {/* System Prompt Message */}
      {systemPrompt && (
          <div className={`${(userPrompt || assistantResponse) ? 'mb-6' : ''} animate-fadeIn`}>
            <div className="flex items-start mb-2">
              <div className="bg-purple-800 p-2 rounded-full mr-3 shadow-md">
                <Cog className="h-5 w-5 text-purple-100" />
              </div>
              <div className="font-medium dark:text-purple-300 text-purple-600 pt-1">system prompt &gt;</div>
            </div>
            <div className="relative mt-4 md:mt-0 md:ml-12 px-6 pt-6 pb-4 rounded-lg dark:bg-gray-800 bg-gray-100 dark:text-gray-100 border-l-4 border-purple-500 shadow-md">
            <div
                className="system-prompt"
                dangerouslySetInnerHTML={{ __html: systemPrompt || '' }} // Render HTML
            />

              {showActions && <ActionButtons copyText={systemPrompt} /> }
            </div>
          </div>
        )}

        {/* User Message */}
        {userPrompt && (
          <div className={`${(assistantResponse) ? 'mb-6' : ''} animate-fadeIn`}>
            <div className="flex items-start mb-2">
              <div className="bg-purple-800 p-2 rounded-full mr-3 shadow-md">
                <User className="h-5 w-5 text-purple-100" />
              </div>
              <div className="font-medium dark:text-purple-300 text-purple-600 pt-1">user &gt;</div>
            </div>
            <div className="relative mt-4 md:mt-0 md:ml-12 px-6 pt-6 pb-4 rounded-lg dark:bg-gray-800 bg-gray-100 dark:text-gray-100 border-l-4 border-purple-500 shadow-md">
            <div
                className="user-prompt"
                dangerouslySetInnerHTML={{ __html: userPrompt || '' }} // Render HTML
            />

              {showActions && <ActionButtons copyText={userPrompt} /> }
            </div>
          </div>
        )}

        {/* AI Response */}
        {assistantResponse && (
          <div className="animate-fadeIn">
            <div className="flex items-start mb-2">
              <div className="bg-purple-600 p-2 rounded-full mr-3 shadow-md">
                <Bot className="h-5 w-5 text-purple-100" />
              </div>
              <div className="font-medium dark:text-purple-300 text-purple-600 pt-1">assistant</div>
            </div>
            <div className="relative mt-4 md:mt-0 md:ml-12 px-6 pt-6 pb-4 dark:bg-gray-800 bg-gray-100 rounded-lg dark:text-gray-100 border-l-4 border-gray-700 shadow-md">
            <div
                className="assistant-prompt"
                dangerouslySetInnerHTML={{ __html: assistantResponse || '' }} // Render HTML
            />
              
              {showActions && <ActionButtons copyText={assistantResponse} />}
            </div>
          </div>
        )}
      </div>

      {/* Description */}
      {description && <div className={`p-4 border-t dark:border-gray-800 dark:bg-gray-900 bg-gray-50`}>
        <div
            className="description italic"
            dangerouslySetInnerHTML={{ __html: description || '' }} // Render HTML
        />
      </div>}

      {/* Input area */}
      {showChat && <div className={`p-4 border-t dark:border-gray-800 dark:bg-gray-900 bg-gray-50`}>
         <ChatWindow /> 
      </div>}

    </div>
  );
};

export default PromptExampleChat; 