import AuthCard from '../../components/dashboard/AuthCard'
import Button from '../../components/dashboard/Button'
import GuestLayout from '../../components/dashboard/Layouts/GuestLayout'
import { useAuth } from '../../hooks/auth'
import { useState, useCallback, useEffect } from 'react'
import {Link, useParams} from 'react-router-dom';
import ReactConfetti from 'react-confetti';
import {ReactComponent as Logo} from "../../assets/img/logo/crafting-ai-logo.svg";
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const VerifyEmail = () => {
  const { user, logout, resendEmailVerification } = useAuth()
  
  const { token } = useParams()
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(true)
  const [showConfirmButton, setShowConfirmButton] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showSecondConfetti, setShowSecondConfetti] = useState(false);
  const [rateLimitError, setRateLimitError] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const verifyEmail = useCallback(async (withConfirmation = false) => {
    let isSubscribed = true;

      if (!token) return;

      try {
        setLoading(true);

        // Special handling for 'send' token
        if (token === "send") {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/verify-email/${token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            credentials: 'include',
          });

          if (response.status === 429) {
            const retryAfter = response.headers.get('retry-after') || 60;
            const errorData = await response.json();
            setStatus('rate-limited');
            setRateLimitError({
              message: errorData.message || 'Too many attempts. Please try again later.',
              retryAfter: parseInt(retryAfter)
            });
            return;
          }

          const data = await response.json();
          
          if (isSubscribed) {
            if (data.message === 'Email already verified') {
              setStatus('already-verified');
            } else if (data.status === 'pending') {
              setStatus('pending');
            } else {
              setStatus(!data.verified ? 'resend' : 'verified');
            }
          }
          return;
        }

        // First check if the token is valid
        const validationResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/public/verify-email/${token}${withConfirmation ? '?confirm=true' : ''}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          credentials: 'include',
        });

        if (validationResponse.status === 429) {
          const retryAfter = validationResponse.headers.get('retry-after') || 60;
          const errorData = await validationResponse.json();
          setStatus('rate-limited');
          setRateLimitError({
            message: errorData.message || 'Too many attempts. Please try again later.',
            retryAfter: parseInt(retryAfter)
          });
          return;
        }

        const validationData = await validationResponse.json();

        if (!isSubscribed) return;

        // Check if already verified
        if (validationData.message === 'Email already verified' || user?.email_verified_at) {
          setStatus('already-verified');
          return;
        }

        // Check if just verified
        if (validationData.verified) {
          setStatus('verified');
          setShowConfetti(true);
          // Start second wave after 1 second
          setTimeout(() => setShowSecondConfetti(true), 1000);
          // Stop both waves after 6 seconds
          setTimeout(() => {
            setShowConfetti(false);
            setShowSecondConfetti(false);
          }, 6000);
          return;
        }

        if (validationData.status === 'pending') {
          setStatus('pending');
          return;
        }

        if (validationData.valid) {
          // If the token is valid but not verified, make the confirmation request
          const confirmResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/public/verify-email/${token}?confirm=true`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
          });
          const confirmData = await confirmResponse.json();

          if (!isSubscribed) return;
          
          if (confirmData.verified) {
            setStatus('verified');
            setShowConfetti(true);
            // Start second wave after 1 second
            setTimeout(() => setShowSecondConfetti(true), 1000);
            // Stop both waves after 6 seconds
            setTimeout(() => {
              setShowConfetti(false);
              setShowSecondConfetti(false);
            }, 6000);
          } else if (confirmData.message === 'Email already verified') {
            setStatus('already-verified');
          } else {
            setStatus('invalid');
          }
        } else {
          setStatus('invalid');
        }
      } catch (error) {
        console.error('Verification error:', error);
        if (isSubscribed) {
          if (error.response?.status === 429 || error.status === 429) {
            setStatus('rate-limited');
            // Try to get the error message from various possible response formats
            let errorMessage = 'Too many attempts. Please try again later.';
            let retryAfter = 60;

            try {
              // If it's a JSON response
              if (error.response?.data) {
                errorMessage = error.response.data.message || error.response.data.error || errorMessage;
                retryAfter = error.response.headers?.['retry-after'] || 60;
              } 
              // If it's a direct response
              else if (error.data) {
                errorMessage = error.data.message || error.data.error || errorMessage;
                retryAfter = error.headers?.['retry-after'] || 60;
              }
              // If it's a text response
              else if (typeof error.message === 'string') {
                errorMessage = error.message;
              }
            } catch (e) {
              console.error('Error parsing error message:', e);
            }

            setRateLimitError({
              message: errorMessage,
              retryAfter: parseInt(retryAfter)
            });
          } else {
            setStatus('invalid');
          }
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    }, [token, setStatus, setLoading]);

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Verify Email"
    
    // Initial check without confirmation
    verifyEmail(false);

    // Handle window resize for confetti
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [token, verifyEmail]);

  // Show confetti for already verified accounts
  useEffect(() => {
    if (status === 'already-verified') {
      setShowConfetti(true);
      setTimeout(() => setShowSecondConfetti(true), 1000);
      setTimeout(() => {
        setShowConfetti(false);
        setShowSecondConfetti(false);
      }, 6000);
    }
  }, [status]);

  return (
    <GuestLayout>
      {/* First wave - shoots up from bottom */}
      {showConfetti && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={150}
          gravity={0.3}
          initialVelocityY={-20}
          initialVelocityX={5}
          wind={0.01}
          colors={['#60A5FA', '#3B82F6', '#2563EB', '#1E40AF', '#1E3A8A']}
          confettiSource={{
            x: windowSize.width / 2,
            y: windowSize.height,
            w: 0,
            h: 0
          }}
          tweenDuration={3000}
          tween
        />
      )}
      {/* Second wave - rains down from top */}
      {showSecondConfetti && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={100}
          gravity={0.2}
          initialVelocityY={5}
          initialVelocityX={-3}
          wind={-0.01}
          colors={['#34D399', '#10B981', '#059669', '#047857', '#065F46']}
          confettiSource={{
            x: 0,
            y: -50,
            w: windowSize.width,
            h: 0
          }}
          tweenDuration={4000}
          tween
        />
      )}
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 px-4 sm:px-6 lg:px-8 transition-all duration-300">
        <div className="max-w-md w-full space-y-8 bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 transform hover:scale-[1.02] transition-all duration-300 border border-gray-100 dark:border-gray-700">
          <div className="text-center">
            <Link to="/" className="inline-block">
              <Logo className="h-12 w-auto mx-auto hover:opacity-80 transition-opacity" />
            </Link>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
              Email Verification
            </h2>
          </div>

          <div className="mt-8">
            {loading ? (
              <div className="flex flex-col items-center justify-center space-y-4">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 dark:border-blue-400"></div>
                <p className="text-gray-600 dark:text-gray-400">Verifying your email address...</p>
              </div>
            ) : status === 'rate-limited' ? (
              <div className="text-center space-y-4">
                <ExclamationCircleIcon className="h-12 w-12 mx-auto text-yellow-500 dark:text-yellow-400" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Too Many Attempts</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  {rateLimitError?.message}
                  {rateLimitError?.retryAfter && (
                    <span className="block mt-2 text-sm">
                      Please wait {Math.ceil(rateLimitError.retryAfter / 60)} minute(s) before trying again.
                    </span>
                  )}
                </p>
                <div className="flex flex-col space-y-3 mt-4">
                  <Button
                    onClick={() => window.location.href = '/dashboard/overview'}
                    className="w-full justify-center inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-lg shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors duration-200">
                    Back to Dashboard
                  </Button>
                </div>
              </div>
            ) : status === 'resend' ? (
              <div className="text-center space-y-4">
                <ExclamationCircleIcon className="h-12 w-12 mx-auto text-blue-500 dark:text-blue-400" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Send New Link</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  {user ? "Didn't receive the link or it has expired? We will send you a new one." : "Please login to request a new verification email."}
                </p>
                <div className="flex flex-col space-y-3">
                  {user ? (
                    <>
                      <Button
                        onClick={() => resendEmailVerification({ setStatus })}
                        className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400 transition-colors duration-200">
                        Resend Verification Email
                      </Button>
                      <Button
                        onClick={() => window.location.href = '/dashboard/overview'}
                        className="w-full justify-center inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-lg shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors duration-200">
                        Go to Dashboard
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => window.location.href = '/login'}
                        className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400 transition-colors duration-200">
                        Go to Login
                      </Button>
                      <Button
                        onClick={() => window.location.href = '/'}
                        className="w-full justify-center inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-lg shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors duration-200">
                        Go to Website
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ) : status === 'invalid' ? (
              <div className="text-center space-y-4">
                <XCircleIcon className="h-12 w-12 mx-auto text-red-500 dark:text-red-400" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Invalid Link</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  This verification link is invalid or has expired. Please request a new verification link.
                </p>
                {user ? (
                  <Button
                    onClick={() => resendEmailVerification({ setStatus })}
                    className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400 transition-colors duration-200">
                    Resend Verification Email
                  </Button>
                ) : (
                  <Button
                    onClick={() => window.location.href = '/login'}
                    className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400 transition-colors duration-200">
                    Go to Login
                  </Button>
                )}
              </div>
            ) : status === 'pending' ? (
              <div className="text-center space-y-4">
                <ExclamationCircleIcon className="h-12 w-12 mx-auto text-yellow-500 dark:text-yellow-400" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Verify Your Email</h3>
                <div className="text-center space-y-6">
                  <div className="space-y-2">
                    <p className="text-gray-600 dark:text-gray-400">
                      Your email verification link is ready!
                    </p>
                    <p className="text-gray-900 dark:text-white font-medium">
                      Click the button below to verify your email address and unlock all features:
                    </p>
                  </div>
                  
                  {showConfirmButton && (
                    <div className="relative">
                      <div className="absolute -inset-1.5 bg-blue-100 dark:bg-blue-900/30 animate-pulse rounded-lg" />
                      <Button
                        onClick={() => {
                          setShowConfirmButton(false);
                          verifyEmail(true);
                        }}
                        className="relative w-full justify-center inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400 transition-colors duration-200">
                        ✉️ Verify My Email Address
                      </Button>
                    </div>
                  )}
                  
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    This will confirm your email address and activate your account.
                  </p>
                </div>
              </div>
            ) : status === 'verified' || status === 'already-verified' ? (
              <div className="text-center space-y-6">
                <CheckCircleIcon className="h-12 w-12 mx-auto text-green-500 dark:text-green-400" />
                <div className="space-y-2">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {status === 'already-verified' ? 'Already Verified' : 'Thank you!'}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    {status === 'already-verified' ? (
                      <span>Your account is already verified!</span>
                    ) : (
                      <span><strong>Your account has been verified</strong> and is now upgraded so you can access all features!</span>
                    )}
                  </p>
                </div>
                <div className="flex flex-col space-y-3">
                  <Button
                    onClick={() => window.location.href = '/dashboard/overview'}
                    className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400 transition-colors duration-200">
                    Go to your dashboard
                  </Button>
                  <Button
                    onClick={() => window.location.href = '/'}
                    className="w-full justify-center inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-lg shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors duration-200">
                    Go to website
                  </Button>
                </div>
              </div>
            ) : (
              <div className="text-center space-y-6">
                <ExclamationCircleIcon className="h-12 w-12 mx-auto text-blue-500 dark:text-blue-400" />
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Verify your email</h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    Thanks for requesting a new verification link! Could you verify your email address by clicking on the link we just
                    emailed to you? If you didn't receive the email, we will gladly send you another. Please note that the verify link is only valid for 60 minutes.
                  </p>
                </div>

                {status === 'verification-link-sent' && (
                  <div className="text-sm font-medium text-green-600 dark:text-green-400">
                    A new verification link has been sent to the email address you provided during registration.
                  </div>
                )}

                <div className="flex flex-col space-y-3">
                  <Button
                    onClick={() => resendEmailVerification({ setStatus })}
                    className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-400 transition-colors duration-200">
                    Resend Verification Email
                  </Button>
                  <div className="flex flex-col space-y-3 mt-4">
                    <Button
                      onClick={() => window.location.href = '/dashboard/overview'}
                      className="w-full justify-center inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-lg shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors duration-200">
                      Back to Dashboard
                    </Button>
                    <Button
                      onClick={logout}
                      className="w-full justify-center inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-lg shadow-sm text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-colors duration-200">
                      Logout
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}

export default VerifyEmail
