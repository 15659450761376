import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import { mutate } from 'swr';
import React, {useEffect, useState} from "react";
import ProfileEdit from "../../components/dashboard/profile/profileEdit";
import ActivityHeatmap from "../../components/dashboard/profile/ActivityHeatmap";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import ForceAuthentication from "../../components/auth/ForceAuthentication";
import axios from "../../libs/axios";
import ProfileStats from "../../components/dashboard/profile/profileStats";
import ProfileAchievements from "../../components/dashboard/profile/profileAchievements";
import { achievements } from '../../config/achievements';
import ProfileDangerZone from "../../components/dashboard/profile/profileDangerZone";

const ProfileContent = ({ user }) => {
    const [ticketsCount, setTicketsCount] = useState(0);
    const [closedTicketsCount, setClosedTicketsCount] = useState(0);
    const [certificateCount, setCertificateCount] = useState(0);
    const [ratingsCount, setRatingsCount] = useState(0);
    const [toolReviewCount, setToolReviewCount] = useState(0);
    const [userAchievements, setUserAchievements] = useState(achievements);
    
    // Fetch the latest user data whenever the profile is updated
    const handleProfileUpdate = async () => {
        // Trigger a revalidation of the /user endpoint
        await mutate('/user');
    };

    useEffect(() => {
        const updatedAchievements = achievements.map(achievement => ({
            ...achievement,
            unlocked: 
                achievement.id === 'quick_learner' ? user?.email_verified :
                achievement.id === 'first_certificate' ? certificateCount > 0 :
                achievement.id === 'ticket_solver' ? closedTicketsCount >= 5 :
                achievement.id === 'security_first' ? user?.profile?.show_examples :
                achievement.id === 'master' ? certificateCount >= 1 :
                achievement.id === 'first_rating' ? ratingsCount >= 1 :
                achievement.id === 'rating_enthusiast' ? ratingsCount >= 10 :
                achievement.id === 'rating_expert' ? ratingsCount >= 30 :
                achievement.id === 'first_review' ? toolReviewCount >= 1 :
                achievement.id === 'review_enthusiast' ? toolReviewCount >= 10 :
                achievement.id === 'review_expert' ? toolReviewCount >= 20 :
                achievement.id === 'share_tools' ? user?.profile?.show_tool_profile : false
        }));
        
        setUserAchievements(updatedAchievements);
    }, [user, certificateCount, closedTicketsCount, ticketsCount, ratingsCount, toolReviewCount]);

    const getHasCertificateOne = async () => {
        try {
            const response = await axios.post("/quiz/verify-certificate");
            if (typeof response.data === 'object') {
                return response.data.certificate === "valid" ? 1 : 0;
            }
            return response.data;
        } catch(error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                window.location.reload();
            }
            return 0;
        }
    }

    const getUserTickets = async () => {
        try {
            const response = await axios.get(`/ticket/get`);
            const tickets = Array.isArray(response.data) ? response.data : [];
            
            // Count closed tickets (status 2)
            const closedCount = tickets.filter(ticket => ticket.status === 2).length;
            // Count active tickets (not status 2)
            const activeCount = tickets.filter(ticket => ticket.status !== 2).length;
            
            return { closedCount, activeCount };
        } catch(error) {
            console.error('Error fetching tickets:', error);
            return { closedCount: 0, activeCount: 0 };
        }
    }

    const getUserRatingsCount = async () => {
        try {
            const response = await axios.get('/user/ratings-count');
            return response.data.count || 0;
        } catch(error) {
            return 0;
        }
    }

    const getUserToolReviewCount = async () => {
        try {
            const response = await axios.get('/user/tools/reviewed');
            return response.data.count || 0;
        } catch(error) {
            return 0;
        }
    }

    useEffect(() => {
        if (!user) return;
        const fetchData = async () => {
            const [certificateData, ticketsData, ratingsData, toolReviewData] = await Promise.all([
                getHasCertificateOne(),
                getUserTickets(),
                getUserRatingsCount(),
                getUserToolReviewCount()
            ]);
            
            setCertificateCount(certificateData);
            setTicketsCount(ticketsData.activeCount);
            setClosedTicketsCount(ticketsData.closedCount);
            setRatingsCount(ratingsData);
            setToolReviewCount(toolReviewData);
        };

        fetchData();
    }, [user]);

    return (
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 dark:text-white leading-tight">
                    User Profile
                </h2>
            }>
            <div className="py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-6">
                    <DashboardCard>
                        <ActivityHeatmap />
                    </DashboardCard>
                    <DashboardCard>
                        <ProfileEdit 
                            user={user} 
                            onProfileUpdate={handleProfileUpdate}
                        />
                    </DashboardCard>
                    <DashboardCard>
                        <ProfileStats user={user} 
                            ticketsCount={ticketsCount} 
                            closedTicketsCount={closedTicketsCount} 
                            certificateCount={certificateCount} 
                            ratingsCount={ratingsCount} 
                            toolReviewCount={toolReviewCount} 
                        />
                    </DashboardCard>
                    <DashboardCard>
                        <ProfileAchievements 
                            userAchievements={userAchievements}
                        />
                    </DashboardCard>
                    <DashboardCard>
                        <ProfileDangerZone />
                    </DashboardCard>
                </div>
            </div>
        </AppLayout>
    );
};

const Profile = () => {
    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Profile"
    }, []);

    return (
        <ForceAuthentication>
            {({user}) => <ProfileContent user={user} />}
        </ForceAuthentication>
    )
}

export default Profile
