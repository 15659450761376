import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface SlotComponentProps {
    styles: string,
    url?: string,
    targetBlank?: boolean,
    children: ReactNode;
  }

const BaseButton: React.FC<SlotComponentProps> = ({styles, url, targetBlank, children }) => {
    const navigate = useNavigate();

    if (targetBlank) {
        return (
            <button
                className={`text-sm text-center rounded-full hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 ${styles}`}
                onClick={(e) => { e.preventDefault(); window.open(`${url}`, "_blank"); }}
            >
                { children }
            </button>
        )
    }
    return (
        <button
            className={`text-sm text-center rounded-full hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 ${styles}`}
            onClick={(e) => { e.preventDefault(); url ? navigate(url) : window.location.href=`${url}`; }}
          >
            { children }
          </button>
    )
}

export default BaseButton