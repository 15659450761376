import React from 'react';
import {
    CheckCircle,
    XCircle,
    Loader,
    ArrowUpCircle,
    ArrowDownCircle,
    Ban,
    Unlock,
    Award,
    Search,
    Users,
    Edit
} from 'lucide-react';
import { Button } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import RoleEnum from "../../../components/enums/RoleEnum";
import RoleLabel from '../../../components/dashboard/RoleLabel';
import Pagination from '../../../components/dashboard/dashboard/Pagination';

const UserList = ({ 
    users, 
    user, 
    upgradeAccountToTakeTests,
    banAccount,
    unBanAccount
}) => {
    // Add helper function for date check
    const isWithinLastThreeDays = (dateString) => {
        const date = new Date(dateString);
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        return date >= threeDaysAgo;
    };
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [sortField, setSortField] = React.useState('id');
    const [sortDirection, setSortDirection] = React.useState('desc');
    const [showCertified, setShowCertified] = React.useState(false);
    const [showVerified, setShowVerified] = React.useState(false);
    const itemsPerPage = 12;
    
    // Reset pagination when search or filters change
    React.useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery, showCertified, showVerified]);
    
    // First apply search and status filters
    const filteredUsers = React.useMemo(() => {
        let filtered = users;

        // Apply search filter
        if (searchQuery.trim()) {
            const query = searchQuery.toLowerCase().trim();
            filtered = filtered.filter(user => 
                user.name?.toLowerCase().includes(query) ||
                user.email?.toLowerCase().includes(query)
            );
        }

        // Apply certified filter
        if (showCertified) {
            filtered = filtered.filter(user => 
                user.tests?.some(test => test.certificate_id !== null)
            );
        }

        // Apply verified email filter
        if (showVerified) {
            filtered = filtered.filter(user => user.email_verified === true);
        }

        return filtered;
    }, [users, searchQuery, showCertified, showVerified]);

    // Then sort the filtered results
    const sortedUsers = React.useMemo(() => {
        return [...filteredUsers].sort((a, b) => {
            const aId = Number(a.id);
            const bId = Number(b.id);
            
            if (sortField === 'id') {
                return sortDirection === 'asc' ? aId - bId : bId - aId;
            }
            
            if (sortField === 'tests') {
                const aTests = a.tests?.length || 0;
                const bTests = b.tests?.length || 0;
                return sortDirection === 'asc' ? aTests - bTests : bTests - aTests;
            }

            if (sortField === 'role') {
                const aRole = a.role || 0;
                const bRole = b.role || 0;
                return sortDirection === 'asc' ? aRole - bRole : bRole - aRole;
            }

            const aValue = a[sortField] || '';
            const bValue = b[sortField] || '';
            
            return sortDirection === 'asc' 
                ? String(aValue).localeCompare(String(bValue))
                : String(bValue).localeCompare(String(aValue));
        });
    }, [filteredUsers, sortField, sortDirection]);

    // Finally apply pagination
    const currentUsers = React.useMemo(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        return sortedUsers.slice(indexOfFirstItem, indexOfLastItem);
    }, [sortedUsers, currentPage]);

    const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);

    return (
        <div className="space-y-4">
            {/* Search and Sort Controls */}
            <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                <div className="flex flex-col md:flex-row md:items-center gap-3">
                    <div className="flex flex-wrap items-center gap-3 flex-grow">
                        {/* Sort Controls */}
                        <div className="flex flex-wrap gap-2">
                            {[
                                { id: 'id', label: 'ID' },
                                { id: 'name', label: 'Name' },
                                { id: 'tests', label: 'Tests' },
                                { id: 'role', label: 'Role' }
                            ].map(option => (
                                <button
                                    key={option.id}
                                    onClick={() => {
                                        if (sortField === option.id) {
                                            setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
                                        } else {
                                            setSortField(option.id);
                                            setSortDirection('asc');
                                        }
                                    }}
                                    className={`px-3 py-2 rounded-full text-sm font-medium transition-all duration-200
                                        ${sortField === option.id
                                            ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300'
                                            : 'bg-gray-100 text-gray-600 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                                        }`}
                                >
                                    {option.label}
                                    {sortField === option.id && (
                                        <span className="ml-1">
                                            {sortDirection === 'asc' ? '↑' : '↓'}
                                        </span>
                                    )}
                                </button>
                            ))}
                        </div>

                        {/* Filter Toggles */}
                        <div className="flex gap-3 items-center">
                            <button
                                onClick={() => setShowCertified(!showCertified)}
                                className={`px-4 py-1.5 rounded-full text-sm font-medium transition-all duration-200 flex items-center gap-2
                                    ${showCertified
                                        ? 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400 shadow-sm'
                                        : 'bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700/50'
                                    }`}
                            >
                                <Award className="w-4 h-4" />
                                Certified
                            </button>
                            <button
                                onClick={() => setShowVerified(!showVerified)}
                                className={`px-4 py-1.5 rounded-full text-sm font-medium transition-all duration-200 flex items-center gap-2
                                    ${showVerified
                                        ? 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400 shadow-sm'
                                        : 'bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700/50'
                                    }`}
                            >
                                <CheckCircle className="w-4 h-4" />
                                Verified Email
                            </button>
                            {(showCertified || showVerified || searchQuery) && (
                                <button
                                    onClick={() => {
                                        setShowCertified(false);
                                        setShowVerified(false);
                                        setSearchQuery('');
                                    }}
                                    className="px-4 py-1.5 rounded-full text-sm font-medium transition-all duration-200 flex items-center gap-2
                                        bg-red-100 text-red-700 dark:bg-red-900/30 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-900/40 shadow-sm"
                                >
                                    <XCircle className="w-4 h-4" />
                                    Reset Filters
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Search Bar */}
                    <div className="relative md:w-64 lg:w-72 shrink-0 md:ml-auto">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search users..."
                            className="w-full px-4 py-2 pl-10 text-sm bg-gray-50 dark:bg-gray-700/50 border border-gray-200 dark:border-gray-600 rounded-lg
                                placeholder-gray-500 dark:placeholder-gray-400
                                text-gray-900 dark:text-white"
                        />
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                    </div>
                </div>
            </div>

            {/* User Cards Grid */}
            <div className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {currentUsers.length === 0 ? (
                    <div className="col-span-full p-6 text-center bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700">
                        <div className="flex flex-col items-center gap-3">
                            <Users className="w-12 h-12 text-gray-400 dark:text-gray-500" />
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                                    No users found
                                </h3>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                    {searchQuery && !showCertified && !showVerified && 'No users match your search query.'}
                                    {showCertified && !showVerified && 'No certified users found.'}
                                    {!showCertified && showVerified && 'No users with verified email found.'}
                                    {showCertified && showVerified && 'No users match all selected filters.'}
                                    {!searchQuery && !showCertified && !showVerified && 'No users available.'}
                                </p>
                            </div>
                            {(showCertified || showVerified || searchQuery) && (
                                <button
                                    onClick={() => {
                                        setShowCertified(false);
                                        setShowVerified(false);
                                        setSearchQuery('');
                                    }}
                                    className="mt-2 px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 flex items-center gap-2
                                        bg-blue-50 text-blue-700 dark:bg-blue-900/20 dark:text-blue-400 hover:bg-blue-100 dark:hover:bg-blue-900/30 shadow-sm"
                                >
                                    <XCircle className="w-4 h-4" />
                                    Clear All Filters
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    currentUsers.map((currentUser) => (
                        <div
                            key={currentUser.id}
                            className={`relative bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all duration-200
                                ${currentUser.banned_user ? 'bg-red-50/50 dark:bg-red-900/10 border border-red-200 dark:border-red-800/30' : ''}
                                ${isWithinLastThreeDays(currentUser.created_at) ? 'border-l-4 border-blue-500' : ''}`}
                        >
                            <div className="p-3">
                                {/* Header Row */}
                                <div className={`rounded-t-lg -mx-3 -mt-3 px-3 py-2.5 mb-3 ${currentUser.banned_user ? 'bg-red-100/50 dark:bg-red-900/20' : 'bg-gray-100 dark:bg-gray-900'} border-b border-gray-100 dark:border-gray-700`}>
                                    {currentUser.banned_user && (
                                        <div className="absolute -top-2 -right-2 px-2 py-0.5 text-xs font-medium text-white bg-red-500 dark:bg-red-600 rounded-full shadow-sm border border-white dark:border-gray-800">
                                            Banned
                                        </div>
                                    )}
                                    <div className="flex items-center justify-between w-full gap-2">
                                        <div className="flex items-center gap-2 min-w-0">
                                            <span className="text-xs text-gray-500 dark:text-gray-400 shrink-0">#{currentUser.id}</span>
                                            <h3 className="font-medium m-0 p-0 text-sm text-gray-900 dark:text-white truncate">
                                                {currentUser.name}
                                            </h3>
                                            {isWithinLastThreeDays(currentUser.created_at) && (
                                                <span className="px-1.5 py-0.5 text-xs font-medium text-blue-600 bg-blue-50 dark:bg-blue-900/20 dark:text-blue-400 rounded-full shrink-0">
                                                    New
                                                </span>
                                            )}
                                        </div>
                                        <Button
                                            onClick={() => navigate(`/dashboard/admin/user/${currentUser.id}`)}
                                            title="Edit user"
                                            className="p-1 hover:bg-white/50 dark:hover:bg-gray-700/50 rounded-full transition-colors shrink-0"
                                        >
                                            <Edit className="w-3.5 h-3.5 text-blue-500 hover:text-blue-600" />
                                        </Button>
                                    </div>
                                </div>

                                {/* Info Row */}
                                <div className="flex items-center justify-between gap-2 mb-4">
                                    <RoleLabel role={currentUser.role} />
                                    <span className="text-xs text-gray-500 dark:text-gray-400">
                                        {new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(new Date(currentUser.created_at))}
                                    </span>
                                </div>

                                {/* Status Row */}
                                <div className="flex items-center justify-between gap-2">
                                    <div className="flex items-center gap-1.5">
                                        {currentUser.email_verified ? (
                                            <CheckCircle className="w-3.5 h-3.5 text-green-500" />
                                        ) : (
                                            <XCircle className="w-3.5 h-3.5 text-red-500" />
                                        )}
                                        <span className="text-xs text-gray-600 dark:text-gray-300">Email</span>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        {currentUser?.tests?.some(test => test !== null) ? (
                                            currentUser.tests.find(test => test !== null).in_progress ? (
                                                <Loader className={`w-3.5 h-3.5 ${currentUser.banned_user ? 'text-gray-400 dark:text-gray-600' : 'text-blue-500'} animate-spin`} />
                                            ) : (
                                                currentUser.tests.some(test => test.certificate_id !== null) ? (
                                                    <CheckCircle className={`w-3.5 h-3.5 ${currentUser.banned_user ? 'text-gray-400 dark:text-gray-600' : 'text-green-500'}`} />
                                                ) : (
                                                    <XCircle className={`w-3.5 h-3.5 ${currentUser.banned_user ? 'text-gray-400 dark:text-gray-600' : 'text-red-500'}`} />
                                                )
                                            )
                                        ) : (
                                            <XCircle className="w-3.5 h-3.5 text-gray-400" />
                                        )}
                                        <span className="text-xs text-gray-600 dark:text-gray-300">Certified</span>
                                    </div>
                                </div>
                            </div>

                            {/* Actions */}
                            {(user.role >= RoleEnum.Admin && user.role > currentUser.role && user.id !== currentUser.id) ? (
                                <div className="px-3 py-2 bg-gray-100 dark:bg-gray-900 border-t border-gray-100 dark:border-gray-700 rounded-b-lg transition-colors duration-200">
                                    <div className="flex items-center justify-end gap-1">
                                        {currentUser.role >= RoleEnum.DefaultUser && (
                                            <Button
                                                onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.Registered)}
                                                title="Downgrade account"
                                                disabled={currentUser.banned_user}
                                                className="p-1 hover:bg-white/80 dark:hover:bg-gray-700/80 rounded-full transition-all duration-200 disabled:opacity-50"
                                            >
                                                <ArrowDownCircle className={`w-3.5 h-3.5 ${currentUser.banned_user ? 'text-gray-300' : 'text-red-500 hover:text-red-600'}`} />
                                            </Button>
                                        )}
                                        {currentUser.role === RoleEnum.Registered && (
                                            <Button
                                                onClick={() => upgradeAccountToTakeTests(currentUser.id, RoleEnum.DefaultUser)}
                                                title="Upgrade account"
                                                disabled={currentUser.banned_user}
                                                className="p-1 hover:bg-white/80 dark:hover:bg-gray-700/80 rounded-full transition-all duration-200 disabled:opacity-50"
                                            >
                                                <ArrowUpCircle className={`w-3.5 h-3.5 ${currentUser.banned_user ? 'text-gray-300' : 'text-green-500 hover:text-green-600'}`} />
                                            </Button>
                                        )}
                                        {user.role >= RoleEnum.SuperAdmin && (
                                            <Button
                                                onClick={() => currentUser.banned_user ? unBanAccount(currentUser.id) : banAccount(currentUser.id)}
                                                title={currentUser.banned_user ? "Unban user" : "Ban user"}
                                                className="p-1 hover:bg-white/80 dark:hover:bg-gray-700/80 rounded-full transition-all duration-200"
                                            >
                                                {currentUser.banned_user ? (
                                                    <Unlock className="w-3.5 h-3.5 text-green-500 hover:text-green-600" />
                                                ) : (
                                                    <Ban className="w-3.5 h-3.5 text-red-500 hover:text-red-600" />
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="px-3 py-2 bg-gray-100 dark:bg-gray-900 border-t border-gray-100 dark:border-gray-700 rounded-b-lg transition-colors duration-200" />
                            )}
                        </div>
                    ))
                )}
            </div>

            {/* Pagination */}
            <div className="flex justify-center py-4">
                <Pagination 
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                />
            </div>
        </div>
    );
};

export default UserList;