import React from 'react';

/**
 * Formats text by styling variables within double curly braces
 * @param text The text to format
 * @returns Formatted JSX with styled variables
 */
export const formatTextWithVariables = (text: string): React.ReactNode[] => {
    if (!text) return [''];
    
    // Create the result array with styled variables
    const result: React.ReactNode[] = [];
    
    // First, process the text to handle example tags
    const processedParts = processExampleTags(text);
    
    // Process each part for variables
    processedParts.forEach((part) => {
        if (typeof part !== 'string') {
            // This is already a React node (processed example)
            result.push(part);
        } else {
            // This is a string, process it for variables
            const variableParts = processVariables(part);
            result.push(...variableParts);
        }
    });
    
    return result;
};

// Helper function to process variables
const processVariables = (text: string): React.ReactNode[] => {
    // Regular expression to match text within double curly braces: {{variable}}
    const regex = /\{\{([^}]+)\}\}/g;
    
    // Split the text by the regex matches
    const parts = text.split(regex);
    const result: React.ReactNode[] = [];
    
    parts.forEach((part: string, index: number) => {
        // Even indices are regular text, odd indices are variables
        if (index % 2 === 0) {
            // Process regular text to preserve line breaks
            if (part.trim() !== '') {
                // Split by newlines and join with <br/> elements
                const lines = part.split('\n');
                for (let i = 0; i < lines.length; i++) {
                    result.push(lines[i]);
                    // Add a <br/> after each line except the last one
                    if (i < lines.length - 1) {
                        result.push(<br key={`br-${index}-${i}`} />);
                    }
                }
            } else {
                result.push(part);
            }
        } else {
            // This is a variable, style it
            result.push(
                <span 
                    key={`var-${index}`}
                    className="inline-flex items-center px-2 py-1 mx-0.5 rounded-md text-xs font-medium
                              bg-gradient-to-r from-slate-100 to-slate-200 dark:from-slate-700 dark:to-slate-800
                              text-slate-800 dark:text-slate-100 border border-slate-300 dark:border-slate-600"
                    title={`Variable: ${part}`}
                >
                    <span className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                        </svg>
                        {part}
                    </span>
                </span>
            );
        }
    });
    
    return result;
};

// Helper function to process example tags
const processExampleTags = (text: string): (string | React.ReactNode)[] => {
    // Regular expression to match content within <example></example> tags (case-insensitive)
    const exampleRegex = /<[eE][xX][aA][mM][pP][lL][eE]>([\s\S]*?)<\/[eE][xX][aA][mM][pP][lL][eE]>/g;
    
    const parts: (string | React.ReactNode)[] = [];
    let lastIndex = 0;
    let match;
    
    // Find all matches of example tags
    while ((match = exampleRegex.exec(text)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
            parts.push(text.substring(lastIndex, match.index));
        }
        
        // Add the styled example content
        const exampleContent = match[1];
        parts.push(
            <div 
                key={`example-${match.index}`}
                className="mt-2 -mb-4 p-3 rounded-md bg-gradient-to-r from-green-50 to-teal-50 dark:from-green-900/20 dark:to-teal-900/20
                           border border-green-200 dark:border-green-800 text-green-800 dark:text-green-200"
            >
                <div className="flex items-center mb-1 text-xs font-medium text-green-700 dark:text-green-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Example
                </div>
                <div className="text-sm whitespace-pre-wrap">{exampleContent}</div>
            </div>
        );
        
        // Update the last index
        lastIndex = match.index + match[0].length;
    }
    
    // Add any remaining text
    if (lastIndex < text.length) {
        parts.push(text.substring(lastIndex));
    }
    
    return parts;
};

/**
 * Component that renders text with styled variables
 */
interface FormattedTextProps {
    text: string;
    className?: string;
}

export const FormattedText: React.FC<FormattedTextProps> = ({ text, className = '' }) => {
    return (
        <span className={className}>
            {formatTextWithVariables(text)}
        </span>
    );
};

export default FormattedText;
