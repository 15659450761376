import React, {useState, useEffect} from 'react';
import { Info, Eye, EyeOff, Check, X, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import axios from "../../../libs/axios";
import RoleLabel from '../RoleLabel';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const TermsOfServiceModal = ({ isOpen, setIsOpen }) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
                <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                    </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all border border-gray-200 dark:border-gray-700/50 backdrop-blur-sm z-10">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white mb-4"
                                >
                                    Terms of Service
                                </Dialog.Title>
                                <div className="mt-2 space-y-4 text-gray-600 dark:text-gray-300">
                                    <p className="font-medium text-gray-800 dark:text-gray-200">
                                        Before accessing the "Prompt Injections" examples on our website, please read and agree to the following terms of service:
                                    </p>
                                    <ul className="list-disc pl-5 space-y-2">
                                        <li><span className="font-semibold text-gray-800 dark:text-gray-200">Educational Purpose Only:</span> The "Prompt Injections" examples provided are intended solely for educational purposes. They are meant to help you understand how prompt injections work and how to defend yourself against them.</li>
                                        <li><span className="font-semibold text-gray-800 dark:text-gray-200">No Misuse:</span> You agree not to use the provided examples for any malicious or unethical activities. This includes, but is not limited to, using prompt injections to manipulate, deceive, or harm others.</li>
                                        <li><span className="font-semibold text-gray-800 dark:text-gray-200">Responsible Use:</span> By accessing these examples, you confirm that your intention is to learn about the risks associated with prompt injections and to enhance your ability to safeguard against them.</li>
                                        <li><span className="font-semibold text-gray-800 dark:text-gray-200">Legal Compliance:</span> You agree to comply with all applicable laws and regulations while using the information provided on this website.</li>
                                        <li><span className="font-semibold text-gray-800 dark:text-gray-200">No Liability:</span> We are not responsible for any misuse of the information provided on our website. Users are solely responsible for their actions and any consequences that may arise from the use of this information.</li>
                                    </ul>
                                </div>

                                <div className="mt-6 flex justify-end gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 dark:bg-blue-900/30 px-4 py-2 text-sm font-medium text-blue-900 dark:text-blue-100 hover:bg-blue-200 dark:hover:bg-blue-900/50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 dark:focus-visible:ring-blue-400 focus-visible:ring-offset-2 dark:focus-visible:ring-offset-gray-800 transition-colors duration-200"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

const ProfileEdit = ({ user, onProfileUpdate }) => {
    const [showExamples, setShowExamples] = useState(false);
    const [shareTools, setShareTools] = useState(false);

    useEffect(() => {
        if (user?.profile) {
            const showExamplesValue = user.profile.show_examples || false;
            const shareToolsValue = user.profile.show_tool_profile || false;
            
            setShowExamples(showExamplesValue);
            setShareTools(shareToolsValue);
            
            // Set localStorage based on show_examples value
            if (showExamplesValue) {
                localStorage.setItem('safetyTermsInjections', 'true');
            } else {
                localStorage.removeItem('safetyTermsInjections');
            }
        }
    }, [user?.profile]);

    const handleShowExamplesChange = async (e) => {
        const value = e.target.checked;
        try {
            await axios.post('/profile/show-examples', {
                show_examples: value
            });
            if (value) {
                localStorage.setItem('safetyTermsInjections', 'true');
            } else {
                localStorage.removeItem('safetyTermsInjections');
            }
            setShowExamples(value);
            onProfileUpdate?.();
        } catch (error) {
            console.error('Error updating show examples:', error);
        }
    };

    const handleShareToolsChange = async (e) => {
        const value = e.target.checked;
        try {
            await axios.post('/profile/show-tool-profile', {
                show_tool_profile: value
            });
            setShareTools(value);
            onProfileUpdate?.();
        } catch (error) {
            console.error('Error updating share tools:', error);
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    // const [isChangingPassword, setIsChangingPassword] = useState(false);
    // const [currentPassword, setCurrentPassword] = useState('');
    // const [newPassword, setNewPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [copiedLinkTooltip, setCopiedLinkTooltip] = useState(false);

    // const handleEdit = () => {
    //     setIsEditing(true);
    //     setError('');
    // };
    //
    // const handleCancel = () => {
    //     setIsEditing(false);
    //     setCurrentPassword('');
    //     setError('');
    // };
    //
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (currentPassword) {
    //         console.log('Submitting updated user data:', { ...user, currentPassword });
    //         setIsEditing(false);
    //         setCurrentPassword('');
    //         setError('');
    //     } else {
    //         setError('Please enter your current password to update your profile.');
    //     }
    // };
    //
    // const handlePasswordChange = (e) => {
    //     e.preventDefault();
    //     if (newPassword === confirmPassword) {
    //         console.log('Changing password:', { currentPassword, newPassword });
    //         setIsChangingPassword(false);
    //         setCurrentPassword('');
    //         setNewPassword('');
    //         setConfirmPassword('');
    //         setError('');
    //     } else {
    //         setError('New passwords do not match. Please try again.');
    //     }
    // };
    //
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     //setUser(prevUser => ({ ...prevUser, [name]: value }));
    // };

    const InputField = ({ label, name, value, onChange, disabled = false, type = "text", rightElement }) => (
        <div className="mb-6">
            {disabled ? (
                <div className="p-4 bg-gray-50 dark:bg-gray-800/50 rounded-xl border border-gray-100 dark:border-gray-700/50">
                    <div className="flex justify-between items-center">
                        <div>
                            <div className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">{label}</div>
                            <div className="text-gray-900 dark:text-gray-100 font-medium">{value}</div>
                        </div>
                        {rightElement}
                    </div>
                </div>
            ) : (
                <>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">{label}</label>
                    <input
                        type={type}
                        name={name}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        className={`w-full py-3 px-3 border rounded-lg transition-colors duration-200 border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:bg-gray-800 dark:border-gray-600 dark:text-white dark:focus:border-blue-400 dark:focus:ring-blue-800`}
                    />
                </>
            )}
        </div>
    );

    const PasswordField = ({ label, value, onChange }) => (
        <div className="mb-6 relative">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">{label}</label>
            <div className="relative">
                <input
                    type={showPassword ? "text" : "password"}
                    value={value}
                    onChange={onChange}
                    className="w-full px-3 py-3 border rounded-lg pr-10 transition-colors duration-200 border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:bg-gray-800 dark:border-gray-600 dark:text-white dark:focus:border-blue-400 dark:focus:ring-blue-800"
                />
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 transition-colors duration-200"
                >
                    {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
            </div>
        </div>
    );

    const ErrorMessage = ({ message }) => (
        message ? (
            <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700 rounded-r-lg relative dark:bg-red-900/10 dark:border-red-500 dark:text-red-400" role="alert">
                <span className="flex items-center font-medium">
          <AlertCircle className="w-5 h-5 mr-2" />
            {message}
        </span>
            </div>
        ) : null
    );

    return (
        <div>
            <RoleLabel role={user.role} className="absolute top-0 right-0" />
            <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">User Profile</h2>
            <ErrorMessage message={error} />
            {/*{!isChangingPassword ? (*/}
            {/*    <form onSubmit={handleSubmit}>*/}
                <form>
                    <InputField
                        label="Name"
                        name="name"
                        value={user.name}
                        // onChange={handleChange}
                        disabled={!isEditing}
                    />
                    <div className="relative">
                        <InputField
                            label="Email"
                            name="email"
                            value={user.email}
                            disabled={true}
                        />
                        <div className="absolute top-0 right-0 mt-3 mr-3">
                            {user && user.email_verified ? (
                                <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400">
                                    <Check className="w-3 h-3 mr-1" />
                                    Verified
                                </span>
                            ) : (
                                <Link 
                                    to="/register/verify/send"
                                    className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-white text-red-600 hover:bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-red-900/30 border border-red-200 dark:border-red-800/50 shadow-sm hover:shadow transition-all duration-200 cursor-pointer"
                                >
                                    <X className="w-3 h-3 mr-1" />
                                    Click to Verify
                                </Link>
                            )}
                        </div>
                    </div>
                    <p className="text-sm -mt-2 text-gray-500 dark:text-gray-400 flex items-center">
                        <Info className="w-4 h-4 mr-2" />
                        Username or email changes require a new ticket.
                    </p>
                    {/*{isEditing && (*/}
                    {/*    <PasswordField*/}
                    {/*        label="Current Password"*/}
                    {/*        value={currentPassword}*/}
                    {/*        onChange={(e) => setCurrentPassword(e.target.value)}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*<div className="mt-6 space-x-4">*/}
                    {/*    {!isEditing ? (*/}
                    {/*        <>*/}
                    {/*            <Button onClick={handleEdit} type="button">Edit Profile</Button>*/}
                    {/*            <Button onClick={() => {setIsChangingPassword(true); setError('');}} type="button" variant="secondary">Change Password</Button>*/}
                    {/*        </>*/}
                    {/*    ) : (*/}
                    {/*        <>*/}
                    {/*            <Button type="submit">Save Changes</Button>*/}
                    {/*            <Button onClick={handleCancel} type="button" variant="secondary">Cancel</Button>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </form>
            {/*) : (*/}
            {/*    <form onSubmit={handlePasswordChange}>*/}
            {/*        <PasswordField*/}
            {/*            label="Current Password"*/}
            {/*            value={currentPassword}*/}
            {/*            // onChange={(e) => setCurrentPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        <PasswordField*/}
            {/*            label="New Password"*/}
            {/*            value={newPassword}*/}
            {/*            // onChange={(e) => setNewPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        <PasswordField*/}
            {/*            label="Confirm New Password"*/}
            {/*            value={confirmPassword}*/}
            {/*            // onChange={(e) => setConfirmPassword(e.target.value)}*/}
            {/*        />*/}
            {/*        /!*<div className="mt-6 space-x-4">*!/*/}
            {/*        /!*    <Button type="submit">Change Password</Button>*!/*/}
            {/*        /!*    <Button onClick={() => {setIsChangingPassword(false); setError('');}} type="button" variant="secondary">Cancel</Button>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*    </form>*/}
            {/*)}*/}
            <div className="mt-8 flex items-center justify-between p-6 bg-gray-50 dark:bg-gray-800/50 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm">
                <div className="flex-1">
                    <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100">Show Examples</h4>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        By enabling this option, you agree to our{' '}
                        <button
                            onClick={() => setIsTermsOpen(true)}
                            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 underline"
                        >
                            Terms of Service
                        </button>{' '}
                        and automatically see all examples while logged in.
                    </p>
                </div>
                <label className="inline-flex relative items-center cursor-pointer">
                    <input 
                        type="checkbox"
                        className="sr-only peer"
                        checked={showExamples}
                        onChange={handleShowExamplesChange}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <div className="mt-8 flex items-center justify-between p-6 bg-gray-50 dark:bg-gray-800/50 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm">
                <div className="flex-1">
                    <h4 className="text-lg font-medium text-gray-900 dark:text-gray-100">Share Tools</h4>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                    Share your tools with the community. You can share your rated toollist. 
                    <br />Please note: Also your private tools will be shown!
                    <br /> All your <strong>rated tools</strong> and <strong>private tools</strong> will be shown. Tools you didn't rate, will be hidden in your personal link.
                    <br />Enable to share your tools at:{' '}
                        {user.profile?.profile_id ? (
                            <>
                                <a 
                                    href={`${window.location.origin}/tools/profile/${user.profile.profile_id}`}
                                    className="text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 text-wrap break-words"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{wordBreak: 'break-word'}}
                                >
                                    {window.location.origin}/tools/profile/{user.profile.profile_id}
                                </a>
                                <br />
                                <button 
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${window.location.origin}/tools/profile/${user.profile.profile_id}`)
                                        setCopiedLinkTooltip(true)
                                        setTimeout(() => setCopiedLinkTooltip(false), 3000)
                                    }}
                                    className={`text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 mt-3 relative ${!shareTools ? 'text-gray-500 dark:text-gray-400 cursor-not-allowed' : ''}`}
                                    disabled={!shareTools}
                                >

                                    Copy your personal link
                                    {copiedLinkTooltip && (
                                        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 rounded-lg p-2 text-sm text-gray-500 dark:text-gray-400 shadow-lg">
                                            Copied!
                                        </div>
                                    )}
                                </button>
                            </>

                        ) : (
                            <span className="text-gray-500 dark:text-gray-400">
                                {window.location.origin}/tools/profile/your-profile-id

                                <br /><br />
                                Your ID will be generated once you enable this feature for the first time. This will be your username if available, otherwise username-xxxx.
                            </span>
                        )}
                    </p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                    <input 
                        type="checkbox"
                        className="sr-only peer"
                        checked={shareTools}
                        onChange={handleShareToolsChange}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
            </div>

            <TermsOfServiceModal isOpen={isTermsOpen} setIsOpen={setIsTermsOpen} />
        </div>
    );
};

export default ProfileEdit;