import React from 'react';
import { motion } from 'framer-motion';

/**
 * PromptEngineerLevel Component
 * 
 * Displays the user's prompt engineering level based on their skill assessment.
 * Features:
 * - Dynamic level calculation based on skill values
 * - Visual progress bar with gradient background
 * - Animated star rating system
 * - XP counter showing progress to next level
 * - Responsive design with dark mode support
 * 
 * @param {Object} skillValues - Object containing the user's skill values
 * @returns {JSX.Element}
 */
const PromptEngineerLevel = ({ prevLevelXP, currentXP, nextLevelXP, currentLevel }) => {
    // Calculate progress percentage to next level
    const progressPercent = (currentXP - prevLevelXP) / (nextLevelXP - prevLevelXP) * 100;
    
    return (
        <>
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-5 mr-8">
                    <div className="w-12 h-12 rounded-full bg-gradient-to-r from-yellow-600 to-yellow-700 flex items-center justify-center text-white font-bold text-xl">
                        {currentLevel}
                    </div>
                    <div className="flex flex-col mt-1">
                        <h3 className="text-lg mb-0 mt-3 font-semibold text-gray-900 dark:text-white">Prompt Engineer Level {currentLevel}</h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                            {nextLevelXP - currentXP} XP to level {currentLevel + 1}
                        </p>
                    </div>
                </div>
                <div className="flex items-center space-x-1">
                    {[...Array(5)].map((_, i) => (
                        <motion.div 
                            key={i} 
                            className="relative"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            transition={{ 
                                delay: i * 0.1,
                                type: "spring",
                                stiffness: 300,
                                damping: 10
                            }}
                            whileHover={{ scale: 1.2, rotate: 5 }}
                        >
                            <svg 
                                width="24" 
                                height="24" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg"
                                className="drop-shadow-md"
                            >
                                <defs>
                                    <linearGradient id={`starGradient-${i}`} x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" stopColor={i < currentLevel ? "#FBBF24" : "#9CA3AF"} />
                                        <stop offset="100%" stopColor={i < currentLevel ? "#F59E0B" : "#6B7280"} />
                                    </linearGradient>
                                </defs>
                                <path 
                                    d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" 
                                    fill={i < currentLevel ? `url(#starGradient-${i})` : "none"}
                                    stroke={i < currentLevel ? "#F59E0B" : "#9CA3AF"}
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className={`transition-all duration-300 ${i < currentLevel ? "dark:opacity-100" : "dark:opacity-60"}`}
                                />
                            </svg>
                        </motion.div>
                    ))}
                </div>
            </div>
            <div className="relative w-full h-4 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                <div 
                    className="absolute top-0 left-0 h-full bg-gradient-to-r from-sky-500 to-yellow-600 rounded-full"
                    style={{ width: `${progressPercent}%` }}
                ></div>
            </div>
            <div className="flex justify-between mt-3 text-xs text-gray-600 dark:text-gray-400">
                <span>Level {currentLevel}</span>
                <span>Level {currentLevel + 1}</span>
            </div>
        </>
    );
};

export default PromptEngineerLevel;
