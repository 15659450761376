// Components
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";
import RequireAuth from "../../components/auth/RequireAuth";

function Applications() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Applications";
  }, []);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="applications">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="Applications" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="applications"
                   data-aos="fade-up"
                   data-aos-delay="100">
                  <RequireAuth
                        id="applications"
                        sectionDescription="Tutorials and guides on how to use the Crafting AI Prompts Framework with different AI models and tools."
                        title="Applications Documentation"
                      >
                    <h1 className="text-bold"><span className="text-header-gradient">Applications</span></h1>
                    <p>Prompt engineering is a versatile skill that can be applied across a wide range of domains and tools, enabling users to unlock the full potential of AI-powered systems. This section explores practical applications of prompt engineering in various use cases, providing tailored guidance and strategies to help you achieve the best results.</p>
                    <p>Each page in this section focuses on a specific application, such as coding with AI. You'll find information on how these tools work, best practices for crafting effective prompts, and how to integrate them with the frameworks outlined in this guide. Whether you're writing code, analyzing text, or working with multimedia, these use-case-specific insights will help you optimize outcomes and enhance your workflows.</p>
                    <p>Explore the possibilities of prompt engineering in action, and learn how to adapt techniques to meet the unique challenges of each application!</p>

                    <div className={"pt-5"}>
                        <BaseButton url={"/documentation/applications/coding#coding"} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                            Next: Coding with AI
                        </BaseButton>
                    </div>             
                </RequireAuth>
              </div>             
          </div>
      </section>
  );
}

export default Applications;
