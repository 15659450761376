import { useAuth } from '../../hooks/auth'
import React, {useEffect} from 'react'
import GuestLayout from "../../components/dashboard/Layouts/GuestLayout";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import {ReactComponent as Logo} from "../../assets/img/logo/crafting-ai-logo.svg";
import RateLimitMessage from "../../components/RateLimitMessage";

const Logout = () => {
    const { logout } = useAuth();
    const [isRateLimited, setIsRateLimited] = React.useState(false);

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Logout"
        logout()
            .then(() => window.location.href = '/')
            .catch((error)  => {
                if (error?.response?.status === 429) {
                    setIsRateLimited(true);
                }
            });
    }, [logout]);

    return (
       <GuestLayout>
           <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 px-4 sm:px-6 lg:px-8 transition-all duration-300">
               {isRateLimited && <RateLimitMessage className="max-w-md w-full mb-4" onExpire={() => {
                   setIsRateLimited(false);
                   window.location.href = '/';
               }} />}
               <DashboardCard className="max-w-md w-full space-y-8 overflow-hidden p-8 transform hover:scale-[1.02] transition-all duration-300 border border-gray-100 dark:border-gray-700">
                   <div className="flex flex-col items-center justify-center space-y-8">
                       <Logo className="h-12 w-auto mx-auto hover:opacity-80 transition-opacity" />
                       <div className="text-center space-y-3">
                           <h2 className="text-2xl font-bold text-gray-900 dark:text-white">See you soon!</h2>
                           <p className="text-gray-600 dark:text-gray-400">Securely logging you out...</p>
                       </div>
                       <div className="relative">
                           <div className="w-16 h-16 border-4 border-blue-100 dark:border-gray-700 rounded-full animate-spin">
                               <div className="absolute top-0 left-0 w-16 h-16 border-t-4 border-blue-500 dark:border-blue-400 rounded-full animate-spin"></div>
                           </div>
                       </div>
                       <p className="text-sm text-gray-500 dark:text-gray-400">You'll be redirected to the homepage shortly</p>
                   </div>
               </DashboardCard>
           </div>
       </GuestLayout>
    )
}

export default Logout
