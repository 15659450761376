import { useState, useCallback, memo } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { Fragment } from "react";
import { AlertCircle } from "lucide-react";
import { TransitionChild, DialogPanel, DialogTitle } from "@headlessui/react";
import { X } from "lucide-react";
import axios from "../../../libs/axios";

// Memoized DeletePopup component to prevent unnecessary re-renders
const DeletePopup = memo(({ isOpen, onClose, onConfirm, input, error, onInputChange }) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black/30 backdrop-blur-sm dark:bg-black/50" />
                        </TransitionChild>

                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel className="relative w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all border border-gray-200 backdrop-blur-sm z-10 mx-4 sm:mx-auto dark:bg-gray-800 dark:border-gray-700/50">
                                <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900 dark:text-white mb-4 flex items-center">
                                    <AlertCircle className="w-5 h-5 text-red-500 mr-2" />
                                    Delete Account
                                </DialogTitle>

                                <div className="mt-4 space-y-4">
                                    <div className="p-4 bg-red-50 dark:bg-red-900/10 rounded-lg border border-red-100 dark:border-red-800/50">
                                        <p className="mb-2 text-sm text-red-800 dark:text-red-200 font-medium">Are you sure you want to delete your account? <br />This action cannot be undone.</p>
                                    </div>

                                    <div className="space-y-2 text-sm text-gray-600 dark:text-gray-300">
                                        <p className="font-medium text-gray-900 dark:text-white">The following data will be permanently deleted:</p>
                                        <ul className="list-disc pl-5 space-y-1">
                                            <li>All certificates (published certifices become invalid)</li>
                                            <li>Test results</li>
                                            <li>Tickets and support history</li>
                                            <li>Tool ratings and reviews</li>
                                            <li>Your complete profile</li>
                                            <li>Achievements and activities</li>
                                            <li>All your Prompt Components and Recipes</li>
                                            <li>Your Prompt Challenges progress</li>
                                            <li>Your Skill Self Assessment</li>
                                        </ul>
                                    </div>

                                    <div className="bg-amber-50 dark:bg-amber-900/10 p-4 rounded-lg border border-amber-100 dark:border-amber-800/50">
                                        <p className="text-sm text-amber-800 dark:text-amber-200">
                                            If you have any concerns, please <Link to="/dashboard" className="font-medium underline hover:text-amber-600 dark:hover:text-amber-300">raise a ticket</Link> first. We're here to help!
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                                            Type "DELETE" to confirm. <strong>No turning back!</strong>
                                        </label>
                                        <input
                                            type="text"
                                            value={input}
                                            onChange={onInputChange}
                                            className="block w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700/50 shadow-sm focus:border-red-500 focus:ring-red-500 dark:text-white text-base px-4 py-2.5"
                                            placeholder="Type DELETE here"
                                        />
                                    </div>

                                    {error && (
                                        <p className="text-sm text-red-600 dark:text-red-400 mt-2 flex items-center">
                                            <X className="w-4 h-4 mr-1" />
                                            {error}
                                        </p>
                                    )}

                                    <div className="mt-6 flex flex-col-reverse sm:flex-row justify-end gap-3">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700/50 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400 transition-colors duration-200"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={onConfirm}
                                            className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                                        >
                                            <AlertCircle className="w-4 h-4 mr-2" />
                                            Delete Account
                                        </button>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
});

const ProfileDangerZone = () => {
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteInput, setDeleteInput] = useState('');
    const [deleteError, setDeleteError] = useState('');

    const handleDeleteClick = useCallback(() => {
        setShowDeletePopup(true);
    }, []);

    const handleDeleteConfirm = useCallback(async () => {
        if (deleteInput === 'DELETE') {
            try {
                await axios.delete('/user/delete-account', { data: { delete: true } }).then(
                    response => {
                        // Logout user
                        setShowDeletePopup(false);
                        setDeleteError('');
                        localStorage.removeItem('username');
                        window.location.reload();
                    }
                );
            } catch (error) {
                setDeleteError('An error occurred while deleting your account. Please try again later.');
            }
        } else {
            setDeleteError('Incorrect input. Please enter "DELETE" to confirm.');
        }
        setDeleteInput('');
    }, [deleteInput]);

    const handleInputChange = useCallback((e) => {
        setDeleteInput(e.target.value);
    }, []);

    const handleClose = useCallback(() => {
        setShowDeletePopup(false);
        setDeleteError('');
        setDeleteInput('');
    }, []);

    return (
        <>
            <div className="relative isolate">
                {/* Glowing border effect */}
                <div className="absolute -inset-1 bg-gradient-to-r from-red-500/50 via-red-600/50 to-red-500/50 rounded-xl blur-xl transition-all duration-500 group-hover:blur-2xl group-hover:opacity-75 animate-gradient-x"></div>
                
                {/* Content container */}
                <div className="relative p-8 bg-white dark:bg-gray-800 rounded-lg border border-red-200/50 dark:border-red-800/50 shadow-xl backdrop-blur-sm">
                    <div className="flex flex-col sm:flex-row items-center justify-between gap-6">
                        {/* Warning Icon */}
                        <div className="shrink-0 w-12 h-12 rounded-full bg-red-50 dark:bg-red-900/30 flex items-center justify-center">
                            <AlertCircle className="w-6 h-6 text-red-600 dark:text-red-400" />
                        </div>

                        {/* Content */}
                        <div className="flex-grow text-center sm:text-left">
                            <h4 className="text-lg font-semibold text-red-600 dark:text-red-400 mb-2 tracking-wide">DANGER ZONE</h4>
                            <p className="text-sm text-gray-600 dark:text-gray-300">Once you delete your account, there is no going back. Please be certain.</p>
                        </div>

                        {/* Action Button */}
                        <div className="shrink-0">
                            <button
                                onClick={handleDeleteClick}
                                className="inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition-colors duration-200"
                            >
                                <AlertCircle className="w-4 h-4 mr-2" />
                                Delete Account
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <DeletePopup 
                isOpen={showDeletePopup}
                onClose={handleClose}
                onConfirm={handleDeleteConfirm}
                input={deleteInput}
                error={deleteError}
                onInputChange={handleInputChange}
            />
        </>
    );
};

export default ProfileDangerZone;