import React from 'react';
import { Users } from 'lucide-react';
import RoleEnum from '../../../components/enums/RoleEnum';

interface DeletedUser {
    id: number;
    user_id: number;
    email: string;
    role: number;
    account_lifetime: string;
    deleted_at: string;
}

interface DeletedUsersListProps {
    deletedUsers: DeletedUser[] | null;
    isLoading: boolean;
}

export const DeletedUsersList: React.FC<DeletedUsersListProps> = ({ deletedUsers, isLoading }) => {
    return (
        <>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white flex items-center gap-2 mb-4">
                <Users className="w-5 h-5 text-red-500" />
                Recently Deleted Users
            </h2>
            <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200/70 dark:border-gray-700/50 divide-y divide-gray-100/70 dark:divide-gray-700/30 overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200">
                <div className="px-3 py-2 bg-gray-50/50 dark:bg-gray-800/50 border-b border-gray-100/70 dark:border-gray-700/30 sticky top-0 z-10">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <span className="text-xs font-medium text-gray-500 dark:text-gray-400">Total Deleted</span>
                        </div>
                        <span className="text-xs text-gray-400 dark:text-gray-500">{(deletedUsers || []).length} users</span>
                    </div>
                </div>
                <div className="max-h-[17rem] overflow-y-auto hover:max-h-[calc(10*2.875rem)] transition-[max-height] duration-500 ease-in-out scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-track-transparent hover:scrollbar-thumb-gray-300 dark:hover:scrollbar-thumb-gray-600 overflow-hidden">
                    {isLoading ? (
                        [...Array(3)].map((_, index) => (
                            <div key={index} className="animate-pulse flex items-center p-3">
                                <div className="h-10 w-10 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
                                <div className="ml-4 flex-1">
                                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4 mb-2"></div>
                                    <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
                                </div>
                            </div>
                        ))
                    ) : (deletedUsers || []).length > 0 ? (
                        (deletedUsers || []).map((user) => (
                            <div 
                                key={user.id}
                                className="group flex items-center p-3 bg-gradient-to-br from-gray-50 to-transparent dark:from-gray-800/30 dark:to-transparent border border-b dark:border-gray-700/50 transition-all duration-300"
                            >
                                <div className="relative">
                                    <div className="bg-gradient-to-br from-red-50 to-red-50/70 dark:from-red-900/20 dark:to-red-800/10 rounded-full p-2 ring-4 ring-red-50/30 dark:ring-red-900/10 transition-all duration-200 shadow-sm group-hover:shadow group-hover:scale-[1.02]">
                                        <span className="text-lg font-semibold bg-gradient-to-r from-red-600 to-red-500 dark:from-red-400 dark:to-red-500 bg-clip-text text-transparent transition-all duration-200">
                                            {user.user_id}
                                        </span>
                                    </div>
                                </div>
                                <div className="ml-5 min-w-0 flex-1">
                                    <div className="flex items-center justify-between pt-1.5 pb-0">
                                        <p className="text-sm m-0 font-medium text-gray-900 dark:text-white truncate transition-all duration-200 flex items-center max-w-[60%]">
                                            {user.email}
                                        </p>
                                        <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-gradient-to-r from-red-50 to-red-50/50 text-red-700 dark:from-red-900/20 dark:to-red-800/10 dark:text-red-400 ring-1 ring-red-500/10 dark:ring-red-400/10 group-hover:from-red-100 group-hover:to-red-50 dark:group-hover:from-red-900/30 dark:group-hover:to-red-800/20 transition-all duration-200 shadow-sm group-hover:shadow group-hover:ring-red-500/20 dark:group-hover:ring-red-400/20">
                                            {RoleEnum[user.role]}
                                        </span>
                                    </div>
                                    <div className="mt-1.5 flex items-center justify-between">
                                        <p className="text-xs text-gray-500 dark:text-gray-400 transition-all duration-200">
                                            Lifetime: {user.account_lifetime}
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400 transition-all duration-200 pl-4">
                                            {new Date(user.deleted_at).toLocaleString('en-US', {
                                                month: 'short',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="flex flex-col py-6 px-4 bg-gradient-to-br from-gray-50 to-transparent dark:from-gray-800/30 dark:to-transparent rounded-xl border border-gray-200 dark:border-gray-700/50 transition-all duration-300 hover:border-gray-300 dark:hover:border-gray-600">
                            <div className="flex items-center">
                                <div className="relative">
                                    <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-3 ring-4 ring-gray-50 dark:ring-gray-700/20">
                                        <Users className="w-8 h-8 text-gray-400 dark:text-gray-500" />
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">No Deleted Users</h3>
                                    <p className="text-sm text-gray-600 dark:text-gray-400">
                                        Deleted user accounts will appear here.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
