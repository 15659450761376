import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FiX } from 'react-icons/fi';
import RoleEnum from '../../enums/RoleEnum';

const AddChallengeModal = ({
    isOpen,
    onClose,
    onSubmit,
    challenge,
    setChallenge,
    tools,
    userRole
}) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({
            ...challenge,
            xp_reward: challenge.xp_reward ? parseInt(challenge.xp_reward) : 0
        });
    };

    return (
        <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-50" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="bg-white dark:bg-gradient-to-br dark:from-indigo-900 dark:to-purple-900 text-gray-900 dark:text-white border border-gray-200 dark:border-gray-700 rounded-xl max-w-xl w-full overflow-auto max-h-[90vh] transform transition-all shadow-xl">
                                <button
                                    onClick={onClose}
                                    className="absolute top-4 right-4 text-gray-500 dark:text-white/70 hover:text-gray-700 dark:hover:text-white transition-colors"
                                >
                                    <FiX className="h-5 w-5" />
                                </button>
                                <div className="p-6">
                                    <h2 className="text-xl font-semibold mb-6 text-gray-900 dark:text-white">Add New Challenge</h2>
                                    <form onSubmit={handleSubmit} className="space-y-6">
                                        <div>
                                            <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter challenge title"
                                                value={challenge.title}
                                                onChange={(e) => setChallenge({ ...challenge, title: e.target.value })}
                                                className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Description</label>
                                            <textarea
                                                placeholder="Enter challenge description"
                                                value={challenge.description}
                                                onChange={(e) => setChallenge({ ...challenge, description: e.target.value })}
                                                className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Skill Focus</label>
                                                <select
                                                    value={challenge.skill_focus}
                                                    onChange={(e) => setChallenge({ ...challenge, skill_focus: e.target.value })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white"
                                                >
                                                    <option value="">Select Skill Focus</option>
                                                    <option value="knowledge">Knowledge</option>
                                                    <option value="application">Application</option>
                                                    <option value="recognition">Recognition</option>
                                                    <option value="ethical_use">Ethical Use</option>
                                                    <option value="risks">Risks</option>
                                                    <option value="developments">Developments</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Difficulty</label>
                                                <select
                                                    value={challenge.difficulty}
                                                    onChange={(e) => setChallenge({ ...challenge, difficulty: e.target.value })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white"
                                                >
                                                    <option value="">Select Difficulty</option>
                                                    <option value="beginner">Beginner</option>
                                                    <option value="intermediate">Intermediate</option>
                                                    <option value="advanced">Advanced</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">XP Reward</label>
                                                <input
                                                    type="number"
                                                    placeholder="Enter XP reward"
                                                    required
                                                    min="0"
                                                    max="1000000"
                                                    value={challenge.xp_reward}
                                                    onChange={(e) => setChallenge({ ...challenge, xp_reward: e.target.value })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Review Status</label>
                                                <select
                                                    value={challenge.needs_review ? '1' : '0'}
                                                    onChange={(e) => setChallenge({ ...challenge, needs_review: e.target.value === '1' })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white"
                                                >
                                                    <option value="0">Instant XP</option>
                                                    <option value="1">Needs Review</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Requirements</label>
                                            <textarea
                                                placeholder="Enter challenge requirements"
                                                value={challenge.requirements}
                                                onChange={(e) => setChallenge({ ...challenge, requirements: e.target.value })}
                                                className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Completion Criteria</label>
                                            <textarea
                                                placeholder="Enter completion criteria"
                                                value={challenge.completion_criteria}
                                                onChange={(e) => setChallenge({ ...challenge, completion_criteria: e.target.value })}
                                                className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                            />
                                        </div>
                                        {userRole >= RoleEnum.Admin && (
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Tool</label>
                                                <select
                                                    value={challenge.tool?.id || ''}
                                                    onChange={(e) => setChallenge({ ...challenge, tool: tools.find(t => t.id === parseInt(e.target.value)) || null })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white"
                                                >
                                                    <option value="">Select Tool (Optional)</option>
                                                    {tools.map((tool) => (
                                                        <option key={tool.id} value={tool.id}>
                                                            {tool.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Lock Date</label>
                                                <input
                                                    type="datetime-local"
                                                    value={challenge.unlock_date}
                                                    onChange={(e) => setChallenge({ ...challenge, unlock_date: e.target.value })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Level Requirement</label>
                                                <input
                                                    type="number"
                                                    placeholder='Level Requirement'
                                                    value={challenge.level_requirement}
                                                    onChange={(e) => setChallenge({ ...challenge, level_requirement: parseInt(e.target.value, 10) })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">XP Requirement</label>
                                                <input
                                                    type="number"
                                                    placeholder='XP Requirement'
                                                    value={challenge.xp_requirement}
                                                    onChange={(e) => setChallenge({ ...challenge, xp_requirement: parseInt(e.target.value, 10) })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Role Requirement</label>
                                                <select
                                                    value={challenge.role_requirement}
                                                    onChange={(e) => setChallenge({ ...challenge, role_requirement: parseInt(e.target.value, 10) })}
                                                    className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white"
                                                >
                                                    <option value="">None</option>
                                                    <option value={RoleEnum.ProUser}>PRO User</option>
                                                    <option value={RoleEnum.DefaultUser}>User</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium mb-1 text-gray-700 dark:text-white">Unlock Requirement</label>
                                            <input
                                                type="text"
                                                placeholder='Unlock Requirement'
                                                value={challenge.unlock_requirement}
                                                onChange={(e) => setChallenge({ ...challenge, unlock_requirement: e.target.value })}
                                                className="w-full px-4 py-3 bg-white/5 dark:bg-white/10 border border-gray-200 dark:border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-500 dark:placeholder:text-white/50"
                                            />
                                        </div>
                                        <div className="mt-8 flex justify-end gap-4">
                                            <button
                                                type="button"
                                                onClick={onClose}
                                                className="px-6 py-3 text-gray-700 dark:text-white bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-lg transition-all duration-200"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                disabled={!challenge.title || !challenge.description}
                                                className="px-6 py-3 disabled:bg-gray-300 dark:disabled:bg-gray-600 disabled:text-gray-500 text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-700 dark:hover:bg-indigo-800 rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddChallengeModal;
