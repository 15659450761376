import Navigation from './Navigation'
import { useAuth } from '../../../hooks/auth'

const AppLayout = ({ header, children }) => {
  const { user } = useAuth({ middleware: 'auth' })
   
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Navigation user={user} />
      {/* Page Heading */}
      <header className="bg-white shadow dark:bg-gray-800">
        <div className="max-w-7xl h-[4.7rem] mx-auto pt-6 pb-2 px-8 sm:px-11 lg:px-11">
            {header}
        </div>
      </header>
      {/* Page Content */}
      <main>{children}</main>
    </div>
  )
}

export default AppLayout
