const RATE_LIMIT_KEY = 'rateLimitStartTime';
const RATE_LIMIT_DURATION = 60; // seconds

/**
 * Force starts a new rate limit timer
 */
export const startRateLimit = () => {
    const now = Date.now();
    sessionStorage.setItem(RATE_LIMIT_KEY, now.toString());
};

/**
 * Handles rate limit state when a rate limit occurs
 */
export const handleRateLimit = () => {
    const storedTime = sessionStorage.getItem(RATE_LIMIT_KEY);
    const now = Date.now();
    
    // If no stored time or time is stale, set new time
    if (!storedTime) {
        sessionStorage.setItem(RATE_LIMIT_KEY, now.toString());
        return true;
    }
    
    const elapsedTime = Math.floor((now - parseInt(storedTime)) / 1000);
    if (elapsedTime >= RATE_LIMIT_DURATION) {
        // Time is stale, set new time
        sessionStorage.setItem(RATE_LIMIT_KEY, now.toString());
    }
    // Always return true to indicate rate limit was handled
    return true;
};

/**
 * Checks if the user is currently rate limited
 */
export const isRateLimited = () => {
    const storedTime = sessionStorage.getItem(RATE_LIMIT_KEY);
    if (!storedTime) return false;
    
    const elapsedTime = Math.floor((Date.now() - parseInt(storedTime)) / 1000);
    return elapsedTime < RATE_LIMIT_DURATION;
};

/**
 * Gets the remaining time in seconds for the rate limit
 */
export const getRateLimitRemaining = () => {
    const storedTime = sessionStorage.getItem(RATE_LIMIT_KEY);
    if (!storedTime) return 0;
    
    const elapsedTime = Math.floor((Date.now() - parseInt(storedTime)) / 1000);
    return Math.max(0, RATE_LIMIT_DURATION - elapsedTime);
};
