import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';

interface RequireAuthProps {
  children: React.ReactNode;
  sectionDescription?: string;
  title?: string;
  description?: string;
  id?: string;
  onAuthenticated?: () => void;
}

export default function RequireAuth({ 
  children, 
  sectionDescription,
  title = "Login Required",
  description = "Please log in to access this content. This content is exclusively available to our registered users. Registration is free!",
  id,
  onAuthenticated
}: RequireAuthProps) {
  const { user, isLoading, authError } = useAuth({ middleware: 'guest' });

  useEffect(() => {
    if (onAuthenticated !== undefined && user) {
      onAuthenticated();
    }
  }, [user]);

  const BackButton = () => {
    return(
      <div className="space-y-4">
        <button
          onClick={() => window.history.back()}
          className="w-full inline-flex items-center justify-center px-5 py-2.5 border border-gray-200 dark:border-gray-700 text-sm font-medium rounded-lg text-gray-600 dark:text-gray-400 bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-800 transition-colors duration-150"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          Go back where you left
        </button>
      </div>);
  }

  const AuthButtons = () => {
    return(
      <div className="flex flex-col sm:flex-row gap-3 justify-center md:justify-start w-full">
        <a
          href={`/login?redirect=${window.location.pathname}`}
          className="flex-1 inline-flex items-center justify-center px-5 py-2.5 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-800 transition-colors duration-150"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
          </svg>
          Log in
        </a>
        <a
          href={`/register`}
          className="flex-1 inline-flex items-center justify-center px-5 py-2.5 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-lg text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-800 transition-colors duration-150"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
          </svg>
          Register
        </a>
      </div>
    );
  }

  const AuthContent = () => {
    return(
      <div id={id} className="relative z-50 min-h-[calc(100vh-30rem)]  dark:from-gray-900 dark:to-gray-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white dark:bg-gray-800 shadow-xl rounded-xl overflow-hidden">
          <div className="grid md:grid-cols-2 gap-6">
            {/* Left side - Image */}
            <div className="relative h-64 md:h-full bg-gradient-to-br from-blue-500 to-indigo-600 p-6 flex items-center justify-center">
              <div className="absolute inset-0 bg-black opacity-10"></div>
              <div className="relative text-center my-6 md:my-0">
                <svg
                  className="h-20 w-20 mx-auto text-white opacity-75"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
                <h2 className="mt-4 text-xl font-bold text-white">{title}</h2>
                <p className="mt-2 text-sm text-blue-100">{sectionDescription}</p>
              </div>
            </div>

            {/* Right side - Content */}
            <div className="p-6 md:p-8 flex flex-col justify-center">
              {isLoading ? (
                <div className="p-6 md:p-8 flex flex-col justify-center">
                  <div className="text-center md:text-left space-y-4">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      Loading....
                    </h3>
                    <p className="text-base text-gray-500 dark:text-gray-400">
                      Please wait... we're getting you logged in.
                    </p>
                  </div>
              </div>
              ) : authError !== 401 ? (
                <div className="p-6 md:p-8 flex flex-col justify-center">
                  <div className="text-center md:text-left space-y-4">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      Something went wrong
                    </h3>
                    <p className="text-base text-gray-500 dark:text-gray-400">
                      Sorry! It seems like something went wrong. Please try again later.
                    </p>
                    <BackButton />
                  </div>
                </div>
                ) : (
                <div className="text-center md:text-left space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Login required
                  </h3>
                  <p className="text-base text-gray-500 dark:text-gray-400">
                    {description}
                  </p>
                  <div className="space-y-4">
                    <AuthButtons />
                    <BackButton />
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
    );
  }

  if (!user) {
      return <AuthContent />;
  }
  
  return <>{children}</>;
}
