const ProfileAchievements = ({ userAchievements }) => {

    const AchievementBadge = ({ achievement }) => {
        const Icon = achievement.icon;
        return (
            <div className="group relative flex flex-col items-center">
                <div className={`w-16 h-16 rounded-full flex items-center justify-center mb-2
                    transition-all duration-300 ${
                    achievement.unlocked 
                        ? `bg-${achievement.color.split('-')[1]}-100 dark:bg-${achievement.color.split('-')[1]}-900/30` 
                        : 'bg-gray-100 dark:bg-gray-800'
                    }`}
                >
                    <Icon className={`w-8 h-8 ${
                        achievement.unlocked 
                            ? achievement.color
                            : 'text-gray-400 dark:text-gray-600'
                    }`} />
                </div>
                <p className={`text-sm text-center font-medium transition-colors duration-200 max-w-[120px] ${
                    achievement.unlocked 
                        ? 'text-gray-900 dark:text-white' 
                        : 'text-gray-500 dark:text-gray-400'
                }`}>
                    {achievement.name}
                </p>
                
                {/* Tooltip */}
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute -top-16 left-1/2 transform -translate-x-1/2 bg-gray-900 text-white text-xs rounded py-2 px-4 w-48 z-10">
                    <div className="text-center">
                        <p className="font-semibold mb-1">{achievement.name}</p>
                        <p className="text-gray-300">{achievement.description}</p>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
                </div>
            </div>
        );
    };
    
    return(            
        <div className="bg-gray-50 dark:bg-gray-800/50 p-6 rounded-xl border border-gray-200 dark:border-gray-700/50 shadow-sm">
            <div className="flex items-center justify-between mb-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">Achievements</h3>
                <span className="px-3 py-1 text-sm font-medium text-gray-600 dark:text-gray-300 bg-gray-100 dark:bg-gray-700/50 rounded-full">
                    {userAchievements.filter(a => a.unlocked).length} / {userAchievements.length} Unlocked
                </span>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-6 justify-items-center">
            {userAchievements.map((achievement) => (
                <AchievementBadge 
                    key={achievement.id} 
                    achievement={achievement} 
                />
            ))}
        </div>
    </div>
);
}

export default ProfileAchievements;