import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import RoleEnum from "../../components/enums/RoleEnum";
import axios from "../../libs/axios";
import {
    Award,
    Users,
    Clock,
    MessageCircle
} from 'lucide-react';
import { DeletedUsersList } from './admin/DeletedUsersList';
import {useNavigate} from "react-router-dom";
import BaseButton from "../../components/base/Button";
import ReplyTicketModal from "../../components/dashboard/dashboard/ReplyTicketModal";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import ForceAuthentication from '../../components/auth/ForceAuthentication';
import UserList from './admin/user-list';
import TicketList from './admin/ticket-list';

const AdminContent = ({ user }) => {
    const [userList, setUserList] = useState([]);
    const [ticketList, setTicketList] = useState([]);
    const [latestLogins, setLatestLogins] = useState([]);
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [currentTicketId, setCurrentTicketId] = useState(null);
    const [replyText, setReplyText] = useState('');


    const apiCallMadeRef = useRef(false);
    const navigate = useNavigate();

    const { csrf } = useAuth();

    // Wrapper for API calls to handle rate limits
    const safeApiCall = async (apiCall, errorValue = null) => {        
        try {
            const result = await apiCall();
            return result;
        } catch (error) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                navigate('/dashboard/overview');
                window.location.reload();
            }
            return errorValue;
        }
    };

    const fetchData = async (isRefresh = false) => {
        if (!isRefresh && apiCallMadeRef.current) return;
        if (isRefresh) {
            setIsRefreshing(true);
        } else {
            setIsLoading(true);
        }
        try {
            await csrf();

            // Fetch data in parallel with rate limit handling
            const [userListResponse, ticketListResponse, loginsResponse, deletedUsersResponse] = await Promise.all([
                safeApiCall(async () => {
                    const response = await axios.get("/admin/get-user-list");
                    return response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                }, []),
                safeApiCall(async () => {
                    const response = await axios.get("/admin/get-ticket-list");
                    return response.data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                }, []),
                safeApiCall(async () => {
                    const response = await axios.get('/admin/latest-logins');
                    // Map login data to include user ID
                    return response.data.map(login => ({
                        ...login,
                        user_id: login.user?.id || login.user_id
                    }));
                }, []),
                safeApiCall(async () => {
                    const response = await axios.get('/admin/deleted-users');
                    return response.data.data;
                }, [])
            ]);

            if (userListResponse) setUserList(userListResponse);
            if (ticketListResponse) setTicketList(ticketListResponse);
            if (loginsResponse) setLatestLogins(loginsResponse);
            if (deletedUsersResponse) setDeletedUsers(deletedUsersResponse);
        } catch (error) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                navigate('/dashboard/overview');
            }
            setError(error.message || 'An error occurred while fetching data');
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }
    };

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Admin";
        fetchData();
    }, []);

    async function upgradeAccountToTakeTests(userID, roleID) {
        // Write for me a post to /admin/upgrade-user-role-1/$userID
        try {
            await csrf();
            const response = await axios.post(`/admin/upgrade-user-role/${userID}/${roleID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function banAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/ban-user/${userID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function unBanAccount(userID) {
        try {
            await csrf();
            const response = await axios.post(`/admin/unban-user/${userID}`);
            setUserList(userList.map(user => user.id === userID ? response.data : user));
        } catch (error) {
            //console.error(error);
        }
    }

    async function closeTicket(ticketId) {
        await csrf();
        axios.post(`/admin/close-ticket/${ticketId}`).then(() => {
            const currentTicket = ticketList.find(ticket => ticket.id === ticketId);
            if (currentTicket && currentTicket.status === 0) {
                const currentOpenTickets = localStorage.getItem('total_open_tickets') ? parseInt(localStorage.getItem('total_open_tickets')) : 0;
                localStorage.setItem('total_open_tickets', currentOpenTickets - 1);
            }
            setTicketList(ticketList.map(ticket => ticket.id === ticketId ? {...ticket, status: 2} : ticket));
        }).catch((error) => {

        });
    }

    function replyToTicket(ticketId) {
        setCurrentTicketId(ticketId);
        setShowReplyModal(true);
    }

    async function handleReplySubmit() {
        try {
            await csrf();
            axios.post(`/ticket/reply`, {
                ticket_id: currentTicketId,
                message: replyText
            }).then(response => {
                const currentTicket = ticketList.find(ticket => ticket.id === currentTicketId);
                if (currentTicket && currentTicket.status === 0) {
                    const currentOpenTickets = localStorage.getItem('total_open_tickets') ? parseInt(localStorage.getItem('total_open_tickets')) : 0;
                    localStorage.setItem('total_open_tickets', currentOpenTickets - 1);
                }

                // Update the ticket list
                setTicketList(ticketList.map(ticket => ticket.id === currentTicketId ? {
                    ...ticket,
                    updates: [...ticket.updates, {
                        user: user,
                        description: replyText,
                        updated_at: new Date().toISOString()
                    }],
                    status: response.data.new_ticket_status // update the status of the ticket
                } : ticket));


            });
            setShowReplyModal(false);
            setReplyText('');
        } catch (error) {
            // Do nothing
        }
    }

    const totalCertificates = userList.reduce((total, user) => total + (user.tests?.filter(test => test.certificate_id !== null).length || 0), 0);
    const bannedUsersCount = userList.filter(user => user.banned_user).length;
    const todayOnlineUsers = latestLogins.filter(login => new Date(login.lastActivity).toDateString() === new Date().toDateString()).length;

    return(
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 dark:text-white leading-tight">
                    Admin Dashboard
                </h2>
            }>
            <div className="py-8 mx-3 lg:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <DashboardCard className="md:col-span-2 overflow-hidden mb-4">
                            <div className="flex items-center gap-6 rounded-xl">
                                <div className="w-16 h-16 rounded-full bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:from-[#0c66ee] dark:to-[#468ef9] flex items-center justify-center">
                                    <span className="text-2xl font-bold text-white dark:text-black">
                                        {user?.name?.[0]?.toUpperCase()}
                                    </span>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                                        Welcome to the admin center, <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#468ef9] to-[#0c66ee] dark:bg-gradient-to-r dark:from-[#0c66ee] dark:to-[#468ef9]">{user?.name}</span>
                                    </h1>
                                    <p className="text-gray-600 dark:text-gray-300 mt-1">Here are some tools for admins!</p>
                                </div>
                            </div>
                        </DashboardCard>
                        <DashboardCard className="md:col-span-1 overflow-hidden mb-4">
                            <h2 id="quick-buttons" className="text-[2rem] mb-4 dark:text-white">
                                Admin tools
                            </h2>
                            <BaseButton 
                                url={"/dashboard/admin/test/questions"} 
                                styles={"px-4 py-2 mr-3 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-colors"}
                            >
                                Test questions
                            </BaseButton>
                            <BaseButton 
                                url={"/dashboard/admin/tools/"} 
                                styles={"px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-colors"}
                            >
                                Tools
                            </BaseButton>
                        </DashboardCard>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                        <DashboardCard className="overflow-hidden">
                            <h2 id="quick-buttons" className="text-[2rem] dark:text-white flex items-center justify-between">
                                <span>Stats</span>
                                <div className="flex items-center gap-4">
                                    {error && <span className="text-sm text-red-500 font-normal">{error}</span>}
                                    <button
                                        onClick={() => fetchData(true)}
                                        disabled={isRefreshing}
                                        className={`text-sm font-medium flex items-center gap-2 px-3 py-1.5 rounded-lg
                                            ${isRefreshing
                                                ? 'bg-gray-100 text-gray-400 dark:bg-gray-800 dark:text-gray-500 cursor-not-allowed'
                                                : 'bg-blue-50 text-blue-600 hover:bg-blue-100 dark:bg-blue-900/20 dark:text-blue-400 dark:hover:bg-blue-900/30 cursor-pointer'
                                            } transition-colors duration-200`}
                                    >
                                        <svg
                                            className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                            />
                                        </svg>
                                        {isRefreshing ? 'Refreshing...' : 'Refresh'}
                                    </button>
                                </div>
                            </h2>
                            <div className="grid grid-cols-3 gap-4 w-full">
                                {isLoading ? (
                                    [...Array(3)].map((_, index) => (
                                        <div key={index} className="group relative rounded-xl bg-gradient-to-br from-gray-50/80 to-transparent dark:from-gray-800/20 dark:to-transparent border border-gray-200/50 dark:border-gray-700/30 animate-pulse overflow-hidden hover:shadow-md transition-all duration-300">
                                            <div className="absolute inset-0 bg-gradient-to-br from-gray-50/50 via-transparent to-transparent dark:from-gray-800/10 dark:via-transparent dark:to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                                            <div className="relative p-4">
                                                <div className="flex items-center">
                                                    <div className="w-8 h-8 bg-gradient-to-br from-gray-200/90 via-gray-100/80 to-gray-50/90 dark:from-gray-700/90 dark:via-gray-600/80 dark:to-gray-500/90 rounded-lg shadow-sm"></div>
                                                    <div className="ml-2">
                                                        <div className="h-7 bg-gradient-to-r from-gray-200/90 via-gray-100/80 to-gray-50/90 dark:from-gray-700/90 dark:via-gray-600/80 dark:to-gray-500/90 rounded-md w-16 shadow-sm"></div>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <div className="h-4 bg-gradient-to-r from-gray-200/90 via-gray-100/80 to-gray-50/90 dark:from-gray-700/90 dark:via-gray-600/80 dark:to-gray-500/90 rounded w-20 shadow-sm"></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <div className="flex items-center rounded-xl bg-gradient-to-br from-yellow-50 to-transparent dark:from-yellow-900/10 dark:to-transparent hover:from-yellow-100 dark:hover:from-yellow-900/20 transition-colors duration-300 shadow-sm hover:shadow-md">
                                            <div className="w-full">
                                                <div className="flex justify-center items-center p-2">
                                                    <Award className={"text-yellow-600 w-8 h-8 mr-2"} />
                                                    <p className={"text-2xl pt-5 font-bold dark:text-white tracking-tight"}>{totalCertificates}</p>
                                                </div>
                                                <p className={"text-sm font-medium text-yellow-600 dark:text-yellow-400 text-center"}>Certificates</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center rounded-xl bg-gradient-to-br from-blue-50 to-transparent dark:from-blue-900/10 dark:to-transparent hover:from-blue-100 dark:hover:from-blue-900/20 transition-colors duration-300 shadow-sm hover:shadow-md">
                                            <div className="w-full">
                                                <div className="flex justify-center items-center p-2">
                                                    <Users className={"text-blue-600 w-8 h-8 mr-2"} />
                                                    <p className={"text-2xl pt-5 font-bold dark:text-white tracking-tight"}>{userList.length}</p>
                                                </div>
                                                <p className={"text-sm font-medium text-blue-600 dark:text-blue-400 text-center"}>Users</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center rounded-xl bg-gradient-to-br from-red-50 to-transparent dark:from-red-900/10 dark:to-transparent hover:from-red-100 dark:hover:from-red-900/20 transition-colors duration-300 shadow-sm hover:shadow-md">
                                            <div className="w-full">
                                                <div className="flex justify-center items-center p-2">
                                                    <Users className={"text-red-600 w-8 h-8 mr-2"} />
                                                    <p className={"text-2xl pt-5 font-bold dark:text-white tracking-tight"}>{bannedUsersCount}</p>
                                                </div>
                                                <p className={"text-sm font-medium text-red-600 dark:text-red-400 text-center"}>Banned</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center rounded-xl bg-gradient-to-br from-green-50 to-transparent dark:from-green-900/10 dark:to-transparent hover:from-green-100 dark:hover:from-green-900/20 transition-colors duration-300 shadow-sm hover:shadow-md">
                                            <div className="w-full">
                                                <div className="flex justify-center items-center p-2">
                                                    <Users className={"text-green-600 w-8 h-8 mr-2"} />
                                                    <p className={"text-2xl pt-5 font-bold dark:text-white tracking-tight"}>
                                                        {todayOnlineUsers}
                                                    </p>
                                                </div>
                                                <p className={"text-sm font-medium text-green-600 dark:text-green-400 text-center"}>Today online</p>
                                            </div>
                                        </div> 
                                        <div className="flex items-center rounded-xl bg-gradient-to-br from-sky-50 to-transparent dark:from-sky-900/10 dark:to-transparent hover:from-sky-100 dark:hover:from-sky-900/20 transition-colors duration-300 shadow-sm hover:shadow-md">
                                            <div className="w-full">
                                                <div className="flex justify-center items-center p-2">
                                                    <MessageCircle className={"text-sky-600 w-8 h-8 mr-2"} />
                                                    <p className={"text-2xl pt-5 font-bold dark:text-white tracking-tight"}>
                                                        {ticketList.filter(ticket => ticket.status === 0).length}
                                                    </p>
                                                </div>
                                                <p className={"text-sm font-medium text-sky-600 dark:text-sky-400 text-center"}>Open tickets</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center rounded-xl bg-gradient-to-br from-sky-50 to-transparent dark:from-sky-900/10 dark:to-transparent hover:from-sky-100 dark:hover:from-sky-900/20 transition-colors duration-300 shadow-sm hover:shadow-md">
                                            <div className="w-full">
                                                <div className="flex justify-center items-center p-2">
                                                    <Clock className={"text-sky-600 w-8 h-8 mr-2"} />
                                                    <p className={"text-2xl pt-5 font-bold dark:text-white tracking-tight"}>
                                                        {ticketList.filter(ticket => ticket.status === 1).length}
                                                    </p>
                                                </div>
                                                <p className={"text-sm font-medium text-sky-600 dark:text-sky-400 text-center"}>In progress tickets</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </DashboardCard>

                        <DashboardCard className="overflow-hidden">
                            <h2 id="activity" className="text-xl dark:text-white flex items-center justify-between">
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center gap-2">
                                        <span className="font-semibold">Active Users</span>
                                        
                                        {isLoading && <span className="text-sm text-green-500 font-medium animate-pulse ml-2">Loading...</span>}
                                        {error && <span className="text-sm text-red-500 font-normal ml-2">{error}</span>}
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="flex items-center gap-2 text-sm font-medium text-green-600 dark:text-green-400">
                                            <span className="flex h-2 w-2">
                                                <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-green-400 opacity-75"></span>
                                                <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                                            </span>
                                            <span className="min-w-[1ch]">{latestLogins?.filter(login => Date.now() - new Date(login.lastActivity).getTime() < 10 * 60 * 1000).length || 0}</span>
                                            <span className="text-gray-600 dark:text-gray-400">active</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-sm font-medium text-orange-600 dark:text-orange-400">
                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-orange-400"></span>
                                            <span className="min-w-[1ch]">{latestLogins?.filter(login => {
                                                const time = Date.now() - new Date(login.lastActivity).getTime();
                                                return time >= 10 * 60 * 1000 && time < 15 * 60 * 1000;
                                            }).length || 0}</span>
                                            <span className="text-gray-600 dark:text-gray-400">idle</span>
                                        </div>
                                    </div>
                                </div>
                            </h2>
                            <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200/70 dark:border-gray-700/50 divide-y divide-gray-100/70 dark:divide-gray-700/30 overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200">
                                <div className="px-3 py-2 bg-gray-50/50 dark:bg-gray-800/50 border-b border-gray-100/70 dark:border-gray-700/30 sticky top-0 z-10">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2">
                                            <div className="flex items-center gap-3">
                                                <div className="flex items-center gap-2">
                                                    <span className="text-xs font-medium text-gray-500 dark:text-gray-400">Active</span>
                                                    <span className="flex h-2 w-2">
                                                        <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-green-400 opacity-75"></span>
                                                        <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                                                    </span>
                                                    <span className="text-xs text-gray-400 dark:text-gray-500 min-w-[1ch]">
                                                        {latestLogins?.filter(login => Date.now() - new Date(login.lastActivity).getTime() < 10 * 60 * 1000).length || 0}
                                                    </span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <span className="text-xs font-medium text-gray-500 dark:text-gray-400">Idle</span>
                                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-orange-400"></span>
                                                    <span className="text-xs text-gray-400 dark:text-gray-500 min-w-[1ch]">
                                                        {latestLogins?.filter(login => {
                                                            const time = Date.now() - new Date(login.lastActivity).getTime();
                                                            return time >= 10 * 60 * 1000 && time < 15 * 60 * 1000;
                                                        }).length || 0}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="text-xs text-gray-400 dark:text-gray-500">{latestLogins?.length || 0} activities</span>
                                    </div>
                                </div>
                                <div className="max-h-[17rem] overflow-y-auto hover:max-h-[calc(10*2.875rem)] transition-[max-height] duration-500 ease-in-out scrollbar-thin scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700 scrollbar-track-transparent hover:scrollbar-thumb-gray-300 dark:hover:scrollbar-thumb-gray-600 overflow-hidden">
                                {isLoading ? (
                                    [...Array(3)].map((_, index) => (
                                        <div key={index} className="px-3 py-2.5 border-b last:border-b-0">
                                            <div className="flex items-center gap-3">
                                                <div className="w-8 h-8 rounded-full bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 dark:from-gray-700 dark:via-gray-600 dark:to-gray-700 animate-shimmer"></div>
                                                <div className="flex-1">
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex flex-col gap-1.5">
                                                            <div className="flex items-center gap-2">
                                                                <div className="h-4 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 dark:from-gray-700 dark:via-gray-600 dark:to-gray-700 rounded w-24 animate-shimmer"></div>
                                                                <div className="h-4 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 dark:from-gray-700 dark:via-gray-600 dark:to-gray-700 rounded-full w-10 animate-shimmer"></div>
                                                            </div>
                                                            <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 dark:from-gray-700 dark:via-gray-600 dark:to-gray-700 rounded w-32 animate-shimmer"></div>
                                                        </div>
                                                        <div className="h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 dark:from-gray-700 dark:via-gray-600 dark:to-gray-700 rounded w-16 animate-shimmer"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : latestLogins && latestLogins.length > 0 ? (
                                    latestLogins.map((login, index) => {
                                        const timeSinceLastActivity = login.lastActivity ? Date.now() - new Date(login.lastActivity).getTime() : Infinity;
                                        const activityStatus = 
                                            timeSinceLastActivity < 10 * 60 * 1000 ? 'active' :
                                            timeSinceLastActivity < 15 * 60 * 1000 ? 'idle' : 'offline';
                                        
                                        return (
                                            <div
                                                key={index} 
                                                onClick={() => navigate(`/dashboard/admin/user/${login.user_id}`)}
                                                className={`relative px-3 py-2.5 border-b last:border-b-0 block cursor-pointer
                                                    ${{
                                                        'active': 'bg-gradient-to-r from-green-50/50 to-transparent dark:from-green-900/10 dark:to-transparent',
                                                        'idle': 'bg-gradient-to-r from-orange-50/50 to-transparent dark:from-orange-900/10 dark:to-transparent',
                                                        'offline': 'bg-white dark:bg-gray-800/50'
                                                    }[activityStatus]} 
                                                    transition-all duration-300 group
                                                    hover:bg-gradient-to-r hover:from-gray-50/80 hover:to-transparent dark:hover:from-gray-800/80 dark:hover:to-transparent
                                                    hover:shadow-md hover:scale-[1.002] focus:outline-none focus:ring-2 focus:ring-green-500/20 dark:focus:ring-green-400/20
                                                    active:scale-[0.998] active:shadow-sm`}
                                            >
                                                <div className="flex items-center gap-3">
                                                    <div className="relative">
                                                        <div className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300
                                                            ${{
                                                                'active': 'bg-gradient-to-br from-green-100 to-green-50 dark:from-green-900/30 dark:to-green-800/10 ring-4 ring-green-50 dark:ring-green-900/10',
                                                                'idle': 'bg-gradient-to-br from-orange-100 to-orange-50 dark:from-orange-900/30 dark:to-orange-800/10 ring-4 ring-orange-50 dark:ring-orange-900/10',
                                                                'offline': 'bg-gradient-to-br from-gray-100 to-gray-50 dark:from-gray-800 dark:to-gray-700 group-hover:from-gray-200 group-hover:to-gray-100 dark:group-hover:from-gray-700 dark:group-hover:to-gray-600'
                                                            }[activityStatus]}`}>
                                                            <Users className={`w-4 h-4 transition-all duration-300 ${{
                                                                'active': 'text-green-600 dark:text-green-400 scale-110',
                                                                'idle': 'text-orange-600 dark:text-orange-400 scale-110',
                                                                'offline': 'text-gray-500 dark:text-gray-400'
                                                            }[activityStatus]}`} />
                                                        </div>
                                                        {(activityStatus === 'active' || activityStatus === 'idle') && (
                                                            <span className="absolute -bottom-0.5 -right-0.5 flex h-2.5 w-2.5">
                                                                <span className={`animate-ping absolute inline-flex h-full w-full rounded-full ${activityStatus === 'active' ? 'bg-green-400' : 'bg-orange-400'} opacity-75`}></span>
                                                                <span className={`relative inline-flex rounded-full h-2.5 w-2.5 ${activityStatus === 'active' ? 'bg-green-500' : 'bg-orange-500'} ring-2 ring-white dark:ring-gray-800`}></span>
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex flex-col">
                                                                <div className="flex items-center gap-2">
                                                                    <span className="text-sm font-medium text-gray-900 dark:text-white truncate group-hover:text-gray-700 dark:group-hover:text-gray-200 transition-colors duration-200">
                                                                        {login.username || 'No activity'}
                                                                    </span>
                                                                </div>
                                                                <span className="text-xs text-gray-500 pt-1 dark:text-gray-400 truncate group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors duration-200">
                                                                    {login.loginAt ? new Date(login.loginAt).toLocaleString('en-US', {
                                                                        month: 'short',
                                                                        day: '2-digit',
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: false
                                                                    }) : ''}
                                                                </span>
                                                                <span className="text-xs text-gray-400 dark:text-gray-500 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ml-1">
                                                                    Click to view profile →
                                                                </span>
                                                            </div>
                                                            <div className="flex items-center gap-1.5 transition-transform duration-200 group-hover:translate-x-1">
                                                                <span className={`w-1.5 h-1.5 rounded-full transition-colors duration-300 ${{
                                                                    'active': 'bg-green-400 group-hover:bg-green-500',
                                                                    'idle': 'bg-orange-400 group-hover:bg-orange-500',
                                                                    'offline': 'bg-red-400 group-hover:bg-red-500'
                                                                }[activityStatus]}`}></span>
                                                                <span className="text-xs text-gray-500 dark:text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors duration-200">
                                                                    {login.lastActivity ? new Date(login.lastActivity).toLocaleString('en-US', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: false
                                                                    }) : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="flex flex-col py-6 px-4 bg-gradient-to-br from-gray-50 to-transparent dark:from-gray-800/30 dark:to-transparent rounded-xl border border-gray-200 dark:border-gray-700/50 transition-all duration-300 hover:border-gray-300 dark:hover:border-gray-600">
                                        <div className="flex items-center">
                                            <div className="relative">
                                                <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-3 ring-4 ring-gray-50 dark:ring-gray-700/20">
                                                    <Users className="w-8 h-8 text-gray-400 dark:text-gray-500" />
                                                </div>
                                                <span className="absolute -bottom-0.5 -right-0.5 block h-2.5 w-2.5 rounded-full bg-gray-400 ring-2 ring-white dark:ring-gray-800"></span>
                                            </div>
                                            <div className="ml-4">
                                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">No Active Users</h3>
                                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                                    Online users will appear here in real-time.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 flex justify-center">
                                            <button
                                                onClick={() => fetchData(true)}
                                                disabled={isRefreshing}
                                                className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200
                                                    ${isRefreshing
                                                        ? 'bg-gray-100 text-gray-400 dark:bg-gray-800 dark:text-gray-500 cursor-not-allowed'
                                                        : 'bg-green-50 text-green-600 hover:bg-green-100 dark:bg-green-900/20 dark:text-green-400 dark:hover:bg-green-900/30 cursor-pointer shadow-sm hover:shadow-md'
                                                    }`}
                                            >
                                                <svg
                                                    className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                                    />
                                                </svg>
                                                {isRefreshing ? 'Checking online users...' : 'Check online users'}
                                            </button>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </DashboardCard>

                        <DashboardCard>
                            <DeletedUsersList deletedUsers={deletedUsers} isLoading={isLoading} />
                        </DashboardCard>
                    </div>

                    <DashboardCard className="mb-4 overflow-hidden">
                        <UserList 
                            users={userList}
                            user={user}
                            upgradeAccountToTakeTests={upgradeAccountToTakeTests}
                            banAccount={banAccount}
                            unBanAccount={unBanAccount}
                        />
                    </DashboardCard>

                    <DashboardCard className="overflow-hidden bg-gradient-to-br from-white to-gray-50/50 dark:from-gray-800 dark:to-gray-800/95 shadow-lg hover:shadow-xl transition-all duration-300">
                        <TicketList
                            tickets={ticketList}
                            replyToTicket={replyToTicket}
                            closeTicket={closeTicket}
                        />
                    </DashboardCard>

                    {showReplyModal && (
                       <ReplyTicketModal
                            showReplyModal={showReplyModal}
                            setShowReplyModal={setShowReplyModal}
                            ticketList={ticketList}
                            currentTicketId={currentTicketId}
                            replyText={replyText}
                            setReplyText={setReplyText}
                            handleReplySubmit={handleReplySubmit}
                            status={ticketList.find(ticket => ticket.id === currentTicketId)?.status}
                        />
                    )}
                </div>
            </div>
        </AppLayout>
    )
}

const Admin = () => {
    return (
        <ForceAuthentication
            requireRole={RoleEnum.Admin}
            accessDeniedTitle="Admin Access Required"
            accessDeniedMessage="You must be an administrator to access this page."
            redirectIfNotAuthenticated="/dashboard">
            {({ user }) => <AdminContent user={user} />}
        </ForceAuthentication>
    );
};

export default Admin;
